.logo{
    text-align: center;
    margin-bottom: 2rem;
  }
.logo svg{
  width: 50%;
}
 .Login{
     display: flex;
     align-items: center;
     justify-content: center;
     min-height: 100vh;
     background-color: var(--black);
     background: url("./images/dark_mode_background.png");
     background-size: cover;
     background-position: center;
     padding: 4rem 0;
 }
  .loginContainer{
    width: 28%;
    min-width: fit-content;
  }
  .loginBox{
    margin: 1rem auto;
    background-color: var(--bg-color);
    box-shadow: var(--shadow);
    padding: 3.5rem 1.66rem 5rem;
    border-radius: 4px;
  }
  .login__header{
    color: var(--white);
    text-align: center;
    padding-bottom: 2rem;
    border-bottom: 2px solid #454545;
    font-size: 1.6rem;
  }
  .login__body{
    margin-top: 1.6rem;
  }
  .login__error{
    color: var(--white);
    text-align: center;
    padding: 0.9rem 1.5rem;
    background-color: var(--light-danger);
    font-size: 0.83rem;
    margin-bottom: 1rem;
  }
  .login__inputControl>div:not(:last-child){
    margin-bottom: 1.2rem;
  }
  .login__label{
    color: var(--white);
    text-transform: capitalize;
    margin-bottom: 0.5rem;
  }
  .login__input{
    display: block;
    width: 100%;
    padding: 0.5rem 1rem;
    color: var(--white);
    background-color: var(--light-black);
    outline: none;
    border: 1px solid transparent;
    border-radius: 4px;
  }
  .login__input:focus{
    border-color: var(--light-sky-blue);
  }
  .login__form{
    padding: 0.83rem 3.33rem;
  }
  .login__forgetPassword{
    text-align: right;
    margin: 1.5rem 0;
  }
  .login__forgetPassword a{
    color: var(--sky-blue);
    font-style: italic;
    text-decoration: none;
    text-transform: capitalize;
  }
  .login__submitControl{
      margin-top: 3rem;
  }
  .login__submitControl input{
    display: block;
    width: 100%;
    background-color: var(--sky-blue);
    color: var(--black);
    padding: 0.65rem;
    border: none;
    font-family: "Montserrat-Medium";
    border-radius: 4px;
    outline: none;
    cursor: pointer;
  }
  .forgetpass {
    margin-top: 2%;
    text-align: right;
  }