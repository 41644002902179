.radio
{
    padding-left: 1.6rem!important;
    padding-right: 1.6rem!important;
}

.modelBox
{
    height: auto!important;
    width: 50rem!important;
}