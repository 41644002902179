.condition-container {
  /* margin: 10px; */
  padding: 15px 8px 8px 20px;
}
.condition-heading {
  font-family: "Montserrat", "Helvetica", sans-serif !important;
  font-weight: 500;
  font-size: 13px;
  /* text-decoration: underline; */
  letter-spacing: 0.004em;
  color: #ffffff;
}
.alarm-table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 98%;
  border: 0.5px solid #333334;
  margin-top: 10px;

}

.alarm-table td,
.alarm-table th {
  border-bottom: 0.5px solid #333334;
  border-top: 0.5px solid #333334;
  text-align: left;
  padding: 8px;
  font-family: "Montserrat", "Helvetica", sans-serif !important;
}

.alarm-table tr:nth-child(even) {
  background-color: #252525;
}
.table-index {
  background: #ed5f53;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  font-size: 12px;
  text-align: center;
  padding-top: 2.5px;
  padding-right: -5px;
  font-family: "Montserrat", "Helvetica", sans-serif !important;
}
.log-icon {
  filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg)
    brightness(102%) contrast(102%);
    width: 15px;
}
.log-icon:hover{
  cursor: pointer;
}
