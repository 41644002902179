.dropdown__container {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.dropdown__group {
    padding: 20px 20px 10px 20px;
    display: flex;
    flex-direction: row;
    gap: 10px;
    width: 100%;
}

.Maintenance__tableContainer {
    padding: 0px 20px;
}

.tableContainer {
    border: 1px solid #3E3E40;
    border-radius: 4px;
    margin: 10px 0px;
    height: calc(100vh - 400px) !important;
    overflow-y: auto;
}

.tableContainer3 {
    border: 1px solid #3E3E40;
    border-radius: 4px;
    margin: 10px 0px;
    height: 97% !important;
    overflow-y: auto;
}

.tableContainer2 {
    border: 1px solid #323233;
    border-radius: 4px;
    margin: 10px 0px;
    width: 96% !important;
    max-height: 160px !important;
    overflow-y: auto;
}

.tableHeader th {
    position: sticky;
    top: 0px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-top: 2px;
    font-family: "Montserrat", "Helvetica", sans-serif;
    border-bottom: 0;
}

table thead tr .head {
    color: white;
    background-color: #1E1E1E;
    font-size: 12px;
    text-align: left;
    font-weight: 600;
}

table tbody tr:nth-of-type(odd) .table-data {
    color: white;
    background-color: #252525;
    font-size: 12px;
    font-weight: lighter;
    font-weight: 400;
    padding: 10px 15px;
    border: none;
    text-align: left;
    font-family: "Montserrat", "Helvetica", sans-serif;
}

table tbody tr:nth-of-type(even) .table-data {
    color: white;
    background-color: #1E1E1E;
    padding: 10px 15px;
    font-size: 12px;
    border: none;
    text-align: left;
    font-weight: lighter;
    font-weight: 400;
    font-family: "Montserrat", "Helvetica", sans-serif;
}

input {
    outline: none;
}

.Maintenance_buttonGroup {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 15px;
}

.MuiOutlinedInput-notchedOutline {
    border: 0px !important;
}

legend {
    border-bottom: 0px !important;
}

.swal-overlay {
    background-color: rgba(0, 0, 0, 0.877);
}

.swal-modal {
    background-color: #1f1f1f;
}

.swal-text,
.swal-title {
    color: #fff;
}

.swal-button {
    padding: 7px 19px;
    border-radius: 2px;
    background-color: #5d97f6 !important;
    font-size: 12px;
    width: 80px;
    height: 36px;
}

.swal-icon--success:before, .swal-icon--success:after, .swal-icon--success__hide-corners{
    background-color: #1f1f1f;
}

.swal-footer {
    display: flex;
    flex-direction: row;
    justify-content: center;
}