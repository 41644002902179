/* All popup css end */

.configureKPI {
  display: flex;
  align-items: center;
  padding: 0.2rem 0.83rem;
  font-family: "Montserrat-Medium";
  font-weight: 400;
  font-size: 1rem;
  color: #5d97f6;
  border-radius: 4px;
  margin-left: 1rem;
}

.configureKPI:hover {
  color: #71aaff;
  /* background: #2a2c2d; */
}

.light .configureKPI:hover {
  color: #296fea;
  /* background-color: #f2f4f6; */
}

.light .configOEE:hover {
  background-color: #fff;
}

.configureKPI:hover .settingIconPath1,
.configureKPI:hover .settingIconPath2 {
  stroke: #82adf3;
}

.light .configureKPI:hover .settingIconPath1,
.light .configureKPI:hover .settingIconPath2 {
  stroke: #185abc;
}

.light .configureKPI {
  color: #4285f4;
}

.light path.plusIcons {
  stroke: #fff;
}

.KPIRadioButtonPanel {
  display: flex;
}

.KPIRadioButtonPanel p {
  padding-right: 2.5rem;
  margin-top: 1rem;
}

.light .KPIRadioButtonPanel label {
  color: #3b414b !important;
}

.KPIRadioButtonPanel label {
  font-family: "Montserrat-Regular";
  font-size: 0.83rem;
  font-weight: 400;
  color: #e0e0e0 !important;
}

.MachineSettingsContent,
.KPIContent {
  padding-top: 1rem;
}

.resetBtn {
  font-size: 0.83rem;
  color: #5d97f6;
}
.light .resetBtn {
  color: #4285f4;
}

.resetpost {
  position: absolute;
  top: 0.83rem;
  right: 0.83rem;
  z-index: 1;
  font-family: "Montserrat-Medium";
}

.pointer {
  cursor: pointer;
}

span.resetBtn.resetpost.pointer:hover {
  color: #71aaff;
}

.light span.resetBtn.resetpost.pointer:hover {
  color: #185abc;
}
/* Pagenation Code Start Here*/

.comanPagination {
  font-family: "Montserrat-Regular";
  font-weight: bold;
  font-size: 0.83rem;
  margin-right: 1.25rem;
}
.comanPagination svg {
  height: 1.33rem;
  width: 1.33rem;
}
#MachineList .comanPagination {
  margin-right: 1.25rem;
}

.light .comanPagination {
  background-color: transparent;
}
.light .longEnough .comanPagination {
  /* background-color: #fff; */
}
.comanPagination .pagination > li > a,
.comanPagination .pagination > li > span {
  color: #58585a;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.5rem 0.83rem;
}

.comanPagination .pagination > li > a:focus,
.comanPagination .pagination > li > a:hover,
.comanPagination .pagination > li > span:focus,
.comanPagination .pagination > li > span:hover {
  color: #e0e0e0;
  background-color: transparent;
  border-color: transparent;
}

.light .comanPagination .staticLi span.active {
  color: #3b414b;
}

.comanPagination .staticLi span.active,
.comanPagination .pagination > li.staticLi > span.active:focus,
.comanPagination .pagination > li.staticLi > span.active:hover {
  color: #e0e0e0;
}

.comanPagination .pagination > li.staticLi > span:focus,
.comanPagination .pagination > li.staticLi > span:hover {
  color: #58585a;
}

.comanPagination .pagination > li.selected svg path {
  stroke: #e0e0e0;
}

.light .comanPagination .pagination > li.selected svg path {
  stroke: #3b414b;
}

.light .MuiAutocomplete-option {
  color: #3b414b !important;
}

span.iconQuestion {
  position: relative;
  top: 0.25rem;
  left: 0.41rem;
}
/* Pagenation Code End Here*/
