.shift_popup {
    position: fixed;
    top: 0;
    left: 0;
    /* z-index: 2; */
    z-index: 3;
    background-color: var(--primary-color);
    width: 100%;
    height: 100%;
    /* padding: 1.5rem; */
    /* display: grid; */
    grid-template-rows: max-content auto;
}

.shift_popup__head {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #313131;
    background:#2d2336;
    padding-left: 2%;
}

.shift_popup_title {
    font-size: 1.33rem;
    color: #e0e0e0;
    width: calc(100% - 0.83rem);
    font-family: "Montserrat-Medium";
}
.shift_close_btn, .shift_close_btn:hover {
    cursor: pointer;
    float: right;
    text-decoration: none;
    margin-right: 1%;
    margin-top: 1%;
    color:#fff;
}
.shftDate {
    height: 2.66rem;
    padding: 0.33rem 1rem;
    font-size: 1rem;
    color: #fff;
    background-color: transparent;
    border-radius: 4px;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    border: 1px solid #5d5e60;
}

.dyntbl .css-rqglhn-MuiTable-root {
    color: #fff;
    background-color: #121212;
}
.dyntbl tr td {
    color: #fff;
    background-color: #121212;
    /* border: 1px solid #e2e2e2; */
    border: 1px solid #403939;
    text-align: center;
}

.dyntbl tr th {
    background-color: #252525;
    color: #fff;
    /* border: 1px solid #e2e2e2; */
    border: 1px solid #403939;
    text-align: center;
    padding: 5px;
}

.dyntbl tr:nth-child(2n) td {
    background: #252525;
}

.dyntbl tr td input[type="text"] {
    width: 50px !important;
}
.shfilter-blk {
    display: flex;
    margin: 1% 1% 0 1%;
}
.shfilter-blk .sh-select {
    margin-right: 1%;
} 
.sht-dateFilter {
    min-height: 38px;
    width: 195px;
    /* background-color: #1f1f1f;
    border-radius: 4px; */
    font-size: 12px;
    margin-right: 1%;
}
.sht-dateFilter input {
  background: transparent;
  width: 100%;
  color:#fff;
  border: none;   
  padding: 0 5px;
  min-height: 34px !important;
  padding: 0px 1.4rem !important;
  margin-top: 15px;
  border: 1px solid #383232 !important;
}
.sh-inputBlock {
    min-height: 38px;

}
.sh-inputBlock input{
    display: block !important;
    outline: none  !important;
    background-color: transparent  !important;
    /* border: 1px solid var(--input-border-color) !important; */
    border: 1px solid #383232 !important;
    /* padding: 0.4rem 0.6rem !important; */
    padding: 0 1.6rem !important;
    color: var(--white) !important;
    border-radius: 0.3rem !important;
    min-height: 34px;
}

.tablescroll{
    width: 100%;
    max-width: 1350px;
    /* height: 32vh; */
    height: 70vh;
    overflow-y: scroll;
}

.shift_report_container {
    display: grid;
    grid-template-rows: max-content auto;
    height: 100%;
}
.shbtnBlk {
    margin: 1%;
    float: right;
}    
.table-content {
    margin: 2% 2% 0% 1%;
}
.sh-noData {
    text-align: center;
    margin-top: 10%;
    font-size: 1.2rem;
    color: #e2e2e2;    
}
.sh-loader {
    text-align: center;
    margin-top: 10%;
}
.shbtnBlk button {  
    background: #5d97f6;
    border-radius: 4px;
    box-shadow: none;
    outline: none;
    border: none;
    color: #000;
    font-family: "Montserrat-Medium";
    font-size: 1rem;
    text-transform: capitalize;
    padding: 1rem 1.5rem;
}