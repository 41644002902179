#heading {
    width: 340px;
    height: 16px;
    /* left: 11.88px;
    top: 15px; */
  
    font-family: "Montserrat", "Helvetica", sans-serif !important;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    /* identical to box height, or 100% */
    /* padding-left: 20px;
    padding-top: 10px;
    padding-bottom: 10px; */
    /* display: flex;
    align-items: center; */
    letter-spacing: 0.004em;
  
    color: #fefcfc;
  }
  .cross-heading {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    /* border: 2px solid red; */
    padding: 15px 15px 10px 20px;
  }