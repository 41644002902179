.ECR__popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 60%;
    min-width: fit-content;
    background-color: var(--primary-color);
    padding: 1rem 2rem 3rem;
    z-index: 3;
}

.ECR__popup>.head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 0;
    margin-bottom: 1.2rem;
}

.ECR__popup>.head .ECR__title {
    font-size: 2rem;
}

.ECR__close {
    cursor: pointer;
    display: block;
}

.ECR__popup>.body {
    display: grid;
    grid-template-rows: max-content auto;
    gap: 1rem;
}

.ECR_Body_content {
    /*     display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 1rem; */
}

.ECR__popup>.body>div {}

.ECR__formula h4 {
    font-weight: 700;
    font-size: 1.5rem;
}

.ECR__formula_list ul {
    list-style-type: none;
}

.ECR__formula_list ul li:not(:last-child) {
    margin-bottom: 1rem;
}

.background_blue {
    background-color: var(--sky-blue);
}

.ECR__date_graph input {
    padding: 0.5rem 2rem;
    border: none;
    outline: none;
    height: 2.5rem;
    border-radius: 0.5rem;
    font-size: 1.2rem;
    width: 100%;
    height: 100%;
}

.ECR__date_graph .react-datepicker-wrapper,
.ECR__date_graph .react-datepicker-wrapper div {
    height: 100%;
}

.ECR__date_graph input:not(:last-child) {
    margin-right: 3rem;
}

.ECR__input_container label {
    display: block;
    font-weight: 400;
    font-size: 1rem;
    white-space: nowrap;
    margin-bottom: 1rem;
}

.ECR__input_container input {
    appearance: none;
    width: 100%;
    height: 3rem;
    border: none;
    outline: none;
    padding: 0 1rem;
    color: #ffffff;
    background-color: transparent;
    border: 1px solid var(--input-border-color);
}

.ECR_fields_container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
    row-gap: 1.5rem;
}

.ECR__submit_btn {
    display: block;
    width: fit-content;
    margin-left: auto;
    background-color: var(--sky-blue);
    padding: 0.8em 1em;
    font-size: 1.3rem;
    border: none;
    border-radius: 0.5rem;
}

.ECR__submit {
    display: flex;
    align-items: flex-end;
    margin-top: 2rem;
}

.ECR__form>div:not(:last-child) {
    margin-bottom: 1.5rem;
}

.ECR__date_graph input[type="date"] {
    text-transform: uppercase;
    height: 100%;
    width: 100%;
}

.ECR__layer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(20 20 20 / 66%);
    z-index: 2;
}

.ECR__date_graph .ECR__grahp_btn {
    color: var(--sky-blue);
    background-color: var(--secondry-color);
    cursor: pointer;
}

.ECR__date_graph input {
    color: #ffffff;
    background-color: transparent;
    border: 1px solid var(--input-border-color);
}

.ECR__filter>.dropdown_rsd {}

.ECR__filter {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
}

.ECR__formula {
    display: none;
}
.ECR_NoFieldFound,.ECR__loader{
    text-align: center;
    border: 1px solid var(--input-border-color);
    padding: 1rem;
}
