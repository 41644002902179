html {
  font-size: 12px !important;
}
@media only screen and (min-width: 1921px) {
.comboDiv .input-group .caret::after,
.borderInput .caret::after{
  content: url('./caret-1920.svg');
}
.MuiAutocomplete-popper .MuiAutocomplete-listbox{
  padding-left: 0rem !important;
}
}
@media only screen and (min-width: 768px) and (max-width: 3840px) {
  .contentWrap .DglobalSearch-mobile,
  .groupTitle,
  .dropdownArrow,
  .pointer.alerts-and-notifications-arrow,
  .pointer.Reports-arrow,
  .pointer.settings-arrow,
  .pointer.admin-arrow,
  .DglobalSearch-mobile.DglobalSearch .searchDiv,
  .rotateIcon,
  .mobile-bell,
  .nav>li.liItem0 {
    display: none;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1600px) {
  .downtime_box_graph {
    height: 50%;
  }
}
@media only screen and (max-width: 3840px) {
  html {
    font-size: 24px !important;
  }
}
@media only screen and (max-width: 3200px) {
  html {
    font-size: 26px !important;
  }
}
@media only screen and (min-width: 3000px) {
.__react_component_tooltip {
  font-size: 0.6rem !important;
}
#custom-input-demo-popup .MuiAutocomplete-option{
  padding-left: 1.5rem;
}
  .widthcardMS .div4MS svg,
  .rightcardKPI .div4KPI svg {
    height: 1.2rem;
    width: 1.2rem;
  }
  .div4MS, .div4KPI {
    font-size: 1rem;
  }
  #KPI .form-group.checkSpoc.onlyKPICheckbox input:checked+label:after {
    top: 0.18rem;
}
}
@media only screen and (max-width: 3000px) {
  html {
    font-size: 24px !important;
  }
}
@media only screen and (max-width: 2560px) {
  html {
    font-size: 22px !important;
  }
}
@media only screen and (min-width: 1919px) {
  .CriticalAlarmML, .MachineStatusML{
    font-size: 1rem;
    width: 60%;
  }
  .notifyTxt, .onTxt{
    width: 36%;
  }
}
@media only screen and (max-width: 1920px) {
  html {
    font-size: 18px !important;
  }
}
@media only screen and (min-width: 1800px) and (max-width: 1920px) {
  .side__Panel {
    max-height: 70vh;
  }
  .cardMachineSetList,
  .cardKpiList {
    height: calc(100vh - 16rem);
  }
  .onOffStatus {
    padding-left: 10%;
  }
}

@media only screen and (max-width: 1600px) {
  html {
    font-size: 13px !important;
  }
}
@media only screen and (max-width: 1440px) {
  html {
    font-size: 12px !important;
  }
}
@media only screen and (max-width: 1023px) {
  .mapDiv.allMachineList.customAlerts, .mapDiv.allMachineList.allNotifications,
  .mapDiv.allMachineList {
    width: 100%;
  }
  .machineStatus {
    left: 0;
  }
}

@media only screen and (min-width: 1280px) and (max-width: 1343px) {
  .cardMachineSetListUL li,
  .cardKpiListUL li {
    width: 23%;
  }
}

@media only screen and (min-width: 1201px) and (max-width: 1280px) {
  .KPIGroupContent .KPIGroupContentBg .col-m6 {
    padding-right: 19%;
  }
  .cardMachineSetList,
  .cardKpiList {
    height: calc(100vh - 16rem);
  }
  #alarms .alarmFilter {
    padding-left: 0;
  }
  .alarmBar.padTopOnly.row.dropRow .alertFilterDiv:nth-child(1) {
    margin-left: 12px;
  }
  .onlyEvent .evntDorp {
    padding-left: 0;
    position: relative;
    right: 20px;
  }
  .onlyEvent .evntDorp .alertFilterDiv:nth-child(2) {
    margin-right: 0;
  }

  .cardKpiListUL li,
  .cardMachineSetListUL li {
    width: 23.3%;
  }
  .KPIGroupContent .col-m6 {
    padding-right: 19%;
  }
  .onOffStatus {
    padding-left: 10%;
  }
  .CriticalAlarmML, .MachineStatusML {
    width: 60%;
  }
  .onTxt {
    width: 30%;
  }
  .notifyTxt{
    width: 35%;
  }
  .longEnough {
    height: calc(100vh - 9.7rem);
  }
  .machineTxt {
    max-width: 232px;
  }
  .machineTitleUl span.svgI-Con {
    margin-right: 0px;
  }
  .text-center.no-parameter-available {
    text-align: center;
  }
  .topDivContent {
    height: 22%;
  }
  .graphDivContent {
    height: 64%;
  }
  .tabContentLast,
  .tabContent {
    height: 8vh;
  }
}

@media only screen and (min-width: 1201px) and (max-width: 1260px) {
  .col-md-4.cardMB14.pointer {
    min-width: 50%;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1200px) {
  #KPI .svgCreate {
    right: 224px;
  }
  .outerSlideTxt {
    font-size: 10px;
    height: auto;
  }
  .KPIContent .KPIGroupContent .col-m6 {
    padding-right: 7vh;
  }
  .alarmBar.padTopOnly.row.dropRow .col-md-9.col-sm-6.col-xs-3 {
    width: 71%;
  }
  .alertDivs .headerTable span:nth-child(4),
  .alertDivs .alarmRows span:nth-child(4) {
    width: 17%;
  }
  .alertDivs .headerTable span:nth-child(5),
  .alertDivs .alarmRows span:nth-child(5) {
    width: 16%;
  }
}
@media only screen and (min-width: 1024px) and (max-width: 1200px) {
  #KPI .svgCreate {
    right: 17rem;
  }
  .down_time_tables .recharts-wrapper {
    height: calc(100% - 200px) !important;
  }

  .customReport.reportContainer.timeSeriesReport {
    height: 1180px;
  }

  .cardKpiListUL li,
  .cardMachineSetListUL li {
    width: 31%;
  }

  .alarmFilter {
    padding-left: 0px;
  }

  .alertDivs .headerTable span:nth-child(3),
  .alertDivs .alarmRows span:nth-child(3) {
    width: 40%;
  }

  .col-md-3.col-sm-6.col-xs-6.evntDorp {
    width: 310px;
    padding-left: 70px;
  }

  /* .onlyCustomAlert .alertFilterDiv,
  .onlyEvent .alertFilterDiv {
    margin-right: 8px;
  } */

  .evntDorp .alertFilterDiv {
    float: left;
    width: 110px;
  }

  #events .headerTable span:nth-child(4) {
    width: 35%;
  }

  #events .headerTable span:nth-child(3) {
    text-align: left;
    width: 37%;
  }

  .DownTimeTable .headerTable span:nth-child(4),
  .DownTimeTable .pannelWrap span:nth-child(4) {
    width: 18%;
  }

  .DownTimeTable .headerTable span:nth-child(5),
  .DownTimeTable .pannelWrap span:nth-child(5) {
    width: 20%;
  }

  .DownTimeTable .headerTable span:nth-child(3),
  .DownTimeTable .pannelWrap span:nth-child(3) {
    width: 16%;
  }

  .col-md-4.cardMB14.pointer {
    margin-bottom: 20px;
    width: 50%;
  }
}

@media only screen and (min-width: 320px) and (max-width: 1024px) {
  /* .widthcardMS {
    flex-direction: column;
  }

  .div4MS{
    align-self: flex-start;
  } */

  ::-webkit-scrollbar {
    display: none;
  }

  #alarms .comanPagination {
    width: 100%;
  }

  #alarms .alarmFilter {
    padding-left: 0px;
  }
  .emailOverview,
  #notifyButton,
  .redimage {
    display: none;
  }

  .configureKPI,
  #alarms .iconTextRight,
  #events .iconTextRight {
    display: none;
  }

  .loadmorebtn button,
  .loadmorebtn button:focus,
  .loadmorebtn button:active {
    margin-bottom: 0px;
  }
}

@media only screen and (min-width: 901px) and (max-width: 1024px) {
  .evntDorp .alertFilterDiv {
    width: 116px;
    margin-right: 20px;
  }

  .eventTopBar.onlyEvent.noboderDrop .evntDorp {
    /* width: 33%;
    padding-left: 0px; */
    margin-right: 0;
  }

  .alarmBar .col-md-9.col-sm-6.col-xs-3 {
    width: 68%;
  }

  #alarms .alertFilterDiv:first-child {
    margin-left: 0;
  }

  .repeatedCls.extraClas {
    width: 32.5%;
  }

  .repeatedCls:nth-child(4n) {
    margin-right: 1%;
    margin-bottom: 0;
  }

  .repeatedCls:nth-child(3n) {
    margin-right: 0;
    margin-bottom: 0;
  }

  .deleteSVGCustom {
    left: 33px;
  }

  .editSVGCustom {
    left: 15px;
  }

  .AllNotificationTable .headerTable span:nth-child(1),
  .AllNotificationTable .pannelWrap span:nth-child(1) {
    width: 28%;
  }

  .AllNotificationTable .headerTable span:nth-child(5),
  .AllNotificationTable .pannelWrap span:nth-child(5) {
    width: 11%;
  }

  #alarms .headerTable,
  #alarms .overflowScrolls {
    width: 100%;
  }

  #alarms .pannelWrap span:nth-child(6) {
    text-align: center;
    padding-right: 5px;
  }

  #MachineList .expandMapWidth .machinesLists {
    padding-left: 40px;
  }

  #MachineList .machinesLists {
    padding-left: 0;
  }

  .light
    #allMachineList
    .CustomNotificationTable
    .heightNoRecord.noHistoryAlarms {
    background: #fff;
    width: 1000px;
  }

  #overview .col-md-6.col-sm-12.col-xs-12.heightper100.mobileView2 {
    width: 60%;
  }

  #overview .col-md-6.col-sm-12.col-xs-12.mobileView1 {
    width: 40%;
  }

  #overview .col-md-6.col-sm-12.col-xs-12.mobileView1 img {
    max-height: 230px !important;
  }

  #overview .expandMapWidth .col-md-6.col-sm-12.col-xs-12.mobileView1 img {
    max-height: 300px !important;
  }

  .mapDiv.down_time_tables.allMachineList
    .eventDivs.AllNotificationTable.DownTimeTable {
    overflow-x: scroll;
  }

  .DownTimeTable .DowntimeOverflowScroll {
    height: calc(100vh - 259px);
  }

  .pannelWrap.DowntimeOverflowScroll,
  .mapDiv.down_time_tables.allMachineList .comanPagination {
    width: 1000px;
  }

  /* #admin {
    visibility: hidden;
  } */

  #KPI .KPIGroupContent .col-m6 {
    padding-right: 25%;
  }

  #events .noHistoryEvents {
    width: 100%;
  }

  .onlyCustomReport.IconBeta {
    top: 5px;
    right: 0px;
    z-index: 9;
    cursor: pointer;
    position: relative;
  }

  .aggregateReport.reportContainer {
    width: 100%;
    overflow-x: scroll;
  }

  .customReport.reportContainer .report-style-class {
    width: 1400px;
  }

  .customReport.reportContainer {
    overflow-x: scroll;
    height: 595px;
  }

  .customReport.reportContainer.timeSeriesReport .report-style-class {
    width: 1300px;
  }

  .customReport.reportContainer.timeSeriesReport {
    overflow-x: scroll;
    height: 595px;
    overflow-y: hidden;
  }

  .aggregate-report-style-class {
    width: 1200px;
    height: 84vh;
  }

  .alarmAnalysis .aggregate-report-style-class {
    width: 1490px;
  }

  .assetHealthDiagnostic .aggregate-report-style-class {
    width: 1224px;
  }

  .supportDivContent {
    height: 45%;
  }

  .evntDorp .alertFilterDiv:last-child {
    margin-right: 0;
  }

  .col-md-8.col-sm-6.col-xs-4.historyEvent {
    width: 52%;
  }

  .col-md-3.col-sm-6.col-xs-6.evntDorp {
    width: 48%;
    padding-left: 170px;
  }

  .col-md-1.col-xs-2.padRights {
    display: none;
  }

  #alarms .col-md-9.col-sm-6.col-xs-12 {
    width: 70%;
  }

  #alarms .col-md-3.col-sm-6.col-xs-12 {
    width: 30%;
  }

  .downtime_box_graph {
    height: 50%;
  }

  .left_graph,
  .right_graph {
    height: calc(63vh - 228px);
  }

  .left_graph {
    width: 49%;
  }

  .oee__factors .recharts-responsive-container {
    max-height: 210px;
  }

  .downtime__content .box_graph {
    height: 254px;
  }

  .trends__content .box_graph {
    height: 213px;
  }

  .downtime__content .recharts-responsive-container {
    max-height: 240px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .top__buttonsOEE .box,
  .top__buttonsOEE .box svg {
    width: 162px;
  }

  #allMachineList .headerTable,
  #allMachineList .eventDivs.AllNotificationTable .headerTable,
  #allMachineList .eventDivs.CustomNotificationTable .headerTable {
    width: 100%;
  }

  #allMachineList .eventDivs.AllNotificationTable .scrollTab,
  #allMachineList .eventDivs.CustomNotificationTable,
  .hcm_overview_table,
  .tripping_analysis_table,
  .downtimeanalysis_table {
    overflow-x: scroll;
  }

  .hcm_overview_table #pannelWrap,
  .taheaderTable,
  .tripping_analysis_table .eventOverflowScroll,
  .downtimeheaderTable,
  .downtimeanalysis_table .eventOverflowScroll,
  .HCMheaderTable {
    min-width: 1040px;
    width: 100%;
  }

  .hcm_overview_table .eventOverflowScroll,
  .downtimeanalysis_table .eventOverflowScroll,
  .tripping_analysis_table .eventOverflowScroll {
    height: calc(100vh - 211px);
  }

  .AllNotificationTable .headerTable span:nth-child(2),
  .AllNotificationTable .pannelWrap span:nth-child(2) {
    width: 30%;
  }

  .alertContent {
    height: 80vh;
  }

  .tabContentLast {
    padding-left: 2%;
  }

  .tabContentLast,
  .tabContent {
    height: 6vh;
  }

  .topDivContent {
    height: 12%;
  }

  .supportDivContent.col-sm-3 {
    width: 49%;
    margin-bottom: 20px;
  }

  .supportDivContent {
    margin-right: 0%;
  }

  .supportDivContent:nth-child(2n) {
    margin-left: 2%;
  }

  .supDivTxt {
    padding-left: 15px;
  }

  #live-batch .graphDivContent {
    height: 80vh;
    overflow-y: scroll;
  }

  .alertDivs {
    height: calc(100vh - 26.67rem);
    overflow-x: scroll;
  }

  .headerTable {
    width: 1000px;
  }
  .AllCallHistoryInner .headerTable {
    width: 100%;
  }
  .history-search .col-md-7.MachineSettingsGroupContent {
    width: 380px;
}
  .contctTab.remote_assistance_tables .headerTable span.contactrow1:nth-child(1), .contctTab.remote_assistance_tables .pannelWrap span.contactrow1:nth-child(1) {
    width: 30%;
}
.contctTab.remote_assistance_tables .headerTable span.contactrow8:nth-child(6), .contctTab.remote_assistance_tables .pannelWrap span.contactrow8:nth-child(6) {
  width: 25%;
}
.contactScrollHeight {
  height: 418px;
}
.remote_assistance_tables .AllCallHistory .headerTable span:nth-child(7), .remote_assistance_tables .AllCallHistory .pannelWrap span:nth-child(7) {
  width: 20%;
}
.remote_assistance_tables .AllCallHistory .headerTable span:nth-child(4), .remote_assistance_tables .AllCallHistory .pannelWrap span:nth-child(4) {
  width: 17%;
}
.alertnotifylog .alertDivs {
    height: calc(100vh - 17rem);
}
.alertnotifylog .alert-notifications .alert-notification-table,
.execution-result .alertDivs,
.notification-template .alertDivs,
.notification-group .alertDivs{
  height: calc(100vh - 18rem);
}
  #events .headerTable,
  #events .eventOverflowScroll {
    width: 100%;
  }

  #allMachineList .eventOverflowScroll {
    height: calc(100vh - 201px);
    background: #1f1f1f;
    width: 100%;
  }
 #FailureModeListing .failuremodulePage .w-100 {
    width: calc(100% - 40rem);
}
  .overflowScrolls {
    height: calc(100vh - 415px);
    width: 1000px;
  }
  .cm-dashboard.overflowScrolls {
    height: calc(100vh - 7rem);
}
  .supportDivContent {
    height: auto;
  }

  .prodTime,
  .prodRate {
    margin-top: 4vh;
  }

  .KPIGroupContent .col-m6 {
    padding-right: 3%;
  }

  .KPIRadioButtonPanel p {
    padding-right: 10px;
    margin-top: 18px;
  }

  .DownTimeTable .headerTable span:nth-child(2),
  .DownTimeTable .pannelWrap span:nth-child(2),
  .CustomNotificationTable .headerTable span:nth-child(5),
  .CustomNotificationTable .pannelWrap span:nth-child(5) {
    width: 17%;
  }

  .DownTimeTable .headerTable span:nth-child(1),
  .DownTimeTable .pannelWrap span:nth-child(1) {
    width: 7%;
  }

  .KPILimitTable .headerTable span:nth-child(2),
  .KPIRows span:nth-child(2).row1 {
    width: 21%;
  }

  .KPILimitTable .headerTable span:nth-child(1),
  .KPIRows span:nth-child(1).row0 {
    width: 5%;
  }

  .cardKpiListUL li,
  .cardMachineSetListUL li {
    width: 31%;
  }

  .cardRowLR .col-sm-6 {
    width: 50%;
  }

  .alarmFilter {
    padding-left: 0;
  }

  .alertDivs .headerTable span:nth-child(3),
  .alertDivs .alarmRows span:nth-child(3) {
    width: 40%;
  }

  .OnlyAlarmTxt .alertDivs .headerTable span:nth-child(3),
  .OnlyAlarmTxt .alertDivs .alarmRows span:nth-child(3) {
    width: 30%;
  }

  .CustomNotificationTable .headerTable span:nth-child(3),
  .CustomNotificationTable .pannelWrap span:nth-child(3),
  .alertDivs .headerTable span:nth-child(4),
  .alertDivs .alarmRows span:nth-child(4),
  .alertDivs .headerTable span:nth-child(6),
  .alertDivs .alarmRows span:nth-child(6) {
    width: 15%;
  }

  .OnlyAlarmTxt .alertDivs .headerTable span:nth-child(4),
  .OnlyAlarmTxt .alertDivs .alarmRows span:nth-child(4),
  .DownTimeTable .headerTable span:nth-child(6),
  .DownTimeTable .pannelWrap span:nth-child(6) {
    width: 18%;
  }

  .alertDivs .headerTable span:nth-child(5),
  .alertDivs .alarmRows span:nth-child(5),
  .OnlyAlarmTxt .alertDivs .headerTable span:nth-child(6),
  .OnlyAlarmTxt .alertDivs .alarmRows span:nth-child(6) {
    width: 13%;
  }

  .CustomNotificationTable .headerTable span:nth-child(2),
  .CustomNotificationTable .pannelWrap span:nth-child(2),
  .OnlyAlarmTxt .alertDivs .headerTable span:nth-child(5),
  .OnlyAlarmTxt .alertDivs .alarmRows span:nth-child(5),
  .DownTimeTable .headerTable span:nth-child(4),
  .DownTimeTable .pannelWrap span:nth-child(4),
  .DownTimeTable .headerTable span:nth-child(5),
  .DownTimeTable .pannelWrap span:nth-child(5) {
    width: 20%;
  }

  .alertDivs .headerTable span:nth-child(7),
  .alertDivs .alarmRows span:nth-child(7) {
    width: 10%;
  }

  .alertDivs .headerTable span:nth-child(8),
  .alertDivs .alarmRows span:nth-child(8) {
    width: 1%;
  }

  #allMachineList .heightNoRecord.noHistoryAlarms {
    height: calc(100vh - 171px);
  }

  .CustomNotificationTable .headerTable span:nth-child(1),
  .CustomNotificationTable .pannelWrap span:nth-child(1) {
    width: 24%;
  }

  .CustomNotificationTable .headerTable span:nth-child(4),
  .CustomNotificationTable .pannelWrap span:nth-child(4) {
    width: 22%;
  }

  .headerTable span {
    font-size: 10px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  html {
    font-size: 12px !important;
}
  .DglobalSearch .searchDiv .MuiAutocomplete-input {
    width: 20rem;
  }
  .iconOffLineMachine svg {
    width:auto;
  }
  .drop-history > div:not(:last-child) {
    margin-right: 0rem;
  }
  .lastDays {
    bottom: 24px;
  }
  #machine-settings .svgCreate {
    right: 10px;
  }
  .onOffStatus {
    padding-left: 0%;
  }
  .loadmorebtn {
    margin-bottom: 15px;
  }

  .cardMachineSetListUL li,
  .cardKpiListUL li {
    width: 46%;
  }

  .cardMachineSetList,
  .cardKpiList {
    height: calc(100vh - 241px);
  }

  .lastDays {
    position: absolute;
  }

  .loginDropdown .alertContentUl li {
    display: block;
  }

  .copyright {
    right: 0;
    left: 0;
    text-align: center;
    font-family: "Montserrat-Regular";
  }

  .loginFormBg {
    max-width: 320px;
    padding: 35px 15px;
  }

  .hr {
    width: 100%;
  }

  .pad20 {
    padding: 10px 15px;
  }

  .searchDiv,
  .leftMenu {
    display: block;
  }
  .leftMenu.expandWidth {
    width: 30%;
  }
  .loginDropdown li {
    display: inline-block;
  }
  li.dropdown.liItem2 {
    margin-top: 0.3rem;
}

  .tabsName li {
    margin-right: 10px;
  }

  .wrapper {
    width: 100%;
  }

  .alarmWrapper {
    width: 100%;
    padding-left: 24px;
    padding-right: 0px;
  }

  .machineName {
    bottom: -10px;
  }

  .loginDropdown > li.liItem1 > a {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .mapDiv {
    margin-left: 0;
  }

  .cardMachine {
    margin-bottom: 15px;
  }

  .mapDiv.allMachineList {
    overflow-y: scroll;
  }

  .footer {
    justify-content: center;
  }

  .filterDiv {
    /* height: calc(100vh - 140px); */
    z-index: 12;
    position: absolute;
    width: 17.75rem;
  }
  header {
    background: transparent;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
  }

  .create_Machines .contentPopup {
    height: auto;
    margin-bottom: 20px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1023px) {
  .expandMapWidth .BarStatus {
    margin-left: 36px;
  }
  .listMachine {
    width: 360px;
  }
  .mapDiv.allMachineList {
    margin-left: 0;
  }
  .cardMachine {
    margin-bottom: 0;
  }
  .outerSlideTxt {
    font-size: 10px;
    height: auto;
  }
  .alarmBar.padTopOnly.row.dropRow .col-md-3.col-sm-6.col-xs-9 {
    width: 32%;
  }
}

@media only screen and (min-width: 901px) and (max-width: 992px) {
  .col-md-2.SideTabGroup {
    width: 190px;
    float: left;
  }
  .MachineSettingsGroupContent,
  .KPIGroupContent {
    float: right;
    width: calc(100% - 190px);
  }

  #KPI .KPIGroupContent .col-m6 {
    padding-right: 16%;
  }

  .KPIGroupContent .MuiAutocomplete-input {
    width: 350px;
  }

  .descriptionCreate .row .col-md-4 {
    width: 33.33333333%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 900px) {
  .wrapicon_text .icon_text {
    margin-right: 0.5rem;
    padding: 5px 0.5rem;
}
  .col-md-6.col-sm-12.col-xs-12.heightper100.mobileView2,
  .col-md-6.col-sm-12.col-xs-12.mobileView1,
  .cm-reports.eventOverflowScroll{
    width: 100%;
  }
  .cm-reports .row .dashboard-card {
    width: 50%;
}
  .AllCallHistoryTop {
    overflow-x: scroll;
}
  .AllCallHistoryInner {
    width: 1000px;
}
.searchAssistance .MachineSettingsGroupContent .MuiAutocomplete-input{
  width: 340px;
}
.groupbtns.remoteAssistancebtn {
  width: 275px;
}
.remote_assistance_tables .AllCallHistory .headerTable span:nth-child(7), .remote_assistance_tables .AllCallHistory .pannelWrap span:nth-child(7) {
  width: 20%;
}
.remote_assistance_tables .AllCallHistory .headerTable span:nth-child(2), .remote_assistance_tables .AllCallHistory .pannelWrap span:nth-child(2) {
  width: 17%;
}
.history-search .col-md-7.MachineSettingsGroupContent {
    float: left;
    width: calc(100% - 375px);
}
.remote_assistance .history-search .col-md-5.MachineSettingsGroupContent {
  float: left;
  width: 350px;
  padding-left: 10px !important;
}
.col-md-5.MachineSettingsGroupContent .MuiAutocomplete-input{
width: 350px;
}
  .contctTab.remote_assistance_tables .headerTable span.contactrow5:nth-child(3), .contctTab.remote_assistance_tables .pannelWrap span.contactrow5:nth-child(3) {
    width: 16%;
}
.contctTab.remote_assistance_tables .headerTable span.contactrow6:nth-child(4), .contctTab.remote_assistance_tables .pannelWrap span.contactrow6:nth-child(4) {
  width: 17%;
}
  .remote_assistance_tables.contctTab .headerTable{
width: 100%;
  }
  .contctTab.remote_assistance_tables .headerTable span.contactrow1:nth-child(1), .contctTab.remote_assistance_tables .pannelWrap span.contactrow1:nth-child(1) {
    width: 24%;
}
.contctTab.remote_assistance_tables .headerTable span.contactrow8:nth-child(6), .contctTab.remote_assistance_tables .pannelWrap span.contactrow8:nth-child(6) {
  width: 27%;
}
.contctTab.remote_assistance_tables .pannelWrap span.contactrow7:nth-child(5) {
  padding: 2.2rem 0rem;
}
  .col-md-6.MachineSettingsGroupContent.remoteSearch {
    float: left;
    width: calc(100% - 300px);
}
.remote_assistance_tables .RemoteSsistanceScroll.contactScrollHeight{
  height: 666px;
}
  .failuremodulePage.conditionMonitoring .input-group.pull-right.search-container .form-control {
    width: 24rem;
  }
  #FailureModeListing .failuremodulePage .w-100 {
    width: calc(100% - 24rem);
}
 #FailureModeListing .failuremodulePage .input-group.search-container .form-control {
    width: 23rem;
}
  #FailureCondition .alertDivs .headerTable span:nth-child(7), 
  #FailureCondition .alertDivs .alarmRows span:nth-child(7),
  #MaintenanceCondition .alertDivs .headerTable span:nth-child(7), 
  #MaintenanceCondition .alertDivs .alarmRows span:nth-child(7),
  .gd-tabledata .alertDivs .headerTable span:nth-child(7), 
  .gd-tabledata .alertDivs .alarmRows span:nth-child(7) {
    width: 5%;
}
.exresult .alertDivs,
.alertnotifylog .alert-notifications .alertDivsBg {
  height: calc(100vh - 18rem);
}
  .cm-filter .filterDiv {
    width: auto;
}
.cm-dashboard .chart-scroll .row [class*="col-"] {
  width: 100%;
}
  .marqueeSn {
    max-width: 10rem;
}
.expandMapWidth .machineIconHolder {
  margin-left: 0rem;
}
  .evntDorp .alertFilterDiv:first-child {
    margin-right: 20px;
  }
  #overview .mapDiv.allMachineList {
    overflow-y: scroll;
  }
  .text-center.no-parameter-available {
    width: 100%;
  }
  .col-md-8.col-sm-6.col-xs-3.historyEvent {
    width: 50%;
  }
  #alarms .alarmFilter {
    padding-left: 30px;
  }
  .tabArea .mobileTabScroll .tabsName li.active a,
  .tabArea .mobileTabScroll .tabsName li a {
    margin: 12px 16px;
  }

  .repeatedCls.extraClas {
    width: 49%;
  }

  #alarms .pannelWrap span:nth-child(6) {
    text-align: center;
    padding-right: 5px;
  }

  .light #events .noHistoryEvents {
    background-color: #fff;
  }

  .slick-carousel.slickmarque.alarmData {
    overflow-x: scroll;
  }

  .slick-slider.alarmSlider.slick-initialized {
    min-width: 1220px;
  }

  #allMachineList .eventOverflowScroll {
    height: calc(100vh - 211px);
  }

  .CustomNotificationTable .comanPagination {
    width: 1000px;
  }

  /* #admin {
    visibility: hidden;
  } */

  .onlyCustomReport.IconBeta {
    top: 5px;
    right: -5px;
    z-index: 9;
    cursor: pointer;
    position: relative;
  }

  .customReport.reportContainer.timeSeriesReport .report-style-class {
    width: 1500px;
  }

  .customReport.reportContainer.timeSeriesReport {
    height: 860px;
    overflow-x: scroll;
    overflow-y: hidden;
  }

  .aggregateReport.reportContainer {
    width: 100%;
    overflow-x: scroll;
  }

  .aggregate-report-style-class {
    width: 1550px;
    height: 88vh;
  }

  .alarmAnalysis .aggregate-report-style-class {
    width: 2035px;
  }

  .assetHealthDiagnostic .aggregate-report-style-class {
    width: 1680px;
  }

  .create_Machines .form-group {
    width: 400px;
  }

  .create_Machines .alertCheckbox .form-group,
  .create_Machines .customchecks .form-group {
    width: auto;
  }

  .alarmSettingHolder.col-md-6.col-xs-6 {
    display: none;
  }

  .topDivContent {
    overflow-x: scroll;
    overflow-y: hidden;
  }

  .innerDivContent {
    width: 1000px;
  }

  .create_Machines .form-group {
    width: 100%;
  }

  .create_Machines .alertCheckbox .form-group,
  .create_Machines .customchecks .form-group {
    width: auto;
  }

  .left_graph,
  .right_graph {
    width: 100%;
    margin-left: 0px;
    margin-bottom: 15px;
    height: 340px;
    margin-top: 0px;
  }

  .flexbottom {
    margin-top: 0px;
  }

  .alertDivs .noHistoryAlarms {
    width: 1000px;
    padding-top: 0%;
  }

  .ThreeDViewTop {
    padding-left: 40px;
    padding-top: 4px;
  }

  .downtime_box_graph {
    height: 37vh;
  }

  .hcm_overview_table .HCMheaderTable .left_section span:nth-child(1),
  .hcm_overview_table .pannelWrap span:nth-child(1) {
    width: 110px;
  }

  .left_section {
    width: 480px;
  }

  .right_section {
    width: calc(100% - 480px);
  }

  .noActiveAlarms {
    padding-top: 0;
  }

  .barchart__oet .recharts-responsive-container,
  .barchart__aut .recharts-responsive-container {
    max-height: 230px;
  }

  .barchart__performance .recharts-responsive-container,
  .barchart__quality .recharts-responsive-container {
    max-height: 300px;
  }

  .noActiveAlarms,
  .barchart__oet .recharts-responsive-container,
  .barchart__aut .recharts-responsive-container,
  .barchart__performance .recharts-responsive-container,
  .barchart__quality .recharts-responsive-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .hcm_trendsDiv .box_graph {
    height: 340px;
  }

  .col-md-3.col-sm-6.col-xs-6.evntDorp {
    padding-left: 6%;
  }

  .EventTabDivs .eventOverflowScroll {
    height: calc(100vh - 301px);
  }

  .EventTabDivs {
    overflow: scroll;
  }

  .eventDivs.AllNotificationTable .headerTable span:nth-child(2),
  .eventDivs.AllNotificationTable .pannelWrap span:nth-child(2) {
    width: 38%;
  }

  .eventDivs.AllNotificationTable .headerTable,
  .eventDivs.AllNotificationTable .eventOverflowScroll,
  .eventDivs.AllNotificationTable .pannelWrap.overflowScrolls {
    width: 100%;
  }

  .AllNotificationTable .headerTable span:nth-child(1),
  .AllNotificationTable .pannelWrap span:nth-child(1) {
    width: 22%;
  }

  #allMachineList .CustomNotificationTable .headerTable {
    width: 1000px;
  }

  .eventDivs.CustomNotificationTable {
    overflow: scroll;
  }

  .col-md-1.col-xs-2.padRights {
    display: none;
  }

  .downtime__content .box_graph {
    height: 375px;
  }

  .downtime__content .recharts-responsive-container {
    max-height: 335px;
  }

  .downtime__content .barchart__line,
  .downtime__content .dmOccurence {
    height: 32vh;
  }

  #allMachineList .DownTimeTable .heightNoRecord.noHistoryAlarms {
    width: 1000px;
    height: calc(100vh - 207px);
  }

  .DownTimeTable .DowntimeOverflowScroll {
    height: calc(100vh - 251px);
  }

  .eventDivs.AllNotificationTable.DownTimeTable {
    overflow-x: scroll;
  }

  .mapDiv.down_time_tables.allMachineList {
    height: calc(100vh - 121px);
  }

  .DownTimeTable .DowntimeOverflowScroll,
  #allMachineList .DownTimeTable .headerTable {
    width: 1000px;
  }

  .AllNotificationTable.DownTimeTable .headerTable span:nth-child(1),
  .AllNotificationTable.DownTimeTable .pannelWrap span:nth-child(1) {
    width: 10%;
  }

  .eventDivs.AllNotificationTable.DownTimeTable .headerTable span:nth-child(2),
  .eventDivs.AllNotificationTable.DownTimeTable .pannelWrap span:nth-child(2) {
    width: 18%;
  }

  .top__buttonsOEE .box,
  .top__buttonsOEE .box svg {
    width: 154px;
  }

  .flextop {
    height: auto;
  }

  .oee__factors.heightOEE {
    width: 100%;
    overflow-y: scroll;
  }

  #allMachineList .headerTable {
    width: 100%;
  }

  .hcm_overview_table,
  .tripping_analysis_table,
  .downtimeanalysis_table {
    overflow-x: scroll;
  }

  .taheaderTable,
  .tripping_analysis_table .eventOverflowScroll {
    min-width: 1040px;
    width: 100%;
  }

  .downtimeanalysis_table .eventOverflowScroll,
  .hcm_overview_table #pannelWrap,
  .downtimeheaderTable,
  .HCMheaderTable {
    min-width: 1080px;
    width: 100%;
  }

  .downtimeanalysis_table .downtimeheaderTable span:nth-child(1),
  .downtimeanalysis_table .pannelWrap span:nth-child(1) {
    width: 132px;
  }

  .hcm_overview_table .eventOverflowScroll,
  .downtimeanalysis_table .eventOverflowScroll,
  .tripping_analysis_table .eventOverflowScroll {
    height: calc(100vh - 211px);
  }

  .AllNotificationTable .headerTable span:nth-child(2),
  .AllNotificationTable .pannelWrap span:nth-child(2) {
    width: 70%;
  }

  .loginDropdown .liItem2.open .dropdown-menu:after {
    border-bottom: 14px solid #232323;
  }

  .loginDropdown ul {
    background: #232323;
  }

  .headerTable,
  .eventOverflowScroll,
  .pannelWrap.overflowScrolls {
    width: 1000px;
  }

  .eventDivs.AllNotificationTable .headerTable {
    width: 100%;
  }

  .prodTime,
  .prodRate {
    margin-top: 3vh;
  }

  .alertDivs {
    overflow-x: scroll;
  }

  .pannelWrap.overflowScrolls {
    height: calc(100vh - 421px);
  }

  .col-md-4.cardMB14.pointer {
    margin-bottom: 5px;
  }

  .machineTxt {
    max-width: 245px;
  }

  .imageMachine.heightper100 {
    min-height: 300px;
  }

  ul.button_clsul.margtop10 {
    margin-left: 30px;
  }

  .heightper100 {
    height: auto;
  }

  .machineOfflineTagDiv {
    position: fixed;
    left: 40%;
    z-index: 8;
    margin-top: -24px;
    display: none;
  }

  .headerTable span {
    font-size: 10px;
  }

  .pannelWrap span {
    font-size: 10px;
  }

  .confirmPin .form-group {
    float: left;
    width: 100%;
  }

  /* .tabContentLast {
    margin-top: 2.5vh;
    padding-left: 8px;
  } */

  #live-batch .tabContent {
    width: 19%;
  }

  #live-batch .tabContent:nth-child(3) {
    width: 14%;
  }

  #live-batch .tabContent:nth-child(5) {
    width: 24%;
  }

  #live-batch .graphDivContent {
    height: 78%;
  }

  .topDivContent {
    height: 13%;
  }

  .alarmSetting .form-group {
    margin-bottom: 15px;
    float: left;
  }

  .modelboxopenInnner,
  .confirmPin .graphboxopenInnner,
  .alarmSetting .modelboxopenInnner {
    width: 100%;
    height: auto;
  }

  .supportDivContent.col-sm-3 {
    width: 49%;
    padding-right: 10px;
    padding-left: 10px;
    margin-right: 0px;
  }

  .supportDivContent.col-sm-3:nth-child(2),
  .supportDivContent.col-sm-3:nth-child(4) {
    margin-right: 0px;
    margin-left: 2%;
  }

  #live-batch .BarStatus {
    padding-left: 5px;
  }

  .supportDivContent {
    height: 340px;
    margin-bottom: 12px;
  }

  .alertDivs .headerTable span:nth-child(3),
  .alertDivs .alarmRows span:nth-child(3) {
    width: 29%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .alertDivs .headerTable span:nth-child(4),
  .alertDivs .alarmRows span:nth-child(4),
  .alertDivs .headerTable span:nth-child(5),
  .alertDivs .alarmRows span:nth-child(5) {
    width: 18%;
  }

  .alertDivs .headerTable span:nth-child(6),
  .alertDivs .alarmRows span:nth-child(6) {
    width: 14%;
  }

  .alertDivs .headerTable span:nth-child(7),
  .alertDivs .alarmRows span:nth-child(7) {
    width: 10%;
  }

  .innerTooltip {
    left: 34px;
  }

  #KPI .KPIGroupContent .col-m6 {
    padding-right: 2%;
  }

  #KPI .cardMachineSetList,
  .cardKpiList {
    padding-top: 0;
  }

  #KPI .KPIRadioButtonPanel p {
    padding-right: 30px;
    margin-top: 8px;
  }

  #KPI .svgCreate {
    right: 40px;
    top: 16px;
  }

  #KPI .configureKPI {
    right: 110px;
    top: 40px;
  }

  .col-md-4.mobclsalign {
    width: 100%;
    float: left;
    display: flex;
    padding-left: 20px;
  }

  .KPIGroupContent .MuiAutocomplete-input,
  .KPIGroupContent .Mui-focused input,
  .MachineSettingsGroupContent .MuiAutocomplete-input,
  .MachineSettingsGroupContent .Mui-focused input,
  .configure .Mui-focused input,
  .configure .MuiAutocomplete-input {
    width: 200px;
  }

  .MachineSettingsGroupContent,
  .KPIGroupContent {
    float: right;
    width: calc(100% - 190px);
  }

  .col-md-2.SideTabGroup {
    width: 190px;
    float: left;
  }

  .alertContent {
    height: 84vh;
  }

  .alarmWrapper {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  }

  #overview .filterDiv.removedWidth {
    display: none;
  }

  #overview .filterDiv {
    display: block;
  }

  #overview .filterDiv.removedWidthh.MfilterDiv {
    display: block;
    width: 213px;
    padding: 20px 10px;
  }

  .tabsName li {
    margin-right: 0;
  }

  #overview .filterDiv {
    border-radius: 4px;
    height: calc(100vh - 13.33rem);
  }

  #loginDropdown .navbar-nav {
    margin: 7.5px -15px;
  }

  li.dropdown.liItem1 {
    margin-top: 5px;
  }

  #MachineList .mapDiv.allMachineList {
    overflow-y: hidden;
  }

  .statusCardHolder .row {
    height: auto;
  }

  .BarStatus {
    padding-top: 15px;
  }

  .machinesLists {
    padding-left: 40px;
  }

  .mapLayer {
    height: 87vh;
  }

  .machineTxt {
    max-width: 240px;
  }

  #loginDropdown .navbar-nav {
    margin: 7.5px -15px;
  }

  li.dropdown.liItem1 {
    margin-top: 5px;
  }

  .alarmSettingHolder.col-md-6.col-xs-6 {
    display: none;
  }

  .left_graph,
  .right_graph {
    width: 100%;
    height: 340px;
    margin-left: 0;
    margin-bottom: 15px;
    margin-top: 0;
  }

  .flexbottom {
    margin-top: 0;
  }

  .light .alertDivs .noHistoryAlarms {
    background: #fff;
  }

  .alertDivs .noHistoryAlarms {
    width: 1000px;
    padding-top: 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 40%;
  }
  .CustomNotificationTable .heightNoRecord.noHistoryAlarms,
  .EventTabDivs .noHistoryEvents {
    width: 1000px;
    justify-content: flex-start;
    padding-left: 40%;
  }
  .ThreeDViewTop {
    padding-left: 40px;
    padding-top: 4px;
  }

  #allMachineList .ThreeDViewTop {
    padding-top: 0;
  }

  .downtime_box_graph {
    height: 37vh;
  }

  .hcm_overview_table .HCMheaderTable .left_section span:nth-child(1),
  .hcm_overview_table .pannelWrap span:nth-child(1) {
    width: 110px;
  }

  .left_section {
    width: 480px;
  }

  .right_section {
    width: calc(100% - 480px);
  }

  .noActiveAlarms {
    padding-top: 0;
  }

  .barchart__oet .recharts-responsive-container,
  .barchart__aut .recharts-responsive-container {
    max-height: 230px;
  }

  .barchart__performance .recharts-responsive-container,
  .barchart__quality .recharts-responsive-container {
    max-height: 300px;
  }

  .noActiveAlarms,
  .barchart__oet .recharts-responsive-container,
  .barchart__aut .recharts-responsive-container,
  .barchart__performance .recharts-responsive-container,
  .barchart__quality .recharts-responsive-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .hcm_trendsDiv .box_graph {
    height: 340px;
  }

  .col-md-3.col-sm-6.col-xs-6.evntDorp {
    padding-left: 6%;
  }

  .EventTabDivs .eventOverflowScroll {
    height: calc(100vh - 301px);
  }

  .EventTabDivs {
    overflow: scroll;
  }

  .eventDivs.AllNotificationTable .headerTable span:nth-child(2),
  .eventDivs.AllNotificationTable .pannelWrap span:nth-child(2) {
    width: 38%;
  }

  .eventDivs.AllNotificationTable .headerTable,
  .eventDivs.AllNotificationTable .eventOverflowScroll,
  .eventDivs.AllNotificationTable .pannelWrap.overflowScrolls {
    width: 100%;
  }

  .AllNotificationTable .headerTable span:nth-child(1),
  .AllNotificationTable .pannelWrap span:nth-child(1) {
    width: 22%;
  }

  .col-md-1.col-xs-2.padRights {
    display: none;
  }

  .downtime__content .box_graph {
    height: 375px;
  }

  .downtime__content .recharts-responsive-container {
    max-height: 335px;
  }

  .downtime__content .barchart__line,
  .downtime__content .dmOccurence {
    height: 32vh;
  }

  #allMachineList .DownTimeTable .heightNoRecord.noHistoryAlarms {
    width: 1000px;
    height: calc(100vh - 207px);
  }

  .DownTimeTable .DowntimeOverflowScroll {
    height: calc(100vh - 251px);
  }

  .eventDivs.AllNotificationTable.DownTimeTable,
  .eventDivs.CustomNotificationTable {
    overflow: scroll;
  }

  .mapDiv.down_time_tables.allMachineList {
    height: calc(100vh - 121px);
  }

  .DownTimeTable .DowntimeOverflowScroll,
  #allMachineList .DownTimeTable .headerTable,
  #allMachineList .CustomNotificationTable .headerTable {
    width: 1000px;
  }

  .AllNotificationTable.DownTimeTable .headerTable span:nth-child(1),
  .AllNotificationTable.DownTimeTable .pannelWrap span:nth-child(1) {
    width: 10%;
  }

  .eventDivs.AllNotificationTable.DownTimeTable .headerTable span:nth-child(2),
  .eventDivs.AllNotificationTable.DownTimeTable .pannelWrap span:nth-child(2) {
    width: 18%;
  }

  .top__buttonsOEE .box,
  .top__buttonsOEE .box svg {
    width: 154px;
  }

  .flextop {
    height: auto;
  }

  .oee__factors.heightOEE {
    width: 100%;
    overflow-y: scroll;
  }

  #allMachineList .headerTable {
    width: 100%;
  }

  .hcm_overview_table,
  .tripping_analysis_table,
  .downtimeanalysis_table {
    overflow-x: scroll;
  }

  .taheaderTable,
  .tripping_analysis_table .eventOverflowScroll {
    min-width: 1040px;
    width: 100%;
  }

  .downtimeanalysis_table .eventOverflowScroll,
  .hcm_overview_table #pannelWrap,
  .downtimeheaderTable,
  .HCMheaderTable {
    min-width: 1080px;
    width: 100%;
  }

  .downtimeanalysis_table .downtimeheaderTable span:nth-child(1),
  .downtimeanalysis_table .pannelWrap span:nth-child(1) {
    width: 132px;
  }

  .hcm_overview_table .eventOverflowScroll,
  .downtimeanalysis_table .eventOverflowScroll,
  .tripping_analysis_table .eventOverflowScroll {
    height: calc(100vh - 211px);
  }

  .AllNotificationTable .headerTable span:nth-child(2),
  .AllNotificationTable .pannelWrap span:nth-child(2) {
    width: 70%;
  }

  .loginDropdown .liItem2.open .dropdown-menu:after {
    border-bottom: 14px solid #232323;
  }
  .light .loginDropdown .liItem2.open .dropdown-menu:after {
    border-bottom: 14px solid #fff;
  }

  .appSetting .clshight90 {
    border-bottom: 0px solid #121212;
  }

  .loginDropdown ul {
    background: #232323;
  }

  .headerTable,
  .eventOverflowScroll,
  .pannelWrap.overflowScrolls {
    width: 1000px;
  }

  .eventDivs.AllNotificationTable .headerTable {
    width: 100%;
  }

  .prodTime,
  .prodRate {
    margin-top: 0;
  }

  .pannelWrap.overflowScrolls {
    height: calc(100vh - 421px);
  }

  .col-md-4.cardMB14.pointer {
    margin-bottom: 5px;
  }

  .machineTxt {
    max-width: 222px;
  }

  .imageMachine.heightper100 {
    min-height: 300px;
  }

  ul.button_clsul.margtop10 {
    margin-left: 30px;
  }

  .heightper100 {
    height: auto;
  }

  .machineOfflineTagDiv {
    position: fixed;
    left: 40%;
    z-index: 8;
    margin-top: -24px;
    display: none;
  }

  .headerTable span {
    font-size: 10px;
  }

  .pannelWrap span {
    font-size: 9px;
  }

  .confirmPin .form-group {
    float: left;
    width: 100%;
  }
  /* 
  .tabContentLast {
    margin-top: 2.5vh;
    padding-left: 40px;
  } */

  #live-batch .tabContent {
    width: 19%;
  }

  #live-batch .tabContent:nth-child(3) {
    width: 14%;
  }

  #live-batch .tabContent:nth-child(5) {
    width: 24%;
  }

  #live-batch .graphDivContent {
    height: 81%;
  }

  .topDivContent {
    height: 13%;
  }

  .alarmSetting .form-group {
    margin-bottom: 15px;
    float: left;
  }

  .confirmPin .graphboxopenInnner,
  .modelboxopenInnner,
  .alarmSetting .modelboxopenInnner {
    width: 90%;
    height: auto;
  }

  .supportDivContent.col-sm-3 {
    width: 49%;
    padding-right: 10px;
    padding-left: 10px;
    margin-right: 0;
  }

  .supportDivContent.col-sm-3:nth-child(2),
  .supportDivContent.col-sm-3:nth-child(4) {
    margin-right: 0;
    margin-left: 2%;
  }

  #live-batch .BarStatus {
    padding-left: 5px;
  }

  .supportDivContent {
    height: 356px;
    margin-bottom: 12px;
  }

  .alertDivs .headerTable span:nth-child(3),
  .alertDivs .alarmRows span:nth-child(3) {
    width: 29%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .alertDivs .headerTable span:nth-child(4),
  .alertDivs .alarmRows span:nth-child(4),
  .alertDivs .headerTable span:nth-child(5),
  .alertDivs .alarmRows span:nth-child(5) {
    width: 18%;
  }

  .alertDivs .headerTable span:nth-child(6),
  .alertDivs .alarmRows span:nth-child(6) {
    width: 14%;
  }

  .alertDivs .headerTable span:nth-child(7),
  .alertDivs .alarmRows span:nth-child(7) {
    width: 10%;
  }

  .innerTooltip {
    left: 34px;
  }
  #events .headerTable span:nth-child(5) {
    text-align: left;
    width: 13%;
  }
  #events .headerTable span:nth-child(4) {
    width: 35%;
  }
  #events .headerTable span:nth-child(3) {
    text-align: left;
    width: 37%;
  }
  #KPI .KPIGroupContent .col-m6 {
    padding-right: 2%;
    width: 40%;
    float: left;
  }
  #KPI .cardMachineSetList,
  .cardKpiList {
    padding-top: 0px;
  }

  #KPI .KPIRadioButtonPanel p {
    padding-right: 30px;
    margin-top: 8px;
  }

  #KPI .svgCreate {
    right: 40px;
    top: 16px;
  }

  #KPI .configureKPI {
    right: 110px;
    top: 40px;
  }

  .col-md-4.mobclsalign {
    width: 50%;
    float: left;
    display: flex;
    padding-left: 20px;
  }

  .KPIGroupContent .MuiAutocomplete-input,
  .KPIGroupContent .Mui-focused input,
  .MachineSettingsGroupContent .MuiAutocomplete-input,
  .MachineSettingsGroupContent .Mui-focused input,
  .configure .Mui-focused input,
  .configure .MuiAutocomplete-input {
    width: 200px;
  }

  .MachineSettingsGroupContent,
  .KPIGroupContent {
    float: right;
    width: calc(100% - 190px);
  }

  .col-md-2.SideTabGroup {
    width: 190px;
    float: left;
  }

  .alertContent {
    height: 84vh;
  }

  .alarmWrapper {
    width: 100%;
    padding-left: 0px;
    padding-right: 0px;
  }

  #overview .filterDiv.removedWidth {
    display: none;
  }

  #overview .filterDiv {
    display: block;
  }

  #overview .filterDiv.removedWidthh.MfilterDiv {
    display: block;
    width: 213px;
    padding: 20px 10px;
  }

  .tabsName li {
    margin-right: 0px;
  }

  #loginDropdown .navbar-nav {
    margin: 7.5px -15px;
  }

  li.dropdown.liItem1 {
    margin-top: 5px;
  }

  #MachineList .mapDiv.allMachineList {
    overflow-y: hidden;
  }

  .statusCardHolder .row {
    height: auto;
  }

  .BarStatus {
    padding-top: 15px;
  }
  .expandMapWidth .BarStatus {
    margin-left: 0;
  }

  .machinesLists {
    padding-left: 40px;
  }

  #loginDropdown .navbar-nav {
    margin: 7.5px -15px;
  }

  li.dropdown.liItem1 {
    margin-top: 5px;
  }
}

@media only screen and (max-width: 767px) {
  .wrapicon_text .icon_text {
    margin-right: 0.5rem;
    padding: 5px 0.5rem;
}
  .col-md-6.col-sm-12.col-xs-12.heightper100.mobileView2,
  .col-md-6.col-sm-12.col-xs-12.mobileView1{
    width: 100%;
  }
  #overview .SliderScroll{
    background-color: transparent;
  }
  .wrap_status{
    height: 100%;
  }
  #serialNo-popup.MuiAutocomplete-listbox{
    height: 20vh !important;
  }
  .mqttConnectionDiv, #remoteassistance{
    display: none;
  }
#MachineList .float-container {
    margin: 0.5rem 0;
}
  .nav>li.liItem0>a {
padding: 0;
}
.homeicon svg {
  height: 2rem;
  width: 2rem;
  line-height: 2rem;
  vertical-align: middle;
}
  .machineCountNumb {
    padding: 0.3rem 1px;
  }
  .longEnough .cardMachineRight {
    width: calc(100% - 0rem);
    margin: 1rem 0rem 0 0rem;
}
  .light .leftMenu ul li.active, .light .leftMenu ul li:hover {
    border-bottom: 0px solid #f1f9ff;
    border-left: 2px solid #4285f4;
}
  .search-bar-container .input-group {
    width: 100%;
}
  .light .statsHolder .lastDays {
    color: #5a585a;
  }
  .shownotext {
    font-size: 1rem;
  }
  .bell.mobile-bell {
    position: absolute;
    right: 1.25rem;
    top: 0.83rem;
}
  .desktop-bell{
    display: none;
  }
  .customAlertsFilter .filterInnerDiv, .AllNotificationFilter .filterInnerDiv {
    left: 2px;
    top: 0px;
}
.customAlertsFilter .filterDiv, .AllNotificationFilter .filterDiv {
  height: calc(100vh - 7rem);
  left: 0;
  top: 0;
}
  .expandMapWidth .machineIconHolder {
    margin-left: 2.33rem;
}
  .KPIRadioButtonPanel.form-radio-div {
    margin-top: 10px;
  }
  html {
    font-size: 12px !important;
  }
  .loginDropdown > .liItem1.dropdown > .dropdown-menu{
    right: -1.33rem;
  }
  .navbar-right .liItem1 .dropdown-menu:after {
    right: 5.6rem;
}
  .filterInnerDiv span {
    padding: 0.5rem;
    width: 2.33rem;
    height: 2.33rem;
}
  .for-mobile-only .breadcrumsCls{
    font-size: 0.83rem;
    line-height: 1;
    /* padding: 1.33rem 0; */
    padding: 0;
    color: #fff;
    font-size: 1rem;
  }
  .page-name .breadcrumsCls .breadcrumb-item:first-child {
    margin-top: -4px;
    font-size: 1rem;
}
  .breadcrumsCls .breadcrumb-item:first-child .marqueeSn{
    font-size: 1rem;
    max-width: 275px;
    overflow: hidden;
    height: 12px;
  }
  .for-mobile-only .breadcrumsCls .breadcrumb>li{
    padding: 1.33rem 0;
    font-size: 1rem;
  }
  .light .for-mobile-only .breadcrumsCls{
    color: #3b414b;
  }
  .for-mobile-only .alertIcon {
    top: -5px;
}
  .for-desktop .breadcrumb > .breadcrumb-item:not(:last-child)::after{
    content: '';
  }

  .for-desktop .breadcrumsCls.machineScroll .breadcrumb-item.active:nth-child(2),
  .for-desktop .breadcrumsCls .breadcrumb li:nth-child(2){
    display: none;
  }
  .eventTopBar.onlyEvent.noboderDrop .row .evntDorp {
    display: flex;
    width: 34rem;
    padding-left: 8rem;
  }

  #loginDropdown .loginDropdown .dropdown-toggle-profile {
    box-shadow: none;
  }
  .DglobalSearch.DglobalSearch-mobile.SearchMobileMonitoring{
    display: none;
  }
  #KPI .svgCreate {
    right: 3rem;
  }

  #MachineList span.bookmarkCls.bookMarkIcon {
    right: 24px;
    top: 6px;
  }

  .loginDiv .navbar-nav {
    margin: 0;
  }

  .wrapper {
    width: 100%;
    padding: 0;
  }

  body header {
    position: relative;
    width: 100% !important;
    margin: 0;
    padding: 16px 16px 0 16px;
    height: auto;
  }
  header .page-name {
    position: absolute;
    top: 20px;
    left: 70px;
  }
  header .left-header {
    width: 100%;
    margin-bottom: 0.4rem;
    flex-direction: column-reverse;
    justify-content: flex-start;
    align-items: flex-start;
  }
  header .loginDiv {
    align-self: flex-end;
    margin: -6px 0 0 0;
  }
  .DglobalSearch {
    width: 100%;
    margin-top: 22px;
  }
  body .searchDiv .MuiAutocomplete-input {
    width: 100%;
    min-height: 40px;
  }
  .listMachine {
    width: 55%;
    min-width: fit-content;
  }
  .machineStatus {
    margin: 0;
    padding-bottom: 2rem;
  }

  .live-map-tracking .tabArea .tabsName li a {
    width: 100%;
    margin: 1rem 0;
    padding: 0.5rem 0;
    font-size: 1.2rem;
  }
  .live-map-tracking .tabArea .tabsName li.active a {
    padding: 0.5rem 0;
  }
  .live-map-tracking .tabArea .nav-tabs > li {
    width: 100%;
  }
  .tabArea .nav-tabs {
    display: flex;
  }
  .live-map-tracking .tabArea .nav-tabs {
    justify-content: space-between;
    margin: 0;
  }
  .light .live-map-tracking .tabArea .nav-tabs {
    background-color: #F2F4F6;
  }
  .mapDiv.allMachineList {
    width: 100%;
  }
  .wrapper {
    padding: 0 15px;
  }

  .wrapper .contentPart,
  .wrapper.live-map-tracking {
    padding: 0;
  }
  .contentWrap.monitoringcontentWrap {
    margin: 0;
    padding: 0;
    width: 100%;
  }
  .statusCardHolder .slide-card-container .slide-card {
    width: calc(50% - 8px);
    height: 21vh;
  }
  .slide-card-container .slide-card:nth-child(3n -1){
    /* margin: 0; */
  }
  .statusCardHolder .slide-card-container .slide-card{
    /* margin: 0.66rem 0; */
  }
  .breadcrumsCls .breadcrumb-item {
    display: flex;
    align-items: center;
  }
  .machinesLists {
    padding: 0;
    padding-left: 2rem;
  }
  .machine-list-rightDivSet {
    margin-top: 1.5rem;
  }
  /* Alert and notification  */
  .mapDiv.allMachineList.customAlerts,
  .mapDiv.allMachineList.allNotifications {
    width: 100%;
  }
  #MachineList .contentPart {
    padding: 10px 16px;
  }
  #MachineList .innerMachineHeight {
    height: calc(100vh - 24.85rem);
  }
  .pagination-tab .navArrow {
    padding-top: 4px;
  }
  .pagination-tab .navArrow svg {
    height: 20px;
    width: 20px;
  }
  #allMachineList .pagination {
    font-size: 1.2rem;
  }
  .longEnough .float-container {
    margin: 0;
    bottom: 1rem;
    position: fixed;
    padding-right: 15px;
}
.pagination {
  font-size: 1.2rem;
}
  .backCloseDiv .closeIcon {
    position: static;
  }
  .widthListView .closeIcon {
    transform: none;
  }
  .longEnough {
    padding: 0 16px;
  }

  .machineListDiv {
    top: 0;
  }

  .comboDiv {
    margin-top: 6px;
    margin-bottom: 10px;
  }

  .machineStatus {
    width: 100%;
    left: 0;
  }
}

@media only screen and (min-width: 415px) and (max-width: 635px) {
  #KPI .form-group.checkSpoc.onlyKPICheckbox label {
    right: 14rem;
  }
}

@media only screen and (min-width: 320px) and (max-width: 767px) {
  .MuiAutocomplete-option {
    min-height: 30px !important;
}
  .searchDiv input::placeholder{
    font-size: 1rem;
  }
  input::placeholder{
    letter-spacing: 0.4px;
  }
  .div4KPI, .div4MS{
    font-size: 1rem;
  }
  .div4MS svg, .div4KPI svg {
    height: 1.5rem;
    width: 1.5rem;
}
  .MachineSettingsContent,
  .KPIContent {
    padding-top: 0;
  }

  #alarms .alarmWrapper .contentWrapp .alarmBar {
    padding: 10px 0 14px 0
  }
  #alarms .alertDivs {
    height: calc(100vh - 34rem);
}
  #alertname {
    width: 100%;
  }

  .graphDivContent .supportDivContent.col-sm-3:first-child .prodRate {
    margin-top: 30px;
  }

  .supDivTxt {
    padding-top: 1%;
  }

  #reports {
    display: none;
  }

  #KPI .cardKpiListUL .leftcardKPI .expandKPI {
    /* top: 0;
    bottom: unset; */
    right: 8px;
    bottom: 8px;
  }

  #KPI .leftcardKPI {
    display: flex;
    justify-content: center;
    margin-top: 8px;
  }

  #KPI .leftcardKPI .wrap23 {
    width: auto;
    justify-content: center;
  }

  #KPI .cardKpiListUL li p {
    text-align: center;
  }

  .widthZero {
    overflow: hidden;
  }

  .lastDays {
    bottom: 15px;
  }

  .contentWrap .contentAreaPart .footerPopupWithBtn {
    margin: 0px 2rem;
    width: -webkit-fill-available;
}
.passwordChangeDiv .footerPopupWithBtn .cancelBtn {
  margin-left: 1.9rem;
  margin-right: 0;
}
  .footerPopupWithBtn {
    width: auto;
    float: none;
    margin: 0 24px;
  }

  .footerPopupWithBtn .saveBtn,
  .footerPopupWithBtn .cancelBtn {
    width: 45%;
  }

  .light .footerPopupWithBtn .cancelBtn {
    box-shadow: none;
  }

  .machine-radio-div span:first-child label {
    margin-left: 0;
  }

  .repeatedCls.extraClas {
    margin-right: 2%;
    margin-bottom: 16px;
    width: 48%;
  }

  .repeatedCls:nth-child(4n) {
    margin-right: 0;
    margin-bottom: 16px;
  }

  /*monitoring*/
  #overview .filterDiv {
left: 0;
  }

  .middleDivContent {
    height: calc(100vh - 296px);
  }

  .alarmFilter {
    padding-left: 0;
    margin-left: 78px;
    margin-top: 5px;
  }

  #MachineList .mapDiv.allMachineList {
    overflow: hidden;
    margin-left: 0;
    padding-top: 0;
  }

  #overview .mapDiv.allMachineList {
    overflow-y: scroll;
    margin-left: 0;
    height: calc(100vh - 14.16rem);
    margin-bottom: 15px;
    background-color: #121212;
  }
  .light #overview .mapDiv.allMachineList {
    background-color: #f2f4f6;
  }

  .appSetting .allCenter .form-group p:first-child {
    margin-right: 3px;
  }

  .cardMachineSetListUL li,
  .cardKpiListUL li {
    width: 100%;
    margin-right: 0;
  }

  .cardMachineSetListUL li:nth-child(even),
  .cardKpiListUL li:nth-child(even) {
    margin-left: 0%;
  }

  #overview .contentPart .filterInnerDiv {
    /* display: block;
    z-index: 1; */
  }
  #overview .col-md-6 {
    background: #121212;
}
 .light #overview .col-md-6 {
    background: #f2f4f6;
}
#overview .col-md-6.mobileView2, .imageMachine{
  position: relative;
  z-index: 3;
}
  .BarStatusTop {
    background: #121212;
    padding-top: 20px;
    padding-bottom: 10px;
  }
  .light .BarStatusTop {
    background: #f2f4f6;
  }
  .GroupUpperTab .input-group .MuiAutocomplete-input {
    width: 100%;
  }
  .search-bar-container {
    width: 99%;
}
  .alarmSettingHolder .settingText,
  .configureKPI .pointer,
  .alarmSettingHolder .popupcomn {
    display: none;
  }

  .KPIGroupContent {
    padding-right: 12px !important;
  }

  .confirmPin .graphboxopenInnner {
    width: 100%;
    height: 100vh;
    z-index: 9999;
  }

  .kpigraph {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 400px;
  }

  .popupCloseButton {
    flex-direction: row;
  }

  .closepopup {
    margin-right: 10px;
  }

  .popupCloseButton > a {
    font-size: 18px;
  }

  .col-md-3.timefilterdiv {
    margin-bottom: 20px;
    width: 200px;
    margin-left: 115px;
    top: -41px;
  }

  .ThreeDViewTop {
    margin: 0 0 24px 0;
  }

  .copyright {
    right: 0;
    left: 0;
    text-align: center;
    font-family: "Montserrat-Regular";
  }

  #LiveMapTracking .mapDiv {
    height: calc(100vh - -15px);
    padding-top: 0px;
  }

  .contentWrap .DglobalSearch-mobile.DglobalSearch .searchDiv,
  .Mfooter {
    display: none;
  }

  .copyright.footer {
    display: none;
  }

  /*Home*/
  header {
    background: #232323;
    box-shadow: none;
  }

  .logoDiv {
    margin-left: 5px;
    z-index: 9999;
  }

  .DglobalSearch .searchDiv {
    display: block;
    border: none;
  }

  .loginDiv {
    display: block;
  }

  .searchDiv .input-group-btn.searchCross .btn {
    height: calc(2rem + 0.6rem);
  }

  .searchDiv .MuiAutocomplete-input {
    width: 26rem;
    font-size: 0.83rem;
  }
  .DglobalSearch .searchDiv .searchCross {
    top: 3px;
    right: 4px;
}
.searchDiv .searchCross .btn svg {
  width: 1.5rem !important;
  padding: 0.8rem 0.6rem;
  height: 1.5rem !important;
}
.searchIconGlobal {
  right: 10px;
}
  #MachineList .col-md-4.cardMB14.pointer {
    margin-bottom: 12px;
    width: 100%;
  }

  #MachineList
    .col-md-4.col-sm-6.col-xs-12.cardMB14.pointer:last-child
    .cardMachine.machineTabHome,
  #MachineList .col-md-4.col-sm-6.col-xs-12.cardMB14.pointer:last-child {
    margin-bottom: 0;
  }

  .machineListTag {
    margin-right: 36px;
  }

  .text-right.forgetpass a,
  .btn.loginBtn .logintxt {
    font-family: "Montserrat-Medium";
  }

  .loginDiv .navbar-nav .open .dropdown-menu {
    background-color: #272727;
    width: 270px;
  }

  .loginDiv .navbar-nav .open .dropdown-menu {
    background-color: #272727;
  }

  .light .loginDiv .navbar-nav .open .dropdown-menu {
    background-color: #fff;
    box-shadow: -2px 2px 6px rgba(0, 0, 0, 0.2);
  }

  .form-signin .btn-primary {
    height: 4rem;
  }

  .MuiFormControl-root.MuiTextField-root.DatePickers-textField-5,
  .dropdown .borderInput,
  .cardRowLR .col-sm-6 {
    width: 100%;
  }

  .leftMenu.expandWidth {
    width: 300px;
  }

  .leftMenu,
  .leftMenu.expandWidth ul {
    width: 100%;
    transition: width 0.5s ease-in-out;
    -o-transition: width 0.5s ease-in-out;
    -moz-transition: width 0.5s ease-in-out;
  }

  .leftMenu {
    width: 0;
    overflow: visible;
  }
  .burgerMenu {
    height: 4.33rem;
    width: 4.33rem;
}
.light .burgerMenu,
  .burgerMenu {
    background-color: transparent;
  }
  .burgerMenu.open_nav {
    width: 300px;
    z-index: 999;
    box-shadow: none;
  }

  .half_rounded_bar_div {
    float: left;
  }

  #LiveMapTracking .contentPart {
    padding-right: 0px;
  }

  .filterDiv .MuiAutocomplete-input,
  .filterDiv .Mui-focused input {
    height: 28px;
    width: 87%;
  }

  .dropdown .borderInput {
    height: 28px;
  }

  .onlyKPIGraphs .dropdown .borderInput {
    height: 32px;
  }

  .filterDiv .font10,
  .resetBtn {
    font-size: 12px;
  }

  .resetpost {
    right: 18px;
  }

  span.Mfilter {
    width: 24px;
    height: 24px;
    margin-left: -10px;
  }

  .filterInnerDiv {
    left: 2px;
    top: 11px;
  }

  .borderInput .caret {
    right: 8px;
  }

  #events .borderInput .caret {
    /* right: 4px; */
  }

  #alarms .borderInput .caret {
    right: 1rem;
  }

  .centerzoom {
    display: block;
    position: absolute;
    z-index: 9;
    right: 16px;
    top: 16px;
  }

  .machineListDiv {
    display: block;
    background-color: #1f1f1f;
    z-index: 14;
    padding: 0 7px;
  }

  .light .machineListDiv {
    background-color: #fff;
  }

  .widthListView {
    width: 100%;
  }

  .offlinebg .cardMachineRight {
    border-radius: 4px;
    background: #2e2e2e;
  }

  .loginFormBg {
    max-width: 320px;
  }

  .hr {
    width: 100%;
  }

  .loginFormBg,
  .forgetFormBg .pad20 {
    padding: 35px 15px;
  }

  .nav > li.liItem1,
  .nav > li.liItem2 {
    display: inline-block;
  }

  .tabsName li {
    margin-right: 0;
  }

  .nav.nav-tabs.tabsName li {
    width: 100%;
  }

  .machineDescription {
    padding-top: 8px;
  }

  .listMachine li {
    margin: 0 0.5rem;
  }

  .machineName {
    bottom: -10px;
    font-size: 1rem;
  }

  .machineStatus {
    background: linear-gradient(rgba(0, 0, 0, 0.01) 0%, rgba(0, 0, 0, 1) 100%);
  }

  .light .machineStatus {
    background: linear-gradient(
      rgba(94, 63, 63, 0.01) 0%,
      rgba(255, 255, 255, 1) 100%
    );
  }

  .loginDropdown > li.liItem1 > a {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  ul.topSideMenu.comnmenu,
  ul.bottomSideMenu {
    height: auto;
    overflow: hidden;
  }

  #admin ul.dropdown-menu.submenuUL.settingSubMenu {
    top: 26%;
  }

  #settings ul.dropdown-menu.submenuUL.settingSubMenu {
    top: 32%;
  }

  .appSetting .form-radio-div label {
    margin-left: 3px;
  }

  .tabArea .nav-tabs {
    display: flex;
    overflow: auto;
    justify-content: flex-start;
    overflow-y: hidden;
    margin-top: 8px;
    margin-bottom: 12px;
  }

  .machine-list-text {
    font-family: "Montserrat-Medium";
    display: flex;
    justify-content: space-between;
    color: #e0e0e0;
    flex-direction: column;
    margin: 1rem 0.08rem;
  }

  .machinesLists {
    justify-content: flex-start;
    align-items: center;
    overflow-x: scroll;
  }

  .machine-list-rightDivSet > div > span {
    margin-right: 5px;
  }
  .machine-list-rightDivSet span:not(:first-child) {
    padding-left: 1.33rem;
}
  .cardMachine {
    margin-bottom: 3px;
  }

  .backCloseDiv {
    background: #1f1f1f;
    border: none;
    outline: none;
  }

  .longEnough {
    background: #1f1f1f;
    margin-right: 0px;
    margin-top: 0px;
  }

  .side__Panel {
    max-height: 560px;
  }

  .footer {
    justify-content: center;
  }

  .loginDropdown .liItem2.open .dropdown-menu:after {
    right: 0;
    top: -10px;
  }

  .loginDropdown .liItem2.open .dropdown-menu:after {
    border-bottom: 14px solid #1f1f1f;
  }
  .light .loginDropdown .liItem2.open .dropdown-menu:after {
    border-bottom: 14px solid #fff;
  }

  /* Mobile view side bar or leftMenu*/
  #alerts-and-notifications img {
    margin-left: 0;
  }

  .leftMenu ul li {
    display: block;
    padding-top: 10px;
  }

  .leftMenu ul.submenuUL.dropdown-menu li a {
    font-size: 10px;
    margin-left: 54px;
    margin-top: -3px;
    margin-bottom: 6px;
    vertical-align: middle;
  }

  .topSideMenu .submenuUL.dropdown-menu,
  .bottomSideMenu .submenuUL.dropdown-menu {
    background-color: #2a2c2d;
    color: #e0e0e0;
    position: initial;
    float: unset;
    border-radius: 0;
    border: 0;
    box-shadow: none;
    margin-top: 0;
  }

  .leftMenu ul li.active #alerts-and-notifications,
  .leftMenu ul li:hover #alerts-and-notifications,
  .leftMenu ul li.active #settings,
  .leftMenu ul li:hover #settings {
    font-family: "Montserrat-Medium";
  }

  .loginDropdown ul {
    box-shadow: none;
  }
  .loginDropdown .liItem1 ul {
    width: 300px;
  }
  .light ul.dropdown-menu.submenuUL {
    background: #fff;
    border: 1px solid #d7e8ff;
    box-shadow: none;
  }

  .leftMenu ul.bottomSideMenu {
    height: auto;
  }

  #reports ul.dropdown-menu.submenuUL {
    width: 268px;
  }

  .leftMenu ul li:active,
  .leftMenu ul li:hover {
    border-left: 3px solid transparent;
    padding-top: 10px;
  }

  .leftMenu ul li:active,
  .leftMenu ul li:hover {
    background: #2a2c2d;
  }

  .light .leftMenu ul li:active,
  .light .leftMenu ul li:hover {
    background: #e8f1fd;
  }

  a span svg.dropdownArrow:hover,
  a span svg.dropdownArrow::after {
    transform: rotate(-180deg);
    -webkit-transform: rotate(-180deg);
  }

  .alerts-and-notifications-arrow .dropdownArrow {
    margin-left: 40px;
    margin-top: 3px;
  }
  .leftMenu ul li.notifySVG.active .alerts-and-notifications-arrow path{
    fill: none;
  }
.leftMenu ul li.notifySVG .dropdownArrow, .leftMenu ul li.settingSVG .dropdownArrow {
    transform: rotate(0deg);
  }
 .leftMenu ul li.notifySVG:hover .dropdownArrow, .leftMenu ul li.settingSVG:hover .dropdownArrow {
  transform: rotate(180deg);
  }
  .Reports-arrow .dropdownArrow {
    margin-left: 129px;
  }

  .admin-arrow .dropdownArrow {
    margin-left: 136px;
  }

  .settings-arrow .dropdownArrow {
    margin-left: 133px;
  }
}

@media only screen and (min-width: 320px) and (max-width: 767px) {
  .wrapper {
    overflow-y: scroll;
}
  .leftMenu{
    z-index: 9999;
  }
  .tabArea .tabsName li a{
    font-size: 1.2rem;
  }
  .breadcrumsCls div.bookmarkIcon {
    height: 15.5px;
  }

  .descriptionCreate.onlyKPIGraphs .bellIconHolder {
    right: 10px;
  }

  .helpbody .leftpanel {
    height: 0;
  }

  .helpbody .leftpanel {
    border: none;
  }
  .helpbody .leftpanel.menu-open {
    border: none;
    min-width: 0;
    max-width: 0;
  }

  .container_help .rightpanel {
    width: 100%;
    z-index: 999;
  }

  .rightpanel .overflowscroll {
    margin: -1rem;
    height: calc(100vh - 95px);
    padding: 0;
    background: #121212;
  }

  .rightpanel .onlyGetstarted .overflowscroll {
    margin: 0;
    height: calc(100vh - 160px);
  }

  .rightpanel .onlyGetstarted .overflowscrolls {
    margin-top: 100px;
  }
  .light .rightpanel .overflowscroll {
    background: #fff;
  }

  .helpbody {
    margin: 0;
  }

  .breadcrumsCls.machineScroll .breadcrumb > li + li:before {
    content: none;
  }

  .breadcrumsCls.machineScroll .breadcrumb-item.active:nth-child(2) {
    width: 140px;
    overflow: hidden;
    white-space: nowrap;
  }

  .breadcrumsCls.machineScroll .breadcrumb-item.active:nth-child(2) .marqueeSn {
    padding-left: 0;
    width: fit-content;
    animation: marquee 7s linear infinite;
  }

  .light .offlinebg .cardMachineRight {
    background: #ced0d3;
  }

  .light ul.dropdown-menu.submenuUL.notificationSubMenu,
  .light ul.dropdown-menu.submenuUL.settingSubMenu {
    border-color: #e8f1fd;
  }

  .MachineSettingsGroupContent {
    padding-right: 10px !important;
  }
  .KPIGroupContent {
    padding-right: 8px !important;
  }
  .panelrighttext {
    width: auto;
  }
  .innerMachineDiv {
    background-color: transparent;
  }
  .light .innerMachineDiv {
    background-color: #fff;
  }
  .innerMachineDiv .longEnough .panelrighttext {
    margin-right: 0;
  }

  .EventTabDivsTop .comanPagination .pagination > li > a,
  .EventTabDivsTop .comanPagination .pagination > li > span,
  #alarms .comanPagination .pagination > li > a,
  #alarms .comanPagination .pagination > li > span {
    padding: 6px 3px;
    font-size: 12px;
  }

  .contentAreaPartInner {
    height: calc(100vh - 198px);
  }

  #alarms .alertDivs .overflowScrolls {
    overflow-y: unset;
  }

  .eventOverflowScroll {
    height: calc(100vh - 350px);
  }

  #events .eventOverflowScroll {
    height: calc(100vh - 355px);
  }

  .eventOverflowScroll .panel-title,
  .EventsTabDivs .panel-title,
  .alertDivs .panel-title {
    width: 100%;
  }

  .contentWrap .contentAreaPart,
  .contentWrap .appSetting {
    margin-top: 0px;
    width: 100%;
  }

  .filterDiv {
    width: 72%;
    padding: 5rem 1.33rem;
    left: 16px;
    top: 16px;
  }
  .centerzoom {
    padding: 0.2rem 0.4rem;
}
  #serialNo-popup .MuiAutocomplete-option #plant-popup li,
  #machinemodel-popup li,
  #country-popup li,
  #state-popup li {
    min-height: 30px;
  }

  #alarms .alarmBar.padTopOnly.row.dropRow .activeAlarmHolder.iconText {
    padding-top: 22px;
  }
  .historyEvent {
    padding-top: 5px;
}
  #overview .threeDView {
    margin: 4px 0 -8px 48px;
  }

  #MachineList .comanPagination {
    margin-right: 0;
  }

  #live-batch .contentPart .BarStatus {
    margin-bottom: 0;
  }

  #live-batch .graphDivContent {
    height: 70%;
  }

  /*on click machine list*/
  .light .longEnough {
    box-shadow: none;
    background: #fff;
  }

  .loadmorebtn {
    height: 12px;
  }

  /*login*/
  .form-signin .form-control.form-control-sm[type="password"] {
    width: 100%;
  }

  .loginFormBg .hr {
    width: 330px;
    right: 12px;
    position: relative;
  }

  .form-signin .loginFormBg {
    width: 93%;
    height: 39rem;
  }

  .pad20.text-left {
    padding: 10px 20px;
  }

  .forgetFormBg .checkEmail {
    font-size: 16px;
    font-family: "Montserrat-Medium";
  }

  .forgetFormBg {
    width: 98%;
    height: 39rem;
  }

  .pad20 .form-group {
    margin-bottom: 27px;
  }

  .logologin {
    transform: scale(0.6);
  }

  .innerPageNotFound .smallTxtNotFound {
    text-align: left;
  }

  /*Admin*/
  #FailureModeListing,
  #ftrprint,
  #ftrFileUpload,
  .monitoringPage .tabsName::-webkit-scrollbar,
  #admin {
    display: none;
  }

  .breadcrumsCls nav {
    min-width: max-content;
  }

  /*home*/
  .tabArea .tabsName li.active a:after {
    height: 3px;
  }

  .innerMachine {
    margin-left: 0px;
  }

  .loginDiv .loginDropdown > li.liItem2 > a {
    margin-top: 0.2rem;
  }
  .light .loginDropdown .dropdown .dropdown-toggle-profile {
    background-color: #fff;
    color: #4285f4;
    box-shadow: none;
  }
  .light .loginDropdown .dropdown.liItem2.open .dropdown-toggle-profile {
    background-color: #4285f4;
    color: #fff;
    box-shadow: none;
  }
  .offlinebg .cardMachine,
  .offlinebg .cardMachineRight {
    border: none;
    border-radius: 4px;
    height: 177px;
  }

  .cardMachineRight {
    border-radius: 4px;
  }

  .light header {
    /* background: #fff; */
  }

  .filterDiv.removedWidthh.MfilterDiv {
    left: 0;
    top: 16px;
  }

  #LiveMapTracking .contentPart .filterInnerDiv {
    left: 16px;
    top: 16px;
  }

  #MachineList .contentPart .filterInnerDiv {
    left: 16px;
    top: 15px;
    z-index: 100;
  }

  span.bookmarkCls.bookMarkIcon {
    position: absolute;
    right: 20px;
    top: 3px;
    z-index: 12;
  }

  .machineDescription .offlineview {
    background: #121212;
    color: #e0e0e0;
    border-radius: 4px;
    padding: 8px 10px 5px 10px;
    width: 168px;
    margin: 22px auto;
  }

  .light .machineDescription .offlineview {
    background: #F2F5F8;
    color: #3b414b;
    border: 0.5px solid #e4e7ec;
  }

  [type="radio"]:not(:checked) + label {
    padding-left: 18px;
  }

  .leftMenu ul li.active a {
    font-family: "Montserrat-Medium";
  }

  .machineTitleUl .svgI-Con {
    margin-top: 2px;
  }

  .submenuUL.dropdown-menu > .active > a,
  .submenuUL.dropdown-menu > .active > a:focus,
  .submenuUL.dropdown-menu > .active > a:hover {
    background: transparent;
    color: #5d97f6;
  }

  .leftMenu ul li a {
    font-size: 14px;
    padding: 6px 0 14px 0;
  }

  .light .leftMenu ul li.active {
    background: #e8f1fd;
    border-bottom: none;
  }

  .leftMenu ul.dropdown-menu.submenuUL > li > a {
    font-size: 14px;
  }

  .light .leftMenu ul .submenuUL.dropdown-menu li.active,
  .light .leftMenu ul .submenuUL.dropdown-menu li:hover,
  .light .leftMenu ul .submenuUL.dropdown-menu li:focus,
  .light .leftMenu ul .submenuUL.dropdown-menu li.active:focus,
  .light .leftMenu ul .submenuUL.dropdown-menu li.active:hover,
  .light .leftMenu .topSideMenu .submenuUL.dropdown-menu li,
  .light .leftMenu .topSideMenu .submenuUL.dropdown-menu li,
  .light .leftMenu .bottomSideMenu .submenuUL.dropdown-menu li {
    background: #e8f1fd;
  }

  .dropdown-menu.submenuUL {
    margin-left: -2px;
  }

  .light .leftMenu ul .submenuUL.dropdown-menu li.active a {
    background-color: #e8f1fd;
  }

  .light .leftMenu ul.dropdown-menu.submenuUL > li > a {
    background: #e8f1fd;
  }

  .leftMenu ul li.active,
  .leftMenu ul li:hover {
    border-bottom: none;
  }

  [type="radio"]:checked + label,
  [type="radio"]:not(:checked) + label {
    position: relative;
    padding-left: 20px;
    cursor: pointer;
    line-height: 14px;
    display: inline-block;
    color: #e0e0e0;
  }

  .breadcrumsCls .breadcrumb {
    /* margin-top: 8px; */
    align-items: center;
  }

  .breadcrumb svg.pathInfo0 {
    position: relative;
    top: 5px;
  }

  .filterDiv .input-group .caret {
    margin-bottom: 7px;
    margin-left: 5px;
  }

  .filterDiv .input-group {
    max-width: 100%;
  }

  .machineAlert.heightDiv .noNotificationDiv {
    font-size: 12px;
  }

  #alertTiggerInterval {
    display: flex;
  }

  #selectInterval {
    width: 100%;
  }

  .or {
    font-size: 12px;
    margin-left: 5px;
  }

  .form-group.clsTB {
    padding-left: 2px;
  }

  #alertTrigrd {
    width: 41px;
  }

  .loginDropdown .dropdown-menu:after {
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    position: absolute;
    content: "";
    top: -12px;
    right: 76px;
  }

  .loginDropdown .dropdown-menu:after {
    border-bottom: 14px solid #272727;
  }

  .alertNumber {
    text-align: center;
    background: #cf3d31;
    color: #fff;
  }

  .alertContent {
    height: 79vh;
  }

  .dismissBtn button {
    padding: 6px;
  }

  .caret {
    margin-left: 41px;
  }

  .alertContentTxt,
  .alertNumber {
    font-family: "Montserrat-Regular";
  }

  .light .dropdown-menu.dropdown-lr.animated.flipInX {
    background-color: #fff;
    box-shadow: 5px 15px 19px rgba(0, 0, 0, 0.6);
  }

  /*monitoring*/
  .lastDateDiv {
    display: none;
  }

  .contentWrap .tabArea {
    margin-right: 0;
    height: calc(100vh - 125px);
    position: relative;
  }
  .live-map-tracking .contentWrap .tabArea{
    height: calc(100vh - 93px);
  }
  .expandMapWidth .BarStatus {
    margin-left: 0;
  }

  .tabArea .tabsName li:first-child a {
    margin-left: 5px;
  }

  .threeDView {
    margin: 7px 0 -8px 40px;
  }

  #allMachineList .eventDivs {
    margin-right: 0;
    width: 1000px;
  }

  .pannelWrap.overflowScrolls,
  .headerTable {
    width: 1000px;
  }

  .alertDivs {
    overflow-x: scroll;
  }

  .alertDivs {
    height: calc(100vh - 33.66rem);
  }

  .IconBeta {
    top: 0px;
    right: -8px;
    z-index: 9;
    cursor: pointer;
  }

  span.IconBeta.liveIConOEE {
    top: 1px;
    position: absolute;
    right: -18px;
  }

  .OnlyAlarmTxt .noHistoryAlarms {
    justify-content: flex-start;
    padding-left: 32%;
  }

  .imageMachine {
    height: auto;
    background: #1e1e1e;
    box-shadow: 0px 0px 8px rgb(0 0 0 / 8%);
    border-radius: 4px;
    display: flex;
    align-items: normal;
    justify-content: center;
    margin-bottom: 0px;
  }
  #overview .BarStatus {
    margin-bottom: 0px;
  }

  .imageMachine img {
    max-height: 380px !important;
    height: 100%;
    margin-top: 0;
    transform: scale(0.8);
  }

  .heightper100 .imageSvg {
    padding: 1px;
    background: transparent;
    float: left;
    border-radius: 4px;
    display: inline-flex;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    position: absolute;
    top: 55px;
  }

  .innerSlide {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 10px;
    border-radius: 4px;
    margin-bottom: 1.5vh;
    background: #2a2a2a;
  }

  .icon_text {
    display: flex;
    align-items: center;
    justify-content: center;
    /* flex-direction: column; */
  }

  .iconTxt {
    display: block;
    text-align: center;
    font-size: 16px;
    font-family: "Montserrat-Medium";
    /* padding-left: 0px; */
    color: rgba(255, 255, 255, 0.87);
  }

  #overview .slickcarousel .row .col-md-4 {
    padding-left: 5px;
    padding-right: 5px;
  }

  #slickcarousel .col-md-4,
  #slickcarousel.col-sm-4,
  #slickcarousel.col-xs-4 {
    position: relative;
    min-height: 1px;
  }

  #overview .SliderScroll {
    height: 100%;
  }

  .iconSlide {
    display: block;
    text-align: center;
    margin-top: 2px;
  }

  user agent stylesheet div {
    display: block;
  }

  .iconTxt,
  .outerSlideTxt {
    font-size: 12px;
  }

  :after,
  :before {
    box-sizing: border-box;
  }

  .lastDays {
    font-size: 10px;
    color: #a0a0a0;
    text-align: right;
    font-family: Montserrat-Regular;
    margin-top: 0;
    position: absolute;
    right: 20px;
  }

  #allMachineList .middleDivContent {
    height: calc(100vh - 3rem);
  }

  div#slickcarousel {
    height: initial;
  }

  .statusCardHolder .row {
    height: calc(100vh - 682px);
  }

  .machineOfflineTagDiv {
    display: none;
  }

  .EventTabDivs {
    overflow: scroll;
  }

  .EventTabDivs .pannelWrap.eventOverflowScroll {
    width: 1220px;
  }

  .eventTopBar.onlyEvent.noboderDrop .row .evntDorp {
    display: unset;
    width: auto;
    padding-left: 15px;
  }

  .onlyEvent .alertFilterDiv {
    margin-right: 0;
    margin-left: 28px;
  }

  /* Monitoring Tab Scroll */
  .mobileTabScroll {
    overflow: hidden;
  }

  #LiveMapTracking {
    min-width: 13.7rem;
  }

  #machineSettingsLi {
    min-width: 18rem;
  }

  #timeSeriesLi {
    min-width: 12rem;
  }

  #machineAlarmsLi {
    min-width: 14rem;
  }

  #liveBatchLi {
    min-width: 7rem;
  }

  #LiveMapTracking .tabArea .tabsName li.active a {
    margin: 10px 0;
  }

  .col-md-2.SideTabGroup {
    height: 35px;
    overflow: hidden;
    width: 30px;
    position: absolute;
    z-index: 999;
    border-radius: 28%;
    border-radius: 0 20% 20% 0;
    padding-left: 36px;
  }

  #KPI .col-md-2.SideTabGroup,
  #machine-settings .col-md-2.SideTabGroup {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.4);
  }

  .form-control.customForm {
    height: 28px;
    width: 91%;
  }
  .alertTrigrdDiv .form-control.customForm, .create_Machines .form-control.customForm {
    height: 32px;
  }
  #KPI .form-group.checkSpoc.onlyKPICheckbox input:checked+label:after{
    top: 0.1rem;
  }
  .row.heightTxtLable {
    display: flex;
    justify-content: left;
    align-items: center;
    width: 100%;
}
  #KPI .col-md-2.SideTabGroup:active,
  #KPI .col-md-2.SideTabGroup:hover,
  #KPI .col-md-2.SideTabGroup:focus,
  #machine-settings .col-md-2.SideTabGroup:active,
  #machine-settings .col-md-2.SideTabGroup:hover,
  #machine-settings .col-md-2.SideTabGroup:focus {
    background-color: #252525;
    box-shadow: 11px 10px 43px 4px rgba(0, 0, 0, 0.44);
    -webkit-box-shadow: 11px 10px 43px 4px rgba(0, 0, 0, 0.44);
    -moz-box-shadow: 11px 10px 43px 4px rgba(0, 0, 0, 0.44);
  }

  .light #KPI .col-md-2.SideTabGroup,
  .light #machine-settings .col-md-2.SideTabGroup {
    background-color: #fff;
    box-shadow: 1px 1px 10px rgb(0 0 0 / 10%);
  }

  .light #KPI .col-md-2.SideTabGroup:active,
  .light #KPI .col-md-2.SideTabGroup:hover,
  .light #KPI .col-md-2.SideTabGroup:focus,
  .light #machine-settings .col-md-2.SideTabGroup:active,
  .light #machine-settings .col-md-2.SideTabGroup:hover,
  .light #machine-settings .col-md-2.SideTabGroup:focus {
    background-color: #fff;
    box-shadow: 4px 4px 20px rgb(0 0 0 / 30%);
    -webkit-box-shadow: 4px 4px 20px rgb(0 0 0 / 30%);
    -moz-box-shadow: 4px 4px 20px rgb(0 0 0 / 30%);
  }

  .light .leftSideVerticalTab.TabActive {
    background: #e8f1fd;
  }

  .light .leftSideVerticalTab {
    box-shadow: none;
  }

  .col-md-2.SideTabGroup:active,
  .col-md-2.SideTabGroup:focus,
  .col-md-2.SideTabGroup:hover {
    overflow-y: auto;
    height: 1000%;
    max-height: calc(100vh - 174px);
    scrollbar-color: #cccccc #121212;
    scrollbar-width: thin;
    border-radius: 0 0 4px 4px;
    width: 63%;
    padding: 16px 16px 16px 0;
  }

  .col-md-2.SideTabGroup:active,
  .col-md-2.SideTabGroup:focus,
  .col-md-2.SideTabGroup:hover {
    box-shadow: 15px 6px 35px 7px rgba(0, 0, 0, 0.44);
    -webkit-box-shadow: 15px 6px 35px 7px rgba(0, 0, 0, 0.44);
    -moz-box-shadow: 15px 6px 35px 7px rgba(0, 0, 0, 0.44);
  }

  .SideTabGroup > .leftSideVerticalTab {
    margin-top: 0 !important;
  }

  .leftSideVerticalTab {
    border-radius: 4%;
    width: 100%;
    background: transparent;
    padding-left: 10px;
    border-left: 3px solid transparent;
    min-height: 40px;
    padding-top: 4px;
  }

  .SideTabGroup .Monitoring-arrow {
    transform: rotate(270deg) scale(1.5);
    margin: 10px -29px;
    position: absolute;
  }

  .KPIGroupContent .col-m6 {
    padding-right: 0;
    padding-left: 12px;
    margin-bottom: 33px;
  }

  .KPIGroupContentBg .GroupUpperTab {
    position: relative;
    height: 11%;
    padding: 0.5rem 1rem;
  }

  .mobclsalign {
    position: absolute;
    top: 7vh;
    left: 1rem;
  }
  #KPI .KPIGroupContentBg .svgCreate {
    right: 18px;
  }

  .kpigraph {
    height: 100%;
    width: 1000px;
    margin-top: 60px;
  }

  .kpigraph1 .formgroup {
    margin: 0;
    overflow-x: scroll;
    width: 100%;
    height: 100%;
  }

  .descriptionCreate {
    padding: 10px 24px;
  }

  .descriptionCreate .row:nth-child(1),
  .descriptionCreate .row:nth-child(2) {
    height: 78px;
  }
  .descriptionCreate .row:nth-child(3) {
    height: 82px;
  }

  .MachineSettingsGroupContent .GroupUpperTab .col-m6 {
    margin-left: -7px;
    margin-top: 5px;
  }

  .noActiveAlarms {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }

  .alertDivs .headerTable span:nth-child(7),
  .alertDivs .alarmRows span:nth-child(7) {
    width: 11%;
    float: left;
    text-align: center;
    display: block;
  }

  .TabActive {
    font-weight: normal;
    border-radius: 0;
    background: #2a2c2d;
    color: #5d97f6;
    border-left: 3px solid #5d97f6;
  }

  .light .TabActive {
    background: #e8f1fd;
    color: #4285f4;
    border-left: 3px solid #4285f4;
  }

  #alarms .iconTextRight,
  #events .iconTextRight {
    display: none;
  }

  .mobileviewPagination {
    width: 100%;
  }

  #events .comanPagination,
  #events .pagination {
    margin: 0;
  }

  .alertDivs .headerTable span:nth-child(3),
  .alertDivs .alarmRows span:nth-child(3) {
    width: 35%;
  }

  .alertDivs .headerTable span:nth-child(4),
  .alertDivs .alarmRows span:nth-child(4) {
    width: 19%;
  }

  .alertDivs .headerTable span:nth-child(5),
  .alertDivs .alarmRows span:nth-child(5) {
    width: 17%;
  }

  .slick-carousel.slickmarque.alarmData {
    overflow-x: scroll;
  }

  .alarmSlider,
  #events .pannelWrap.overflowScrolls,
  #events .headerTable {
    width: 1220px;
  }

  .noHistoryEvents {
    width: 1220px;
    text-align: left;
    padding-top: 160px;
    padding-left: 30%;
    height: calc(100vh - 29rem);
    justify-content: flex-start;
    align-items: flex-start;
  }

  .groupTitle {
    height: 40px;
    color: #fff;
    padding: 10px 50px 0 50px;
    font-size: 12px;
    display: block;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .light .groupTitle {
    color: #121212;
  }

  #machine-settings .GroupUpperTab .input-group .MuiAutocomplete-input,
  #KPI .GroupUpperTab .input-group .MuiAutocomplete-input {
    min-width: 330px;
    height: 2.5rem;
  }

  .KPIGroupContent .col-m6 {
    padding-right: 36%;
  }

  #machine-settings .svgCreate,
  #KPI .svgCreate {
    top: 51px;
  }

  #machine-settings .svgCreate {
    right: 20px;
  }

  .cardMachineSetList {
    height: calc(100vh - 24rem);
    margin-top: 12px;
    padding: 0 10px 0 16px;
    width: 97%;
  }

  .light .cardMachineSetList .comanPagination {
    background: #fff;
  }

  .cardKpiList {
    width: 99%;
    margin-top: 2.5rem;
    padding: 0 14px 0 12px;
    height: calc(100vh - 26rem);
  }

  .MachineSettingsGroupContentBg,
  .KPIGroupContentBg {
    margin-right: -15px;
  }

  .GroupUpperTab .configure-parameter-container {
    position: unset;
  }

  .rotateIcon {
    display: flex;
    justify-content: flex-end;
    margin-top: 16px;
    margin-right: 20px;
    margin-bottom: 8px;
    width: auto;
    float: right;
    position: absolute;
    right: 43px;
    z-index: 9;
    display: none;
  }

  .descriptionCreate.onlyKPIGraphs .timefilterdiv {
    margin-right: 81px;
  }

  /*create alert popup mobile view*/
  .customchecks {
    display: flex;
    flex-direction: column;
  }

  .modelboxopen.confirmPin .modelboxopenInnner {
    width: 100%;
    height: 50%;
  }

  #modelboxopenInnner4 .popupCloseButton {
    font-size: 14px;
  }

  .topDivContent {
    height: auto;
    margin: 8px 0 0 0;
  }

  .topDivContent .innerDivContent {
    width: 100%;
  }

  .topDivContent,
  .topDivContent .innerDivContent {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  #live-batch .tabContent,
  #live-batch .tabContentLast {
    width: 92%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0;
    height: auto;
    margin-top: 20px;
  }
  #live-batch .topDivTxt {
    width: 50%;
    text-align: left;
  }
  #live-batch .tabContentWrap {
    width: 50%;
    justify-content: left;
  }
  .tabContent {
    border-right: 0;
    height: 7vh;
    margin: 20px 0 0 0;
    padding: 0px 1% 75px 1%;
    position: relative;
  }
  .tabContent::after {
    position: absolute;
    content: "";
    top: 0;
    bottom: 0;
    right: 0;
    height: 0;
    width: 1px;
    margin: auto 0;
    background-color: rgba(153, 153, 153, 0.38);
  }
  .tabContentLast {
    margin-top: 20px;
  }

  .supportDivContent.col-sm-3 {
    width: 100%;
    margin: 18px 0 0 0;
  }

  .supportDivContent {
    height: 100%;
  }

  .prodTime,
  .prodRate {
    margin-top: 0;
  }

  /*Logout*/
  #logoutpage .innerPageNotFound,
  .errorBodyContent .pageNotFound .innerPageNotFound {
    position: absolute;
    left: 0;
    transform: translate(0, -40%);
    top: 40%;
    text-align: -webkit-center;
    padding: 16px;
  }
  #logoutpage .innerPageNotFound{
    width: 100%;
  }
  .headerErrors .logoError {
    left: 0;
    top: 0;
}

  .pageNotFound .errorHead {
    color: #1f1f1f;
    font-size: 16px;
    font-family: "Montserrat-Regular";
    margin: 0;
    padding: 24px 0 0 16px;
    text-align: left;
  }

  /*All notifications*/
  .mapDiv.allMachineList.allNotifications,
  .mapDiv.allMachineList.customAlerts,
  .mapDiv.allMachineList.down_time_tables,
  .mapDiv.allMachineList.allParameters {
    margin-left: 0;
  }

  .mapDiv.allMachineList.customAlerts {
    overflow: hidden;
    height: auto;
  }

  #allMachineList .eventDivs.CustomNotificationTable {
    height: calc(100vh - 198px);
    overflow: scroll;
  }

  .heightNoRecord.noHistoryAlarms.centerTxt {
    justify-content: left;
    padding-left: 30%;
    width: 1000px;
  }
  .onlyCustomAlert .pannelWrap.eventOverflowScroll {
    width: 1004px;
  }

  .mapDiv.allMachineList {
    overflow: scroll;
    height: calc(100vh - 130px);
    margin-left: 0;
    padding-top: 0px;
  }

  .mapDiv.allMachineList.down_time_tables {
    height: calc(100vh - 160px);
  }

  #overview .mapDiv.allMachineList {
    overflow-x: hidden;
  }

  #allMachineList .eventDivs .scrollTab {
    width: 1000px;
  }

  .AllNotificationTable .scrollTab .headerTable span:nth-child(1),
  .AllNotificationTable .scrollTab .pannelWrap span:nth-child(1) {
    width: 20%;
    margin-left: 0;
  }

  .AllNotificationTable .scrollTab .headerTable span:nth-child(2),
  .AllNotificationTable .scrollTab .pannelWrap span:nth-child(2) {
    width: 30%;
  }

  .AllNotificationTable .scrollTab .headerTable span:nth-child(3),
  .AllNotificationTable .scrollTab .pannelWrap span:nth-child(3) {
    width: 20%;
  }

  .AllNotificationTable .scrollTab .headerTable span:nth-child(4),
  .AllNotificationTable .scrollTab .pannelWrap span:nth-child(4),
  .AllNotificationTable .scrollTab .headerTable span:nth-child(5),
  .AllNotificationTable .scrollTab .pannelWrap span:nth-child(5) {
    width: 15%;
  }

  .AllNotificationTable .scrollTab .headerTable span:nth-child(1),
  .AllNotificationTable .scrollTab .pannelWrap span:nth-child(1),
  .AllNotificationTable .scrollTab .headerTable span:nth-child(2),
  .AllNotificationTable .scrollTab .pannelWrap span:nth-child(2),
  .AllNotificationTable .scrollTab .headerTable span:nth-child(3),
  .AllNotificationTable .scrollTab .pannelWrap span:nth-child(3),
  .AllNotificationTable .scrollTab .headerTable span:nth-child(4),
  .AllNotificationTable .scrollTab .pannelWrap span:nth-child(4),
  .AllNotificationTable .scrollTab .headerTable span:nth-child(5),
  .AllNotificationTable .scrollTab .pannelWrap span:nth-child(5) {
    float: left;
    text-align: center;
    display: block;
  }

  .dropdown-menu.dropdown-lr.animated.slideInRight {
    box-shadow: -11px 15px 23px -11px rgba(0, 0, 0, 0.8);
    -webkit-box-shadow: -11px 15px 23px -11px rgba(0, 0, 0, 0.8);
    -moz-box-shadow: -11px 15px 23px -11px rgba(0, 0, 0, 0.8);
  }

  .dropdown-menu.dropdown-lr.animated.flipInX {
    height: 100vh;
    width: 300px;
    box-shadow: -6px 5px 12px 0px rgba(0, 0, 0, 0.64);
    -webkit-box-shadow: -6px 5px 12px 0px rgba(0, 0, 0, 0.64);
    -moz-box-shadow: -6px 5px 12px 0px rgba(0, 0, 0, 0.64);
  }

  .modelboxopenInnner {
    width: 100%;
    height: 100%;
    text-align: left;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9;
    background: #1f1f1f;
    border-radius: 4px;
  }

  .contentPart
    #allMachineList
    .eventDivs.AllNotificationTable
    .eventOverflowScroll {
    height: calc(100vh - 23rem);
  }

  #allMachineList .eventOverflowScroll,
  #allMachineList .heightNoRecord.noHistoryAlarms {
    height: calc(100vh - 20rem);
  }

  .alertContentTxt {
    width: 50%;
  }
  .alertContentUl .alertContentTxt {
    width: 100%;
  }
  .timeSpan {
    width: 300px;
  }

  /*Report*/
  .textDm {
    width: 200px;
  }

  .oee__factors .flex3 {
    width: 1258px;
  }

  .downtime_box_graph {
    height: 50%;
  }

  .downtime_box_graph .recharts-responsive-container {
    max-height: 200px;
  }

  .downtime_box_graph .barchart__line,
  .downtime_box_graph .dmOccurence {
    height: 23vh;
  }

  button.MuiButtonBase-root.MuiButton-root.MuiButton-contained.MuiButtonGroup-grouped.MuiButtonGroup-groupedHorizontal.MuiButtonGroup-groupedContained.MuiButtonGroup-groupedContainedHorizontal.MuiButtonGroup-groupedContainedPrimary.comnEditDel.editGroupBtn.MuiButton-containedPrimary {
    margin-left: 36px;
  }

  /*OEE INSIGHTS*/
  .flextop,
  .flexbottom {
    height: max-content;
    display: flex;
    flex-direction: column;
  }

  .flexbottom {
    margin-top: 0;
  }

  .left_graph,
  .top__buttons {
    width: calc(100vh + 523px);
  }

  .right_graph {
    margin-left: 0;
    margin-top: 12px;
    width: calc(100vh + 521px);
  }

  .right__content {
    overflow: visible;
    margin-left: 0px;
  }

  .DatePickers-textField-5 {
    width: 216px;
  }

  /*HCM DOWNTIME*/

  .contentPart .full__right,
  .scrollonlyTripingTrends,
  .customReport.reportContainer,
  .tab-content.tab__flex {
    overflow: scroll;
  }

  .barchart__line,
  .dmOccurence {
    height: 21vh;
    width: 100%;
  }

  .tends__buttons .margtop10 {
    margin-left: 30px;
  }

  .hcmDowntimetab > li {
    float: left;
    margin-bottom: -1px;
    min-width: 190px;
  }

  .hcmDowntimeTabScroll {
    width: 383px;
  }

  .HCMheaderTable,
  .downtimeheaderTable,
  .scrollonlyTripingTrends,
  .downtimeanalysis_table .eventOverflowScroll {
    width: 1200px;
  }

  .tripping_analysis_table {
    width: 1220px;
  }

  .full__left {
    margin-left: 24px;
  }

  /*power bi report*/
  .aggregateReport.reportContainer {
    width: 100%;
    overflow-x: scroll;
  }

  .aggregate-report-style-class {
    width: 1460px;
    height: 87vh;
  }

  .report-style-class {
    width: 1400px;
    height: 80vh;
  }

  .top__buttons.hcm_trendsDiv {
    margin-top: 10px;
    margin-left: -6px;
  }

  .box_graph {
    margin-left: -4px;
  }

  /*Settings*/
  .appSetting .allCenter .form-group p:first-child {
    margin-right: 12px;
  }

  .appSetting .allCenter .form-group p {
    margin-right: 0;
  }

  .appSetting .rightDivSet {
    float: left;
    width: 270px;
  }

  /*pagination*/
  .AllNotificationTable .comanPagination {
    position: fixed;
    bottom: 0;
    left: 92px;
  }
}

@media only screen and (min-width: 375px) and (max-width: 635px) {
  #MachineList .col-md-4.cardMB14.pointer {
    width: 100%;
  }
}

@media only screen and (min-width: 636px) and (max-width: 767px) {
  .machine-list-text {
    margin: 6px 10px 10px 16px;
  }
}

@media only screen and (min-width: 375px) and (max-width: 415px) {
  #machine-settings .svgCreate {
    top: 46px;
  }

  #MachineList span.bookmarkCls.bookMarkIcon {
    top: 6px;
  }

  #alarms .alarmFilter {
    margin-left: 0px;
  }

  #alarms .overflowScrolls {
    height: calc(100vh - 412px);
  }
}

@media only screen and (max-width: 480px) {
  .onlyAllNotificationTable .heightNoRecord.noHistoryAlarms.centerTxt {
    padding-left: 122px;
  }

  .alertDivs .noHistoryAlarms {
    width: 1000px;
  }

  #allMachineList .eventDivs.CustomNotificationTable {
    width: 480px;
  }

  #live-batch .mapDiv.allMachineList {
    height: calc(100vh - 160px);
    overflow-x: hidden;
  }

  .backCloseDiv {
    width: 100%;
  }

  .machineAddress {
    max-width: calc(100% - 92px);
  }

  span.machineBack.pointer {
    right: 68px;
  }

  .modelboxopen2.create_Machines
    #modelboxopenInnner3
    .selectIntervalDiv
    .borderInput
    .caret {
    /* right: 1.5rem; */
    right: 0.5rem;
  }

  .col-md-3.selectIntervalDiv {
    padding: 0 0 0 4px;
  }

  .col-md-7.col-xs-8.alertCheckbox {
    padding: 0 0 0 10px;
  }

  [type="radio"]:checked + label,
  [type="radio"]:not(:checked) + label {
    padding-left: 20px;
  }

  .machineIconHolder {
    margin-left: 20px;
    margin-right: 5px;
    height: 20px;
  }

  .onlinebg .cardMachineRight .machineTxt,
  .offlinebg .cardMachineRight .machineTxt {
    max-width: 270px;
  }

  .machine-list-rightDivSet span {
    padding-left: 0px;
  }
  .filterDiv{
    height: 560px;
    /* height: 440px; */
    overflow: overlay;
  }
}

@media only screen and (max-width: 414px) {
  .filterDiv{
    position: absolute;
  }
  #overview .mapDiv.allMachineList {
    height: calc(100vh - 18rem);
    margin-bottom: 0px;
}
  .cardMachineSetListUL {
    height: calc(100% - 7rem);
  }
  .cardKpiListUL {
    height: calc(100% - 7rem);
  }
  .panellefttext {
    padding: 1rem 0.416rem 0.833rem 0;
}
  .alarmFilter.noboderDrop .alertFilterDiv:first-child {
    margin-left: 22px;
  }
  .side__Panel {
    max-height: calc(100vh - 263px);
  }
  .innerMachineDiv .panellefttext {
    padding-left: 0;
    margin-top: 7px;
  }
  #allMachineList .eventDivs.CustomNotificationTable {
    width: 414px;
  }
  .machineTitle .machineTitleUl .machineTxt {
    max-width: 242px;
  }
  #live-batch .mapDiv.allMachineList {
    height: calc(100vh - 235px);
  }
  .prodRate_dynamicData {
    bottom: 50px;
  }
  .nav > li.liItem1 {
    position: relative;
    top: 0px;
  }
  .nav > li.liItem2 {
    position: relative;
    top: -8px;
  }
  .breadcrumsCls {
    overflow-x: auto;
    overflow-y: hidden;
  }
  #alarms .alertDivs {
    /* height: calc(100vh - 37.66rem); */
  }
}

@media only screen and (max-width: 375px) {
  .breadcrumsCls.machineScroll .breadcrumb-item.active:nth-child(2) {
    width: 204px;
  }
  .mobileTabScroll {
    width: 345px;
    overflow-y: hidden;
  }
  .panelrighttext .comanPagination .pagination > li > a,
  .panelrighttext .comanPagination .pagination > li > span {
    padding: 3px 3px;
  }
  .panellefttext {
    margin-top: 2px;
  }
  #allMachineList .eventDivs.CustomNotificationTable {
    width: 375px;
  }
  #live-batch .mapDiv.allMachineList {
    height: calc(100vh - 201px);
  }

  #live-batch .tabContent {
    padding: 0;
  }

  .onlyEvent .alertFilterDiv {
    margin-left: 14px;
  }
  .alarmFilter.noboderDrop .alertFilterDiv:first-child {
    margin-left: 0;
  }
}

@media only screen and (max-width: 376px) {
  #KPI .svgCreate {
    top: 46px;
  }

  #KPI .cardKpiListUL .leftcardKPI .expandKPI {
    right: 8px;
  }

  #MachineList span.bookmarkCls.bookMarkIcon {
    top: 4px;
  }

  #alarms .overflowScrolls {
    height: calc(100vh - 345px);
  }

  .cardMachineSetListUL li,
  .cardKpiListUL li {
    width: 100%;
    margin-right: 0;
  }

  .cardMachineSetListUL li:nth-child(even),
  .cardKpiListUL li:nth-child(even) {
    margin-left: 0;
  }

  .wrap23 {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  #machine-settings .GroupUpperTab .input-group .MuiAutocomplete-input,
  #KPI .GroupUpperTab .input-group .MuiAutocomplete-input {
    min-width: 285px;
  }

  #machine-settings .GroupUpperTab .input-group .MuiAutocomplete-input,
  #KPI .GroupUpperTab .input-group .MuiAutocomplete-input {
    min-width: 300px;
  }

  .appSetting .allCenter .form-group p:first-child {
    margin-right: 7px;
  }
}

@media only screen and (max-width: 360px) {
  .searchDiv .MuiAutocomplete-input {
    width: 25rem;
  }
  body .searchDiv .MuiAutocomplete-input {
    width: 99.5%;
}
.cardKpiListUL li{
  height: auto;
}
  #allMachineList .eventDivs.CustomNotificationTable {
    width: 360px;
  }
  .breadcrumb .alertIcon {
    padding-left: 8px;
  }
  .breadcrumsCls div.bookmarkIcon {
    left: 8px;
  }
  .eventTopBar.onlyEvent.noboderDrop .row .evntDorp {
    width: 268px;
  }
  .onlyEvent .alertFilterDiv {
    margin-left: 8px;
  }
  .alarmFilter {
    margin-left: 0;
  }
  .prodRate_dynamicData,
  .prodTime_dynamicData {
    bottom: 20px;
  }
  #live-batch .mapDiv.allMachineList {
    height: calc(100vh - 221px);
  }
  .prodTime,
  .prodRate {
    margin-top: -20px;
  }
  .tabContent::after {
    top: 115%;
  }
  .modelboxopen2.create_Machines #modelboxopenInnner3 .footerPopupWithBtn {
    margin-top: 0px;
  }

  #machine-settings .svgCreate {
    top: 46px;
  }
  #KPI .svgCreate {
    top: 48px;
  }

  #machine-settings .GroupUpperTab .input-group .MuiAutocomplete-input,
  #KPI .GroupUpperTab .input-group .MuiAutocomplete-input {
    min-width: 285px;
  }

  #KPI .GroupUpperTab .col-m6 {
    margin-top: 8px;
  }

  #KPI .KPIGroupContentBg .GroupUpperTab {
    height: 8%;
  }

  .mobclsalign {
    top: 5.6vh;
  }

  .mobileTabScroll {
    width: 330px;
  }

  .backCloseDiv {
    width: 100%;
  }

  .longEnough {
    overflow: inherit;
  }

  .cardMachineSetListUL li,
  .cardKpiListUL li {
    width: 100%;
    margin-right: 0;
  }

  .cardMachineSetListUL li:nth-child(even),
  .cardKpiListUL li:nth-child(even) {
    margin-left: 0;
  }

  .wrap23 {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .aggregate-report-style-class {
    height: 85vh;
  }

  /*Settings*/
  .appSetting .allCenter .form-group p:first-child {
    margin-right: 4px;
  }
}

@media only screen and (max-width: 320px) {
  .contentWrap .tabArea .nav.nav-tabs.tabsName {
    overflow: hidden;
  }
  .machineTitle .machineTitleUl .machineTxt {
    max-width: 212px;
  }
  #MachineList span.bookmarkCls.bookMarkIcon {
    right: 17px;
    top: 1.5px;
  }
  .machineDescription .offlineview {
    padding: 8px 2px 5px 2px;
    width: 152px;
    margin: 22px 0;
  }

  .breadcrumsCls.machineScroll .breadcrumb-item.active:nth-child(2) {
    width: 165px;
  }
  .breadcrumsCls.machineScroll .breadcrumb-item.active:nth-child(2) .marqueeSn {
    padding-left: 160px;
  }
  .mobileTabScroll {
    width: 295px;
  }
  #machine-settings .GroupUpperTab .input-group .MuiAutocomplete-input,
  #KPI .GroupUpperTab .input-group .MuiAutocomplete-input {
    min-width: 245px;
  }

  .alertFilterDiv {
    min-width: 98px;
  }
  #alarms .borderInput .caret {
    right: 1rem;
  }
  .searchDiv .MuiAutocomplete-input {
    width: 22rem;
  }
  .dropdown .borderInput {
    width: 90%;
    padding: 1px 12px 1px 8px;
  }
  #events .borderInput .caret {
    right: 14px;
  }
  .onlyEvent .alertFilterDiv:nth-child(2) {
    margin-left: 0px;
  }
  .alarmFilter .alertFilterDiv:first-child,
  .appSetting .rightDivSet .form-group {
    margin-left: 10px;
  }
  .eventTopBar.onlyEvent.noboderDrop .row .evntDorp {
    width: 235px;
    display: flex;
  }
  .tabContentLast .tabContentWrap .topDivSupTxt {
    text-align: left;
  }
  .contentAreaPart {
    margin-right: 18px;
  }
  .appSetting .allCenter .form-group p:first-child {
    margin-bottom: 8px;
  }
  .appSetting .caret {
    right: 16px;
  }
}

@media only screen and (min-width: 635px) and (max-width: 767px) {
  .filterDiv.removedWidthh {
    width: 37%;
  }

  .filterDiv.removedWidth {
    display: block;
    width: 37%;
    padding: 24px 0 0 0;
  }

  #MachineList .filterDiv.removedWidth {
    width: 37%;
    padding: 24px 24px 0 24px;
    z-index: 11;
  }

  /*Overview*/
  .imageMachine {
    height: calc(100vh - 10px);
    margin-bottom: 12px;
  }

  .imageMachine img {
    transform: scale(0.8);
  }

  #overview .SliderScroll {
    height: calc(100vh + 25px);
  }

  /*KPI*/
  .cardKpiList {
    padding-top: 30px;
  }

  .KPIGroupContentBg .svgCreate {
    right: 30px;
    margin-top: -2px;
  }

  #machine-settings .svgCreate {
    margin-right: -3px;
  }

  .kpigraph1 .formgroup {
    overflow: scroll;
    height: 292px;
  }

  .MachineSettingsGroupContentBg,
  .KPIGroupContentBg {
    margin-right: -10px;
    margin-left: 14px;
  }

  .KPIGroupContent .col-m6 {
    padding-left: 15px;
    margin-bottom: 0px;
  }

  .col-md-2.SideTabGroup {
    margin-left: 16px;
  }

  /*kpi popup graph*/
  .alertFilterDiv {
    min-width: 110px;
  }

  .UnauthorizedAccess .modelboxopenInnner {
    width: 100%;
    height: 40%;
  }

  .modelboxopenInnner {
    overflow-x: hidden;
  }

  /*Alarms*/
  .tabArea .tab-content > .active {
    opacity: 1;
    height: calc(100vh - 121px);
    overflow-y: scroll;
    overflow-x: hidden;
  }

  .alarmWrapper {
    height: 1000px;
  }

  div#pannelWrap {
    height: 670px;
    overflow-y: hidden;
  }

  .alertDivs {
    overflow-y: hidden;
    height: 780px;
  }

  /*Live Batch*/
  .topDivContent,
  .supportDivContent {
    height: max-content;
  }

  /*MACHINE DOWNTIME*/
  .flex3.machineDowntimeFlex3,
  .trends__content .flex3 {
    height: 100vh;
  }

  .downtime_box_graph .barchart__line,
  .downtime_box_graph .dmOccurence {
    height: 37vh;
  }

  /*OEE INSIGHTS*/
  .left_graph,
  .right_graph {
    height: 63vh;
  }

  /*HCM DOWNTIME*/
  .recharts-wrapper {
    height: 500px !important;
  }

  .mapDiv.down_time_tables.allMachineList .recharts-wrapper {
    height: 150px !important;
  }

  .trends__content .barchart__line,
  .trends__content .dmOccurence {
    height: 37vh;
  }

  .top__buttons,
  .oee__factors .flex3 {
    width: calc(100vh + 885px);
  }

  .hcmDowntimeTabScroll {
    width: inherit;
  }
}

/* Landscape orientation */
@media screen and (max-device-width: 1024px) and (orientation: landscape) {
  .eventTopBar.onlyEvent.noboderDrop .row .evntDorp {
    display: flex;
    width: 36rem;
  }
  .col-md-3.col-sm-6.col-xs-6.evntDorp {
    padding-left: 12%;
  }
  .eventTopBar.onlyEvent.noboderDrop .row .evntDorp {
    float: right;
  }
}

@media screen and (min-device-width: 900px) and (max-device-width: 1024px) and (orientation: landscape) {
  .cardKpiListUL li,
  .cardMachineSetListUL li {
    width: 31%;
  }
  .mobileTabScroll {
    width: auto;
  }
}
@media screen and (min-device-width: 640px) and (max-device-width: 767px) and (orientation: landscape) {
  .mobileTabScroll {
    width: auto;
  }
  .alertContent {
    height: 66vh;
  }
  #overview .SliderScroll {
    height: calc(100vh + 85px);
  }
  #alarms .alarmFilter {
    float: right;
  }
  .alertDivs {
    height: 645px;
  }
  .eventTopBar.onlyEvent.noboderDrop .row .evntDorp {
    padding-left: 27rem;
  }
  .eventTopBar.onlyEvent.noboderDrop .row .evntDorp {
    float: none;
  }
}

@media screen and (max-device-width: 667px) and (orientation: landscape) {
  .eventTopBar.onlyEvent.noboderDrop .row .evntDorp {
    padding-left: 20rem;
  }
  .mobileTabScroll {
    width: auto;
  }
}
