
.AdminPanelContainer {
    height: 100%;
    width: 100%;
    overflow: hidden;
    display: grid;
    grid-template-rows: max-content auto;
}

.AdminPanel__body {
    overflow: hidden;
}

.AdminPanel__body>div {
    height: 100%;
    overflow: hidden;
    display: grid;
    grid-template-rows: auto max-content;
}
.noRecord {
    display: flex;
    justify-content: center;
    align-items: center;
}
