.EM__search{
    position: relative;
}
.EM__search .icon{
    position: absolute;
    top: 50%;
    right: 0.5rem;
    transform: translateY(-50%);
    pointer-events: none;
}
.EM__search .icon svg{
    width: 1.3rem;
    vertical-align: middle;
}
.EM__search input{
    width:100%;
}