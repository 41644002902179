.Edit__popup{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    min-width: fit-content;
    background-color: var(--secondry-color);
    padding: 1rem 2rem 3rem;
    z-index: 3;
    display: grid;
    grid-template-rows: max-content auto max-content;
    gap: 1rem;
    height: 80%;
}
.Edit__popupTitle{
    font-size: 2rem;
}
.Edit__popupHead{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.bg-layer{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(20 20 20 / 66%);
    z-index: 2;
}
.Edit__popupBody{
    display: grid;
    grid-template-columns: repeat(2,1fr);
    gap: 2rem;
}
.Edit__popupContentTitle{
    font-size: 1.5rem;
}
.Edit__popupContent{
    display: grid;
    grid-template-rows: max-content auto max-content;
    gap: 1rem;
}
.Edit__popupContentData{
    display: grid;
    grid-template-rows: max-content auto;
    gap: 1rem;
}
.Edit__popupContentListContainer{
    overflow: auto;
    position: relative;
    border: 1px solid var(--input-border-color);
}
.Edit__popupContentListContainer .EM_options{
    position: absolute;
    display: block;
    margin: 0;
    top: 0;
    left: 0;
    border: none;
    max-height:100%;
}
.Edit__popupFooter{
    display: flex;
    justify-content: flex-end;
}
.Edit__popupSave{
    
}
.Edit__close{
    cursor: pointer;
}
.Edit__popup .cnfmBtn {
    text-align: right;
}