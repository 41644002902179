:root {
  --primary: #5d97f6;
  --primary-background: #121212;
  --secondary-background: #252525;
  --grid-odd-color: #252525;
  --grid-even-color: #1e1e1e;
  --primary-font-color: #e0e0e0;
  --accordin-color: #2f2f2f;
  --border-color: #5D5E60;
  --tooltip-bg: #000000;
}

:root .light {

  --primary-background: #f2f4f6;
  --secondary-background: #fafbfc;
  --grid-odd-color: #fafbfc;
  --grid-even-color: #ffffff;
  --primary-font-color: #3b414b;
  --accordin-color: #fafbfc;
  --border-color: #a6aab4;
  --tooltip-bg: #ffffff;
}

.toolstips.MuiTooltip-popper span {
  overflow: auto;
  top: -0.3rem;
  color: var(--primary-font-color);
  background: var(--tooltip-bg);
  width: 1em;
  height: 1em;
  transform: rotate(45deg);
}

.toolstips.MuiTooltip-popper .MuiTooltip-arrow::before {
  display: none;
}

.cntact .row-6 span div {
  cursor: pointer !important;
}
.conditionMonitoring .panel-default>.panel-heading {
  background-color: var(--accordin-color);
  border: var(--accordin-color) solid 0.5px;
  border-radius: 4px;
  height: 3rem;
  color: #fff;
  padding: 1rem;
  margin-top: 10px;
}

.conditionMonitoring .panel-default>.panel-heading:after {
  content: '';
  background: url('../../../images/caret-1920.svg');
  float: right;
  position: relative;
  height: 1.1rem;
  width: 1.1rem;
  right: 0rem;
  margin-top: -0.8rem;
  background-repeat: no-repeat !important;
  background-size: 1rem !important;
}

.conditionMonitoring .panel-default>.panel-heading.active {
  background: var(--accordin-color);
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;

}

.conditionMonitoring .panel-default>.panel-heading.active:after {
  content: '';
  background: url('../../../images/caret-1920.svg');
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  margin-top: -1.4rem;
}

.conditionMonitoring .pannelWrap.overflowScrolls .panel-default>.panel-heading:after,
.conditionMonitoring .pannelWrap.overflowScrolls .panel-default>.panel-heading.active:after {
  content: none;
}

.conditionMonitoring .tab-content {
  padding-top: 1rem;
}

.conditionMonitoring .pannelWrap .panel-heading span {
  color: var(--primary-font-color);
  cursor: default !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.conditionMonitoring .edit svg:hover path {
  stroke: var(--primary);
  cursor: pointer !important;
}

.conditionMonitoring .pannelWrap .panel-heading span:hover {
  cursor: default !important;
}

.conditionMonitoring .panel-default>.panel-heading {
  border: var(--accordin-color) solid 0.5px;
  border-radius: 4px;
  height: 3rem;
  color: #fff;
  padding: 1rem;
  margin-top: 10px;

}

.conditionMonitoring .pannelWrap .panel-default>.panel-heading {
  background-image: none;
}



.conditionMonitoring .alertDivs .headerTable span:nth-child(6),
.conditionMonitoring .alertDivs .alarmRows span:nth-child(6) {
  width: 9%;
  float: left;
  text-align: center;
  display: block;
}

.conditionMonitoring .overflowScrolls {
  height: calc(100vh - 21.5rem);
}

.conditionMonitoring .icon-btn {
  padding: 0;
  min-width: auto !important;
  position: absolute;
  top: -3.5rem;
  right: 0px;
}

.conditionMonitoring .confirmPin .modelboxopenInnner {
  height: 94vh;
}

.conditionMonitoring .descriptionCreate {
  padding: 0rem;
  max-height: calc(100vh - 15rem);
  margin-bottom: 2rem;
  overflow: auto;
  float: left;
  width: 100%;
}

.conditionMonitoring .descriptionCreate .col-md-12 {
  padding: 1rem 2rem;
}

.conditionMonitoring .descriptionCreate .modal-panel:nth-child(3) .col-md-12 {
  border: none !important;
}

.conditionMonitoring .icon-btn img {
  height: 1.5rem !important;
}

.light .conditionMonitoring .tabArea .tab-content>.active {
  background: #fafbfc;
}

.dark .conditionMonitoring .tabArea .tab-content>.active {
  background: var(--grid-odd-color);
  border-radius: 4px;
}

.conditionMonitoring .alarmBar.padTopOnly.row.dropRow {

  padding: 1.1rem 1rem 0.83rem;
}

.conditionMonitoring .btn {
  font-size: 1.16rem;
  font-family: "Montserrat-Medium";
  border-radius: 6px;
  height: 3.33rem;
  min-width: 8.33rem;
}

.light .conditionMonitoring .panel-default>.panel-heading,
.light .conditionMonitoring .panel-default>.panel-heading+.panel-collapse>.panel-body {
  background-color: #ffffff !important;
  border: 0.5px solid #e4e7ec;
  color: #3b414b;
}

.conditionMonitoring .panel-default>.panel-heading:first-child {
  margin-top: 0;
}

.conditionMonitoring .pannelWrap .panel-default>.panel-heading:first-child {
  margin-top: 0;
  padding: 0 7px;
  border-color: transparent;
  border-radius: 0;
}

.conditionMonitoring .form-group label {
  font-family: "Montserrat-Regular";
  font-size: 1rem;
  font-weight: inherit;
  margin-bottom: 6px;
  margin-top: 6px;
}

.notification-template-editor .descriptionCreate {
  margin-top: 1rem;
}

.notification-template-editor .mt-2 {
  margin-top: 2rem;
}

.conditionMonitoring .descriptionCreate .col-md-12 {
  border-bottom: 1px solid #313131;
}

.light .conditionMonitoring .descriptionCreate .col-md-12 {
  border-bottom: 1px solid #eeeeee;
}

.conditionMonitoring .tabArea .tabsName li.active:first-child a {
  margin-left: 0;

}

.conditionMonitoring .text-area-color strong {
  background-color: transparent !important;
}

.conditionMonitoring .alertDivs .alarmRows span:nth-child(6) {
  cursor: pointer !important;
}

.conditionMonitoring .alertDivs .alarmRows span.active:nth-child(6) {
  color: #52b56d;
  cursor: pointer;
}

.conditionMonitoring .alertDivs .alarmRows span.inactive:nth-child(6) {
  color: #ed5f53;
  cursor: pointer;
}

.conditionMonitoring .input-group.pull-right.search-container svg {
  position: absolute;
  right: 1rem;
  top: 0.45rem;
  height: 1.1rem;
  width: 1.1rem;
}

.conditionMonitoring .input-group.pull-right.search-container .form-control {
  border-radius: 4px;
  height: 2.2rem;
  width: 34rem;
}

.conditionMonitoring .back.arrow svg {
  width: 1.1rem;
  height: 1rem;
  margin-right: 0.5rem;
  cursor: pointer;
}

.conditionMonitoring .back.arrow svg path {
  fill: var(--primary-font-color);
}

.conditionMonitoring .back.arrow svg:hover path {
  fill: var(--primary);
}

.conditionMonitoring .input-group.pull-right.search-container .form-control::placeholder,
.conditionMonitoring .panel-default>.panel-heading+.panel-collapse>.panel-body .MuiInputBase-root input[value=""]::placeholder,
.MuiInputBase-input::placeholder,
.MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input::placeholder,
.conditionMonitoring .panel-default>.panel-heading+.panel-collapse>.panel-body .input-control fieldset::placeholder,
.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]::placeholder,
.conditionMonitoring .MuiFormControl-root.MuiTextField-root.MuiFormControl-marginNormal .MuiInputBase-input {
  color: #8f8f8f !important;
  font-size: 0.83rem;
  font-family: "Montserrat-Regular";
  opacity: 1 !important;
}

.conditionMonitoring .MuiFormControl-root.MuiTextField-root.MuiFormControl-marginNormal .MuiInputBase-input {
  color: var(--primary-font-color) !important;
}

.conditionMonitoring .MuiOutlinedInput-notchedOutline {
  border: none;
}

.conditionMonitoring .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child {
  padding: 0;
}

.conditionMonitoring .enterpins .MuiAutocomplete-root {
  margin-top: 0.66rem;
}

.conditionMonitoring .alertDivs .alarmRows span:nth-child(7) {
  color: #5d97f6;
  cursor: pointer !important;
}

.conditionMonitoring .panel.panel-default {
  background-color: transparent;

}

.conditionMonitoring .row0.rotate {
  padding: 0;
}

.conditionMonitoring .panel-default>.panel-heading+.panel-collapse>.panel-body {
  border: var(--accordin-color) solid 0.5px;
  border-radius: 4px;
}

.conditionMonitoring .panel-title {
  font-family: "Montserrat-Medium";
  font-size: 1rem;
  font-weight: normal;
}

.conditionMonitoring .panel-default>.panel-heading+.panel-collapse>.panel-body input,
.conditionMonitoring .panel-default>.panel-heading+.panel-collapse>.panel-body select,
.conditionMonitoring .panel-default>.panel-heading+.panel-collapse>.panel-body textarea {
  background: #1f1f1f;
  border: #5D5E60 solid 0.5px;
  color: var(--primary-font-color);
  height: 2.66rem !important;
  border-radius: 4px;
  padding: 0.33rem 1rem;
  font-family: "Montserrat-Regular";
  font-size: 0.83rem !important;
  outline: none;
}

.light .conditionMonitoring .panel-default>.panel-heading+.panel-collapse>.panel-body input,
.light .conditionMonitoring .panel-default>.panel-heading+.panel-collapse>.panel-body select,
.light .conditionMonitoring .panel-default>.panel-heading+.panel-collapse>.panel-body textarea {
  background: transparent;
  border-color: #a6aab4;
  color: #3b414b;

}

.conditionMonitoring .MuiInput-underline:after {
  display: none !important;
}

.conditionMonitoring .customchecks .form-group input:checked+label:after {
  top: 0.07rem;
  border: var(--primary-background) solid 0.1rem;
  left: 0.0611rem;
  width: 1.12rem;
  height: 1.12rem;
}

.light .conditionMonitoring .customchecks .form-group input:checked+label:after {
  top: 0.07rem;
  border: #ffffff solid 0.1rem;
  left: 0.0611rem;
  width: 1.12rem;
  height: 1.12rem;
}

.light .conditionMonitoring .pannelWrap .panel-default:nth-child(2n + 1) .panel-heading {
  background: #fafbfc !important;

}

.conditionMonitoring .MuiInput-underline:hover:not(.Mui-disabled):before,
.conditionMonitoring .MuiInput-underline:before {
  border-bottom: none !important;
}

.conditionMonitoring .btn-primary {
  color: #1f1f1f !important;
  font-family: "Montserrat-Medium";
  border-color: var(--primary) !important;
  background-color: var(--primary) !important;
}

.conditionMonitoring .btn-primary:hover,
.conditionMonitoring .btn-primary:focus,
.conditionMonitoring .btn-primary:active {
  background-color: #6AA2FF !important;
  border-color: #6AA2FF !important;
}

.light .conditionMonitoring .btn-primary {
  border-color: #4285F4 !important;
  background-color: #4285F4 !important;
  color: #ffffff !important;

}

.light .conditionMonitoring .btn-primary:hover,
.light .conditionMonitoring .btn-primary:focus,
.light .conditionMonitoring .btn-primary:active {
  background-color: #296FEA !important;
  border-color: #296FEA !important;

}

.conditionMonitoring .btn-primary:hover,
.conditionMonitoring .btn-primary:focus,
.conditionMonitoring .btn-primary:active {
  background-color: #6AA2FF;
  border-color: #6AA2FF;
}

.conditionMonitoring .btn.add-entry-button {
  background: var(--accordin-color);
  color: var(--primary) !important;
  margin-left: 10px;
  height: 2.7em;
  padding: 0.3rem 1rem;
  font-size: 0.93rem;
}

.conditionMonitoring .btn.add-entry-button:hover,
.conditionMonitoring .btn.add-entry-button:focus,
.conditionMonitoring .btn.add-entry-button:active {
  background: var(--accordin-color);
  color: #6AA2FF !important;
}

.light .conditionMonitoring .btn.add-entry-button:hover,
.light .conditionMonitoring .btn.add-entry-button:focus,
.light .conditionMonitoring .btn.add-entry-button:active {
  color: #6AA2FF !important;
}

.light .conditionMonitoring .btn.add-entry-button {

  background: #edf0f3;
  border-color: #edf0f3;
  margin-left: 10px;
  height: 2.366rem;
}

.light .conditionMonitoring .btn.add-entry-button:hover,
.light .conditionMonitoring .btn.add-entry-buttonfocus,
.light .conditionMonitoring .btn.add-entry-buttonactive {
  background: #edf0f3 !important;
  color: #6AA2FF !important;
}

.conditionMonitoring .btn-outline {
  background: transparent;
  color: var(--primary);
}

.conditionMonitoring .btn-outline:hover,
.conditionMonitoring .btn-outline:focus,
.btn-outline:active {
  background: #272727;
  color: var(--primary) !important;
}

.light .conditionMonitoring .btn-outline {
  background: transparent;
  color: var(--primary);
}

.conditionMonitoring .btn svg {
  margin-right: 0.93rem;
  height: 0.93rem;
  width: 0.93rem;
  position: relative;
  top: 0.1rem;
}

.conditionMonitoring .btn.add-entry-button svg path {
  stroke: var(--primary) !important;
}

.conditionMonitoring .btn.add-entry-button:hover svg path,
.conditionMonitoring .btn.add-entry-button:focus svg path,
.conditionMonitoring .btn.add-entry-button:active svg path {
  stroke: #6AA2FF !important;
}

.light .conditionMonitoring .btn-outline:hover,
.light .conditionMonitoring .btn-outline:focus,
.light .conditionMonitoring .btn-outline:active {
  background-color: #E0E4EA !important;
  color: var(--primary) !important;
}

.conditionMonitoring .btn-secondary {
  background: var(--accordin-color);
  color: #ffffff;
}

.conditionMonitoring .btn-secondary:hover,
.conditionMonitoring .btn-secondary:focus,
.conditionMonitoring .btn-secondary:active {
  background: #272727;
  color: #ffffff !important;
}

.light .conditionMonitoring .btn-secondary {
  color: #3b414b;
  background: #edf0f3;
  border-color: #edf0f3;
}

.light .conditionMonitoring .btn-secondary:hover,
.light .conditionMonitoring .btn-secondary:focus,
.light .conditionMonitoring .btn-secondary:active {
  color: #3b4143 !important;
  background-color: #E0E4EA !important;
  border-color: #E0E4EA !important;
}

.conditionMonitoring .btn img {
  margin: 0 0.3rem;
  height: 1rem;
  position: relative;
  bottom: 0.1rem;
}

.conditionMonitoring .recommended .btn {
  width: auto !important;
  min-width: auto !important;
  height: 2.66rem;
  padding: .5rem;
}

.light .conditionMonitoring .recommended .btn {
  width: auto !important;
  min-width: auto !important;
  height: 2.66rem;
  background: #edf0f3;
  border-color: #B6B6B7;
}

.conditionMonitoring .panel-default>.panel-heading+.panel-collapse.collapse.in>.panel-body {
  border-top-right-radius: 0px !important;
  border-top-left-radius: 0px !important;
  background: #1f1f1f;
  max-height: calc(100vh - 18rem);
  overflow: auto;
  padding: 1.3rem;
}

.conditionMonitoring .critical-label {
  margin-left: 0;
}

.conditionMonitoring .MuiInputBase-root,
.conditionMonitoring .descriptionCreate .MuiInputBase-root,
#edit-notification-group-fields .MuiInputBase-root {
  padding-right: 24px;
  background: #1f1f1f;
  border: #5D5E60 solid 0.5px;
  color: var(--primary-font-color);
  height: 2.66rem !important;
  border-radius: 4px !important;
  padding: 6px 12px !important;
  font-family: "Montserrat-Regular";
  font-size: 0.83rem;
  box-shadow: none;
}

.light .conditionMonitoring .MuiInputBase-root,
.light .conditionMonitoring .descriptionCreate .MuiInputBase-root {
  background: transparent;
  border: #a6aab4 solid 0.5px;
  color: #3b414b;
}

.conditionMonitoring .input-control .MuiAutocomplete-input,
.conditionMonitoring .input-control .Mui-focused input {
  font-size: 0.83rem;
  font-family: FontAwesome, Montserrat-Regular;
  padding: 0.41rem 0.83rem;
  margin-top: 0;
  color: var(--primary-font-color);
  background: transparent;
  box-sizing: border-box;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.02);
  border-radius: 4px;
  outline: none;
}

.conditionMonitoring .panel-default>.panel-heading+.panel-collapse>.panel-body .input-control input,
.conditionMonitoring .panel-default>.panel-heading+.panel-collapse>.panel-body .MuiInputBase-root input {
  border: none !important;
  height: 1.2rem !important;
}

.conditionMonitoring .panel-default>.panel-heading+.panel-collapse>.panel-body .input-control .Mui-focused.Mui-focused.MuiAutocomplete-inputRoot,
.light .conditionMonitoring .MuiInputBase-root.Mui-focused,
.conditionMonitoring .MuiInputBase-root.Mui-focused {
  border: var(--primary) solid 0.5px !important;
  box-shadow: none !important;
  outline: none;
}

.conditionMonitoring .panel-default>.panel-heading+.panel-collapse>.panel-body .input-control .Mui-focused.Mui-focused fieldset,
.conditionMonitoring .panel-default>.panel-heading+.panel-collapse>.panel-body .input-control fieldset,
.conditionMonitoring .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border: none;
  box-shadow: none !important;
}


.conditionMonitoring .no-gutters {
  padding: 0 !important;
}

.conditionMonitoring .panel-default>.panel-heading+.panel-collapse>.panel-body .recommended textarea {
  width: calc(100% - 3.5rem);
  float: left;
  margin-right: 10px;
  height: 80px !important;
}

.conditionMonitoring .radio-button.customchecks .form-group label:before {
  border-radius: 1rem !important;
}

.conditionMonitoring .recommended img {
  margin: 0;
  ;
}


.conditionMonitoring .no-gutters {
  padding: 0 !important;
}

.conditionMonitoring .panel-default>.panel-heading+.panel-collapse>.panel-body .recommended textarea {
  width: calc(100% - 54px);
  float: left;
  margin-right: 10px;
  height: 80px !important;
}

.conditionMonitoring .radio-button.customchecks .form-group label:before {
  border-radius: 1rem !important;
}

.conditionMonitoring .recommended img {
  margin: 0;
  ;
}


.conditionMonitoring .critical-label>.icon::before {
  border-color: #131212 !important;
}

.conditionMonitoring .critical-label>.icon {
  background: #3b3b3b;
}

.light .conditionMonitoring .critical-label>.icon {
  background: #ffffff;
}

.conditionMonitoring .tooltip_formula {
  cursor: pointer;
}

.conditionMonitoring .recommended-action-list .card {
  background: var(--accordin-color);
  border-radius: 4px;
  padding: 10px 10px 5px;
  margin-top: 10px;
}

.conditionMonitoring .recommended-action-list .card .fa {
  cursor: pointer;
}

.light .conditionMonitoring .recommended-action-list .card {
  background: #edf0f3;
}

.conditionMonitoring .mb-15 {
  margin-bottom: 15px;
}

.conditionMonitoring .grid-header .btn {
  margin-left: 1.66rem;
}

/* Autocomplete */

.conditionMonitoring .input-control .form-group input {
  background: transparent;
  border: none;
}

.conditionMonitoring .input-control .input-group .caret {
  /* content: "^"; */
  /* background: url("../images/dropdownArrowDown.png") center center transparent no-repeat; */
  transition: transform 0.5s ease-in-out;
  color: transparent;
  position: absolute;
  top: 50%;
  right: 0.83rem;
  transform: translateY(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  border: 0;
}

.conditionMonitoring .input-control .comboDiv.datepick .Mui-focused input {
  padding: 0.41rem 0;
}

.conditionMonitoring .input-control .comboDiv.datepick .MuiInputBase-input,
.conditionMonitoring .MuiInputBase-root {
  color: var(--primary-font-color);
}

.conditionMonitoring .customchecks .form-group {
  margin-bottom: 0;
  ;
}

.light .conditionMonitoring .input-control .comboDiv.datepick .MuiInputBase-input,
.conditionMonitoring .MuiInputBase-root {
  color: #3b414b;
}

.conditionMonitoring .input-control .comboDiv .date_range .MuiInputBase-input,
.conditionMonitoring .input-control .comboDiv .date_range .Mui-focused input,
.conditionMonitoring .input-control .comboDiv .date_range .MuiInputBase-root {
  padding: 0.08rem 0;
  width: 100% !important;
}

.conditionMonitoring .input-control .comboDiv .date_range .MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-formControl {
  padding: 1px 0;
}

.conditionMonitoring .input-control .comboDiv .date_range .MuiInputBase-root.Mui-focused.MuiInput-root.MuiInput-underline.MuiInputBase-formControl {
  min-width: 14.66rem;
}

.conditionMonitoring .input-control .comboDiv .date_range svg {
  fill: var(--primary-font-color);
}

.light .conditionMonitoring .input-control .comboDiv .date_range svg {
  fill: #3b414b;
}

.light .conditionMonitoring .input-control .MuiAutocomplete-input,
.light .conditionMonitoring .input-control .Mui-focused input {
  color: #3b414b;
}

.conditionMonitoring .comboDiv .input-group [aria-expanded="true"] .caret {
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
}

.conditionMonitoring .MuiAutocomplete-listbox {
  width: 100%;
  border-radius: 0 0 4px 4px;
  margin: -0.16rem 0 0;
  max-height: 21vh;
  overflow: auto;

}

.conditionMonitoring .MuiAutocomplete-option[data-focus="true"] a {
  color: white;
  text-decoration: none;
}

.conditionMonitoring .MuiAutocomplete-option a {
  color: white;
  text-decoration: none;
}

.light .conditionMonitoring .MuiAutocomplete-option a {
  color: black;
  text-decoration: none;
}

.conditionMonitoring .input-control .input-group {
  width: 100%;
  box-sizing: border-box;

}

.conditionMonitoring .dropdown.disabledDropDown .borderInput,
.conditionMonitoring .disabledDropDown .btn-default.disabled.focus,
.conditionMonitoring .disabledDropDown .btn-default.disabled:focus,
.conditionMonitoring .disabledDropDown .btn-default.disabled:hover,
.conditionMonitoring .disabledDropDown .btn-default[disabled].focus,
.conditionMonitoring .disabledDropDown .btn-default[disabled]:focus,
.conditionMonitoring .disabledDropDown .btn-default[disabled]:hover,
.conditionMonitoring .disabledDropDown fieldset[disabled] .btn-default.focus,
.conditionMonitoring .disabledDropDown fieldset[disabled] .btn-default:focus,
.conditionMonitoring .disabledDropDown fieldset[disabled] .btn-default:hover {
  background-color: #3b3b3b;
}

.conditionMonitoring .disabledCls {
  cursor: not-allowed;
}

.conditionMonitoring .disabledDropDown .disabledCls {
  cursor: not-allowed;
}

.conditionMonitoring .disabledAuto .MuiAutocomplete-input {
  cursor: not-allowed;
}

.conditionMonitoring .noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.conditionMonitoring .enterGroupName {
  position: absolute;
  z-index: 9;
  width: 16.66rem;
  left: 20.83rem;
  top: 0;
}

.conditionMonitoring ul.enterGroupNameUL {
  padding: 0;
  margin: 0;
  list-style: none;
}

.conditionMonitoring .enterGroupName .inputDiv.form-control {
  background: #2a2a2a;
  border-radius: 4px !important;
  padding-top: 0;
  padding-bottom: 0;
  height: 2.33rem;
  border-color: #2a2a2a;
  font-size: 0.83rem;
  color: #fff;
}

.light .conditionMonitoring .enterGroupName .inputDiv.form-control {
  background: #fff;
  color: #000;
}

.conditionMonitoring .tabArea .nav-tabs.tabUlsInner.margTab a {
  margin: 0.25rem 0.83rem;
}

.conditionMonitoring ul.nav.nav-tabs.tabsName.tabUlsInner.margTab {
  height: 3.16rem;
}

.conditionMonitoring .ddivClsMargin .input-group {
  display: block;
}

.conditionMonitoring .titleClass {
  font-family: Montserrat-Medium;
  text-align: left;
  font-size: 1rem;
  color: var(--primary-font-color);
  margin-bottom: 0.83rem;
  margin-top: 0.5rem;
}

.light .conditionMonitoring .titleClass {
  color: #58585a;
}

.conditionMonitoring .MuiButtonGroup-contained .floatLeft {
  float: left;
  margin-top: 1.66rem;
  height: 2.33rem;
  padding: 0.5rem 0;
  width: 8.33rem;
  font-family: montserrat-Regular;
}

.conditionMonitoring .clsWrapCheck .MuiButtonGroup-contained,
.conditionMonitoring .input-control .MuiButtonGroup-contained {
  box-shadow: none;
}

.conditionMonitoring .input-control .MuiInputBase-input,
.conditionMonitoring .MuiInputBase-root {
  padding: 0.08rem 0;
  font-size: 0.83rem;
  font-family: "Montserrat-Regular";
}

.conditionMonitoring .input-control .MuiInput-underline:after,
.conditionMonitoring .input-control .MuiInput-underline:before,
.conditionMonitoring .input-control .MuiInput-underline:hover:not(.Mui-disabled):before {
  border-bottom: 0;
}

.conditionMonitoring .MuiSwitch-sizeSmall {
  margin-left: 4.66rem;
}

.conditionMonitoring ::-webkit-calendar-picker-indicator {
  filter: invert(1);
  cursor: pointer;
}

.conditionMonitoring .ddivClsMargin input,
.conditionMonitoring .form-control.customForm,
.conditionMonitoring .searchParatr,
.conditionMonitoring .MuiAutocomplete-root {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.conditionMonitoring .leftSideVerticalTab span.TabTxt {
  margin-left: 1rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: block;
  margin-right: 1rem;
}

.conditionMonitoring .DatePickers-container-5 label+.MuiInput-formControl {
  margin-top: 0;
}

.conditionMonitoring .DatePickers-textField-6 .MuiFormLabel-root {
  display: none;
}

.conditionMonitoring label.font10.mainDateRange {
  display: block;
}

.conditionMonitoring .input-control .DatePickers-textField-6 .MuiAutocomplete-input,
.conditionMonitoring .input-control .DatePickers-textField-6 .Mui-focused input {
  padding: 1px 0;
}

.conditionMonitoring .input-control .Mui-focused input[type="date"] {
  padding: 1px 0;
}

.conditionMonitoring .input-control.MuiInputBase-root input[value=""],
.conditionMonitoring .panel-default>.panel-heading+.panel-collapse>.panel-body .MuiInputBase-root input[value=""] {
  color: #a0a0a0 !important;
  border: none !important;
  height: 2rem !important;
}

.conditionMonitoring .panel-default>.panel-heading+.panel-collapse>.panel-body .MuiInputBase-root input[value=""]::placeholder {
  color: #8f8f8f !important;
  font-size: 0.83rem;
  font-family: "Montserrat-Regular";
  opacity: 1 !important;
}

.notification-group .alertDivs .row-0 {
  width: 30% !important;
  text-align: left;
  padding-left: 0.5rem;
}

.notification-group .alertDivs .row-1 {
  width: 50% !important;
  text-align: left;

}

.notification-group .alertDivs .row-2 {
  width: 20% !important;
  text-align: left;
  float: left;
}

.notification-group .alertDivs .row-2 span {
  color: var(--primary);
  cursor: pointer;
}

.conditionMonitoring.notification-group .panel-default>.panel-heading+.panel-collapse.collapse.in>.panel-body {

  max-height: calc(100vh - 10rem);
}

.conditionMonitoring .valid-formula,
.conditionMonitoring .invalid-formula {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 5px;
}

.conditionMonitoring .valid-formula {
  background-color: lawngreen;
}

.conditionMonitoring .invalid-formula {
  background-color: red;

}

.contact-details {
  border-top: var(--accordin-color) solid 1px;
  padding-top: 1rem;
  margin-top: 1rem;
}



.notification-group .contact-details .alertDivs .row-1 {
  width: 15% !important;
  text-align: left;
}

.notification-group .contact-details .alertDivs .row-2 {
  width: 15% !important;
  text-align: left;
}

.notification-group .contact-details .alertDivs .row-3 {
  width: 20% !important;
  text-align: left;
}

.notification-group .contact-details .alertDivs .row-4 {
  width: 20% !important;
  text-align: left;
}

.notification-group .contact-details .alertDivs .row-5 {
  width: 20% !important;
  text-align: left;
}

.notification-group .contact-details .alertDivs .row-6 {
  width: 10% !important;
  float: left;
  text-align: left !important;
}

.notification-group .contact-details .alertDivs .row-6 span {
  cursor: pointer;
  color: var(--primary);
}

.contact-details .font12 {
  font-weight: inherit;
  padding-top: 10px;
}

.contact-details .child-header {
  padding: 10px 0;
}

.conditionMonitoring.notification-group .contact-details .overflowScrolls {
  height: calc(100vh - 32.5rem);
}

.conditionMonitoring.notification-group .contact-details .pannelWrap.overflowScrolls {
  height: calc(100vh - 34rem);
}

.conditionMonitoring.notification-group .contact-details .alertDivsBg {

  border-radius: 4px;
  height: calc(100vh - 31rem);
}

.conditionMonitoring.notification-group .contact-details .alertDivs {
  height: calc(100vh - 31rem);
}

.notification-group .dropdown .btn-default,
.notification-group .dropdown .btn-default:focus,
.notification-group .dropdown .btn-default:hover,
.notification-group.dropdown .btn-default.active,
.notification-group .dropdown .btn-default:active,
.notification-group .open>.dropdown-toggle.btn-default {
  height: 2.66rem;
}

.notification-group .comboDiv>div>div>div {
  padding: 0;
}

.conditionMonitoring .descriptionCreate .btn-default.dropdown-toggle {


  height: 2.66rem !important;
  border-radius: 4px;
  padding: 6px 12px !important;
  font-family: "Montserrat-Regular";
  font-size: 0.83rem;
}

.conditionMonitoring .descriptionCreate .comboDiv>div>div>div {
  padding-right: 0;
}

.conditionMonitoring .confirmPin .footerPopupWithBtn .saveBtn:hover,
.conditionMonitoring .confirmPin .footerPopupWithBtn .saveBtn:focus {
  background-color: #6AA2FF !important;
}

.conditionMonitoring .alertDivs .headerTable span.hide,
.conditionMonitoring .alertDivs .alarmRows span.hide {
  display: none;
}

.conditionMonitoring .alertDivs .headerTable span:nth-child(3),
.conditionMonitoring .alertDivs .alarmRows span:nth-child(3) {

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 27%;
}

.conditionMonitoring .alertDivs .headerTable span:nth-child(4),
.conditionMonitoring .alertDivs .alarmRows span:nth-child(4) {

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 40%;
}

.notification-group>div.panel.panel-default.alarmRows>div.panel-collapse.collapse.in>div>div>div:nth-child(2)>div.undefined>div>div.col-md-4>div>.redStar {
  font-size: 1.18rem !important;
}


.notification-template .alertDivs .row-0 {
  width: 20% !important;
  text-align: left;
  padding-left: 5rem;
}

.notification-template .alertDivs .row-1 {
  width: 30% !important;
  text-align: left;

}

.notification-template .alertDivs .row-2 {
  width: 30% !important;
  text-align: left;
}

.notification-template .alertDivs .row-3 {
  width: 20% !important;
  text-align: left;
  float: left;
}

.notification-template .alertDivs .row-3 span {
  color: var(--primary);
  cursor: pointer;
}

#template-field>div>div {
  font-size: 0.83rem !important;

}

#template-field>div>div>textarea {
  font-size: 0.83rem !important;
  padding: 0.75rem 1rem !important;
  border-radius: 4px;
  outline: none;
  border: 1px solid #5d5e60;
}

.light #template-field>div>div>textarea {
  border: 1px solid #a6aab4;
}

#template-field>div>div>textarea:focus {
  border-color: var(--primary) !important;
}

#template-field>div>div:nth-child(2)>ul>li>div>div {
  float: right;
  font-size: .8rem;
  padding: 6px 12px !important;
}

#edit-notification-group-fields>div>.error-field>div>div>form>#template-field>div>div>textarea,
.conditionMonitoring .error-field textarea,
#edit-notification-group-fields>div>div>.error-field,
#edit-notification-group-role-field>.error-field,
#notification-group-name-text>.error-field,
#notification-group-name-text .error-field input,
#notification-group-name-text .error-field textarea {
  border: 0.5px solid #ea4335 !important;
  outline: none;
}

.conditionMonitoring .mb-8 {
  margin-bottom: 3rem !important;
}

.conditionMonitoring .delete .descriptionCreate,
.conditionMonitoring .delete .modelboxopenInnner {
  height: auto !important;
}

.conditionMonitoring .delete .descriptionCreate .col-md-12 {
  border: none;
}

#edit-notification-group-fields .text-area-color div div:nth-child(2),
#edit-notification-group-fields .text-area-color div div:nth-child(2) ul {
  height: 200px !important;
  overflow: auto !important;
}

.light .conditionMonitoring #template-field>div>div:nth-child(2)>ul {
  background: #fff !important;
}

.light .conditionMonitoring .modelboxopen .text-area-color div div:nth-child(2) ul {
  background: #f1f1f1 !important;
  box-shadow: rgba(0, 0, 0, 0.25) 2px 2px;
}

.conditionMonitoring .text-area-color div div:nth-child(2),
.conditionMonitoring .text-area-color div div:nth-child(2) ul {
  height: 200px !important;
  overflow: auto !important;
}

.light .conditionMonitoring .text-area-color div div:nth-child(2) ul {
  background: #f1f1f1 !important;
  box-shadow: rgba(0, 0, 0, 0.25) 2px 2px;
}

.conditionMonitoring .text-area-color ul li div,
.conditionMonitoring .text-area-color div.user div:nth-child(2) {
  height: auto !important;
}

.conditionMonitoring .error-field__input {
  border-color: #ea4335 !important;
}

.conditionMonitoring .redStar {
  color: #ea4335;
  font-size: 1.83rem;
  padding: 0 0.08rem;
  position: relative;
  top: 0.33rem;
  line-height: 0px;
}

.conditionMonitoring input.error-field,
.conditionMonitoring select.error-field {
  border: 0.5px solid #ea4335 !important;
}

.conditionMonitoring [type="radio"]:checked+label,
.conditionMonitoring [type="radio"]:not(:checked)+label {
  color: var(--primary-font-color);
}

.light .conditionMonitoring [type="radio"]:checked+label,
.conditionMonitoring [type="radio"]:not(:checked)+label {
  color: #666666;
}

.toolstips.MuiTooltip-popper .fia-desc-head {
  font-size: 1rem;
  font-family: "Montserrat-Medium" !important;
}

.toolstips.MuiTooltip-popper .MuiTooltip-tooltip {
  padding: 1rem !important;
  background: var(--tooltip-bg);
  color: var(--primary-font-color);
  border-radius: 0.4rem !important;
  line-height: 20px !important;
  max-width: 500px !important;
  font-family: "Montserrat-Regular" !important;
  box-shadow: -4px -4px 8px -1px rgba(0, 0, 0, 0.08), 4px 4px 8px -1px rgba(0, 0, 0, 0.08);
}


.toolstips.MuiTooltip-popper span {
  font-size: 0.83rem;
  font-family: "Montserrat-Regular" !important;

}

.notification-template-editor .formula-dropdown textarea {
  overflow: auto !important;
}

.notification-template-editor .formula-dropdown div:nth-child(1) div:nth-child(1) div:nth-child(1) {
  height: 200px !important;
}

#notification-dialog>div>div>div.contentPopup>div>div>div>div>div>div.enterpins.placeColor>div {
  position: relative;
}

.conditionMonitoring .contact-details .headerTable {
  background: var(--grid-odd-color);
  border-radius: 4px;
}

.conditionMonitoring .contact-details .pannelWrap .panel-default:nth-child(2n + 1) .panel-heading,
.conditionMonitoring .pannelWrap .panel-default:nth-child(2n + 1) .rowDiv,
.conditionMonitoring .pannelWrap .panel-default:nth-child(2n + 1) .rowDiv:nth-child(2n + 1) {

  background-color: var(--grid-even-color);
  border: 0.5px solid transparent;
  border-radius: 0;
}

.conditionMonitoring .contact-details .pannelWrap .panel-default>.panel-heading,
.conditionMonitoring .kpiPara .panel-heading:nth-child(2n) {
  background: var(--grid-odd-color);
  border: none;
  border-radius: 0;
}

.light .conditionMonitoring .contact-details .headerTable {
  background: #fafbfc !important;
}

.light .conditionMonitoring .contact-details .pannelWrap .panel-default:nth-child(2n + 1) .panel-heading,
.light .conditionMonitoring .pannelWrap .panel-default:nth-child(2n + 1) .rowDiv,
.light .conditionMonitoring .pannelWrap .panel-default:nth-child(2n + 1) .rowDiv:nth-child(2n + 1) {
  background-color: #ffffff !important;
  border: 0.5px solid transparent;
}

.light .conditionMonitoring .contact-details .pannelWrap .panel-default>.panel-heading,
.light .conditionMonitoring .kpiPara .panel-heading:nth-child(2n) {
  background: #fafbfc !important;
}

.notification-template-editor .radio-label label.font14 {
  font-size: 0.83rem;
}

.conditionMonitoring .MuiAutocomplete-popupIndicator svg {
  display: none !important;
}

.conditionMonitoring .MuiAutocomplete-popupIndicator .MuiIconButton-label::after {
  display: inline-block;
  content: "";
  background: url('../../../images/caret-1920.svg') no-repeat center;
  color: #000;
  line-height: 1;
  width: 0.8rem;
  height: 1rem;
  background-size: contain;

}

.conditionMonitoring .radio-label label {
  padding-left: 1.6rem !important;
}




/*Multiselect dropdwon style*/

.MuiMenu-paper {
  outline: 0;
  background: #1f1f1f !important;


}

.light .MuiMenu-paper {
  background: #ffffff !important;

}

.MuiMenu-list .MuiTypography-body1 {
  color: #fff;
  font-family: "Montserrat-Regular";
  font-size: 0.83rem;
}

.MuiOutlinedInput-notchedOutline {
  border: none;
}

.light .MuiMenu-list .MuiTypography-body1 {
  color: #3b414b;

}

.MuiSelect-icon {
  color: #ffffff !important;
}

.light .MuiSelect-icon {
  color: #3b414b !important;
}

.MuiCheckbox-colorSecondary.Mui-checked {
  color: var(--primary) !important;
}

.MuiCheckbox-root {
  color: #8f8f8f !important;
}

.MuiInput-underline.Mui-focused:after {
  display: none !important;
}

.light .MuiIconButton-label {
  color: #4285f4 !important;
}

.MuiIconButton-colorSecondary:hover {
  background-color: rgba(93, 151, 246, 0.04) !important;
}

.MuiSelect-select:focus {
  background: transparent !important;
}

.MuiSelect-select.MuiSelect-select {
  color: var(--primary-font-color);
}

.custom-multiselect-mui .MuiSvgIcon-root.MuiSelect-icon path {
  display: none;
}

.custom-multiselect-mui svg.MuiSvgIcon-root.MuiSelect-icon {
  display: inline-block;
  background: url('../../../images/caret-1920.svg') no-repeat center;
  color: #000;
  line-height: 1;
  width: 0.8rem;
  height: 1rem;
  background-size: contain;
  margin-right: 0.4rem;
  margin-top: 0.3rem;
}

/*Multiselect dropdwon style*/
.conditionMonitoring .MuiInputBase-root svg path {
  display: none;
}

.conditionMonitoring .MuiInputBase-root .MuiSelect-icon {
  display: inline-block;
  background: url('../../../images/caret-1920.svg') no-repeat center;
  line-height: 1;
  width: 0.8rem;
  height: 1rem;
  background-size: contain;
  margin-right: 0.6rem;
  margin-top: 0.3rem;
  top: auto !important;
}

.tooltip_formula svg {
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  margin-top: -0.3rem;
}

.tooltip_formula svg:hover path {
  fill: #4285F4;
  stroke: #ffffff;
}

.tooltip_formula svg:hover path:first-child {
  stroke: none;
}

.notification-group .MuiInputBase-root {
  height: 2.66rem;
}

.notification-template-editor svg {
  margin-top: 0.7rem;
}

.conditionMonitoring.descriptionCreate .drop-text {
  font-size: 0.83rem;
}

.notification-template-editor .contentPopup,
.notification-template-editor {
  max-height: calc(100vh - 300px) !important;
  overflow: auto;
}

.delete-modal .footerPopupWithBtn .cancelBtn:hover {
  color: var(--primary-font-color) !important;
  background-color: #3e3e3e !important;
  border-color: #3e3e3e;
}

.delete-modal .footerPopupWithBtn .cancelBtn:active,
.delete-modal .footerPopupWithBtn .cancelBtn:focus,
.delete-modal .footerPopupWithBtn .cancelBtn.active {
  background: var(--accordin-color) !important;
  border-color: var(--accordin-color);
  color: var(--primary-font-color) !important;
}

.conditionMonitoring #failure-mode-form .panel-default>.panel-heading+.panel-collapse.collapse.in>.panel-body {
  max-height: calc(100vh - 18rem);
}

.Addfailurenotification.conditionMonitoring #failure-mode-form .panel-default>.panel-heading+.panel-collapse.collapse.in>.panel-body {
  max-height: calc(100vh - 14rem);
}

.conditionMonitoring .recommended-action-list label:first-child {
  width: calc(100% - 100px);
  word-break: break-word;
}

.conditionMonitoring .sub-grid .headerTable {
  background: var(--accordin-color) !important;
  border-radius: 4px;
}

.conditionMonitoring .sub-grid {
  float: left;
  width: calc(100% - 30px);
  border: var(--accordin-color) solid 1px;
  border-radius: 4px;
  margin-left: 15px;
  margin-bottom: 15px;
}

.light .conditionMonitoring .sub-grid .headerTable {
  background: #ffffff !important;
}

.light .conditionMonitoring .sub-grid {
  border: 1px solid var(--border-color);
}

.conditionMonitoring .sub-grid .row0,
.conditionMonitoring .sub-grid .row1,
.conditionMonitoring .sub-grid .row2 {
  width: 33.33% !important;
  display: block;
  float: left;
  text-align: left;
  padding: 0;
}

.conditionMonitoring .sub-grid .panel-heading {
  padding: 1rem;
  float: left;
  width: 100%;
}

.notification-group.conditionMonitoring .borderInput .caret::after {
  display: inline-block;
  background: url('../../../images/caret-1920.svg');
  line-height: 1;
  width: 0.8rem;
  height: 1rem;
  background-size: contain;
  content: '';
  padding: 0;
  margin: 0.7rem 0 0;
  background-repeat: no-repeat;
  left: 0.23rem;
  position: relative;
}

.conditionMonitoring .error-field .MuiInputBase-root {
  border: 0.5px solid #ea4335 !important;
}

.conditionMonitoring .panel-default>.panel-heading+.panel-collapse>.panel-body textarea {
  padding-top: 0.75rem !important;
}

.conditionMonitoring .form-control.customForm,
.notification-template-editor .form-control.customForm {
  font-size: 0.83rem;
}

@media (max-width:1599px) {

  .conditionMonitoring .panel-default>.panel-heading.active:after,
  .conditionMonitoring .panel-default>.panel-heading:after {
    background-size: contain;
    font-size: 1px;
  }
}

.conditionMonitoring .contact-details .no-records .headerTable {
  background: var(--accordin-color);
}

.notification-template-editor .formula-dropdown div:nth-child(1) div:nth-child(1) div:nth-child(1) {
  height: auto !important;
}

.notification-template-editor .formula-dropdown strong {
  background: transparent !important;
}

.conditionMonitoring.notification-group .panel-default>.panel-heading+.panel-collapse>.panel-body textarea {
  height: 80px !important;
}

.conditionMonitoring .noHistoryAlarms {
  height: calc(100vh - 18.5rem);
}

.conditionMonitoring .pinErrorTxt {
  color: #ea4335;
}

.MuiAutocomplete-noOptions {
  color: var(--primary-font-color) !important;
  font-size: 0.83rem;
}

.conditionMonitoring .folder svg {
  position: relative;
  float: right;
  bottom: 1.8rem;
  right: 0.5rem;
  width: 1rem;
}

.notification-group .back.arrow,
.conditionMonitoring .back.arrow {
  float: left;
  flex: auto;
}

.notification-group.conditionMonitoring .grid-header {
  display: block;
}


.conditionMonitoring .edit svg {
  width: 1.1rem;
}

.conditionMonitoring .grid-header {
  display: flex;
  align-items: center;
}

.conditionMonitoring .hover-row .panel.panel-default.alarmRows:hover .panel-heading {
  background-color: var(--accordin-color) !important;
  box-shadow: #04040426 -1px 2px 6px 2px;
  z-index: 1;
  position: relative;
  cursor: pointer !important;
}

.conditionMonitoring .hover-row .panel.panel-default.alarmRows:hover .panel-heading span {
  cursor: pointer !important;
}

.execution-result .alertDivs .row1 {
  width: 16% !important;
}
.execution-result .alertDivs .row4 {
  width: 16% !important;
}
.execution-result .alertDivs .row2 {
  width: 16% !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  display: block;
  float: left;
}
.execution-result .alertDivs .row3{
  width: 25% !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  display: block;
  float: left;
}
/* .execution-result .alertDivs .row0,
.execution-result .alertDivs .row3,
.execution-result .alertDivs .row5 {
  width: 20% !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  display: block;
  float: left;
} */
/* .execution-result .alertDivs .row4{
  width: 35% !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  display: block;
  float: left;
} */
.mt-1{
  margin-top: 0.83rem;
}
.alert-notifications .alertDivs [class*="row"] {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  display: block;
  float: left;
}

.alert-notifications .alertDivs .row0 {
  width: 4% !important;
}

.alert-notifications .alertDivs .row1,
.alert-notifications .alertDivs .row2,
.alert-notifications .alertDivs .row3,
.alert-notifications .alertDivs .row4,
.alert-notifications .alertDivs .row5,
.alert-notifications .alertDivs .row6,
.alert-notifications .alertDivs .row7 {
  width: 8% !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
}

.alert-notifications .alertDivs .row8,
.alert-notifications .alertDivs .row9,
.alert-notifications .alertDivs .row10,
.alert-notifications .alertDivs .row11 {
  width: 10% !important;
}

.alert-notifications .scrollabletab {
  width: 150%;
}

.alert-notifications .alert-notification-table {
  overflow-x: auto;
  width: 100%;
}

.modelBox {
  height: auto !important;
  width: 50rem !important;
}

textarea.text-area {
  resize: none;
  height: unset !important;
}

ul.alert-notify {
  margin: 0 !important;
  padding: 0 !important;
  margin-bottom: 0.25rem !important;
  max-height: unset !important;
  border: 0 !important;
  overflow: hidden;
}

ul.alert-notify li a {
  margin-left: 0 !important;
}

.date-picker.form-control .MuiInput-underline:before {
  border-bottom: 0 !important;
}

.date-picker.form-control .MuiInputBase-root {
  color: #e0e0e0 !important;
}

.execution-filter .eventOverflowScroll {
  height: calc(100vh - 17rem);
}

.execution-filter .filterDiv {
  width: 94%;
  top: 0 !important;
  height: calc(100vh - 10.9rem);
}

.alert-notifications .filterInnerDiv,
.alert-notifications .filterDiv {
  top: 0;
}

.execution-result .alarmBar.padTopOnly.row.dropRow,
.alert-notifications .alarmBar.padTopOnly.row.dropRow {
  flex-direction: row-reverse;
}

.execution-result .filterInnerDiv,
.execution-result .filterDiv {
  top: 0 !important;
}

.execution-filter .filterDiv .input-group {
  opacity: 1;
}

.execution-filter .MuiFormControl-marginNormal {
  margin-top: 0;
  margin-bottom: 0;
  width: 100%;
}

.execution-filter .filterDiv .MuiInputBase-root input[value=""] {
  text-transform: none;
}

.conditionMonitoring .font10+svg {
  margin-left: 0.2rem;
}

.conditionMonitoring .contact-details .noHistoryAlarms {
  float: left;
  width: 100%;
}

.conditionMonitoring.cm_report .tabArea .tab-content>.active,
.conditionMonitoring.execution-result .tabArea .tab-content>.active,
.conditionMonitoring.alert-notifications .tabArea .tab-content>.active {
  background: transparent !important;
  padding-right: 15px;
}

.notification-template-editor #edit-notification-group-fields .mui-date-picker .MuiInputBase-root {
  border: none !important;
  padding: 0 !important;
}

.cm-reports-bg {
  background: var(--grid-odd-color);
  height: 100%;
  /* margin-right: 1rem; */
  border-radius: 4px;
}

.cm-filter [class*='DatePickers-textField-'].MuiFormControl-root {
  width: auto;
  margin: 0;
}

.conditionMonitoring .mui-date-picker .input-group {
  display: block;
}

.conditionMonitoring .mui-date-picker .input-group .MuiInputBase-input,
#edit-notification-group-fields .mui-date-picker .input-group .MuiInputBase-input {
  color: var(--primary-font-color);
}

#edit-notification-group-fields .mui-date-picker .MuiInputBase-root {
  border-color: var(--border-color) !important;
  padding: 0 0.83rem !important;
  height: 2.66rem;
  background: transparent !important;
}

.mui-date-picker .MuiInput-underline:after,
.mui-date-picker .MuiInput-underline:before {
  display: none;
}

#edit-notification-group-fields .MuiInput-underline:hover:not(.Mui-disabled):before {
  border-bottom: 0 !important;
}

.conditionMonitoring .cm-filter .mui-date-picker .MuiInputBase-root {
  padding-right: 0.1rem !important;
}

.mui-date-picker svg path,
.mui-date-picker svg {
  fill: var(--primary-font-color) !important;
  stroke: var(--primary-font-color) !important;
  color: var(--primary-font-color) !important;
}

.alert-notifications .alertDivsBg {
  /* height: calc(100vh - 20.1rem); */
}
.alert-notifications .fmeatabb .alertDivsBg {
  height: calc(100vh - 15.33rem);
}
.conditionMonitoring.alert-notifications .overflowScrolls {
  height: calc(100vh - 21.4rem);
}

#edit-notification-group-fields .width-100 {
  width: 100%;
}

.mui-date-picker input[type="datetime-local"]::-webkit-calendar-picker-indicator {
  opacity: 0.3;
}

.light .mui-date-picker input[type="datetime-local"]::-webkit-calendar-picker-indicator {
  filter: grayscale(200);
}

.closepopup {
  cursor: pointer;

}

.dashboard-card label {
  display: inline-flex;
  flex: auto;
  align-items: center;
  margin: 0;
}

.conditionMonitoring.alert-notifications .t.active .cm-reports-bg,
.conditionMonitoring.execution-result .t.active .cm-reports-bg {
  /* margin-left: 0.8rem; */
}

.conditionMonitoring.alert-notifications .active .cm-reports-bg,
.conditionMonitoring.execution-result .active .cm-reports-bg {
  margin-left: 0rem;
}

.execution-result .cm-reports-bg .headerTable span,
.conditionMonitoring.execution-result .alertDivs .alarmRows span {
  text-align: left;
}

.conditionMonitoring .alertDivs .panel-heading .edit span:hover svg path {
  stroke: var(--primary);
}

.conditionMonitoring .alertDivs .panel-heading .edit span:hover {
  cursor: pointer !important;
}

.alert-notifications .alertDivs .alarmRows span:nth-child(7) {
  color: inherit;
  cursor: default !important;
}

.alert-notifications .alertDivs .alarmRows span:nth-child(2) {
  color: var(--primary);
  cursor: pointer !important;
}

.email-smsalert ul {
  overflow-y: hidden !important;
}

.email-smsalert .row-md-16 {
  width: 70%;
}

.email-smsalert .form-control.customForm textarea {
  padding: 0.7rem 1rem;
}

.email-smsalert .timestamp-badge {
  height: 2rem;
  border-radius: 6px;
  font-size: 0.83rem;
  background: var(--accordin-color);
  padding: 0.45rem 1rem;
  color: var(--primary-font-color);
}

.email-smsalert .alert-notify {
  border-bottom: var(--accordin-color) solid 1px !important;
  margin-bottom: 2rem !important;
}

.viewpage .form-group {
  opacity: 0.7;
}

.alert-notifications .alarm-pagination {
  margin: 0;
}

.conditionMonitoring .confirmPin .modelboxopenInnner {
  height: auto;
}

.modelboxopenInnner .noHistoryAlarms {
  height: calc(100vh - 24.5rem);
}

.email-smsalert .form-group {
  display: inline-block;
  width: 95%;
}

.email-smsalert .fa {
  position: relative;
  left: 10px;
}

.email-smsalert .fa.fa-times {
  color: red;
}

.email-smsalert .fa.fa-check {
  color: green;
}
#FailureCondition .alertDivs .headerTable span:nth-child(8), 
#FailureCondition .alertDivs .alarmRows span:nth-child(8),
#MaintenanceCondition .alertDivs .headerTable span:nth-child(8), 
#MaintenanceCondition .alertDivs .alarmRows span:nth-child(8),
.gd-tabledata .alertDivs .headerTable span:nth-child(8), 
.gd-tabledata .alertDivs .alarmRows span:nth-child(8) {
  width: 0%;
}

.MuiRadio-colorSecondary.Mui-checked{
  color:#5d97f6 !important;
}

.conditionMonitoring .pannelWrap .panel-default>.panel-heading>h4 {
font-weight: normal !important;
}

.notification-group .notigrp-note {
position: relative;
top: 30px;
}
.notification-group .notigrp-note p{
color:#f15a5a;
}

.exresult .resolved {
  color: #77cb77 !important;
}
.exresult .underline {
  text-decoration: underline;
  text-align: center !important;
}
.exresult .row6 {
  text-align: center !important;
}