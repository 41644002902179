.iomatrixContent {
  padding: 2rem 0;
}
.iotmatrixRow {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.iotmatrixCol {
  width: calc(33.33% - 0.6rem);
  margin-bottom: 1rem;
}
.iotmatrixInput {
  padding: 6px;
  background: #252525 !important;
  color: #fff;
  border: 1px solid #252525 !important;
  width: 60%;
  border-radius: 0 !important;
}
.iotmatrixCol label {
  margin-bottom: 0.6rem;
  display: block;
}
button:not(:first-child) {
  margin-left: 0.6rem;
}
.iomatrixForm{
  margin-top: 2rem;
}
.iomatrixTime{
  margin-left: 1rem;
}
.submitSection{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.iotmatrixDownload{
  margin-left: 1rem;
}
.iotmatrixDownload a{
  color: var(--sky-blue);
  margin-left: 1rem;
}

