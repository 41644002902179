.od_sweetAlert {
    background-color: #1f1f1f;
    text-align: left;
}
.od_sweetAlert .swal-text{
    color: #fff;
    margin-left: 4%;
}
.od_sweetAlert .swal-button {
  padding: 5px 24px !important;
}