/* Machine List Div Start*/

.machineListDiv {
    width: 0;
    height: 100%;
    background: transparent;
    position: absolute;
    right: 0;
    color: #fff;
    top: 0.833rem;
    z-index: 1302;
  }
  
  .cardMachineRight {
    background: #262626;
    height: 13rem;
    width: 100%;
    float: left;
    padding: 0 1rem;
    border-top: none;
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.08);
    border-radius: 0;
    margin-top: 1.33rem;
  }
  
  .light .cardMachineRight {
    background: #ffffff;
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.08);
    border: 0.5px solid #e4e7ec;
  }
  
  .col-md-12.pointer:first-child .cardMachineRight {
    /* margin-top: 0; */
  }
  
  .machineRightImg {
    float: left;
    width: 30%;
    text-align: center;
    padding-top: 0.833rem;
  }
  
  .offlinebg .machineRightImg {
    opacity: 0.9;
  }
  
  .mapLayer {
    overflow: hidden;
    height: 100vh;
    margin-left: 0;
    padding-top: 0.833rem;
    border-radius: 0 4px 4px 0;
    background: rgb(18 18 18 / 0.5);
    width: 0;
    position: absolute;
    left: 0;
    right: 0;
    z-index: 0;
    top: 0.833rem;
  }
  
  .mapLayerVisible.mapLayer {
    width: 100%;
  }
  
  .machineRightImg img {
    width: 100%;
    height: auto;
    max-width: 6.66rem;
  }
  
  .machineRightDescription {
    float: right;
    width: 70%;
    padding-top: 0.833rem;
  }
  
  .machineModeRight {
    float: left;
  }
  
  .machineSinceRight {
    float: right;
  }
  
  .machineModeRight,
  .machineSinceRight {
    width: 50%;
    height: 3.33rem;
    margin-bottom: 0.833rem;
  }
  
  .modetextB {
    font-family: "Montserrat-Medium";
    font-size: 1rem;
    color: rgba(204, 204, 204, 0.87);
    float: left;
    width: 100%;
    text-align: center;
  }
  
  .light .modetextB {
    color: #3b414b;
  }
  
  .modetextN {
    font-size: 0.833rem;
    color: rgba(204, 204, 204, 0.87);
    float: left;
    width: 100%;
    line-height: 1.5rem;
    text-align: center;
  }
  
  .light .modetextN {
    color: #58585a;
  }
  
  .light .machineModeRight,
  .light .machineSinceRight {
    text-align: center;
  }
  
  .light .machineListTag {
    color: #3b414b;
  }
  
  .onoffTxtRight {
    border-radius: 8px;
    font-size: 0.833rem;
    text-align: center;
    font-family: "Montserrat-Regular";
    width: 3rem;
    height: 1.33rem;
    line-height: 15px;
    float: left;
    margin-left: 64%;
  }
  
  .onTxtRight {
    background: rgba(88, 167, 104, 0.1) !important;
    border: 1px solid #58a768;
    color: #58a768;
  }
  
  .offTxtRight {
    background: rgba(88, 167, 104, 0.1) !important;
    border: 1px solid #4c4c4c;
    color: #4c4c4c;
  }
  
  .longEnough {
    height: calc(100vh - 10.5rem);
    overflow: auto;
    background: #333;
    border-radius: 0 0 4px 0;
    position: relative;
    z-index: 9;
  }
  .searchCross .btn-default:hover {
    background-color: transparent;
  }
  .light .longEnough .loadmorebtn button:hover span {
    color: #ffffff;
  }
  .longEnough button#kpiSeeMore,
  .longEnough button#kpiSeeLess {
    margin-top: 1.25rem;
  }
  .longEnough .row.mb14 {
    margin-bottom: 0;
  }
  .light .longEnough {
    /* background: rgb(255, 255, 255); */
    box-shadow: none;
    background: #F5F5F5;
    border: 0.5px solid #E4E7EC;
    box-shadow: -4px 0px 20px rgb(0 0 0 / 10%);
  }
  
  .mCSB_inside > .mCSB_container {
    margin-right: 1.66rem;
  }
  
  .mCS-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
    background: #999999 !important;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
  }
  
  .backCloseDiv {
    display: flex;
    background: #333;
    vertical-align: middle;
    align-items: center;
    position: relative;
    z-index: 9;
    justify-content: space-between;
    padding: 0.58rem 1.33rem;
  }
  
  .light .backCloseDiv {
    /* background: #ffffff; */
    background: #F5F5F5;
    /* border: 0.5px solid #E4E7EC; */
    box-shadow: -4px 0px 20px rgb(0 0 0 / 10%);
  }
  .machineListDiv.widthListView {
    padding-right: 0;
    padding-left: 0;
  }
  button#kpiSeeMore,
  button#kpiSeeLess {
    margin-top: 0.66rem;
  }
  #MachineList button#kpiSeeMore,
  #MachineList button#kpiSeeLess {
    margin-top: 0;
  }
  
  .backCloseDiv svg:hover path,
  .light .backCloseDiv svg:hover path {
    stroke: #5d97f6;
  }
  
  .closeIcon {
    /* padding: 0.66rem;
    line-height: 1;
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #333;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    transition: all 0.5s ease-in-out; */
  }
  .light .closeIcon {
    /* background-color: #fff; */
    /* background: #F5F5F5;
    border: 0.5px solid #E4E7EC;
    box-shadow: -4px 0px 20px rgb(0 0 0 / 10%); */
  }
  .widthListView .closeIcon {
    /* transform: translateX(-100%); */
  }
  .machineListTxt {
    font-family: "Montserrat-Medium";
    font-size: 1rem;
    color: rgba(255, 255, 255, 0.87);
  }
  
  .light .machineListTxt {
    color: rgba(65, 65, 65, 0.87);
  }
  
  .machineAddCount {
    width: calc(100% - 4.83rem);
    display: flex;
    align-items: center;
  }
  
  .machineAddress {
    font-family: "Montserrat-semibold";
    font-size: 1rem;
    color: #e0e0e0;
    width: auto;
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .machineAddCount {
    display: flex;
    align-items: center;
    max-width: 90%;
  }
  
  .light .machineAddress {
    color: #3b414b;
  }
  
  .widthZero {
    width: 0;
    margin-right: -1.66rem;
  }
  
  .widthListView {
    width: 33.33rem;
  }
  
  .widthZero,
  .widthListView {
    -webkit-transition: width 0.5s ease-in-out;
    -moz-transition: width 0.5s ease-in-out;
    -o-transition: width 0.5s ease-in-out;
    transition: width 0.5s ease-in-out;
  }
  
  /* Machine List Div End*/
  
  /* MachineList CSS in BarkMode Start */
  
  .no-parameter-available {
    font-size: 1rem;
    color: #e0e0e0;
    height: 100%;
    font-family: "Montserrat-Regular";
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
  }
  
  .light .no-parameter-available {
    color: #3b414b;
  }
  
  .cardMachine {
    background: #1f1f1f;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    height: 15rem;
    min-width: 25rem;
    width: 100%;
    float: left;
    padding: 0 1rem;
  }
  .cardMachine.machineTabHome:hover{
    box-shadow: 6px 6px 20px rgba(0, 0, 0, 0.2), -12px 12px 40px rgba(0, 0, 0, 0.3);
  }
  
  .light .cardMachine {
    background: #ffffff;
    box-shadow: none;
  }
  
  .light .onlinebg .cardMachine {
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.08);
  }
  
  .innerMachine {
    margin-top: 0.833rem;
  }
  .longEnough .cardMachineRight{
    width: calc(100% - 2rem);
    margin: 1rem 1rem 0 1rem;
  }
  .longEnough .cardMachineRight label{
  font-weight: normal;
  }
  .cardMachine.machineTabHome:hover .countNotify {
    font-size: 1rem;
  }
  .mb14 {
    margin-bottom: 1.66rem;
    margin-left: -0.83rem;
    margin-right: -0.83rem;
  }
  .mb14 .col-md-12.pointer {
  padding-left: 0.83rem;
  padding-right: 0.83rem;
  }
  .innerMachine .col-md-4 {
    padding-right: 0.83rem;
    padding-left: 0.83rem;
  }
  
  .machineTitle,
  .machineDetails {
    float: left;
    width: 100%;
  }
  
  .machineTitleUl {
    padding: 0;
    margin: 0;
    list-style: none;
  }
  
  .statusCls {
    height: 0.67rem;
    width: 0.67rem;
    border-radius: 50%;
    float: left;
  }
  
  .statusClsGreen {
    background: #5aaf6c;
  }
  
  .statusClsGray {
    background: #ea4335;
  }
  
  .machineCritical {
    background: #9a453e;
  }
  
  .machineAMCDue {
    background: #985c0c;
  }
  
  .mapDiv.allMachineList .row.mb14 {
    margin-left: -0.83rem;
    margin-right: -0.83rem;
  }
  
  .machineListTag {
    padding: 0.2rem 0.7rem;
    border-radius: 0 0 4px 4px;
    font-size: 0.83rem;
    font-family: "Montserrat-Medium";
    color: #e0e0e0;
    text-align: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: fit-content;
    margin-right: 1rem;
    float: left;
  }
  
  .offlinebg .machineAMCDue.machineListTag {
    opacity: 100%;
  }
  
  .cardMachine_home .machineListTag {
    margin-right: 1.33rem;
    /* margin-left: 1.66rem; */
  }
  
  .CriticalAlarmML,
  .MachineStatusML {
    width: 50%;
    height: 1.66rem;
    float: left;
    color: #e0e0e0;
    font-size: 0.833rem;
    line-height: 1.5rem;
    text-align: right;
    margin-right: 2%;
  }
  
  .light .CriticalAlarmML,
  .light .MachineStatusML {
    color: #58585a;
  }
  
  .light .cardMachine_home .machineListTag {
    color: #3b414b;
  }
  
  .light .machineAMCDue {
    background: #f2b666;
  }
  
  .light .machineCritical {
    background: #f49f98;
  }
  
  .machineImg {
    float: left;
    width: 38%;
    text-align: center;
    padding-top: 0.833rem;
  }
  
  .offlinebg .machineImg {
    opacity: 0.9;
  }
  #MachineList .panellefttext {
    padding-left: 1.33rem;
  }
  .simplebar-track {
    right: -0.16rem !important;
  }
  
  .machineTabHome .onTxt {
    margin-left: 0;
  }
  
  .machineImg img {
    width: 100%;
    height: auto;
    max-width: 9.58rem;
  }
  
  .machineDescription {
    float: right;
    width: 60%;
    padding-top: 1.66rem;
  }
  
  .machineTxt {
    color: #e0e0e0;
    display: block;
    font-weight: 500;
    font-size: 1rem;
    font-family: "Montserrat-Regular";
    max-width: 80%;
    width: auto;
    overflow: hidden;
    float: left;
    word-break: break-all;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .light .machineTxt {
    color: #3b414b;
  }
  .longEnough .machineTxt {
    max-width: 27.5rem;
  }
  .offlinebg .machineTxt {
    opacity: 0.9;
  }
  .light .offlinebg .machineTxt,
  .light .offlinebg .machineRightImg {
    opacity: 0.9;
  }
  .offlinebg .cardMachineRight .machineTxt {
    margin-right: 0;
    max-width: 27rem;
  }
  
  .offlinebg .cardMachine {
    background: #1F1F1F;
    box-shadow: -4px 4px 15px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
  }
  .light .offlinebg .cardMachine {
    box-shadow: none;
  }
  .offlinebg .cardMachineRight .machineTxt{
    /* color: #e0e0e0; */
  }
  .offlinebg .machineTxt{
    color: #a0a0a0;
  }
  .light .offlinebg .cardMachineRight .machineTxt {
    color: #58585A;
  }
  .offlineview {
    background: #121212;
    border-radius: 4px;
    color: #e0e0e0;
    padding: 0.66rem 0.833rem 0.416rem 0.833rem;
    width: 94%;
    margin: 0 auto;
    margin-top: 1.16rem;
  }
  
  .offlinebg .cardMachineRight {
    /* background: #2e2e2e; */
    background-color: #1f1f1f;
    box-shadow: 2px -2px 10px rgba(0, 0, 0, 0.04), -4px 4px 8px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
  }
  
  .light .offlineview {
    background: #F2F5F8;
    color: #3b414b;
    border: 0.5px solid #e4e7ec;
  }
  
  #live-batch .contentPart {
    padding-right: 0;
  }
  
  .light .offlinebg .cardMachine{
    background: #FFFFFF;
  border: 0.5px solid #E4E7EC;
  box-shadow: 2px -2px 10px rgba(0, 0, 0, 0.04), -4px 8px 10px rgba(0, 0, 0, 0.04);
  border-radius: 4px;
  }
  .light .cardMachine:hover{
  background: #FFFFFF;
  border: 0.5px solid #E4E7EC;
  box-shadow: 4px 4px 45px rgba(0, 0, 0, 0.06), -8px 8px 20px rgba(0, 0, 0, 0.16);
  border-radius: 4px;
  }
  .light .innerMachineHeight .offlinebg .machineTxt{
  color: #58585A;
  }
  .light .offlinebg .cardMachineRight {
    /* background: #d5d5d5;
    border-radius: 4px; */
  background: #FFFFFF;
  border: 0.5px solid #E4E7EC;
  box-shadow: 2px -2px 10px rgba(0, 0, 0, 0.04), -4px 8px 10px rgba(0, 0, 0, 0.04);
  border-radius: 4px;
  }
  
  .offTextMach,
  .offTimeTxt {
    font-size: 1rem;
    text-align: center;
  }
  
  .iconOffLineMachine {
    float: left;
    width: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 3rem;
    margin-left: 0.5rem;
  }
  .iconOffLineMachine svg {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
  }
  
  .machineDescription .offlineview label {
    font-size: 0.83rem;
  }
  
  .machineBar {
    position: relative;
    margin-bottom: 0.8rem;
    float: left;
    width: 100%;
    min-height: 1.5rem;
  }
  .text-center.noDataCls{
    padding-top: 50%;
    font-size: 1rem;
  }
  .machineTitleUl span {
    display: inline-block;
    line-height: 1;
  }
  .machineTitleUl span svg {
    height: 1.3rem !important;
    width: 1.3rem !important;
    margin-left: 0.33rem;
  }
  .machineTitleUl span:first-child {
    margin-right: 0.833rem;
    margin-bottom: 0.43rem;
  }
  .machineTitleUl > li {
    display: flex;
    align-items: center;
  }
  
  .svgIcons {
    position: relative;
    top: 0.33rem;
    left: 0;
  }
  
  .machineDescription label {
    font-family: "Montserrat-Medium";
    font-weight: normal !important;
    font-size: 1rem;
    color: #e0e0e0;
  }
  
  .light .machineDescription label {
    color: #3b414b;
  }
  
  .txtMode {
    font-family: "Montserrat-Medium";
    font-weight: normal !important;
    font-size: 0.833rem;
    color: #a0a0a0;
    text-align: center;
  }
  
  .machineMode {
    float: left;
  }
  
  .bookmarkCls, .machineSince {
    float: right;
  }
  
  .machineSince, .machineMode {
    text-align: center;
    width: 50%;
    height: 5.41rem;
  }
  
  .onTxt {
    border-radius: 8px;
    color: #34a854;
    font-size: 1rem;
    text-align: left;
    font-family: "Montserrat-Medium";
    font-weight: 500;
    width: 48%;
    height: 1.5rem;
    line-height: 1.33rem;
    float: left;
  }
  
  .offTxt {
    color: #ea4335 !important;
    line-height: 1.33rem;
  }
  
  .NATxt {
    color: #e0e0e0 !important;
  }
  
  .light .NATxt {
    color: #3b414b !important;
  }
  
  .onOffStatus {
    float: right;
    width: 100%;
    padding-left: 0;
  }
  
  .notifyTxt {
    color: #ea4335;
    font-size: 1rem;
    font-family: "Montserrat-Medium";
    border-radius: 8px;
    width: 46%;
    float: right;
    text-align: left;
    padding: 0.08rem 0;
    line-height: 1.66rem;
  }
  
  .light .notifyTxt {
    color: #ea4335;
  }
  
  .countNotify {
    position: relative;
    top: -0.16rem;
    padding-left: 00.33rem;
    text-decoration: underline;
  }
  
  .countNotifyHyphen {
    position: relative;
    top: -0.16rem;
    padding-left: 0.33rem;
    text-decoration: none;
    color: #e0e0e0;
  }
  
  #alarms .pannelWrap span:nth-child(6) {
    text-align: right;
    padding-right: 2.75rem;
    float: left;
  }
  
  .light #allMachineList .eventOverflowScroll {
    background: #ffffff;
  }
  
  /* MachineList CSS in BarkMode End */
  
  .barchart__line .recharts-tooltip-wrapper {
    background: #000;
    padding: 0.416rem;
  }
  
  .light .barchart__line .recharts-tooltip-wrapper {
    background: #f2f4f6;
  }
  
  .light .barchart__line .recharts-tooltip-wrapper p {
    color: #3b414b;
  }
  
  .MuiAutocomplete-popper {
    z-index: 99;
  }
  
  /* Tabs Styles Start */
  .contentPart {
    position: relative;
    padding-right: 0;
  }
  
  .tabArea {
    height: calc(100% - 3.33rem);
  }
  
  span.IconBeta.liveIConOEE {
    top: -0.25rem;
    position: absolute;
    right: -1.66rem;
  }
  
  .tabArea .tabsName li.active a {
    color: #5d97f6;
    padding: 0;
  }
  .tabArea .tabsName li a {
    margin: 0.833rem 1.66rem;
  }
  
  .tabArea .nav-tabs {
    border-bottom: 1px solid #1f1f1f;
    margin-bottom: 0.16rem;
  }
  
  .light .tabArea .nav-tabs {
    border-bottom: 1px solid #e0e0e0;
  }
  
  .tabArea .tabsName li a:hover {
    text-decoration: none;
  }
  
  .tabArea .tab-content > .active {
    opacity: 1;
  }
  
  .tabArea .tabsName li.active a:after {
    content: "";
    position: absolute;
    background: #5d97f6;
    height: 0.16rem;
    width: 100%;
    left: 0;
    bottom: -1rem;
  }
  .light .tabArea .tabsName li.active a:after {
    background: #4285f4;
  }
  
  .tabArea .tabsName li.active a {
    font-family: "Montserrat-Medium";
  }
  /* Tabs Styles End */
  
  /* Map Area Styles Start */
  .searchCross svg:hover path {
    stroke: #5d97f6;
  }
  
  .mapDiv {
    overflow: hidden;
    height: 84vh;
    margin-left: 0;
    padding-top: 0.833rem;
    border-radius: 0 4px 4px 0;
  }
  
  #live-batch .mapDiv.allMachineList {
    width: 100%;
  }
  .mapDiv.allMachineList {
    overflow: hidden;
    height: calc(100vh - 10rem);
    margin-left: auto;
    padding-top: 1rem;
    width: calc(83% - 1.6rem);
  }
  .mapDiv.allMachineList.customAlerts,
  .mapDiv.allMachineList.allNotifications {
    margin-left: auto;
    width: calc(83% - 1.3rem);
  }
  .expandMapWidth {
    margin-left: 4.16rem;
  }
  
  .mapDiv.allMachineList.expandMapWidth {
    width: 100%;
  }
  
  #overview .mapDiv.allMachineList.expandMapWidth {
    margin-left: 0;
  }
  
  #overview .mapDiv.allMachineList {
    overflow: hidden;
    height: calc(100vh - 10rem);
    padding-top: 1rem;
  }
  
  /* Map Area Styles End */
  
  .col-md-4.cardMB14.pointer {
    margin-bottom: 1.7rem;
  }
  
  .pathInfo0:hover path.pathInfo1 {
    fill: #5d97f6;
    stroke: #5d97f6;
  }
  
  .light path.fillNoColor.svg1p2,
  .pathInfo0:hover path.pathInfo2,
  .pathInfo0:hover path.pathInfo3 {
    stroke: #fff;
    fill: #fff;
  }
  
  .machineName {
    color: #a0a0a0;
    font-size: 1rem;
    font-family: "Montserrat-Medium";
  }
  
  .light .machineName {
    color: #58585a;
    font-size: 0.833rem;
  }
  .comnMachine.active .machineName {
    font-family: "Montserrat-Semibold";
  }
  .comnMachine.active .machineName,
  .machineName:hover {
    color: #e0e0e0;
  }
  
  .light .comnMachine.active .machineName,
  .light .shownotext {
    color: #3b414b;
  }
  
  .machineBackSvg-path1,
  .machineBackSvg-path2 {
    stroke: #8f8f8f;
  }
  
  .light .machineBackSvg-path1,
  .light .machineBackSvg-path2 {
    stroke: #8f8f8f;
  }
  .cardBelowNine {
    display: none;
  }
  .float-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 1rem 0;
    position: sticky;
    bottom: 0;
    box-shadow: 0 -4px 8px rgba(0, 0, 0, 0.1);
  }
  .longEnough .float-container {
    margin: 0;
    bottom: 1.66rem;
    position: absolute;
  }
  .longEnough .panellefttext {
    padding: 0.66rem 0.416rem 0.833rem 2rem;
  }
  .panellefttext {
    width: auto;
    padding: 0.66rem 0.416rem 0.833rem 0;
  }
  .panelrighttext {
    width: auto;
  }
  .shownotext {
    font-size: 0.91rem;
    font-family: montserrat-regular;
    color: #e0e0e0;
  }
  .panelrighttext .comanPagination .pagination > li > a,
  .panelrighttext .comanPagination .pagination > li > span {
    padding: 0;
  }
  .panelrighttext .pagination {
    margin: 0.16rem 0 0 0;
  }
  .cardBelowThree {
    display: none;
  }
  .globSerch .statusCls {
    height: 0.58rem;
    width: 0.58rem;
    border-radius: 50%;
    float: left;
    margin-top: 0.416rem;
    margin-bottom: 1.66rem;
    margin-left: -0.5rem;
    margin-right: 0.5rem;
  }
  .text-center.no-parameter-available.onlyNoData {
    height: 41.25rem;
    background: #252525;
    height: calc(100vh - 22vh);
  }
  .light .text-center.no-parameter-available.onlyNoData {
    background: #fff;
  }
  span.bookmarkCls.bookMarkIcon.bookmarked:focus {
    outline: none;
  }