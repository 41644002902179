/* Code Start for Event */
.noHistoryEvents {
    background-color: #252525;
    color: #e0e0e0;
    height: calc(100vh - 17.33rem);
    width: 100%;
    border-radius: 0.33rem;
    font-size: 1rem;
    padding-top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .EventsTableLoader {
    background-color: #252525;
    height: calc(100vh - 17rem);
  }
  
  .EventsTableLoader .makeStyles-root-5 {
    display: block;
  }
  
  .passwordChangeDiv .makeStyles-root-5 {
    position: absolute;
  }
  
  .light .EventsTableLoader {
    background-color: #f9fafb;
  }
  
  .eventTopBar {
    margin-top: 1rem;
    align-items: center;
    margin-bottom: 1rem;
  }
  
  .eventTopBar.onlyCustomAlert,
  .eventTopBar.onlyEvent {
    margin-bottom: 1rem;
  }
  
  .onlyCustomAlert .alertFilterDiv,
  .onlyEvent .alertFilterDiv {
    min-width: 8.33rem;
    margin-left: 0;
    margin-right: 2rem;
  }
  .onlyCustomAlert .alertFilterDiv:not(:last-child) {
    margin-right: 2.5rem;
  }
  .evntDorp .alertFilterDiv {
    float: left;
    width: 9.18rem;
    min-width: 9.18rem;
  }
  .eventTopBar.onlyEvent.noboderDrop .alarmText {
    font-family: Montserrat-Medium;
    color: #e0e0e0;
    padding: 1rem 0 1rem 0;
    font-size: 1rem;
  }
  
  .light .eventTopBar.onlyEvent.noboderDrop .alarmText {
    color: #3b414b;
  }
  
  .onlyEvent .padLefts .alertFilterDiv {
    margin-right: 0;
  }
  
  .confirmPin .formgroup {
    margin: 1.66rem 0 5rem 0;
  }
  
  span.crossSearch > svg {
    position: absolute;
    top: 0.416rem;
    right: 0.83rem;
    background: #1f1f1f;
    height: 1.5rem;
    width: 1.5rem;
    z-index: 1;
  }
  .light span.crossSearch > svg{
    background-color: #fff;
  }
  .KPIGroupContent span.crossSearch {
    right: 0.66rem;
  }
  
  .input-group.KPIGroupContent.divClsMargin {
    margin-bottom: 1.66rem;
    margin-left: 3.75rem;
    display: inline-flex;
  }
  
  span.crossSearch svg {
    cursor: pointer;
  }
  
  .enterpins {
    position: relative;
  }
  
  .enterpins .pinErrorTxt {
    position: absolute;
    z-index: 8;
  }
  
  .criticalTxt {
    color: #f36262 !important;
    font-family: "Montserrat-medium"!important;
  }
  
  .majorTxt {
    color: #f9b32d !important;
    font-family: "Montserrat-medium"!important;
  }
  
  .minorTxt {
    color: #77c58c !important;
    font-family: "Montserrat-medium"!important;
  }
  
  .accordianListTag {
    color: #5d97f6 !important;
  }
  
  .pannelWrap {
    float: left;
    width: 100%;
  }
  
  .overflowScrolls {
    height: calc(100vh - 32.5rem);
    overflow-y: auto;
    scrollbar-color: #cccccc #121212;
    scrollbar-width: thin;
    border-radius: 0 0 4px 4px;
  } 
  #alarms .alertDivs {
    height: calc(100vh - 28rem);
  }
  .light #alarms .alertDivs {
    background: #fff;
  }
  .alertDivsBg {
    background: #252525;
    border-radius: 4px;
    height: calc(100vh - 24.66rem);
  }
  .alertDivsBg .float-container{
    margin: 0.5rem 0;
  }
  .EventTabDivsTop .float-container{
    margin: 1rem 0 1rem 0;
  }
  .alertDivsBg .panellefttext,
  .EventTabDivsTop .panellefttext{
    padding-left: 1.5rem;
  }
  .alertDivsBg .alarm-pagination.exepaginations .panellefttext{
    padding-left: 0rem;
  }
  .light .alertDivsBg {
    background: #fff;
  }
  #alarms .overflowScrolls {
    height: calc(100vh - 32rem);
  }
  #alarms .pagination {
    margin: 0rem 0;
  }
  .eventOverflowScroll {
    height: calc(100vh - 22.66rem);
    overflow-y: auto;
    scrollbar-color: #cccccc #121212;
    scrollbar-width: thin;
    border-radius: 0 0 4px 4px;
  }
  
  .colorPopBg .autoCompDiv .makeStyles-list-11,
  .colorPopBg .KPIGroupContent.divClsMargin.searchParatr {
    background-color: #252525;
  }
  
  .colorPopBg .MuiTypography-body2 {
    font-size: 0.83rem;
  }
  
  .colorPopBg svg.MuiSvgIcon-root path {
    fill: #fff;
  }
  
  .light .colorPopBg svg.MuiSvgIcon-root path {
    fill: #3b414b;
  }
  #ConfigureGroups .colorPopBg svg.MuiSvgIcon-root path {
    fill: #8f8f8f;
  }
  .light #ConfigureGroups .colorPopBg svg.MuiSvgIcon-root path {
    fill: #3b414b;
  }
  .light .colorPopBg .autoCompDiv .makeStyles-list-11,
  .light .colorPopBg .KPIGroupContent.divClsMargin.searchParatr,
  .light #ConfigureGroups .modelboxopenInnnerBig {
    background-color: #ffffff;
  }
  
  #ConfigureGroups .modelboxopenInnnerBig {
    background-color: #1f1f1f;
    height: 45.83rem;
    max-width: 100rem;
    width: 100%;
  }
  
  .colorPopBg .MuiListItemIcon-root {
    min-width: 2.83rem;
  }
  
  .colorPopBg .makeStyles-root-9 {
    width: 71.83rem;
  }
  
  .colorPopBg .MuiGrid-spacing-xs-2 > .MuiGrid-item {
    width: 50%;
  }
  
  .colorPopBg .makeStyles-list-11 {
    width: 100%;
  }
  
  .alertDivs .pagination,
  .EventTabDivs .pagination {
    margin: 0;
  }
  
  .loaderHistoryAlarms {
    overflow: hidden;
  }
  
  .loaderHistoryAlarms svg {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .eventTooltip {
    top: 1px;
  }
  
  .alarmCardTitleTB {
    padding-top: 0.416rem;
    padding-bottom: 0.416rem;
  }
  
  .alarmSettingHolder .settingIcon {
    margin-top: 0;
    margin-bottom: 0;
    margin-right: 0.16rem;
  }
  
  .alarmSettingHolder .settingText {
    margin-top: 0;
    margin-bottom: 0;
  }
  
  .settingIconPath1,
  .settingIconPath2 {
    stroke: #5d97f6;
  }
  
  .light .settingIconPath1,
  .light .settingIconPath2 {
    stroke: #4285f4;
  }
  
  .alertDivs {
    height: calc(100vh - 28rem);
  }
  
  .criticalTxt,
  .majorTxt,
  .minorTxt,
  .accordianListTag,
  .alertDivs,
  .colorPopBg .MuiTypography-body2,
  .eventDivs,
  .row6.MAJOR,
  .row6.MINOR,
  .row6.CRITICAL {
    font-family: Montserrat-Medium !important;
  }
  
  .CRITICAL {
    color: #f5978e !important;
  }
  
  .light .CRITICAL {
    color: #ED5F53 !important;
  }
  
  .MAJOR {
    color: #ffb452 !important;
  }
  
  .light .MAJOR {
    color: #EA8600 !important;
  }
  
  .MINOR {
    color: #77c58c !important;
  }
  
  .light .MINOR {
    color: #137333 !important;
  }
  
  .serialNo {
    color: #e0e0e0 !important;
    margin-left: 0.33rem;
  }
  
  .light .serialNo {
    color: #3b414b !important;
  }
  
  .alarm-drop {
    color: #fff !important;
  }
  
  .light .alarm-drop {
    color: #b7b7b7 !important;
  }
  
  .alarmRows .rotate svg {
    transform: rotate(180deg);
  }
  
  .alertDivs .headerTable span:nth-child(2),
  .alertDivs .alarmRows span:nth-child(2) {
    padding-right: 1.66rem;
  }
  
  .alertDivs .headerTable span:nth-child(1),
  .alertDivs .alarmRows span:nth-child(1) {
    width: 2.91rem;
  }
  
  .alertDivs .headerTable span:nth-child(2),
  .alertDivs .alarmRows span:nth-child(2),
  .alertDivs .headerTable span:nth-child(8),
  .alertDivs .alarmRows span:nth-child(8) {
    width: 1%;
  }
  
  .alertDivs .headerTable span:nth-child(3) {
    text-align: left;
    padding-left: 0.5rem;
  }
  
  .alertDivs .headerTable span:nth-child(3),
  .alertDivs .alarmRows span:nth-child(3) {
    width: 46%;
  }
  
  .alertDivs .headerTable span:nth-child(4),
  .alertDivs .alarmRows span:nth-child(4) {
    width: 14%;
  }
  
  .alertDivs .headerTable span:nth-child(5),
  .alertDivs .alarmRows span:nth-child(5) {
    width: 16%;
  }
  
  .alertDivs .headerTable span:nth-child(6),
  .alertDivs .alarmRows span:nth-child(6) {
    width: 9%;
  }
  
  .alertDivs .headerTable span:nth-child(7),
  .alertDivs .alarmRows span:nth-child(7) {
    width: 8%;
  }
  
  .alertDivs .alarmRows span:nth-child(8) {
    padding-top: 0.66rem;
    padding-bottom: 0.66rem;
  }
  
  #alarms .alertDivs .alarmRows span:nth-child(8) {
    display: none;
  }
  
  span.bookmarkCls.bookMarkIcon {
    position: absolute;
    right: 1.7rem;
    top: 0.4rem;
    z-index: 12;
    line-height: 1;
  }
  span.bookmarkCls.bookMarkIcon svg {
    height: 1.3rem !important;
    width: 1.3rem !important;
  }
  span.bookmarkCls.bookMarkIcon:hover path {
    stroke: #5d97f6;
    stroke-width: 2;
  }
  
  .light span.bookmarkCls.bookMarkIcon:hover path {
    stroke: #4285f4;
  }
  
  .light .comanPagination .pagination > li.staticLi > span.active:focus,
  .light .comanPagination .pagination > li.staticLi > span.active:hover {
    color: #3b414b;
  }
  
  .light .pannelWrap .panel-default:nth-child(2n + 1) .rowDiv,
  .light .pannelWrap .panel-default:nth-child(2n + 1) .rowDiv:nth-child(2n + 1) {
    background: #f9fafb;
  }
  
  .light .pannelWrap .panel-default:nth-child(2n + 1) .rowDiv:first-child,
  .light .pannelWrap .panel-default .rowDiv:first-child {
    border-top: 1px solid #e7e7e7;
  }
  
  .headerTable {
    background-color: #1f1f1f;
    border-radius: 0.33rem 0.33rem 0 0;
    padding: 0.84rem 0.4rem;
    color: #e0e0e0;
    float: left;
    width: 100%;
    height: 3.33rem;
  }
  
  .light .KPILimitTable .headerTable,
  .light .headerTable {
    background-color: #fff;
    color: #3b414b;
    border-bottom: 1px solid #e0e0e0;
  }
  
  .headerTable span:nth-child(2),
  .headerTable span:nth-child(3),
  .headerTable span:nth-child(4),
  .headerTable span:nth-child(5),
  .headerTable span:nth-child(6) {
    float: left;
    text-align: center;
    display: block;
    padding-left: 0.416rem;
    padding-right: 0.416rem;
  }
  
  .headerTable span:nth-child(1),
  .pannelWrap span:nth-child(1) {
    float: left;
    text-align: right;
    display: block;
  }
  .headerTable span:nth-child(1) {
    width: 5%;
    padding-left: 0.416rem;
    padding-right: 0.416rem;
  }
  
  .pannelWrap span:nth-child(1),
  .headerTable span:nth-child(2),
  .pannelWrap span:nth-child(2) {
    width: 5%;
  }
  
  .alertDivs .headerTable span:nth-child(7),
  .alertDivs .alarmRows span:nth-child(7),
  .alertDivs .headerTable span:nth-child(8),
  .alertDivs .alarmRows span:nth-child(8),
  .pannelWrap span:nth-child(2),
  .pannelWrap span:nth-child(4),
  .pannelWrap span:nth-child(5) {
    float: left;
    text-align: center;
    display: block;
  }
  
  .headerTable span:nth-child(3) {
    width: 30%;
  }
  
  #events .headerTable span:nth-child(3) {
    text-align: left;
    width: 37%;
  }
  
  #events .pannelWrap span:nth-child(3) {
    width: 37%;
  }
  
  #events .headerTable span:nth-child(4),
  #events .pannelWrap span:nth-child(4) {
    width: 35%;
    text-align: center;
  }
  
  #events .headerTable span:nth-child(5) {
    text-align: left;
    width: 8%;
  }
  
  #events .pannelWrap span:nth-child(5) {
    width: 7%;
    text-align: left;
  }
  
  .pannelWrap span:nth-child(3) {
    width: 30%;
    float: left;
    text-align: left;
    display: block;
  }
  
  .headerTable span:nth-child(4),
  .pannelWrap span:nth-child(4) {
    width: 24%;
  }
  
  .headerTable span:nth-child(5),
  .pannelWrap span:nth-child(5) {
    width: 31%;
  }
  
  .headerTable span:nth-child(6),
  .pannelWrap span:nth-child(6) {
    width: auto;
  }
  
  .pannelWrap .glyphicon-chevron-down:before {
    content: ">";
  }
  
  .pannelWrap .panel-title a {
    display: block;
  }
  
  .bs-example {
    margin: 0;
  }
  
  .rotate svg {
    -webkit-transform: rotate(-180deg);
    -moz-transform: rotate(-180deg);
    -ms-transform: rotate(-180deg);
    transform: rotate(-180deg);
  }
  
  .pannelWrap .panel-heading,
  .pannelWrap .panel-body {
    padding: 0;
  }
  
  .pannelWrap .panel-heading span {
    padding: 0.91rem 0.416rem;
    font-family: "Montserrat-Regular";
  }
  
  .rowDiv {
    float: left;
    width: 100%;
    padding: 0;
    height: 2.83rem;
  }
  
  .pannelWrap .panel-group .panel,
  .pannelWrap .panel-heading {
    border-radius: 0;
    border: 0 solid transparent;
  }
  
  .pannelWrap .panel-group .panel + .panel {
    margin-top: 0;
  }
  
  .pannelWrap .panel-default > .panel-heading,
  .kpiPara .panel-heading:nth-child(2n) {
    color: #e0e0e0;
    background-color: #1e1e1e;
    border-color: #1e1e1e;
    border: 1px solid transparent;
    float: left;
    width: 100%;
    min-height: 3rem;
  }
  
  .light .pannelWrap .panel-default > .panel-heading {
    background-color: #fff;
  }
  
  .AllNotificationTable .pannelWrap span.row6:nth-child(5) {
    padding: 0.416rem;
  }
  
  .pannelWrap span {
    font-size: 0.83rem;
    color: #e0e0e0;
    padding: 1rem 0.416rem;
  }
  
  .light .pannelWrap span {
    color: #3b414b;
  }
  
  .pannelWrap .panel-group {
    margin-bottom: 0;
  }
  
  .rowDiv:nth-child(2n + 1),
  .rowDiv {
    border-bottom: 1px solid #2f2f2f;
  }
  
  
  .pannelWrap .panel-default:nth-child(2n + 1) .rowDiv {
    border-bottom: 1px solid #1f1f1f;
  }
  .light .rowDiv,
  .light .rowDiv:nth-child(2n + 1),
  .light .pannelWrap .panel-default:nth-child(2n + 1) .rowDiv {
    border-bottom: 1px solid #e7e7e7;
  }
  
  .rowDiv,
  .pannelWrap .panel-default:nth-child(2n + 1) .rowDiv:nth-child(2n + 1) {
    background: #1e1e1e;
  }
  
  .light .pannelWrap .panel-default:nth-child(2n + 1) .rowDiv:nth-child(2n + 1) {
    background: #f9fafb;
  }
  
  .pannelWrap .panel-default:nth-child(2n + 1) .panel-heading,
  .pannelWrap .panel-default:nth-child(2n + 1) .rowDiv,
  .pannelWrap .panel-default:nth-child(2n + 1) .rowDiv:nth-child(2n + 1) {
    background: #252525;
  }
  
  .pannelWrap .panel-default:nth-child(2n + 1) .rowDiv:first-child {
    border-top: 1px solid #1f1f1f;
  }
  
  .pannelWrap .panel-default .rowDiv:first-child {
    border-top: 1px solid #2f2f2f;
  }
  
  .light .rowDiv {
    background: #fafafa;
  }
  
  .light .pannelWrap .panel-default:nth-child(2n + 1) .panel-heading {
    background: #fafbfc;
  }
  
  .pannelWrap .panel-group .panel-heading + .panel-collapse > .list-group,
  .pannelWrap .panel-group .panel-heading + .panel-collapse > .panel-body {
    border-top: 0 solid #ddd;
  }
  
  .eventDivs {
    margin-right: 1.25rem;
    box-shadow: 0 0 2px rgba(0, 0, 0, 8%);
  }
  
  .EventTabDivs {
    font-family: Montserrat-Medium;
    box-shadow: 0 0 0.16rem rgba(0, 0, 0, 8%);
    height: 35.83rem;
  }
  .EventTabDivs .noHistoryEvents,
  .OnlyAlarmTxt .noHistoryAlarms {
    border-radius: 0 0 4px 4px;
  }
  /* Code End for Event */
  .EventTabDivsTop {
    background: #252525;
    border-radius: 4px;
    height: calc(100vh - 14rem);
  }
  .light .EventTabDivsTop {
    background: #fff;
  }
  .mobileviewPagination {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .mobileviewPagination .pagination{
    margin: 1rem 0;
  }
  .EventTabDivsTop .headerTable span:nth-child(2), .EventTabDivsTop .pannelWrap span:nth-child(2) {
    width: 2%;
  }