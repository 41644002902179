.history-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 10px 5px;
}

.history-head-text {
  font-family: "Montserrat", "Helvetica", sans-serif !important;
  margin-bottom: 5px;
  margin-left: 10px;
  font-size: 13px;
  /* text-decoration: underline; */
  letter-spacing: 0.004em;
  color: #ffffff;
}

.history-table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
  border: 0.5px solid #444334;
}

.history-table td,
.history-table th {
  border-bottom: 0.5px solid #444334;
  border-top: 0.5px solid #444334;
  text-align: left;
  padding: 8px;
  font-family: "Montserrat", "Helvetica", sans-serif !important;
}

.history-table tr:nth-child(even) {
  background-color: #252525;
}

.history-table-heading {
  background: #383838;
}

.history-table-box {
  overflow: auto;
  height: 170px;
  margin-left: 10px;
  margin-right: 10px;

  margin-top: -5px;
}
.btn-cancel {
  background-color: gray;
  color: white;
  border-radius: 4px;
  font-family: "Montserrat", "Helvetica", sans-serif !important;
  padding: 5px 10px 5px 10px;
  width: 80px;
  border: none;
  /* margin-top: 5px; */
  height: 36px;
  margin-left: 10px;
}
.css-3ozp7s-control{
  min-height: 32px !important;
  max-height: 32px !important;
}
.css-1xc3v61-indicatorContainer{
  padding: 0px !important;
  padding-right: 10px !important;
}
table.mainTbl thead tr th.head:nth-child(1), table.mainTbl tbody tr td:nth-child(1){
  width: 10%;
}
table.mainTbl thead tr th.head:nth-child(2), table.mainTbl tbody tr td:nth-child(2){
  width: 20%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
table.mainTbl thead tr th.head:nth-child(3), table.mainTbl tbody tr td:nth-child(3){
  width: 10%;
}
table.mainTbl thead tr th.head:nth-child(4), table.mainTbl tbody tr td:nth-child(4){
  width: 10%;
}
table.mainTbl thead tr th.head:nth-child(5), table.mainTbl tbody tr td:nth-child(5){
  width: 10%;
}
table.mainTbl thead tr th.head:nth-child(6), table.mainTbl tbody tr td:nth-child(6){
  width: 10%;
}
table.mainTbl thead tr th.head:nth-child(7), table.mainTbl tbody tr td:nth-child(7){
  width: 20%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
table.mainTbl thead tr th.head:nth-child(8), table.mainTbl tbody tr td:nth-child(8){
  width: 10%;
}
