:root {
    --primary:#5d97f6;
    --primary-background: #121212;
    --secondary-background: #252525;
    --grid-odd-color:#252525;
    --grid-even-color:#1e1e1e;
    --primary-font-color:#e0e0e0;
    --accordin-color:#2f2f2f;
    --bg:#1f1f1f;
    --btn-primary:#5D97F6;
    --btn-primary-color:#000000;
    --btn-secondary:#2f2f2f;
    --btn-secondary-color:#e0e0e0;
    --btn-primary-hover:#6AA2FF;
    --btn-secondary-hover:#3e3e3e;
    --border-color:#5d5e60;
  }
  :root .light {
  
    --primary-background: #f2f4f6;
    --secondary-background: #fafbfc;
    --grid-odd-color:#fafbfc;
    --grid-even-color:#ffffff;
    --primary-font-color:#3b414b;
    --accordin-color:#fafbfc;
    --bg:#ffffff;
    --btn-primary:#4285F4;
    --btn-primary-color:#ffffff;
    --btn-secondary:#edf0f3;
    --btn-secondary-color:#3b414b;
    --btn-primary-hover:#296FEA;
    --btn-secondary-hover:#b6b6b7;
    --border-color:#a6aab4;
  }
  .cm-dashboard .input-group.datefilter {
    width: 140px !important;
    float: right;
    position: absolute;
    margin-top: -3rem;
    right: 1rem;
  }
  .cm-dashboard .dashboard-panel
  {
  display: flex;
  clear: left;
  }
  .cm-dashboard .dashboard-panel-card
  {
    display: flex;
    flex: 100%;
    
  }
  .cm-dashboard .card
    {
      background: var(--grid-even-color);
      border-radius: 4px;
      padding: 1rem;
      margin-bottom: 1rem;
      min-height: 250px;
      }
      .cm-dashboard .recharts-responsive-container
      {
        background: var(--grid-even-color);
        margin-top: 1rem;
        min-height: 250px;
      }
  .cm-dashboard .tinycard
  {
    background: var(--grid-even-color);
    border-radius: 4px;
    border: none;
    padding: 1rem;
    margin-right: 1rem;
    width: 100%;
    }
  .cm-dashboard .mr-1
  {
    margin-right: 0.5rem;
  }
  .cm-dashboard .dashboard-card label svg{
    width: 1.4rem;
    height: 1.4rem;
    cursor: pointer;
    margin-left: 1rem;
  }
  .cm-dashboard .dashboard-card label svg.conditional-monitoring
  {
    position: relative;
   /* top: 0.3rem;*/
    margin-left: 0.2rem;
  }
  .cm-dashboard .dashboard-card label svg:hover path{
    stroke:var(--primary);
  
  }
  
    .cm-dashboard .seven-col
    {
      display: flex;
    }
   
  .cm-dashboard .title
  {
    text-align: center;
    font-weight: 400;
    /* font-size: 0.63rem; */
    font-size: 0.83rem;
    color:var(--primary-font-color);
  }
  
  .cm-dashboard .value
  {
    text-align: center;
    font-weight: 500;
    font-size:0.83rem;
    color:var(--primary-font-color);
    margin-top: 10px;
  }
  .conditionMonitoring.modelboxopenInnner
  {
    height: 87vh!important;
  }
  .conditionMonitoring .filterDiv
  {
    height: calc(100vh - 10.9rem);
    padding: 2.3rem 0.5rem!important;
  }
  .conditionMonitoring .cm-filter .eventOverflowScroll {
    height: calc(100vh - 14rem);
    padding: 0.5rem;
  }
  .conditionMonitoring .filterDiv .input-group, .conditionMonitoring .filterDiv .input-control .MuiAutocomplete-input, .conditionMonitoring .filterDiv .input-control .Mui-focused input
  {
    border: none!important;
    padding: 0 !important;
  }
  .conditionMonitoring .cm-filter .MuiInputBase-root
  {
    border-color: var(--border-color) !important;
    padding: 0 0.83rem!important;
    height: 2.055rem!important;
  }
  .conditionMonitoring.cm_report .tabArea .tab-content > .active
  {
  padding: 0;
  }
  .conditionMonitoring.cm_report .cm-filter .MuiSelect-select
  {
    padding: 0.45rem 0!important;
  }
  .conditionMonitoring ::placeholder, .conditionMonitoring .input-control .input-group ::placeholder, .MuiInputBase-root::placeholder {
    opacity: 1!important;
   color: #a0a0a0!important
  }
  .conditionMonitoring .MuiSelect-icon
  {
    margin-top: 0!important;
  }
  .conditionMonitoring .cm-filter .form-group label
  {
    font-size: 0.83rem;
  }
  .conditionMonitoring .tab-content
  {
    padding-top: 0.833rem;
  }
  .MuiTypography-root
  {
    font-family: "Montserrat-Medium"!important;
  }
  .conditionMonitoring .input-group.datefilter .MuiInputBase-root{
    height: 2.2rem!important;
    padding: 0.4rem 0.7rem!important;
  }
  .cm-filter.conditionMonitoring .panel-body
  {
  padding: 0;
  }
  
  .cm-dashboard .headerTable>span, .cm-dashboard .panel-title>span
  {
      float: left;
      text-align: left !important;
      display: block;
      padding-left: 0.416rem;
      padding-right: 0.416rem;
  } 
  
  .cm-dashboard .row1
  {
    width: 2%!important;
  }
  .cm-dashboard .row2
  {
    width: 15%!important;
  }
  .cm-dashboard .row3
  {
    width: 8%!important;
  }
  .cm-dashboard .row4
  {
    width: 9%!important;
  }
  .cm-dashboard .row5
  {
    width: 9%!important;
  }
  .cm-dashboard .row6
  {
    width: 9%!important;
  }
  .cm-dashboard .row7
  {
    width: 9%!important;
  }
  .cm-dashboard .row8
  {
    width: 12%!important;
  }
  .cm-dashboard .row9
  {
    width: 9%!important;
  }
  .cm-dashboard .row10
  {
    width: 7%!important;
  }
  .cm-dashboard .row11
  {
    width: 8%!important;
  }
  .cm-dashboard .row12
  {
    width: 3%!important;
  }
  
  .modelboxopenInnner.conditionMonitoring
  {
    height: auto!important;
  }
  
  .cmmodel ul
  {
    border: none !important;
  }
  
  .modelboxopenInnner.conditionMonitoring .alarm-pagination
  {
    background: var(--grid-odd-color);
      float: left;
      width: 100%;
  }
  .modelboxopenInnner.conditionMonitoring .panellefttext
  {
    float: left;
  }
  .modelboxopenInnner.conditionMonitoring .panelrighttext
  {
    float: right;
  }
  .modelboxopenInnner.conditionMonitoring .panelrighttext .pagination
  {
    background: transparent;
    border: none;
    margin-top: 0.7rem;
  }
  .modelboxopenInnner.conditionMonitoring .eventOverflowScroll {
    height: calc(100vh - 21.66rem);
  }
  .modelboxopenInnner.conditionMonitoring .mb-8
  {
    margin-bottom: 0!important;
  }
  .cm-dashboard .chart-scroll
  {
  height: calc(100vh - 17rem);
  overflow: auto;
  margin-top: 1rem;
  margin-left: 0;
  }
  .cm-dashboard .chart-scroll .row{
    display: flex;
  }
  .cm-dashboard .chart-scroll .row [class*="col-"] {
  align-items: stretch;
  padding: 0 .8rem 0 0;
  }
  .cm-reports .dashboard-card {
    align-items: stretch;
    padding: 0 .4rem 0 .4rem;
    }
    .cm-reports .pl-04
    {
  padding-left:0.4rem;
    }
  .cm-reports .pr-0
  {
    padding-right: 0!important;
  }
  .cm-dashboard .chart-scroll .row .card{
    height: calc(100% - 1rem);
  }
  .conditionMonitoring.modelboxopenInnner .pannelWrap .panel-heading span:hover,
  .conditionMonitoring .cm-reports .pannelWrap .panel-heading span:hover
  {
    cursor: default!important;
  }
  .cm-dashboard .dashboard-card svg.conditional-monitoring:hover path
  {
  fill: var(--primary);
  
  }
  .cm-dashboard .dashboard-card svg.conditional-monitoring:hover path:nth-child(2)
  {
    stroke: var(--primary-font-color);
  }
  .cm-dashboard .noRecords
  {
    text-align: center;
    margin-top: 100px;
    font-family: "Montserrat-Medium";
  }
  
  .top10failurecondition .row4, .top10failurecondition .row5, .top10failurecondition .row9, .failurecountcustomerwise .row4, .failurecountcustomerwise .row5,
  .failurecountmachinemodelwise .row4, .failurecountmachinemodelwise .row5, .failurecountseveritywise .row4, .failurecountseveritywise .row5, .failurecountseveritywise .row9
  {
  width: 15%!important;
  }
  .failurecountcustomerwise .row6, .failurecountcustomerwise .row9 ,.failurecountmachinemodelwise .row6,.failurecountmachinemodelwise .row9
  {
  width: 10%!important;
  }
  .top10failurecondition .descriptionCreate, .failurecountcustomerwise .descriptionCreate, .failurecountmachinemodelwise .descriptionCreate, .failurecountseveritywise .descriptionCreate, .failureconditionsaging .descriptionCreate, .resolutiontimefailureconditions .descriptionCreate, .maintenanceconditionaging .descriptionCreate, .resolutiontimefailureconditions .descriptionCreate
  {
  overflow: inherit!important;
  }
  .MuiTooltip-popper.toolstips
  {
    z-index: 9999999 !important;
  }
  .cm-reports.cm-table .scrollTab span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .conditionMonitoring.cm_report .tabArea .tab-content > #Condition-Monitoring-Trends.active
  {
    padding-right: 1rem;
  }
  .top10failurecondition, .failurecountcustomerwise, .failurecountmachinemodelwise, .failurecountseveritywise, .failurecountseveritywise, .maintenanceconditionaging,
  .resolutiontimemaintenanceconditions, .resolutiontimefailureconditions, .failureconditionsaging
  {
    width: calc(100vw - 100px)!important;
  }
  .top10failurecondition .cm-dashboard .row8, .failurecountcustomerwise .cm-dashboard .row8,.top10failurecondition .cm-dashboard .row9, .failurecountcustomerwise .cm-dashboard .row9,
  .failurecountmachinemodelwise .cm-dashboard .row8, .failurecountmachinemodelwise .cm-dashboard .row9, .failurecountseveritywise .cm-dashboard .row8, .maintenanceconditionaging .row4
  {
  width: 14%!important;
  }
  .resolutiontimefailureconditions .row4,.resolutiontimemaintenanceconditions .row4
  {
    width: 12%!important;
    }
  .failureconditionsaging .row4, .failureconditionsaging .row9, .failureconditionsaging .row10{
    width: 10%!important;
  }
  .resolutiontimefailureconditions .row3,.resolutiontimefailureconditions .row12, .resolutiontimemaintenanceconditions .row3,.resolutiontimemaintenanceconditions .row12{
    width: 5%!important;
  }
  .MuiPickersYear-root
  {
    color: var(--primary-font-color);
  }
  .MuiTypography-colorPrimary.MuiPickersYear-root
  {
    color: var(--primary);
  }
  .mui-date-picker input{
    background: url('../../../../src/images/bi_calendar-event.svg') right 7px center no-repeat!important;
    background-size: 1rem!important;
  }
  .light .mui-date-picker input{
    background: url('../../../../src/images/calendar-icon-light.svg') right 7px center no-repeat!important;
  }
  .cm-filter .btn-primary
  {
    width: 100%;
    height: 2.6rem;
  }
  .cm-filter.conditionMonitoring .form-group
  {
  margin-top: 1.66rem;
  margin-bottom: 0.66rem;
  }
  
  .cm-dashboard svg.recharts-surface
  {
    margin-top: -3px;
  }
  .light .cm-dashboard .recharts-layer text
  {
    fill: #3b414b !important;;
  }
  
  .cm-filter .comnEditDel.MuiButton-containedPrimary
  {
    font-family: "Montserrat-Regular";
    margin-top: 1.66rem;
    width: 8.33rem;
  }
  .conditionMonitoring .recharts-responsive-container
  {
  max-height: inherit!important;
  }
  .conditionMonitoring .alert.alert-info
  {
    margin: 1rem 2rem;
    background: var(--accordin-color);
    border: none;
    padding: 0.5rem 1rem 1rem;
    display: flex;
    align-items: center;
  }
  .conditionMonitoring .alert.alert-info label
  {
    margin-bottom: 0;
  }
  .conditionMonitoring .alert.alert-info svg{
    position: relative;
      top: 0.4rem;
      width: 1.5rem;
      height: 1.5rem;
  }
  .cm-reports .row{
    display: flex;
  }
  .cm-reports .row .dashboard-card
  {
    align-items: stretch;
  }
  .cm-reports .row .dashboard-card .card{
    height: calc(100% - 1rem);
  }

  .cm-dashboard {
    padding-left: 0!important;
  }
  