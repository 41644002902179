#alarm_bar_div {
  margin-top: -40px !important;
  /* border: 2px solid red; */
}
.tooltip {
  background-color: red !important;
}
.tooltip-overlay {
  background-color: black !important;
}
.dataset rect {
  height: 29px !important;
}
#alarm_bar_container {
  /* border: 2px solid gray; */
  height: 100px !important;
  /* overflow: hidden !important; */
  /* margin-left: -100px !important; */
}
.has-data {
  display: none !important;
}
.visavail-tooltip div.tooltip-overlay {
  width: 310px !important;
}
.visavail .xAxis text {
  fill: white !important;
}
.tick text {
  font-size: 12px !important;
}
