.linkExLogo{
    text-align: center;
    margin-bottom: 2rem;
  }
  .linkExLogo svg{
    width: 50%;
  }
.txt {
  font-size: 15px;
}
  /* .fgs_mainBlk {
    margin-top : 5%;
  } */

  .fgs_mainBlk{
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background-color: var(--black);
    background: url("../../images/dark_mode_background.png");
    background-size: cover;
    background-position: center;
    padding: 4rem 0;
}
 /* .loginContainer{
   width: 28%;
   min-width: fit-content;
 } */