.groupingContainer {
    display: grid;
    grid-template-rows: max-content auto;
    height: 100%;
}

.groupingContainer__body {
    overflow: auto;
    padding-top: 1.5rem;
}

.groupingContainer__head>button {
    background-color: var(--dashboard-background);
    padding: 0.5rem;
}

.groupingContainer__head>button.active {
    background-color: var(--secondry-color);
    color: var(--sky-blue);
}

.EM__Header {
    display: flex;
    justify-content: space-between;
    align-items:center;
}


.EnergyMeter__container {
    height: 100%;
    display: grid;
    grid-template-rows: max-content auto;
    gap: 1rem;
}

.EnergyMeter__container .EM__right {
    display: grid;
    grid-template-rows: auto max-content;
    gap: 1rem;
    border: 1px solid #221c1c;
}

.EM__groups {
    display: flex;
    gap: 1rem;
    position: relative;
    z-index: 2;
    flex-grow: 1;
}

.EM__groups>div {
    width: 25%;
    min-width: fit-content;
}

.EM__sidebar>div:not(:last-child) {}

.EM__content .EM__rightHead {
    display: flex;
    justify-content: flex-end;
    margin-left: 1%;
}
.EM__Header .EM__rightHead {
    margin-left: 1%;
}
.EM__rightContent {
    overflow: auto;
    /* margin:auto; */
    padding-bottom: 2px;
}

.EM__rightContent::-webkit-scrollbar {
    display: none;
}

.EnergyMeter__container .EM__content {
    display: grid;
    grid-template-columns: 20% auto;
    gap: 1rem;
    overflow: hidden;
}

.EnergyMeter__container .EM__content>div {
    height: 100%;
    overflow: auto;
    border: 1px solid #2a2727;
}

.EM__subGroups ul li {
    list-style-type: none;
    padding: 0.7rem 1rem;
    background-color: var(--secondry-color);
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* cursor: default; */
    cursor: pointer;
}
.EM_subGroupItem .icon {
    /* pointer-events: none; */
    display: none;
    height: 1rem;
    cursor: pointer;
}
.EM_subGroupItem .icon svg {
    height: 100%;
    width: 100%;
}
.EM_subGroupItem.active .icon{
    display: inline-block;
}
.EM__subGroups ul li:not(:last-child) {
    margin-bottom: 0.5rem;
}

.EM__subGroups ul li.active {
    color: var(--sky-blue);
}
.noSubgrp {
    position: relative;
    top: 43%;
    left: 20%;
}
.noEnrgyBlk{
    position: relative;
    left: 50%;
    top: 48%;
}