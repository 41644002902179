.head{
    display: flex;
    justify-content: center;
    width: 100%;
    align-items: center;
    height: 100%;
    /* border: 1px solid red; */
}
.icon{
    margin-top: 2px;
}
.text{
    margin-left: 5px;
    font-size: 12px;
}