/* Filter Styles Start */
.filterDiv {
  width: 17%;
  height: calc(100vh - 10.41rem);
  background: #1f1f1f;
  box-shadow: 8px 0px 40px rgba(0, 0, 0, 0.3);
  border-radius: 4px 0 0 4px;
  float: left;
  position: relative;
  left: 0;
  color: #fff;
  padding: 3.5rem 1rem;
  top: 0.833rem;
  z-index: 99;
  /* box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.3); */
  border-radius: 4px;
}

.light .filterDiv {
  background: #ffffff;
  color: #3b414b;
  box-shadow: 8px 0px 20px rgba(0, 0, 0, 0.08);
}

.machinesLists {
  display: flex;
  justify-content: center;
  align-items: center;
}
.breadcrumsCls .breadcrumb>li{
  font-size: 1rem;
}
.form-control:focus {
  border-color: #5d97f6 !important;
  outline: 0;
  box-shadow: none !important;
}

.light .form-control:focus {
  border-color: #4285f4 !important;
}

.confirmPin .form-control:focus,
.form-signin input[type="email"]:focus {
  border-color: #5d97f6;
  outline: 0;
  box-shadow: none;
}

.form-signin input[type="email"]:focus,
.form-signin input[type="password"]:focus {
  border-color: #5d97f6;
  border-width: 1px;
}
.filterIcon svg:hover path{
  stroke: #5d97f6;
}
.light .filterIcon svg:hover path{
  stroke: #4285F4;
}
.filterIcon .pointer {
  background: #27333d;
}

.light .filterIcon .pointer {
  background: #e5e5e5;
}
.cardMachineRight .svgI-Con .pathInfo0{
  position: relative;
  top: -3px;
}
.filterInnerDiv span {
  background-color: #2a2c2d !important;
  box-shadow: 0 0 8px 2px rgba(0, 0, 0, 0.25);
  border-radius: 4px 4px 4px 0;
  padding: 0.44rem 0.33rem;
  margin-top: 0;
  display: inline-block;
  width: 2.33rem;
  height: 2.33rem;
  display: flex;
  justify-content: center;
}
.expandKPI img {
  height: 1.33rem;
  width: 1.33rem;
}
.filterInnerDiv span svg, .centerzoom img {
  height: 1.41rem;
  width: 1.41rem;
}

.machineBack svg,
.closeIcon svg,
.machineIconHolder svg,
.alarmIconHolder svg,
.bellIconHolder svg,
.goBackBtn svg,
.change-themewrap svg {
  height: 1.66rem;
  width: 1.66rem;
}
.icoinInfo {
  width: 1.2rem;
  height: 1.2rem;
}
.filterInnerDiv.active path {
  fill: #5d97f6;
  stroke: #5d97f6;
}
.light .filterInnerDiv.active path {
  fill: #4285f4;
  stroke: #4285f4;
}
.innerMachineHeight {
  overflow-x: hidden;
  height: calc(100vh - 16.25rem);
  z-index: 2;
}

.innerMachineHeight.maxHeight {
  height: calc(100vh - 12rem);
}

.innerMachineHeight .loadmorebtn {
  margin-top: 0.25rem;
}

.filterInnerDiv {
  position: absolute;
  z-index: 100;
  left: 0;
  top: 0.83rem;
}

.leftMenu .pointer,
.filterInnerDiv .pointer {
  cursor: pointer;
}

.light .filterInnerDiv .pointer {
  background-color: #fff !important;
  box-shadow: 0px 0px 10px rgba(44, 46, 49, 0.1);
}

.light .filterInnerDiv .lightFilterBG {
  cursor: pointer;
  background-color: #fff !important;
  box-shadow: 0px 0px 10px rgba(44, 46, 49, 0.1);
}

.logologin {
  margin-bottom: 3.33rem;
}

.removedWidth {
  display: none;
}

.dropdown .borderInput {
  border: 1px solid #5d5e60;
  box-sizing: border-box;
  border-radius: 4px;
  color: #fff;
  font-size: 0.83rem;
  background: transparent;
  width: 100%;
  text-align: left;
  padding: 0.5rem 2.5rem 0.5rem 0.83rem;
  line-height: 1;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.alarmBar .dropdown .borderInput {
  padding: 0.5rem 3rem 0.5rem 0.83rem;
}
.light .dropdown .borderInput {
  border: 1px solid #a6aab4;
  color: #3b414b;
}
.alarmFilter .dropdown .borderInput {
  background-color: #1f1f1f;
  position: relative;
  min-width: 8.75rem;
}

.alertFilterDiv .dropdown.open .caret {
  /* right: 0;
  top: 5px; */
}
.alertFilterDiv .dropdown.open .caret::after{
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
}
.rightDivSet .dropdown.open .caret {
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
}

.light .clockHolder path {
  stroke: #3b414b;
}

.alertFilterDiv .open > .dropdown-menu,
.rightDivSet .open > .dropdown-menu {
  border-radius: 0 0 4px 4px;
  margin: 0 0 0;
  background: #2a2a2a;
  border: 1px solid #1f1f1f;
  box-shadow: -4px 0 10px rgba(0, 0, 0, 0.25), 4px 4px 10px rgba(0, 0, 0, 0.25);
  padding: 0;
  max-height: 8.33rem;
  overflow-y: auto;
  min-width: 8.33rem;
}
.alertFilterDiv .dropdown-menu > li > a {
  display: block;
  padding: 0.416rem 0.833rem;
  font-size: 0.83rem;
  color: #e0e0e0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: normal;
}

.alertFilterDiv .dropdown-menu > li > a:focus,
.alertFilterDiv .dropdown-menu > li > a:hover {
  color: #5d97f6;
  text-decoration: none;
  background-color: #2a2c2d;
}

.light .alertFilterDiv .dropdown-menu > li > a:focus,
.light .alertFilterDiv .dropdown-menu > li > a:hover {
  color: #4285f4;
}

.light .alertFilterDiv .open > .dropdown-menu {
  background: #fff;
  border: 1px solid #e4e7ec;
  box-shadow: none;
  margin: -0.16rem 0;
}

.light .alertFilterDiv .dropdown-menu > li > a {
  color: #3b414b;
}

.light .alertFilterDiv .dropdown-menu > li > a:focus,
.light .alertFilterDiv .dropdown-menu > li > a:hover {
  color: #4285f4;
  background-color: #e8f1fd;
}

.light .alarmFilter .dropdown .borderInput {
  background-color: #fff;
  color: #b7b7b7;
}

.panel.alarmRows {
  background-color: #fafafa;
}

.rightDivSet .dropdown-menu > li > a {
  display: block;
  padding: 0.416rem 0.833rem;
  font-size: 0.83rem;
  color: #e0e0e0;
}

.rightDivSet .dropdown-menu > li > a:focus,
.rightDivSet .dropdown-menu > li > a:hover {
  color: #e0e0e0;
  text-decoration: none;
  background-color: #666;
}

.light .rightDivSet .open > .dropdown-menu {
  background: #ffffff;
  border: 1px solid #1f1f1f;
  box-shadow: none;
  margin: -2px 0;
}

.light .rightDivSet .dropdown-menu > li > a {
  color: #3b414b;
}

.light .rightDivSet .dropdown-menu > li > a:focus,
.light .rightDivSet .dropdown-menu > li > a:hover {
  color: #5d97f6;
  background-color: #ecf3f8;
}

.mb15 {
  margin-bottom: 1.25rem;
}

.breadcrumb svg.pathInfo0 {
  position: relative;
  top: 1px;
}

.MuiAutocomplete-paper {
  margin: 0 !important;
}
.font10 {
  font-family: "Montserrat-Medium";
  font-weight: normal !important;
  font-size: 0.83rem;
}
.font10 {
  color: #e0e0e0;
}

.light .font10 {
  color: #3b414b;
}

.filterDiv .font10 {
  margin-bottom: 8px;
}

h2.MuiTypography-root.MuiTypography-h6 h4 {
  font-size: 1.33rem;
}

.light label.font10 {
  font-weight: normal;
}

.drop-text {
  font-family: "Montserrat-Regular";
  color: #e0e0e0;
  font-size: 0.83rem;
}

.onlyEvent .drop-text {
  color: #e0e0e0;
}

.light .drop-text {
  color: #3b414b;
}

.alertFilterDiv .borderInput .drop-text,
.alarmFilter .borderInput .drop-text {
  font-size: 0.83rem;
}

.alertFilterDiv .borderInput .caret,
.alarmFilter .borderInput .caret {
  transition: transform 0.5s ease-in-out;
  color: transparent;
  position: absolute;
  top: 50%;
  right: 0.7rem;
  transform: translateY(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  border: 0;
  width: 0.83rem;
  height: 0.83rem;
}

.borderInput .caret {
  /* text-align: right;
  right: 1rem;
  position: absolute;
  border-top: 0 dashed;
  border-right: 0 solid transparent;
  border-left: 0 solid transparent;
  top: 5%;
  padding: 0.83rem;
  height: 90%;
  margin-right: 0; */
  transition: transform 0.5s ease-in-out;
  color: transparent;
  position: absolute;
  top: 50%;
  right: 0.83rem;
  transform: translateY(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  border: 0;
}

.btn-default.active,
.btn-default.focus,
.btn-default:active,
.btn-default:focus,
.btn-default:hover,
.open > .dropdown-toggle.btn-default {
  color: #fff;
  background-color: #1f1f1f;
  border-color: #1f1f1f;
  outline: 0;
  outline: 0 auto -webkit-focus-ring-color;
  outline-offset: 0;
  position: relative;
}
.light .btn-default.active,
.light .btn-default.focus,
.light .btn-default:active,
.light .btn-default:focus,
.light .btn-default:hover,
.light .open > .light .dropdown-toggle.btn-default {
  color: #1f1f1f;
  background-color: #b6b6b7 !important;
  border-color: #b6b6b7 !important;
}

.light .dropdown .btn-default.active,
.light .dropdown .btn-default.focus,
.light .dropdown .btn-default:active,
.light .dropdown .btn-default:focus,
.light .dropdown .btn-default:hover,
.light .dropdown .open > .light .dropdown-toggle.btn-default {
  color: #1f1f1f;
  background-color: transparent !important;
  border-color: #8c8c8c !important;
}

.headerTable span {
  font-size: 1rem;
  position: relative;
  top: 0.25rem;
}

/* Filter Styles End */
/* Breadcrum CSS in DarkMode Start */

.wrapper {
  height: 100%;
  background: #121212;
  float: left;
  width: 100%;
  padding-left: 7rem;
  position: relative;
  /* z-index: 0; */
}

.light .wrapper {
  background: #f2f4f6;
}

.wrapper .contentWrap {
  float: right;
  width: 100%;
  height: 100%;
}

.contentWrap.monitoringcontentWrap {
/*   width: calc(100% - 1.3rem);
  margin-right: 1.3rem; */
}

.contentWrap .tabArea {
  height: 100%;
  margin-right: 1.3rem;
}
.monitoringcontentWrap.contentWrap .tabArea {
  margin-right: 0;
}
.breadcrumsCls div.bookmarkIcon {
  display: inline-block;
  margin-left: 1.25rem;
  z-index: 5;
}

.bookmarked path {
  fill: #5d97f6;
  stroke: #5d97f6;
}

.light .bookmarked path {
  fill: #4285f4;
  stroke: #4285f4;
}

.breadcrumsCls.machineScroll {
  overflow: unset;
}

.breadcrumsCls .breadcrumb {
  display: flex;
  align-items: center;
  padding: 0;
  border-radius: 0;
  margin: 0;
  background: transparent;
}

.breadcrumsCls .breadcrumb > li + li:before {
  /* content: ">"; */
}
.breadcrumb>li+li:before{
  display: none;
}

.light .breadcrumb > li + li:before {
  color: #3b414b;
}

.breadcrumsCls .breadcrumb-item a {
  font-family: "Montserrat-Medium";
  color: #e0e0e0;
  font-size: 0.83rem;
}

.light .breadcrumsCls .breadcrumb-item a {
  color: #3b414b;
}

.breadcrumsCls .breadcrumb-item.active {
  font-family: "Montserrat-Medium";
  font-size: 0.83rem;
  color: #e0e0e0;
  position: relative;
}
.breadcrumsCls .breadcrumb-item:first-child {
  font-size: 1.2rem;
  font-family: "Montserrat-semibold";
}

.breadcrumb > .breadcrumb-item:not(:last-child)::after {
  content: ">";
  display: inline-block;
  margin: 0 0.5rem;
}

.light .breadcrumsCls .breadcrumb-item.active {
  color: #3b414b;
}

li.breadcrumb-item.active:nth-child(1):after {
  padding: 0 0.33rem;
  font-size: 1rem;
}

.reportsection li.breadcrumb-item.active:nth-child(1):after {
  content: "";
}

li.breadcrumb-item.active:nth-child(2):before {
  content: none;
}
/* Breadcrum CSS in BarkMode End */

.filterDiv.removedWidthh {
  width: 0;
  overflow: hidden;
  padding: 0;
}

.widthcontainer {
  width: 100%;
}

.filterDiv.onlyHCM {
  height: calc(100vh - 13.33rem);
}

.bNavERC {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

.width50 {
  width: 50%;
}
