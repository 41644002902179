.dropDownButton,.Inputclass {
    display: flex;
    justify-content: start;
    align-items: center;
}

.dropdown_rsd{
    width: 100%;
    /* margin-right: 10px; */
}
.buttonHeight {
    height: 38px;
}

.Inputclass{}
.fileupload{
    height: calc(100% - 2rem);
    display: grid;
    grid-template-columns: 25% auto;
    grid-column-gap: 1rem;
}
.fileuploadL .InputHtml {
    margin-left: 0;
}
.Inputclass.buttonupload{
justify-content: center;
display: flex;
align-items: center;
}
.InputLightTheme {
    padding: 6px;
    background: #fff !important;
    color: #252525 ;
    
    border: 1px solid #252525;
}

.InputHtml{
    padding: 6px;
    margin: 10px;
    background: #252525;
    color: #fff !important;
    border: 1px solid #252525;
}

.alignInput {
    width: 187px;
    padding: 13px;
    margin-right: 23px;
}
.buttonCss{
    width: 72px;
    height: 38px;
    margin-right: 16px;
}

.scrollContent {
    overflow-y: scroll;
    overflow-x: hidden;
    height: 550px;
}

.dropdown_rsd > div {
    border: 1px solid #5d5e60;
    box-sizing: border-box;
    border-radius: 4px;
    color: #fff;
    background: #252525;
    width: 100%;
    text-align: left;
    padding: 1px 10px;
    line-height: 1;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }



/* .scrollHeight {
    height: 300px;
} */
.scrollHeightTop {
    height: calc(100vh - 200px);
    overflow-y: scroll;
    overflow-x: hidden;
}
.btn.mb-15{
    margin-bottom: 15px;
}
.dropdown_ftr{
    min-width: 100px;
}

.machineHeader {
    margin-right: 0px;
    padding: 0px;
    width: 232px;
    margin-left: 10px;
}
.fileuploadR{
    
}