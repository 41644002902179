.pagination {
    padding: 1rem 0.5rem 0.5rem 0.5rem;
    position: sticky;
    bottom: 0;
    display: flex;
    justify-content: space-between;
}

.pagination>.left {
    display: flex;
    align-items: center;
}

.pagination button {
    border: none;
    background: none;
}

.pagination button:not(:disabled) svg {
    fill: #fff;
}

.pagination button svg {
    height: 1.33rem;
    width: 1.33rem;
}

.pagination .page_count {
    margin-left: 1rem;
}

.pagination .pageCount {
    margin-left: 1rem;
}

.goToPageInput {
    background-color: transparent;
    border: 1px solid var(--input-border-color);
    padding: 0.1rem 0.5rem;
    border-radius: 0.2rem;
    font-size: 1rem;
    color: var(--text-color);
    outline: none;
}

.actionBtns span {
    display: inline-block;
    cursor: pointer;
}
.spsendbtn {
    width: 2rem !important;
    height: 2rem !important;
    position: relative;
    bottom: 9px;
    color: gray;
    background: #1f1f1f;
    padding: 6px;
    cursor: pointer;
}
.spsendbtn :hover {
    color: #5D97F6;
}
.actionBtns svg {
    height: 3rem;
    width: 3rem;
}


