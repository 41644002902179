.configureTitle {
  margin-left: 2rem;
  font-family: "Montserrat-Regular";
  font-style: normal;
  font-weight: 500;
  font-size: 1.33rem;
  line-height: 1.58rem;
  display: flex;
  align-items: center;
  letter-spacing: 0.001em;
  color: #e0e0e0;
  position: static;
  padding: 0.83rem 0;
}

.lineHorizontals {
  opacity: 0.88;
  height: 0.08rem;
  background-color: #313131;
}

.tabUls {
  margin-left: 4rem;
}

.tabUls.nav-tabs > li {
  border-right: 1px solid #797979;
}

.tabUls.nav-tabs > li:last-child {
  border-right: 0 solid #797979;
}

/* Tabs Styles Start */

.contentPart {
  /* position: relative;
  padding-right: 1.33rem; */
}

.tabArea {
  height: calc(100% - 3.33rem);
}

.dark .tabArea .tabsName li.whenActive:hover a,
.dark .tabArea .tabsName li.whenActive:active a {
  color: #5d97f6;
}
.dark .tabArea .tabsName li.whenActive .IconBeta.liveIConOEE svg rect {
  fill: #34a853;
  opacity: 0.87;
}
.dark .tabArea .tabsName li.whenActive .IconBeta.liveIConOEE svg path {
  fill: #fff;
}
.tabArea .tabsName li.inactiveNodata a {
  color: #525151;
}
.tabArea .tabsName li.inactiveNodata .IconBeta.liveIConOEE svg rect {
  fill: #252525;
}
.tabArea .tabsName li.inactiveNodata .IconBeta.liveIConOEE svg path {
  fill: #525151;
}

.light .tabArea .tabsName li.inactiveNodata a {
  color: #999;
}
.light .tabArea .tabsName li.inactiveNodata .IconBeta.liveIConOEE svg rect {
  fill: #cacaca;
}
.light .tabArea .tabsName li.inactiveNodata .IconBeta.liveIConOEE svg path {
  fill: #999;
}
.tabArea .tabsName li.inactiveNodata {
  cursor: not-allowed;
  pointer-events: none;
}

.tabArea .tabsName li a {
  font-family: "Montserrat-Medium";
  font-size: 1rem;
  text-align: center;
  padding: 0;
  margin: 1rem 1.66rem;
  cursor: pointer;
  color: #a0a0a0;
}

.light .tabArea .tabsName li a {
  color: #58585a;
}

.light .tabArea .tabsName li.active a {
  color: #4285f4;
}
.downtime-report .tabArea .tabsName li:first-child a,
.live-map-tracking .tabArea .tabsName li:first-child a,
.monitoringPage .tabArea .tabsName li:first-child a {
  margin-left: 0;
}
.tabArea .tabsName li.active a {
  color: #5d97f6;
  padding: 0;
}

.tabArea .nav-tabs.tabUls {
  border-bottom: 0 solid #1f1f1f;
  margin-top: 1.25rem;
  margin-bottom: 0.83rem;
}

.tabArea .tabsName li a:hover {
  text-decoration: none;
  color: #71aaff;
}

.light .tabArea .tabsName li a:hover {
  color: #4285f4;
}

.tabArea .tab-content > .active {
  opacity: 1;
  height: calc(100vh - 9.58rem);
}

.tabArea .tabsName.tabUls li.active a:after {
  height: 0;
}

/* Tabs Styles End */

.container {
  color: #ffffff;
  text-align: center;
  font-size: 1.16rem;
  margin-top: 3.33rem;
}

.add-Parameter-button {
  height: 2rem;
  width: 11.66rem;
  background: #5d97f6;
  color: #ffffff;
  display: flex;
  margin-left: 8.33rem;
  align-items: center;
  background: #5d97f6;
  border-radius: 4px;
  font-family: "Montserrat-Regular";
  font-style: normal;
  font-weight: 500;
}

.textFiledArea {
  width: 20.83rem;
  height: 2.33rem;
  background: #2a2a2a;
  border-radius: 4px;
}

.textFiledAreaInput {
  font-family: "Montserrat-Regular";
  font-style: normal;
  font-weight: 500;
  color: #ffffff;
  text-align: center;
  font-size: 1.16rem;
}

.root {
  background: "black";
}

.input {
  color: "white";
}

.navTab {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.tabButtons {
  margin-left: 1.66rem;
  width: auto;
  padding-top: 0.83rem;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}
.tabButtons button {
  margin-right: 1.66rem;
}

.testClass {
  background-color: red;
}
.removesmg {
  font-family: montserrat-regular;
  font-size: 0.83rem;
  font-weight: normal;
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}
.removesmg span {
  font-family: montserrat-Medium;
  font-size: 1rem;
}
.MuiDialogContent-root.removeformdiv {
  padding-bottom: 0;
}
.removeform .MuiDialogActions-root.MuiDialogActions-spacing {
  padding-top: 1px;
}
.removeformtitles {
  min-height: 4.16rem;
}
.addSpoccForm input, .KPILimitContent.settingTable table input{
color: #e0e0e0;
font-family: "Montserrat-Regular";
}
.addSpoccForm h5, .removeform h5 {
  font-size: 1rem;
  font-weight: normal;
  font-family: Montserrat-Medium;
}
.KPILimitContent.settingTable table .alertIcon {
  padding-left: 0.5rem;
}
.KPILimitContent th,
.KPILimitContent.settingTable th{
font-size: 1rem !important;
}
.KPILimitContent.settingTable .checkSpoc.form-group input:checked+label:after {
  top: 0.33rem;
  }
.KPILimitContent.settingTable button span svg {
height: 1.33rem;
width: 1.33rem;
  }
  #OEMServiceSPOC .searchIconGlobal svg{
    height: 1.11rem;
    width: 1.11rem;
  }
  .spoc-content .MuiTooltip-tooltip.MuiTooltip-tooltipPlacementBottom {
    max-width: 100%;
    min-width: 46.66rem;
  }
  .userDetailsSection, .userContact{
    width: 100%;
  }
  div#ConfigureView,
  div#ConfigureCreateGroup,
  div#ConfigureLimits {
    margin: 0 1rem;
}
.form-group.formKPISPOC {
  margin-bottom: 0;
}

@media only screen and (max-width: 767px) {
  .tabArea .tabsName li a{
    font-size: 1.2rem;
  }
}