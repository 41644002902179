/* .cm-reports .font10
{
    font-family: "Montserrat-Medium";
    font-weight: normal !important;
    font-size: 0.70rem;
} */
:root {
  --primary:#5d97f6;
  --primary-background: #121212;
  --secondary-background: #252525;
  --grid-odd-color:#252525;
  --grid-even-color:#1e1e1e;
  --primary-font-color:#e0e0e0;
  --accordin-color:#2f2f2f;
  --bg:#1f1f1f;
  --btn-primary:#5D97F6;
  --btn-primary-color:#000000;
  --btn-secondary:#2f2f2f;
  --btn-secondary-color:#e0e0e0;
  --btn-primary-hover:#6AA2FF;
  --btn-secondary-hover:#3e3e3e;
  --border-color:#5d5e60;
}
:root .light {

  --primary-background: #f2f4f6;
  --secondary-background: #fafbfc;
  --grid-odd-color:#fafbfc;
  --grid-even-color:#ffffff;
  --primary-font-color:#3b414b;
  --accordin-color:#fafbfc;
  --bg:#ffffff;
  --btn-primary:#4285F4;
  --btn-primary-color:#ffffff;
  --btn-secondary:#edf0f3;
  --btn-secondary-color:#3b414b;
  --btn-primary-hover:#296FEA;
  --btn-secondary-hover:#b6b6b7;
  --border-color:#a6aab4;

}
.cm-reports
{
  overflow-x: scroll;
  height: calc(100vh - 13.5rem);
}
.cm_report .alarm-pagination.float-container
{
  margin: 0;
}
.modelboxopenInnner.conditionMonitoring .scrollTab span
{
  white-space: nowrap; 
  overflow: hidden;
  text-overflow: ellipsis;
}
.modelboxopenInnner.conditionMonitoring .headerTable
{
background:var(--accordin-color);
}
.modelboxopenInnner.conditionMonitoring .panel-default>.panel-heading:after
{
  display: none;
}
.conditionMonitoring .datefilter .MuiInputBase-root
{
background: var(--grid-even-color) !important;
border:var(--border-color) solid 0.5!important
}

.modelboxopenInnner.conditionMonitoring .col-md-12
{
  border: none !important;
}
.cm-reports .scrollTab
{
  width:178vw;
}

  .cm-reports .card
  {
    background: var(--grid-even-color);
    border-radius: 4px;
    padding: 1rem;
    margin-bottom: 1rem;
    min-height: 300px;
    }
    .cm-reports .card .recharts-wrapper
{
  background: var(--grid-even-color);
  margin-top:1rem ;
}
.cm-reports .headerTable>span, .cm-reports .panel-title>span
{
    float: left;
    text-align: left !important;
    display: block;
    padding-left: 0.416rem;
    padding-right: 0.416rem;
} 


.cm-reports .row2,.cm-reports .row5,.cm-reports .row6,.cm-reports .row7, 
.cm-reports .row12,.cm-reports .row13,.cm-reports .row14
{
  width:4%!important;
}
.cm-reports .row4,.cm-reports .row5,.cm-reports .row11,.cm-reports .row15,.cm-reports .row3,.cm-reports .row10
{
  width: 8.6%!important;
}
 .cm-reports .row9, .cm-reports .row8
{
  width: 12%!important;
}
.cm-reports .pannelWrap .row15, .cm-reports .headerTable .row15{
  text-align: center!important;
}
.cm-reports.cm-table .eventOverflowScroll
{
  height: calc(100vh - 17.8rem);
}

.cm-reports.eventOverflowScroll {
  height: calc(100vh - 10.9rem);
}

.cm-filter .eventOverflowScroll {
  height: calc(100vh - 10rem);
}

.cm-filter .filterDiv
{
    width: 100%;
}
.cm-filter .filterDiv .input-group
{
opacity: 1;
}
.cm-filter .MuiFormControl-marginNormal
{
margin-top: 0;
margin-bottom: 0;
width: 100%;
}
.conditionMonitoring .cm-filter .MuiSelect-icon
{
  top: 0!important;
  height: 2rem;
}
.cm-filter .filterDiv .MuiInputBase-root input[value=""]{
  text-transform: none;
}
.cm-reports.cm-table .panel-default>.panel-heading:after
{
display: none;
}

.cm-reports .dashboard-card .font10.pull-right svg
{
  cursor: pointer;
  width:1.4rem;
  height: 1.4rem;
}
.cm-reports .dashboard-card .font10.pull-right svg:hover path{
  stroke: var(--primary);
}
.MuiPickersBasePicker-container .MuiPickersToolbar-toolbar, .MuiPickerDTTabs-tabs, .MuiPickersDay-daySelected, .MuiPickersCalendarHeader-iconButton
{
background: var(--primary)!important;
}
.MuiTypography-colorInherit, .MuiPickersSlideTransition-transitionContainer > *, .MuiPickersCalendarHeader-dayLabel, .MuiPickersClock-squareMask, .MuiPickersClockNumber-clockNumber
{
color: var(--primary-font-color)!important;
}
.MuiPickersBasePicker-container h3, .MuiPickersBasePicker-container h4{
  font-size: 1.23rem!important;
}
.MuiPickersBasePicker-pickerView
{
  background: var(--accordin-color);
}
.MuiPickersCalendarHeader-switchHeader .MuiSvgIcon-root path:first-child
{
  fill:var(--accordin-color);
}
.cm-reports .noRecords
{
  text-align: center;
  margin-top: 100px;
}
.cm_report .filterInnerDiv, .cm_report .filterDiv
{
  top: 0 !important;
}
.cm-dashboard .recharts-default-tooltip
{
background: var(--primary-background);
border: none;
border-radius: 6px;
}

.cm-reports .top-model .recharts-legend-item-text{
  white-space: nowrap;
}

.cm-reports .recharts-legend-item-text
{
  float: none !important;
  text-align: left !important;
  display: inline !important;
}

.cm-reports svg.recharts-surface
{
  margin-right: 0px !important;
  margin-top: -3px;
}

.alert-notifications .col-md-2 .col-sm-12,
.cm_report .col-md-2 .col-sm-12,
.execution-result .col-md-2 .col-sm-12
{
  padding: 0;
}
.tab-pane.fade.no-gutters.in.active
{
  width: calc(83% - 1.6rem);
    margin-left: auto;
    overflow: hidden;
    height: calc(100vh - 10rem);
    padding-top: 1rem;
}
.t.tab-pane.fade.no-gutters.in.active
{
    margin-left: 0;
    width: 100%;
}
.cm-filter.conditionMonitoring {
  width: 17%;
}
.exresult .contentPart, .alertnotifylog .contentPart{
  padding-right: 0;
}
#Condition-Monitoring_Trends, #Condition-Monitoring_Table{
    width: calc(83% - 1.6rem);
    margin-left: auto;
    overflow: hidden;
    height: calc(100vh - 10rem);
    display: none;
}
#Condition-Monitoring_Trends.active, #Condition-Monitoring_Table.active{
  display: block;
}
#Condition-Monitoring_Trends.cmtrends, #Condition-Monitoring_Table.cmtable{
  width: 100%;
  margin-left: 0;
}
.conditionalmonitoringreport .noHistoryAlarms{
  text-align: left;
  justify-content: left;
  padding-left: 36rem;
}
.conditionalmonitoringreport .contentPart{
  padding-right: 0;
}
/* .cm-reports.cm-table {
  height: calc(100vh - 11rem);
} */
/* .cm-reports-bg .alarm-pagination {
  padding-bottom: 1em;
} */