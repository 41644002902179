@keyframes toastClose {
  0% {
    width: 100%;
  }

  100% {
    width: 0%;
  }
}

@font-face {
  font-family: "Montserrat-Regular";
  src: url("./fonts/Montserrat/Montserrat-Regular.ttf");
}

@font-face {
  font-family: "Montserrat-Medium";
  src: url("./fonts/Montserrat/Montserrat-Medium.ttf");
}

@font-face {
  font-family: "Montserrat-SemiBold";
  src: url("./fonts/Montserrat/Montserrat-SemiBold.ttf");
}

@font-face {
  font-family: "Ubuntu-Regular";
  src: url("./fonts/Ubuntu/Ubuntu-Regular.ttf");
}

:root {
  --white: #fff;
  --bg-color: rgba(56, 56, 56, 0.75);
  --shadow: 0 0 20px rgb(0 0 0 / 25%);
  --light-danger: rgba(243, 98, 98, 0.3);
  --light-black: #1f1f1f;
  --light-sky-blue: rgba(32, 141, 244, 0.8);
  --sky-blue: #5d97f6;
  --sky-blue-hover-color: #6aa2ff;
  --black: #000;
  --primary-color: #232323;
  --secondry-color: #2a2c2d;
  --sidebar-width: 5rem;
  --dashboard-background: #121212;
  --text-color: #a0a0a0;
  --copyright-color: #8f8f8f;
  --input-border-color: #5d5e60;
  --bg-layer-color: rgba(18, 18, 18, 0.5);
  --danger-color: #d71635;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

/* width */
::-webkit-scrollbar {
  /* width: 0.2rem; */
  width: 0.5rem;
  height: 0.5rem;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-corner {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #8f8f8f;
}

html {
  font-size: 12px;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  line-height: 1;
}

body {
  margin: 0;
  padding: 0;
  min-height: 100vh;
  /* background: url("./images/dark_mode_background.png"); */
  background-position: center;
  background-size: cover;
  font-size: 1rem;
  font-family: "Montserrat-Regular";
  background-color: var(--dashboard-background);
  color: var(--white);
}

/* Common CSS */
input[type="text"],
/* input[type="email"], */
input[type="search"],
input[type="number"] {
  display: block;
  outline: none;
  background-color: transparent;
  border: 1px solid var(--input-border-color);
  padding: 0.4rem 0.6rem;
  color: var(--white);
  border-radius: 0.3rem;
}
input[type="text"]:focus,
input[type="email"]:focus,
input[type="search"]:focus,
input[type="number"]:focus {
  border-color: var(--sky-blue);
}
.showOnHover {
  display: none;
}

.toggleIconOnHover:hover .showOnHover,
.toggleIconOnHover.active .showOnHover {
  display: block;
}

.toggleIconOnHover:hover .hideOnHover,
.toggleIconOnHover.active .hideOnHover {
  display: none;
}

.dashboard__content {
  width: calc(100% - var(--sidebar-width));
  margin-left: auto;
  padding: 0 1.2rem 0 2rem;
  height: 100vh;
  display: grid;
  grid-template-rows: max-content auto max-content;
}

.dashboard__data {
  overflow: hidden;
}

.dashboard__innerData {
  height: 100%;
  width: 100%;
}

.btn-primary {
  display: inline-block;
  background-color: var(--sky-blue);
  color: var(--white);
  padding: 0.6rem;
  border: 1px solid var(--sky-blue);
  font-family: "Montserrat-Medium";
  border-radius: 0.2rem;
  outline: none;
  cursor: pointer;
  margin: 0;
}

.btn-primary:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.btn-clear {
  background-color: #2f2f2f;
  color: var(--white) !important;
  padding: 0.6rem;
  border-radius: 0.2rem;
  border: 1px solid #2f2f2f;
  cursor: pointer;
}

.btn-danger {
  cursor: pointer;
  background-color: var(--danger-color);
  color: var(--white);
}

:disabled {
  cursor: not-allowed;
}

/* Report */
.solarPlantReport {
  height: 100%;
  width: 100%;
}

.solarPlantReport>iframe {
  border: none;
}

/* for Logo SVG */
.svgLogo .cls-1 {
  fill: none;
}

.svgLogo .cls-2 {
  fill: #d71635;
}

.svgLogo .cls-3 {
  clip-path: url(#clip-path);
}

/* ************* */

/* ********* Breadcrumbs CSS ************ */
.breadscrums {
  display: flex;
  list-style-type: none;
  font-size: 1rem;
  align-items: center;
}

.breadscrums>li a {
  color: var(--white);
  text-decoration: none;
}

.breadscrums>li:first-child>a {
  font-size: 1.2rem;
  font-family: "Montserrat-SemiBold";
}

.breadscrums>li:not(:last-child)::after {
  content: ">";
  display: inline-block;
  margin: 0 0.5rem;
}

/* ********* End Breadcrumbs CSS ************ */

/* *********** Tab CSS ************* */
.tab-container {
  height: 100%;
  display: grid;
  grid-template-rows: max-content auto;
}

.tab-content {
  overflow: overlay;
}

.tab-pane {
  height: 100%;
  /* display: none; */
}

.tab-pane.active {
  /* display: block; */
}

.tab-button-container {
  list-style-type: none;
  display: flex;
}

.tab-button-container>.tab-button:not(:first-child) {
  margin-left: 2rem;
}

.tab-button-container>.tab-button>a {
  display: block;
  padding: 1rem 0;
  border-bottom: 0.2rem solid transparent;
  cursor: pointer;
  font-size: 1rem;
  color: #fff;
}
.tab-button-container>.tab-button>a:hover{
  text-decoration: none;
}
.tab-button-container>.tab-button.active>a {
  border-color: var(--sky-blue);
  color: var(--sky-blue);
}

.PowerBI-tab-content {
  position: relative;
}

.PowerBI-tab-content .tab-pane {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: unset;
}

.PowerBI-tab-content .tab-pane.active {
  z-index: 1;
}

/* *********** Tab CSS End ************* */
.print_file_container {
  height: 100%;
  overflow: overlay;
}

.file_upload_container {
  height: 100%;
  overflow: overlay;
  display: grid;
  grid-template-rows: max-content auto;
}

.ftr_file_not_found {
  display: flex;
  align-items: center;
  justify-content: center;
  border: aliceblue;
  width: 100%;
  background: #252525;
  height: 100%;
  color: aliceblue;
  text-align: center;
}

.ftr_upload_btn {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1rem;
}

.IOMatrix_container .dropdown_rsd {
  width: 60%;
}

.Reports {
  display: grid;
  /* grid-template-columns: repeat(4, calc(25% - 1rem)); */
  grid-template-columns: repeat(4, 1fr);
  gap: 1.25rem;
  max-height: 100%;
  overflow: overlay;
}

.card_tile {
  display: flex;
  align-items: center;
  background-color: var(--primary-color);
  padding: 1.3rem;
  cursor: pointer;
}

.title {
  margin-left: 1.6rem;
}

.fileupload .dropdown_rsd {
  width: 100%;
}

.powerBI_report_container {
  height: 100%;
}

iframe {
  border: none;
}

.RSD_report_container {
  display: grid;
  grid-template-rows: max-content auto;
  height: 100%;
}

.add_entry_btn {
  display: flex;
  justify-content: flex-end;
  padding-bottom: 1rem;
}
.report_add_entry_btn {
  position: absolute;
  right:2%;
}

/* PredictModel Component CSS */
.prediction_table {
  border-collapse: collapse;
  border-spacing: 1rem;
}

.prediction_table th {}

.prediction_table td,
.prediction_table th {
  border: 1px solid #fff;
  padding: 1rem;
  line-height: 1;
}

.prediction_table .PredictionResult,
.prediction_table .Prediction {
  font-weight: 700;
  border-width: 3px;
}

.prediction_table .DBCalibartionViscosity,
.prediction_table .DBAcutalViscosity,
.DBAcutalTemp,
.LubiricationOfTime {
  min-width: 100px;
  word-break: break-word;
}

/* PredictModel Component CSS END */


/* RSD Report (Add Entry) CSS */
.RSD_popup {
  position: fixed;
  top: 0;
  left: 0;
  /* z-index: 2; */
  z-index: 3;
  background-color: var(--primary-color);
  width: 100%;
  height: 100%;
  padding: 1.5rem;
  display: grid;
  grid-template-rows: max-content auto;
}

.RSD_popup_title {
  font-size: 1.33rem;
  color: #e0e0e0;
  width: calc(100% - .83rem);
  font-family: "Montserrat-Medium";
}

.RSD_close_btn,.RSD_close_btn:hover {
  cursor: pointer;
  float:right;
  text-decoration: none;
}

.RSD_popup__head {
  display: flex;
  justify-content: space-between;
  /* flex-wrap: wrap; */
  padding: 0 0 1.5rem 0;
  border-bottom: 1px solid #313131;
}

.RSD_popup__body {
  padding-top: 1.5rem;
  overflow: hidden;
}

.RSD_popup__body>div {
  /* height: 50%; */
}

.RSD_Weekly_report_head {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.RSD_Weekly_report_body {
  margin-top: 1.2rem;
}

.RSD_filter {
  display: flex;
  align-items: center;
  flex-grow: 1;
  justify-content: space-between;
}

.RSD_filter>div {}

.RSD_Weekly_report_head_left {
  display: flex;
  align-items: flex-end;
  width: 50%;
}

.RSD_Weekly_report_head_left>div:not(:first-child) {
  margin-left: 1rem;
}

.RSD_select {
  width: 100% !important;
}

.RSD_select_container {
  width: calc(33.33% - 1rem);
}

.RSD_select_container>label {
  display: block;
  margin-bottom: 0.5rem;
}

.modelboxopenInnnerRsd {
  display: none;
}
.RSD_reset {
  margin-bottom: 0.6rem;
}
.RSD_reset_btn {
  background: #2f2f2f;
  color: #5d97f6;
  font-size: 1rem;
  text-transform: capitalize;
  padding: 1.2rem 2rem;
  border: none;
  border-radius: 4px;
}


.weeklyTable {
  width: 100%;
}

.weeklyTable>tbody>tr>td,
.weeklyTable>thead>tr>th {
  padding: 1.1rem 0;
  border: 1px solid #313131;
  text-align: center;
}

.weeklyTable thead tr {
  background-color: #2a2a2a;
}

.weeklyTable tbody tr:nth-child(even) {
  background-color: #252525;
}

/* .weeklyTable tbody tr:nth-child(odd) {
  background-color: #2a2a2a;
} */

.innerTableweek td span {
  border-radius: 4px;
  height: 24px;
  width: 40px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.RSD_save_btn {
  display: flex;
  justify-content: flex-end;
  background: #2a2a2a;
  /* padding: 1rem; */
  padding: 0.7rem;
}

.RSD_save_btn button {
  background: #5d97f6;
  border-radius: 4px;
  box-shadow: none;
  outline: none;
  border: none;
  color: #000;
  font-family: "Montserrat-Medium";
  font-size: 0.9rem;
  text-transform: capitalize;
  padding: 0.9rem 0.9rem;
}

.toast_popup {
  width: fit-content;
  position: fixed;
  top: 3rem;
  left: 50%;
  transform: translateX(-50%);
  padding: 2.5rem;
  background: var(--primary-color);
  border-radius: 4px;
  box-shadow: var(--shadow);
  overflow: hidden;
}

.toast_popup::after {
  /* animation: infinite 3s toastClose; */
  animation: toastClose 4s linear;
  content: '';
  display: block;
  width: 100%;
  height: 0.5rem;
  background: var(--white);
  position: absolute;
  left: 0;
  bottom: 0;
}

.toast_popup.Success {
  background-color: #3498db;
}

.toast_popup.Danger {
  background-color: #b34f46;
}

.toast_close {
  position: absolute;
  top: 0.6rem;
  right: 0.6rem;
  cursor: pointer;
}

.RSD_Edit_popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  background-color: var(--primary-color);
  width: 60%;
  padding: 1.5rem 1.5rem 6rem 1.5rem;
  display: grid;
  grid-template-rows: max-content auto;
  border-radius: 0.2rem;
}

.RSD_Edit_popup_head {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.RSD_Edit_popup_body {
  padding-top: 2rem;
}

.RSD_Edit_close {
  color: #8f8f8f;
  cursor: pointer;
  font-size: 1.66rem
}

.RSD_Edit_popup_row {
  display: grid;
  grid-template-columns: 25% auto;
}

.RSD_Edit_popup_row:not(:last-child) {
  margin-bottom: 1rem;
}

.RSD_Edit_popup_value input {
  /* border: .5px solid #1f1f1f; */
  border: 1px solid #5d5e60;
  box-sizing: border-box;
  border-radius: 4px;
  background: transparent;
  padding: 5px 10px;
  outline: none;
  box-shadow: none;
  color: #e0e0e0;
  max-width: 100%;
  width: 100%;
}

.RSD_Edit_popup_BG {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .7);
  z-index: 1;
}

.RSD_popup .lbl {
  position: relative;
  bottom: 4px;
}

/* RSD Report (Add Entry) CSS END */


.critical-label {
  cursor: pointer;
  margin: 0;
  margin-left: 25px;
  font-weight: normal;
}

.critical-label>.icon {
  height: 1.16rem;
  width: 1.16rem;
  background-color: transparent;
  border: 1px solid #8f8f8f;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 0.5rem;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 0.66rem;
  border-radius: 0.33rem;
  ;

}

.critical-label>.icon::before {
  content: '';
  display: none;
  position: absolute;
  top: 0;
  /* border: 1px solid #fff; */

  left: 0.33rem;
  width: 0.33rem;
  height: 0.83rem;
  border: solid #000;
  border-width: 0 1px 1px 0;
  transform: rotate(45deg);
}

.critical-label>input {
  display: none;
}

.critical-label>input:checked+.icon {
  background-color: #5d97f6;
  border: 1px solid #5d97f6;
}

.critical-label>input:checked+.icon::before {
  display: block;
}

.MuiAutocomplete-popper .MuiAutocomplete-listbox {
  font-size: 0.83rem;
  color: #fff;
  font-family: "Montserrat-Regular";
}

.MuiAutocomplete-option[data-focus="true"] {
  color: #5d97f6;
  font-size: 0.83rem;
  font-family: "Montserrat-Regular";
  text-decoration: none;
  background-color: #2a2c2d !important;
}

.io-matrix .fade {
  opacity: 1 !important;
}

.ECR__popup label,
.ECR__popup p,
.ECR__popup input {}

.dropdown_rsd  input{
  color: #fff!important;
  background: transparent!important;
}

.admin-panel-dropdown>div:last-child>div{
  max-height: 120px!important;
}
.dm-tabcontnt input[type="text"] {
  display: inline!important;
  border: none!important;
}