/* kpi css start */
.KPIList .form-radio-div {
  margin-top: 1rem;
  margin-bottom: 0 !important;
  display: flex;
}

.KPIList .radioDiv {
  display: flex;
  margin-right: 2.5rem;
}

.KPIList .radioDiv input {
  float: left;
  margin-right: 0.41rem;
  margin-top: 0.33rem;
}

.KPIList .radioDiv label {
  font-family: "Montserrat-Regular";
  font-size: 1rem;
  padding-top: 0.16rem;
  color: #e0e0e0;
}

.light .KPIList .radioDiv label {
  color: #3b414b;
}
.flexCls {
  display: flex;
}
.KPIList .searchDiv .input-group .form-control {
  background: #1f1f1f;
}

.light .KPIList .searchDiv .input-group .form-control {
  background: #ffffff;
  width: 16.6rem;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.02);
  border-radius: 4px !important;
}

.KPIList .searchDiv input.empty {
  font-family: Montserrat-Medium, FontAwesome;
  font-weight: normal;
  font-size: 0.83rem;
  position: absolute;
  top: 0.41rem;
  color: #1f1f1f;
}

.light .KPIList .searchDiv input.empty {
  color: #b7b7b7;
}

.KPISetting {
  margin-top: 1rem;
  float: left;
  width: 8.33rem;
}

.KPIList .searchDiv {
  margin-top: 0.66rem;
  margin-bottom: 0.83rem;
  margin-left: 14.58rem;
}

.KPIList .ms_filter {
  padding-top: 0.83rem;
  margin-left: 19.16rem;
}

.KPIList .ms_alert_notification {
  padding-top: 0.66rem;
  margin-left: 1.66rem;
}

.KPIList {
  overflow: hidden;
  height: 80vh;
  font-family: "Montserrat-Regular";
}

.cardKpiListUL {
  margin: 0;
  padding: 0;
  list-style: none;
  float: left;
  width: 100%;
  height: calc(100% - 3rem);
  overflow: auto;
}

.cardKpiListUL li {
  background: #252525;
  border-radius: 4px;
  width: calc(20% - 1.3rem);
  height: 22vh;
  position: relative;
  margin-right: 1.3rem;
  margin-bottom: 1.3rem;
  float: left;
}
.cardKpiListUL li > div {
  padding: 0.7rem;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  position: relative;
}

.light .cardKpiListUL li {
  background: #f6f8fa;
  border: 0.5px solid #f0f1f3;
}

.leftcardKPI {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}
.formKPISPOC .btn {
  display: flex;
  align-items: center;
  justify-content: center;
}
.formKPISPOC .btn span.editIconGroup {
  display: flex;
  padding-right: 5px;
}
.formKPISPOC .refreshDiv button:hover svg path {
  stroke: #5d97f6;
}
.light .formKPISPOC .refreshDiv button:hover svg path {
  stroke: #4285f4
}
.imageKPI {
  float: left;
  width: 3.4rem;
  margin-right: 1rem;
}
.imageKPI svg{
  height: 3.42rem;
  width: 3.42rem;
}
.expandKPI {
  position: absolute;
  right: 0;
  bottom: 0.25rem;
  padding: 0.16rem 0.41rem;
  z-index: 1;
}
.div4KPI span {
  line-height: 1;
  margin-right: 0.4rem;
}
.rightcardKPI {
  display: flex;
  align-items: flex-end;
  position: absolute;
  bottom: 7px;
}

.rightcardKPI p {
  text-overflow: ellipsis;
  overflow: hidden;
}

.div1KPI {
  color: #e0e0e0;
  font-size: 0.7rem;
  text-align: right;
}

.light .div1KPI {
  color: #3b414b;
}

.div1KPI span {
  color: #a0a0a0;
  font-size: 0.7rem;
}

.light .div1KPI span {
  color: #58585a;
}

.wrap23 {
  display: flex;
  align-items: center;
  word-break: break-all;
}
.unitLineBreak {
  word-break: break-word !important;
}
.leftcardKPI .wrap23 {
  justify-content: flex-start;
}

.div2KPI {
  font-family: "Montserrat-Regular";
  font-size: 1.3rem;
  line-height: 1;
  text-align: left;
  font-weight: bold;
}

.In-Range {
  color: #58a768;
}

.light .In-Range {
  color: #34a853;
}

.Out-of-Range {
  color: #ed5f53;
}

.light .Out-of-Range {
  color: #ea4335;
}

.div3KPI {
  width: 100%;
  color: #e0e0e0;
  font-size: 0.83rem;
  text-align: left;
  font-family: "Montserrat-Regular";
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  padding: 0rem 0;
  letter-spacing: 0.004em;
  margin-bottom: 0rem;
  min-height: 6rem;
}
.light .div3KPI {
  color: #3b414b;
}
.div3KPI > span {
  display: inline-block;
  line-height: 1;
}
.imageKPI img {
  width: 100%;
}
.div3KPI p {
  margin-bottom: 0;
  line-height: 1.5rem;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  text-transform: capitalize;
  /* height: 2.8rem; */
  min-height: 2.8rem;
}
.div3KPI span {
  vertical-align: middle;
}

.div4KPI {
  color: #a0a0a0;
  font-size: 0.83rem;
  font-family: "Montserrat-Regular";
  display: flex;
  align-items: center;
}
.light .div4KPI {
  color: #58585a;
}

.mb15card {
  margin-bottom: 1.66rem;
}
.div2KPI.In-Range span:first,
.div2KPI.Out-of-Range span:first {
  padding-right: 0.41rem;
}
/* kpi css end */

.cardKpiListUL li p {
  font-size: 1rem;
}

.light .cardKpiListUL li p {
  color: #3b414b;
}

.light .MuiList-dense.MuiList-padding {
  background-color: #ffffff;
}

#ConfigureCreateGroup
  .MuiButtonBase-root.MuiListItem-root.MuiListItem-gutters.MuiListItem-button {
  background: #2a2a2a;
  margin: 0 0.83rem;
  width: calc(100% - 1.66rem);
  padding-left: 0;
}

.light
  #ConfigureCreateGroup
  .MuiButtonBase-root.MuiListItem-root.MuiListItem-gutters.MuiListItem-button {
  background: #f6f8fa;
}

.selectParameterKPI .MuiOutlinedInput-notchedOutline,
.selectParameterKPI
  .MuiOutlinedInput-root:hover
  .MuiOutlinedInput-notchedOutline {
  border: none;
  border-color: #1f1f1f;
}

.selectParameterKPI .MuiAutocomplete-clearIndicatorDirty .MuiSvgIcon-root {
  fill: #e0e0e0;
}

.selectParameterKPI .MuiAutocomplete-popupIndicator .MuiSvgIcon-root {
  content: "";
  background: url(../images/dropdownArrowDown.png) center center transparent
    no-repeat;
  width: 0.83rem;
  height: 0.5rem;
  -webkit-transition: -webkit-transform 0.5s ease-in-out;
  transition: transform 0.5s ease-in-out;
  fill: transparent;
}

#ConfigureLimits .selectParameterKPI .MuiAutocomplete-input {
  background: transparent;
  padding-left: 0.83rem;
  font-size: 0.83rem;
  font-family: Montserrat-Regular;
  padding-right: 3.33rem;
  border: 1px solid #5d5e60;
}
.light #ConfigureLimits .selectParameterKPI .MuiAutocomplete-input {
  border: 1px solid #a6aab4;
}

/* Configure Group Code Start */

.bgColorDiv {
  background-color: #1f1f1f;
}

.tableSpan .headerTable span:nth-child(1),
.KPIViews.pannelWrap span.row1,
.tableSpan .headerTable span:nth-child(2),
.KPIViews.pannelWrap span.row2,
.tableSpan .headerTable span:nth-child(3),
.KPIViews.pannelWrap span.row3,
.tableSpan .headerTable span:nth-child(4),
.KPIViews.pannelWrap span.row4,
.tableSpan .headerTable span:nth-child(5),
.KPIViews.pannelWrap span.row5,
.tableSpan .headerTable span:nth-child(6),
.KPIViews.pannelWrap span.row6,
.tableSpan .headerTable span:nth-child(7),
.KPIViews.pannelWrap span.row7,
.tableSpan .headerTable span:nth-child(8),
.KPIViews.pannelWrap span.row8 {
  float: left;
  text-align: center;
  display: block;
  margin-left: 0;
}

.tableSpan .headerTable span:nth-child(1),
.KPIViews.pannelWrap span.row1 {
  width: 5%;
}

.tableSpan .headerTable span:nth-child(2),
.KPIViews.pannelWrap span.row2 {
  width: 34%;
  text-align: left;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  padding-left: 0.83rem;
}

.tableSpan .headerTable span:nth-child(3),
.KPIViews.pannelWrap span.row3,
.tableSpan .headerTable span:nth-child(4),
.KPIViews.pannelWrap span.row4,
.tableSpan .headerTable span:nth-child(5),
.KPIViews.pannelWrap span.row5,
.tableSpan .headerTable span:nth-child(6),
.KPIViews.pannelWrap span.row6 {
  width: 12%;
}

.tableSpan .headerTable span:nth-child(7),
.KPIViews.pannelWrap span.row7 {
  width: 12%;
  padding: 0 0.41rem;
}

.tableSpan .headerTable span:nth-child(8),
.KPIViews.pannelWrap span.row8 {
  width: 12%;
  padding: 0;
}

.svgRedClose {
  position: relative;
  top: 0.83rem;
}
.prod > .MuiButtonGroup-root.MuiButtonGroup-contained button {
  margin-right: 4rem !important;
}
.comnEditDel.MuiButton-containedPrimary {
  background: #363636;
  border-radius: 4px;
  color: #5d97f6;
  margin-right: 0;
  font-size: 1rem;
  text-transform: capitalize;
}
.light .comnEditDel.MuiButton-containedPrimary {
  color: #4285f4;
}
.comnEditDel.MuiButton-containedPrimary:hover {
  background: #6aa2ff;
  color: #fff;
}
.light .comnEditDel.MuiButton-containedPrimary:hover {
  background: #296fea;
}
.comnEditDel.MuiButton-containedPrimary.saveBtmCreateGroup {
  background-color: #5d97f6;
  border-radius: 4px;
  color: #000000;
  margin-right: 0;
  font-size: 1rem;
  text-transform: capitalize;
  width: 8.33rem;
  height: 3.33rem;
}
.comnEditDel.MuiButton-containedPrimary.saveBtmCreateGroup:hover {
  background-color: #6aa2ff;
  color: #000000;
}
.light .comnEditDel.MuiButton-containedPrimary.saveBtmCreateGroup {
  background-color: #4285f4;
  color: #ffffff;
}
.light .comnEditDel.MuiButton-containedPrimary.saveBtmCreateGroup:hover {
  background-color: #296fea;
}

.light .comnEditDel.MuiButton-containedPrimary {
  background: #f6f8fa;
  box-shadow: none;
}

.light .comnEditDel.MuiButton-containedPrimary:hover {
  background: #5d97f6;
  color: #fff;
}

.light #ConfigureView p {
  color: #3b414b;
}

.refreshBtn.font10 {
  color: #5d97f6;
  font-size: 0.83rem;
}

.light .refreshBtn.font10 {
  color: #4285f4;
  background: #fff;
}

.refreshBtn.font10:hover,
.refreshBtn.font10:active,
.refreshBtn.font10:focus {
  color: #6aa2ff;
}

.light .refreshBtn.font10:hover,
.light .refreshBtn.font10:active,
.light .refreshBtn.font10:focus {
  color: #296fea;
}

.light #ConfigureGroups .comnBtns.MuiButton-containedPrimary {
  background: #e5e5e5;
  border-color: transparent;
}

.comnEditDel.MuiButtonGroup-groupedHorizontal:not(:last-child) {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.comnEditDel.MuiButtonGroup-groupedHorizontal:not(:first-child) {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.comnEditDel.MuiButtonGroup-groupedContainedPrimary:not(:last-child) {
  border-color: #5d97f6;
}

.comnEditDel.MuiButton-containedPrimary:hover {
  background-color: #5d97f6;
  color: #fff;
}

.comnEditDel.MuiButton-containedPrimary.saveBtmCreateGroup:hover {
  background-color: #5d97f6;
}

.bgColorDiv .MuiButtonGroup-root.MuiButtonGroup-contained {
  float: right;
  height: 2rem;
  margin: 1.25rem 0;
  box-shadow: none;
}

.comnEditDel span.editIconGroup {
  position: relative;
  top: 0.16rem;
  left: -0.41rem;
}

.tableSpan .headerTable {
  background-color: #252525;
  border-radius: 4px;
}

.light .tableSpan .headerTable {
  background-color: #fff;
}

.eventDivs.tableSpan {
  margin-right: 0;
}

.tabArea .nav-tabs.tabUlsInner {
  border-bottom: 0 solid #1f1f1f;
  margin: 0 4rem;
}

.tabUlsInner.nav-tabs > li {
  border-right: 1px solid #797979;
}

.tabArea .tabUlsInner.nav-tabs > li.active a:after {
  height: 0;
}

.tabUlsInner.nav-tabs > li:last-child {
  border-right: 0 solid #797979;
}

.bgColorDiv .noHistoryAlarms {
  background: transparent;
}

.light .bgColorDiv .noHistoryAlarms {
  background: #fff;
}

.KPIViews.pannelWrap span:nth-child(7) .MuiSwitch-root {
  width: 4.83rem;
  height: 2.66rem;
  display: inline-flex;
  padding: 1rem;
  margin-left: 0;
}

.KPIViews.pannelWrap span:nth-child(7) .MuiSwitch-switchBase {
  color: #fafafa;
  width: auto;
  padding: 0.66rem 1rem;
  margin-left: 0;
}

.KPIViews.pannelWrap span:nth-child(7) .MuiSwitch-track {
  width: 100%;
  height: 100%;
  padding: 0;
}

.KPIViews.pannelWrap span:nth-child(7) .MuiSwitch-thumb {
  width: 1.33rem;
  padding: 0;
}

.KPIViews.pannelWrap span:nth-child(7) .MuiIconButton-label {
  width: 100%;
  display: flex;
  align-items: inherit;
  justify-content: inherit;
  padding: 0;
  margin-left: 0;
}

.KPIViews.pannelWrap span:nth-child(7) .MuiTouchRipple-root {
  width: auto;
  padding: 0;
}

.light .MuiIconButton-label {
  color: #000;
}

input.PrivateSwitchBase-input-4.MuiSwitch-input {
  margin-top: 0;
}

.bgColor252525.KPILimitTable .headerTable,
.altRowBg .KPIRows {
  background: #252525;
}

.altRowBg .KPIRows:nth-child(2n + 1) {
  background: #1e1e1e;
}

.KPIRows > span {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.KPIRows > span.row2,
.KPIRows > span.row3,
.KPIRows > span.row4,
.KPIRows > span.row5,
.KPIRows > span.row6 {
  overflow: visible;
}

#ConfigureLimits .MuiAutocomplete-input {
  background: #2a2a2a;
  border-radius: 0.33rem;
  border: 0;
  box-shadow: none;
  font-size: 0.83rem;
  padding: 0.41rem;
  color: #fff;
  padding-left: 1.2rem;
}
#ConfigureLimits .MuiAutocomplete-input::-webkit-input-placeholder,
#ConfigureLimits .MuiAutocomplete-input:-ms-input-placeholder,
#ConfigureLimits .MuiAutocomplete-input::placeholder {
  color: #e0e0e0;
}
#ConfigureLimits .Mui-focused .MuiAutocomplete-input {
  outline: none;
}

#ConfigureLimits span.crossSearch > svg {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0.83rem;
  background: #2a2a2a;
  height: 1.5rem;
  z-index: 1;
}

.light #ConfigureLimits span.crossSearch > svg {
  background-color: #fff;
}
/* Configure Group Code End */

.machine-list-rightDivSet {
  float: left;
}

.machine-radio-div {
  margin: 0;
}

.machine-radio-div span {
  float: left;
  margin: 0;
  color: #e0e0e0;
  font-size: 1rem;
}

.machine-radio-div input[type="radio"] {
  float: left;
  margin: 0.08rem 0 0 0;
  color: #3b3b3b;
}

.machine-radio-div label {
  width: auto;
  margin-left: 0.83rem;
  margin-bottom: 0;
  float: left;
  font-family: Montserrat-Medium;
  font-weight: normal !important;
  font-size: 1rem;
}
.machine-radio-div span.active label{
  color: #e0e0e0;
  font-family: "Montserrat-medium";
}
.footerPopupWithBtn .btn-default.disabled.focus,
.footerPopupWithBtn .btn-default.disabled:focus,
.footerPopupWithBtn .btn-default.disabled:hover,
.footerPopupWithBtn .btn-default[disabled].focus,
.footerPopupWithBtn .btn-default[disabled]:focus,
.footerPopupWithBtn .btn-default[disabled]:hover,
.footerPopupWithBtn fieldset[disabled] .btn-default.focus,
.footerPopupWithBtn fieldset[disabled] .btn-default:focus,
.footerPopupWithBtn fieldset[disabled] .btn-default:hover {
  color: #5d97f6;
}

.tooltipforkpi {
  position: relative;
  display: inline-block;
}

.tooltipforkpi .tooltipforkpitext {
  visibility: hidden;
  font-size: 1rem;
  background-color: #000;
  color: #e0e0e0;
  text-align: center;
  border-radius: 0.5;
  padding: 0.41rem;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 50%;
  margin-left: -5rem;
}

.light .tooltipforkpi .tooltipforkpitext {
  background-color: #fff;
  color: #3b414b;
}

.tooltipforkpi:hover .tooltipforkpitext {
  visibility: visible;
}

.tooltipforkpi .tooltipforkpitext::after {
  content: " ";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -0.41rem;
  border-width: 0.41rem;
  border-style: solid;
  border-color: transparent transparent #000 transparent;
}

.light .tooltipforkpi .tooltipforkpitext::after {
  border-style: solid;
  border-color: transparent transparent #fff transparent;
}

.UnauthorizedAccess.editgroupPopup .footerPopupWithBtn .btn {
  float: left;
  margin-right: 3.33rem;
}

.UnauthorizedAccess.editgroupPopup .footerPopupWithBtn {
  width: 23.33rem;
}

.noItemSelected {
  text-align: center;
  margin-top: 8.33rem;
}

.GroupUpperTab.ConfigureHide .svgCreate,
#KPI .GroupUpperTab.ConfigureHide .svgCreate {
  right: 2.5rem;
}

.GroupUpperTab.ConfigureHide .form-group.checkSpoc.onlyKPICheckbox label {
  right: -5rem;
}
