/* All popup css start */

.modelboxopen2, .modelboxopen, .modelboxopen3 {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999992;
  background: rgba(0, 0, 0, 0.7);
}
.modelboxopen.confirmPin.kpigraph1{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999992;
  background: rgba(0, 0, 0, 1); 
}

.modelboxopen2 {
  z-index: 999999;
}

.modelboxopenInnner {
  width: 80.5rem;
  height: 53.58rem;
  text-align: left;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9;
  background: #1f1f1f;
  border-radius: 4px;
}

.modelboxopenInnnerBig {
  width: 75rem;
  height: 41.66rem;
  text-align: left;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9;
  background: #1f1f1f;
  border-radius: 4px;
}

.modelboxopenLiveBatchBig {
  width: 45.83rem;
  height: 45.83rem;
  text-align: left;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9;
  background: #1f1f1f;
  border-radius: 4px;
}

.light .modelboxopenLiveBatchBig {
  background: #fff;
}

.modelboxopenLiveBatchBig .contentPopup {
  height: 34.55rem;
}

.descriptions {
  height: 41.66rem;
  overflow: auto;
}

.modelclose {
  border: 0 solid #999;
  border-radius: 50%;
  cursor: pointer;
  display: inline-block;
  position: absolute;
  top: 1.16rem;
  right: 1.16rem;
  font-size: 2.08rem;
  line-height: 25px;
  width: 2.5rem;
  height: 2.5rem;
  text-align: center;
  color: #fff;
  background: #ff0000;
  z-index: 9;
}
.modelboxopen.confirmPin.kpigraph1 > div {
  display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    margin: 0;
}
.textRotate p{
  color: #fff;
  font-size: 2rem;
}
.titleDiv {
  margin: 0;
  padding: 0;
  font-size: 1.33rem;
  color: #e0e0e0;
  width: calc(100% - 0.83rem);
  font-family: "Montserrat-Medium";
  font-weight: normal;
}

.popupCloseButton {
  height: 5rem;
  display: flex;
  justify-content: left;
  align-items: center;
  padding: 0 2rem;
}

.bbt {
  border-bottom: 1px solid #313131;
  border-radius: 4px;
}
.alarmHistory .MuiPaper-elevation24.MuiPaper-root{
  background: transparent;
}
.alarmHistory .MuiPaper-elevation24{
  box-shadow: none;
}
.edit_iconconfig [editext="edit-button"]:before {
  content: "";
  background: url("../images/EditIConConfig.png") no-repeat center center;
  display: block;
  width: 1.33rem;
  height: 1.33rem;
  background-size: contain;
}

.edit_iconconfig [editext="edit-button"]:hover:before {
  background: url("../images/EditIConConfigHover.png") no-repeat center center;

}

.edit_iconconfig .styles_Editext__input__1534X {
  max-width: 33.33rem;
  border: 0 solid #313131;
  padding: 0.41rem 0.83rem;
  outline: none;
  border-radius: 4px;
  background-color: #121212;
}

.light .edit_iconconfig .styles_Editext__input__1534X {
  background-color: #f2f4f6;
  color: #3b414b;
}

.edit_iconconfig button,
.edit_iconconfig .styles_Editext__buttons_after_aligned__1nlcG,
.edit_iconconfig .styles_Editext__button__6H8n_:hover {
  background: transparent;
  border: 0;
}

.closepopup {
  float: right;
  color: #8f8f8f;
  cursor: pointer;
  font-size: 1.66rem;
}

.closepopup:hover,
.closepopup:focus {
  color: #6aa2ff;
  text-decoration: none;
}

.light .closepopup:hover,
.light .closepopup:focus {
  color: #296fea;
  text-decoration: none;
}

.contentPopup {
  height: calc(100% - 5rem);
}

.download-bellIcon {
  padding: 0.83rem 3.33rem;
  text-align: right;
}

.downBtn {
  margin-right: 1.25rem;
}

.configGroups .configureTitle table{
  line-height: 2.33rem;
}

.breadcrumb #custom-color-no-arrowabc span {
  color: #a0a0a0;
}

.breadcrumb #custom-color-no-arrowabc table{
  line-height: 2.5rem;
}

.breadcrumb #custom-color-no-arrowabc table{
  color: #e0e0e0;
  font-family: Montserrat-Regular;
}
.light .breadcrumb #custom-color-no-arrowabc table{
  color: #3b414b;
}
.tooltipModel td {
  color: #e0e0e0;
  font-size: 1rem;
  width: 16.67rem;
  margin-right: 0.83rem;
}

.light .tooltipModel td {
  color: #3b414b;
}

.confirmPin .footerPopupWithBtn .saveBtn:hover,
.confirmPin .footerPopupWithBtn .saveBtn:focus {
  color: #000;
  background-color: #6AA2FF;
  border-color: #6AA2FF;
}

.light .confirmPin .footerPopupWithBtn .saveBtn:hover,
.light .confirmPin .footerPopupWithBtn .saveBtn:focus {
  color: #fff;
  background-color: #296fea;
  border-color: #296fea;
}

.UnauthorizedAccess .btn-default:focus,
.UnauthorizedAccess .btn-default:active,
.UnauthorizedAccess .btn-default:visited{
  background-color: #6AA2FF;
  border-color: #6AA2FF;
}
.UnauthorizedAccess .btn-default.cancelBtn:focus{
  background-color: #2f2f2f;
  border-color: #2f2f2f;
}
.unitTxt {
  font-size: 1rem;
  color: rgba(255, 255, 255, 0.6);
}

.light .modelboxopenInnner {
  background: #fff;
}

.light .ulMachinePopup li {
  background: #eeeeee;
}

.light .ulMachinePopup li {
  color: #3b414b;
}

.light .bbt {
  border-bottom: 1px solid #eeeeee;
  border-radius: 4px;
}

.light .MuiSwitch-thumb {
  background-color: #ccc;
}

.light .MuiButtonGroup-contained {
  box-shadow: none;
}

#ConfigureCreateGroup .MuiTypography-body2 {
  font-size: 0.83rem;
  font-family: "Montserrat-Regular";
}

.light .cancelBtn {
  color: #3b414b;
  background: #b6b6b7;
  border-color: #b6b6b7;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.light .saveBtn {
  color: #3b414b;
  background: #5d97f6;
  box-shadow: 1px 4px 0.83rem rgba(0, 0, 0, 0.25);
  border-color: #5d97f6;
}

.light .btn-default.active,
.light .btn-default.focus,
.light .btn-default:active,
.light .btn-default:focus,
.light .btn-default:hover,
.light .open > .dropdown-toggle.btn-default {
  color: #3b414b;
}

.light .alertCheckbox .form-group label:before,
.light .customchecks .form-group label:before {
  background-color: #fff;
  border: 1px solid #3b3b3b;
}

.sidebar-nav {
  list-style: none;
  width: 16.66rem;
}

.sub-menu {
  padding: 0;
  display: none;
  list-style: none;
}

.sidebar-nav li.active ul {
  display: block;
}

.btn-default.active.focus,
.btn-default.active:focus,
.btn-default.active:hover,
.btn-default:active.focus,
.btn-default:active:focus,
.btn-default:active:hover,
.open > .dropdown-toggle.btn-default.focus,
.open > .dropdown-toggle.btn-default:focus,
.open > .dropdown-toggle.btn-default:hover {
  color: #333;
  background-color: transparent !important;
  border-color: #1f1f1f;
}

.col-md-3.timefilterdiv {
  margin-bottom: 0.41rem;
}

.light .KPIGroupContent .MuiAutocomplete-input,
.light .KPIGroupContent .Mui-focused input,
.light .MachineSettingsGroupContent .MuiAutocomplete-input,
.light .MachineSettingsGroupContent .Mui-focused input {
  border: 1px solid #a6aab4;
  box-sizing: border-box;
  filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.02));
  background: transparent;
}

.light .KPIGroupContent .MuiAutocomplete-input::placeholder,
.light .KPIGroupContent .Mui-focused input::placeholder,
.light .MachineSettingsGroupContent .MuiAutocomplete-input::placeholder,
.light .MachineSettingsGroupContent .Mui-focused input::placeholder {
  color: #a6aab4;
}

.light .MachineSettingsGroupContentBg,
.light .KPIGroupContentBg {
  background: #fff;
  border-radius: 0 4px 4px 4px;
}

.light .onlyKPIGraphs .alertFilterDiv .form-group .dropdown button{
  box-shadow: none;
  border-color: #a6aab4;
}

.light .onlyAllNotification .drop-text,
.light .onlyCustomAlert .drop-text {
  color: #3b414b;
}

.light .contentAreaPart {
  background: #ffffff;
}

.light .fontSizeColor12 {
  color: #3b414b;
}

.light .resetFormBg {
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.25);
}

.onlyAllNotification .row {
  margin-right: -0.83rem;
  margin-left: -0.83rem;
}

.onlyAllNotification .col-md-2.noboderDrop {
  padding-right: 0.83rem;
  padding-left: 0.83rem;
}

.noNotificationDiv {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 7rem);
  color: #e0e0e0;
  background: #1f1f1f;
}
.light .noNotificationDiv {
  background: #fff;
}
.light .logoDiv {
  background-image: url("../images/FutureDNA_logo_dark.svg");
}

#allMachineList .pagination-downtime .pagination {
  margin: 10px 0;
  font-size: 1rem;
}