.AdminPanel__head {
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
    padding: 0.5rem 0 1rem 0;
}

.AdminPanel__head>.left {
    width: 25%;
    min-width: fit-content;
}

.AdminPanel__head>.right {
    display: flex;
    width: 25%;
    min-width: fit-content;
    justify-content: flex-end;
}

.AdminPanel__head .add_btn{
    padding: 0.6rem 1rem;
}

.AdminPanel__head .dropdown_rsd input {
    color: #fff !important;

}

.input_search {
    display: block;
    outline: none;
    background-color: transparent;
    border: 1px solid var(--input-border-color);
    padding: 0.4rem 0.6rem;
    color: var(--white);
    border-radius: 0.3rem;
    flex-grow: 1;
}