.inputText {
    display: block;
    outline: none;
    height: 2.6rem;
    background-color: transparent;
    border: 1px solid var(--input-border-color);
    padding: 0.4rem 0.6rem;
    color: var(--white);
    width: 100%;
    border-radius: 0.3rem;
}
.formRow {
    display: flex;
    align-items: center;
}

.formRow:not(:last-child) {
    margin-bottom: 1rem;
}

.labelControl {
    width: 20%;
    min-width: fit-content;
}
.labelControl label{
    font-weight: 700;
}
.inputControl {
    width: 30%;
}

.formActions>input:not(:last-child) {
    margin-right: 1rem;
}
.formActions>input{
    padding: 0.5em 1em;
    /* border-radius: 0.8rem ; */
}
.UpdateDataContainer{
    width: 100%;
    height: 100%;
    overflow: overlay;
    display: grid;
    grid-template-rows: auto max-content;
    background-color: var(--primary-color);
    padding: 3rem 2rem;
}

.formControls{
    overflow: overlay;
}
.formActions{
    display: flex;
    padding: 1rem 0 0 0;
}