.headerBox {
  width: 100%;
  height: 64px;
  background: #1f1f1f;
  border: 1px solid #494949;
  border-radius: 5px;
  margin-top: 10px;
  display: flex;
}
.headerBox div {
  border-right: 1px solid #676666 !important;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  /* padding: 10px; */
  padding: 6px;
  background-color: #252525;
  border: none;
  color: #fff;
  font-family: Montserrat, Helvetica, sans-serif;
  font-size: 9px;
  font-weight: 500;
  width: 5%;
}
.accordianBox {
  width: 100%;
  background: #434343;
  /* border: 1px solid #494949; */
  border-bottom: 4px solid #494949;
  border-radius: 5px;
  height: 64px;
  margin-top: 10px;
  display: flex;
  cursor: pointer;
}
.boxs {
  border-right: 1px solid #5d5d5d;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /* padding: 10px; */
}
.boxs div {
  color: white;

  font-size: 12px;
  font-weight: lighter;
  font-weight: 400;
  border: none;
  text-align: left;
  font-family: "Montserrat", "Helvetica", sans-serif;
  width: 5%;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 0.4px;
  padding-left: 0px;
  color: #ffffff;
  width: 100%;
  height: 100%;
  /* padding-left: 30px; */
}
.accordianBox div:first-child {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
}
.accordianBox div:first-child div {
  border: none;
}
.accordianBox div:last-child {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}
.accordianBox div:last-child div {
  border: none;
}
.accordian {
  height: auto;
  width: 100%;
  background-color: #2d2d2d;
  padding: 10px 10px;
  /* transition: all 5s ease-in; */
}
.versionBox {
  width: 100%;
  background: #1f1f1f;
  border: 1px solid #494949;
  border-radius: 5px;
  height: 64px;
  display: flex;
  justify-content: center;
  margin-top: 10px;
}
.accorboxs:first-child,
.accorboxs:last-child {
  flex: 0.9;
}
.accorboxs {
  border-right: 1px solid #494949;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /* padding: 8px; */
}
.accorboxs div {
  color: white;

  font-size: 12px;
  font-weight: lighter;
  font-weight: 400;
  border: none;
  text-align: left;
  font-family: "Montserrat", "Helvetica", sans-serif;

  display: flex;
  align-items: center;
  letter-spacing: 0.4px;

  color: #ffffff;
  width: 100%;
  height: 100%;
}
.versionBox div:first-child {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}
.versionBox div:first-child div {
  border: none;
}
.versionBox div:last-child {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}
.versionBox div:last-child div {
  border: none;
}

.boxs div:first-child {
  border-bottom: 1px solid #5d5d5d;
}
.boxs > div {
  justify-content: center !important;
}
.versionBox .accorboxs:last-child {
  /* border: 1px solid red; */
  display: flex;
  padding: 1%;
}
.versionBox .accorboxs:last-child div:first-child {
  /* margin-right: -20px; */
}
.accorboxs div:first-child {
  border-bottom: 1px solid #494949;
}
.accorboxs > div {
  justify-content: center !important;
}
.body {
  /* height: calc(100vh - 245px); */
  height: calc(100vh - 195px);
  overflow: auto;
  /* border: 1px solid red; */
  padding-bottom: 10px;
}
/* ::-webkit-scrollbar {
  width: 2px !important;
} */
