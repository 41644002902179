/* Header CSS in DarkMode Start */
.light header {
  background: #f2f4f6;
}
.loginDropdown .bluebellIcon {
  width: auto;
  height: auto;
}
.loginDropdown .bluebellIcon svg {
  height: 1.8rem;
  width: 1.8rem;
  vertical-align: middle;
}
.loginDropdown .bluebellIcon .withCount svg path {
  fill: #5d97f6;
  stroke: transparent;
}
.loginDropdown .bluebellIcon .withoutCount svg path {
  fill: #2a2a2a;
  stroke: #fff;
}
.alertNumber {
  position: absolute;
  top: 0.3rem;
  right: 0.3rem;
  transform: translate(50%, -50%);
  text-align: center;
  background: #cf3d31;
  line-height: 1.5;
  font-size: 0.83rem;
  font-family: "Montserrat-Regular";
  border-radius: 0.7rem;
  color: #fff;
  padding: 0 0.33rem;
  height: 1.5rem;
  min-width: 1.5rem;
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.div4MS svg,
.div4KPI svg {
  height: 1rem;
  width: 1rem;
}

.svgCreate {
  position: absolute;
  right: 24rem;
  top: 0.91rem;
  display: flex;
  padding: 0.33rem;
}

.svgCreate:hover {
  background: #2a2c2d;
  border-radius: 4px;
}
.light .svgCreate:hover {
  background: #f2f4f6;
  border-radius: 4px;
}

#KPI .svgCreate {
  right: 20rem;
}

.errorMsg {
  background-color: rgba(243, 98, 98, 0.3);
  color: #fff;
  border-radius: 4px;
  line-height: 2.5rem;
  font-size: 0.83rem;
  font-family: "Montserrat-Regular";
}

.light .errorMsg {
  color: #121212;
}

.alertContent {
  height: 42rem;
  overflow-y: auto;
  overflow-x: hidden;
}

.padTopBotton {
  padding: 0.83rem 0;
}

.dismissBtn {
  float: right;
}

.dismissBtn button {
  font-size: 0.83rem;
  background: none;
  border-radius: 4px;
  color: #5d97f6;
  border: 0;
  font-family: "Montserrat-Medium";
}

.light .dismissBtn button {
  color: #4285f4;
}

.dismissBtn button:hover,
.dismissBtn button:focus {
  font-size: 0.83rem;
  color: #82adf3;
  border: 0;
  outline: none;
}

.MuiAutocomplete-noOptions {
  background: #2e2e2e;
  color: #fff;
  padding: 0.41rem 0.83rem;
}

.light .MuiAutocomplete-noOptions {
  background: #fff;
  color: #2e2e2e;
}

.rednotifyIcon svg path {
  fill: #c7b3b6;
}

.bluebellIcon svg path {
  fill: #5d97f6;
}

.badge {
  position: relative;
  margin-left: 60%;
  margin-top: -60%;
}

.bellCountZero .bellIconLayout .layout-vert-fixed-top {
  display: none;
}

.liItem1 .badge {
  position: absolute;
  margin-left: 0%;
  margin-top: 0;
  height: 1.66rem;
  width: 1.66rem;
  border-radius: 50%;
  font-size: 0.83rem;
  display: flex;
  vertical-align: middle;
  text-align: center;
  justify-content: center;
  line-height: 1.33rem;
  top: -0.25rem;
  right: 0;
}

.tabArea .nav-tabs > li.active > a,
.tabArea .nav-tabs > li.active > a:focus,
.tabArea .nav-tabs > li.active > a:hover {
  color: #5d97f6;
  cursor: default;
  background-color: transparent;
  border: 0 solid #ddd;
  border-bottom-color: transparent;
  outline: none;
}

a:focus {
  outline: thin dotted !important;
  outline: 0 auto -webkit-focus-ring-color !important;
  outline-offset: 0 !important;
}

.tabArea .nav-tabs > li > a,
.tabArea .nav-tabs > li > a:hover {
  background: transparent;
  border: 0 solid #ddd;
}

.dropdown-menu {
  width: 100%;
}

.trendDiv {
  margin-top: 2%;
  background-color: #252525;
  color: #a0a0a0;
  height: 2.5rem;
  text-align: left;
  padding-left: 10%;
  padding-top: 4%;
}

.modelboxopen ul,
.modelboxopen2 ul {
  margin: -0.16rem 0 0;
  border: 1px solid #5d5e60;
  padding-top: 0.25rem;
  max-height: 8.33rem;
  background: #1f1f1f;
  overflow-y: scroll;
}

.modelboxopen .dropdown-menu > li > a,
.modelboxopen2 .dropdown-menu > li > a {
  display: block;
  padding: 0.41rem 0.83rem;
  font-size: 0.83rem;
  color: white;
  font-family: "Montserrat-Regular";
}

.modelboxopen .dropdown-menu > li > a:hover,
.modelboxopen2 .dropdown-menu > li > a:hover {
  color: #5d97f6;
  text-decoration: none;
  background-color: #2a2c2d;
}

.light .modelboxopen .dropdown-menu > li > a:hover,
.light .modelboxopen2 .dropdown-menu > li > a:hover {
  background-color: #f1f9ff;
  color: #5d97f6;
}

.KPIRows > span .drop-text {
  color: #e0e0e0;
  overflow: hidden;
  width: 10.33rem;
}

.KPIRows .row0,
.KPIRows .row1 {
  color: #e0e0e0;
}

.create_Machines .form-group {
  margin-bottom: 0;
}

.create_Machines .form-group.addMargin {
  margin-bottom: 2.08rem;
}

.create_Machines .notReq .iconQuestion {
  margin-left: 0.41rem;
}

.filterDiv .dropdown-menu,
.tableAlarm .dropdown-menu {
  width: 100%;
  border-radius: 0 0 0.33rem 0.33rem;
  margin: -0.16rem 0 0;
  background: #2a2a2a;
  border: 1px solid #5d5e60;
  box-shadow: -0.33rem 0 0.83rem rgba(0, 0, 0, 0.25),
    0.33rem 0.33rem 0.83rem rgba(0, 0, 0, 0.25);
  padding-top: 0.25rem;
  max-height: 8.33rem;
  overflow-y: auto;
  overflow-x: hidden;
}

.light .filterDiv .dropdown-menu,
.light .tableAlarm .dropdown-menu {
  box-shadow: none;
}

.pannelWrap .rowDiv span {
  padding: 0.75rem 0.41rem;
}

.col-md-2.padLefts {
  width: 13.33rem;
}

.col-md-8.padRights {
  width: calc(100% - 26.83rem);
}

.KPIViews.tableSpan .pannelWrap .panel-group .panel + .panel:nth-child(2n) {
  margin-top: 0;
  float: left;
  width: 100%;
  background: #252525;
}

.light .KPIViews.tableSpan .pannelWrap .panel-group .panel + .panel {
  background: #ffffff;
}

.light .tabArea .nav-tabs.tabUlsInner {
  border-bottom: 0 solid #1f1f1f;
}

.tabArea ul.nav-tabs.nav-tabs.tabUlsInner {
  margin: 0 4rem 0 4.44rem;
}

.light .tabArea .nav-tabs.tabUlsInner li a {
  margin: 0.33rem 1.33rem;
}

.light
  .KPIViews.tableSpan
  .pannelWrap
  .panel-default:nth-child(2n + 1)
  .panel-heading {
  background: #000;
}

button.MuiButtonBase-root.MuiButton-root.MuiButton-contained.MuiButtonGroup-grouped.MuiButtonGroup-groupedHorizontal.MuiButtonGroup-groupedContained.MuiButtonGroup-groupedContainedHorizontal.MuiButtonGroup-groupedContainedPrimary.comnEditDel.deleteGroupBtn.MuiButton-containedPrimary,
button.MuiButtonBase-root.MuiButton-root.MuiButton-contained.MuiButtonGroup-grouped.MuiButtonGroup-groupedHorizontal.MuiButtonGroup-groupedContained.MuiButtonGroup-groupedContainedHorizontal.MuiButtonGroup-groupedContainedPrimary.comnEditDel.editGroupBtn.MuiButton-containedPrimary {
  margin-right: 1.25rem;
}

button.MuiButtonBase-root.MuiButton-root.MuiButton-contained.MuiButtonGroup-grouped.MuiButtonGroup-groupedHorizontal.MuiButtonGroup-groupedContained.MuiButtonGroup-groupedContainedHorizontal.MuiButtonGroup-groupedContainedPrimary.comnEditDel.editGroupBtn.MuiButton-containedPrimary:hover {
  background-color: #6aa2ff;
}
.light
  button.MuiButtonBase-root.MuiButton-root.MuiButton-contained.MuiButtonGroup-grouped.MuiButtonGroup-groupedHorizontal.MuiButtonGroup-groupedContained.MuiButtonGroup-groupedContainedHorizontal.MuiButtonGroup-groupedContainedPrimary.comnEditDel.editGroupBtn.MuiButton-containedPrimary:hover {
  background-color: #296fea;
}

.filterDiv .dropdown-menu > li > a,
.tableAlarm .dropdown-menu > li > a {
  display: block;
  padding: 0.41rem 0.83rem;
  font-size: 0.83rem;
  color: #e0e0e0;
}

.light .filterDiv .dropdown-menu > li > a,
.light .tableAlarm .dropdown-menu > li > a {
  color: #3b413b;
}

.filterDiv .dropdown-menu > li > a:focus,
.filterDiv .dropdown-menu > li > a:hover,
.tableAlarm .dropdown-menu > li > a:focus,
.tableAlarm .dropdown-menu > li > a:hover {
  color: #e0e0e0;
  text-decoration: none;
  background-color: #27333d;
}

.light .filterDiv .dropdown-menu > li > a:focus,
.light .filterDiv .dropdown-menu > li > a:hover,
.light .tableAlarm .dropdown-menu > li > a:focus,
.light .tableAlarm .dropdown-menu > li > a:hover {
  color: #666;
  text-decoration: none;
  background-color: #fff;
}

.dropdown .btn-default:focus,
.dropdown .btn-default:hover,
.dropdown .btn-default.active,
.dropdown .btn-default:active,
.open > .dropdown-toggle.btn-default {
  color: #333;
  background-color: transparent;
  background-image: none;
  border-color: #5d5e60 !important;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  position: relative;
}

.light .filterDiv .dropdown-menu {
  background: #fff;
  border: 1px solid #5d5e60;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.15), 1px 0 10px rgba(0, 0, 0, 0.15);
}

.light .filterDiv .dropdown-menu > li > a {
  display: block;
  padding: 0.5rem 0.83rem;
  font-size: 0.83rem;
  color: #3b414b;
}

.filterDiv .dropdown-menu > li > a:focus,
.tableAlarm .dropdown-menu > li > a:focus,
.btn.active.focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn:active:focus,
.btn:focus {
  outline: thin dotted;
  outline: 0 auto -webkit-focus-ring-color !important;
  outline-offset: 0;
}

.borderInput .caret {
  /* content: ""; */

  /* background: url(../images/dropdownArrowDown.png) center center transparent
    no-repeat; */
  width: 0.83rem;
  height: 0.5rem;
  -webkit-transition: -webkit-transform 0.5s ease-in-out;
  transition: transform 0.5s ease-in-out;
}
.input-group [aria-expanded="true"] .caret {
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
}
.borderInput .caret::after{
  content: url('./caret.svg');
  display: inline-block;
  color: #000;
  line-height: 1;
}

.borderInputSettings .caret {
  content: "";
  background: url(../images/dropdownArrowDown.png) center center transparent
    no-repeat;
  width: 3.33rem;
  height: 0.5rem;
  -webkit-transition: -webkit-transform 0.5s ease-in-out;
  transition: transform 0.5s ease-in-out;
}

.filterDiv .dropdown.open .caret {
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
}

.btn .caret {
  margin-right: 0.41rem;
}

.loginDropdown.nav > li > a:focus,
.loginDropdown.nav > li > a:hover {
  text-decoration: none;
  background-color: transparent;
}
.loginDropdown {
  display: flex;
  align-items: center;
  position: relative;
}
.loginDropdown .dropdown-toggle-profile {
  color: #5d97f6;
  font-size: 0.83rem;
  background: #424242;
  box-shadow: none;
  height: 2rem;
  width: 2rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  margin-top: 0;
  margin-bottom: 0;
  font-family: "Montserrat-Medium";
  letter-spacing: 1.5px;
  padding: 0;
  line-height: 1.66rem;
}
.light .loginDropdown .dropdown-toggle-profile {
  color: #4285f4;
}
.loginDropdown .liItem2.open .dropdown-toggle-profile {
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
}

.light .loginDropdown .liItem2.open .dropdown-toggle-profile {
  box-shadow: 0 0 8px #e0e4ea;
}

.loginDropdown .liItem2.open .dropdown-toggle-profile {
  color: #ffffff;
  background: #5d97f6;
}

.dropdown.liItem2.open .dropdown-menu > li > a {
  padding: 0 1.25rem 0 1rem;
}

.loginDiv .loginDropdown.nav > li.liItem2 > a:focus,
.loginDiv .loginDropdown.nav > li.liItem2 > a:hover,
.loginDiv .loginDropdown.nav > li.liItem2 > a:active {
  background-color: #5d97f6 !important;
  border-color: #5d97f6 !important;
  color: #ffffff;
}

.loginDiv .nav .open > a,
.loginDiv .nav .open > a:focus,
.loginDiv .nav .open > a:hover {
  background-color: #5d97f6 !important;
  border-color: #5d97f6 !important;
}

.nav .open > a,
.nav .open > a:focus,
.nav .open > a:hover,
.loginDiv .nav .liItem1.open > a,
.loginDiv .nav .liItem1.open > a:focus,
.loginDiv .nav .liItem1.open > a:hover {
  background-color: transparent !important;
  border-color: transparent !important;
}

.loginDropdown.nav > li.liItem2 ul a {
  color: #262626;
}

.fa-stack {
  /* line-height: 3.16rem; */
}

.loginDropdown > li.liItem1 > a {
  padding-top: 0;
  padding-bottom: 0;
}

.loginDropdown > li.liItem2 > a {
  padding-top: 0.08rem;
  padding-left: 0.16rem;
}

.loginDropdown ul {
  width: 22.5rem;
  background: #1f1f1f !important;
  border-radius: 4px;
  box-shadow: none;
}

.light .loginDropdown ul {
  background: #ffffff !important;
}

.dropdown-menu > li > a {
  white-space: break-spaces;
}

.loginDropdown .dropdown-menu > li > a:focus,
.loginDropdown .dropdown-menu > li > a:hover {
  background-color: transparent;
}

.loginDiv .navbar-nav > li > .dropdown-menu {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  position: absolute;
  z-index: 9999;
  box-shadow: -4px 4px 25px rgb(0 0 0 / 70%);
}

.light .loginDiv .navbar-nav > li > .dropdown-menu {
  box-shadow: -4px -4px 8px -1px rgba(0, 0, 0, 0.08),
    4px 4px 8px -1px rgba(0, 0, 0, 0.08);
}

.loginDropdow .dropdown-menu > li > a {
  padding-top: 0;
  padding-bottom: 0;
}

.loginDropdown.nav > li.liItem2 ul li a {
  font-family: "Montserrat-Medium";
  font-size: 1rem;
  color: #e0e0e0;
}

.light .loginDropdown.nav > li.liItem2 ul li a {
  color: #000;
}

.loginDropdown.nav > li.liItem2 ul li a:hover {
  background: transparent;
}

.nameEmailBtn,
.logoutBtn {
  width: 2.5rem;
  float: left;
  min-height: 4.16rem;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: left;
}

.nameEmailBtn {
  min-height: 5rem;
}

.nameUserDiv {
  display: block;
  width: calc(100% - 2.5rem);
  float: left;
  height: 5.3rem;
  padding: 0.83rem 0;
}

.nameUser,
.emailUser {
  display: block;
}

.emailUser {
  color: #a0a0a0;
  font-size: 1rem;
  margin-top: 0.33rem;
}

.burgerMenu {
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  height: 5rem;
  width: 5rem;
  background: #232323;
}

.light .burgerMenu {
  background: #ffffff;
}
.burgerMenu,
.light .burgerMenu,
.open_nav .logoDiv {
  -webkit-transition: width 0.5s ease-in-out;
  -moz-transition: width 0.5s ease-in-out;
  -o-transition: width 0.5s ease-in-out;
  transition: width 0.5s ease-in-out;
}

.open_nav .logoDiv {
  margin: 1.33rem 5rem 1.33rem 7.5rem;
}

.burgerMenu.open_nav {
  width: 100%;
  /* box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3); */
}

.light .burgerMenu.open_nav {
  background: #ffffff;
}

.light .burgerMenu.open_nav {
  box-shadow: none;
}

.logoutTxt {
  display: flex;
  width: calc(100% - 5.3rem);
  float: left;
  height: 4.16rem;
  padding: 1.25rem 0;
  justify-content: left;
  align-items: center;
}

.custom-color-no-arrow span {
  color: #a0a0a0;
}

.light .custom-color-no-arrow2,
.light .custom-color-no-arrow8,
.light .custom-color-no-arrow9,
.light .custom-color-no-arrow4,
.light .custom-color-no-arrow td {
  color: #3b414b;
}

.light .custom-color-no-arrow span {
  color: #888c96;
}

.light [data-rbd-drag-handle-context-id="1"]:nth-child(2n) {
  background-color: #fafafa;
}

.KPIRows input:focus {
  border: 0.5px solid #1a5d8d;
}

.light #ConfigureLimits .MuiAutocomplete-input {
  background: #fff;
  color: #a0a0a0;
  border: 1px solid #e4e7ec;
}

.custom-color-no-arrow td {
  color: #e0e0e0;
  font-size: 1rem;
  font-family: "Montserrat-Regular";
}

.light .KPIRows .row0,
.light .KPIRows .row1,
.light .configureTitle,
.light .KPIRows > span .drop-text {
  color: #3b143b;
}

.loginDropdown.nav > li.liItem2 ul li {
  float: left;
  width: 100%;
}

.loginDropdown.nav > li.liItem2 ul li:first-child {
  border-bottom: 1px solid rgba(154, 154, 154, 0.1);
}

.loginDropdown .dropdown-menu {
  padding: 0;
  margin: 0;
  border: 0 solid #ccc;
  box-shadow: none;
}

.loginDropdown .dropdown-menu:after {
  content: "";
  width: 0;
  height: 0;
  border-left: 0.83rem solid transparent;
  border-right: 0.83rem solid transparent;
  border-bottom: 1.16rem solid #252525;
  position: absolute;
  top: -0.75rem;
  right: 0.16rem;
}
.loginDropdown .liItem2.open .dropdown-menu:after{
  border-bottom: 1.16rem solid #1f1f1f;
}
.light .loginDropdown .liItem2.open .dropdown-menu:after{
  border-bottom: 1.16rem solid #fff;
}

.light .loginDropdown .dropdown-menu:after {
  border-bottom: 1.16rem solid #ffffff;
}

.light .loginDiv .nav .open > a,
.light .loginDiv .nav .open > a:focus,
.light .loginDiv .nav .open > a:hover {
  background-color: transparent;
  border-color: transparent;
}

.light .loginDiv .nav .liItem2.open > a,
.light .loginDiv .nav .liItem2.open > a:focus,
.light .loginDiv .nav .liItem2.open > a:hover {
  background-color: #5d97f6 !important;
  border-color: #5d97f6 !important;
}
.loginDiv .nav .liItem2.open > ul{
  width: 28rem;
}
.light .dismissBtn button:hover,
.light .dismissBtn button:focus {
  background: none;
  color: #185abc;
}

.loginDropdown .liItem1 ul {
  width: 33.33rem;
  height: calc(100vh - 4.16rem);
  background: #232323;
  border-radius: 0.33rem 0 0.33rem 0.33rem;
}

.light .loginDropdown .liItem1 ul {
  background: #ffffff;
}

.loginDropdown > .dropdown > .dropdown-menu {
  right: 0;
  top: calc(100% + 1rem);
  left: unset;
}
.loginDropdown > li {
  position: static !important;
}
.navbar-right .liItem1 .dropdown-menu:after {
  right: 4rem;
}

.light .navbar-right .liItem1 .dropdown-menu:after {
  border-bottom: 1.16rem solid #ffffff;
}

.smartAlert button {
  background: transparent;
  border: none;
}

.alertContentTxt {
  font-family: "Montserrat-Medium";
  font-size: 1rem;
  color: #e0e0e0;
}

.light .alertContentTxt {
  color: #3b414b;
}

.heahingAlert {
  font-size: 1rem;
  color: #e0e0e0;
  margin: 0;
  padding: 0.91rem;
  background: #252525;
  border-radius: 4px;
}

.timeSpan {
  font-style: italic;
  font-size: 0.83rem;
  color: #a0a0a0;
}

.alertContentUl li {
  position: relative;
  background: #121212;
  margin: 0 0.83rem 0.66rem 0.83rem;
  min-height: 5rem;
  padding: 1.25rem 2rem 1.25rem 1.25rem;
  list-style-type: none;
}

.light .alertContentUl li {
  background: #f9fafb;
  border: 1px solid #f5f5f5;
}

.closeIconAlert {
  position: absolute;
  right: 0.83rem;
  top: 0.83em;
}

.alertContentUl {
  width: 100%;
  padding: 0;
  margin: 0;
}

/* Header CSS in BarkMode End */

.filterDiv .MuiAutocomplete-popper {
  z-index: 129;
}