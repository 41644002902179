.SmTbl {
    table-layout: fixed;
}
.SmTbl td p{
    cursor: pointer;
}
.sm-popup input[type="text"] {
    border: none!important;
}
.SmTbl input[type=file]{
    position: absolute;
    /* top: -86%;
    bottom:-65%;
    left: 0%;
    right: 0%; */
    /* line-height: 3; */
    width: 15rem;
    height: 3rem;
    opacity: 0;
    cursor: pointer;
}
.SmTbl input[type=file]:hover{
    cursor: pointer;
}
.SmTbl td {
    padding: 1%;
    border: 2px solid #5a5959;
    text-align: center;
}
/* .SmTbl td .downloadicon{
    position: absolute;
    left: 28%;
    top:0;
} */
/* .SmTbl td .sdownloadicon{
    position: absolute;
    left: 31%;
    top:0;
}
.SmTbl td .Ddownloadicon{
    position: absolute;
    left: 28%;
    top:0;
} */
.SmTbl .orange {
    color: #f3a70ef0;
}
.SmTbl .uploaded {
    color:#52b56d;
}
.SmTbl .danger {
    color: #e12e25;
}
.SmTbl .ntuplod {
    color: #878787;
}
.SmTbl .danger p span svg, .SmTbl .ntuplod p span svg ,.SmTbl .orange p span svg{
    margin-right:3px
}
.SmTbl tr td p {
    position: relative;
    margin:0 !important;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
}
.sm_container {
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
}

.tp_blk {
    /* display: flex;
    justify-content: space-between; */
    margin-top: 1rem;
    margin-bottom: 1rem;
    /* align-items: center; */
}
.tp_blk label {
    color:#fff;
}

 .tp_blk .dropdown_rsd {
    width: 20rem;
}
.sm-modal-inner {
    background: #1f1f1f;
}
.sm-modal-inner h2{
    color: #fff;
}
.sm-modal-inner .error-field {
    border-color: #ea4335 !important;
}
.tp_blk  .css-qc6sy-singleValue, .sm-modal-inner .css-qc6sy-singleValue {
    color: #fff;
}
.sm-popup svg {
    margin-top: -0.3rem !important;
}

.sm-popup .css-tlfecz-indicatorContainer {
    padding: 4
}
.sm-popup .css-1okebmr-indicatorSeparator { 
    margin-bottom:10
}
.sm-modal-inner .err-txt {
    color: #ea4335;
    font-size: 1rem;
}