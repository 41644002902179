.ParamPerf__container {
    overflow: auto;
    height: calc(100vh - 120px) !important;
}

.middle-box {
    width: calc(100%-10px);
    height: auto;
    display: flex;
    flex-direction: column;
    background-color: #1f1f1f;
    border-radius: 4px;
    padding: 20px 20px 0px 20px;
    margin-right: 10px;
    margin-bottom: 20px;
}

.graph-label {
    display: flex;
    flex-direction: row;
    justify-content: end;
    margin-bottom: 5px;
}