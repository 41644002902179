/* .ngContainer
{
    width: calc(100%);
    padding-left: 5rem;
}

.gridContainer
{
    margin:2rem;
}

.cell
{
    width: auto!important;
    padding-left: 4rem!important;
    text-align: left!important;
}

.searchIcon
{
    background: none!important;
}

.ngGridCol1
{
    width: 20%!important;
}

.ngGridCol2
{
    width: 60%!important;
}

.ngGridCol3
{
    width: 20%!important;
}

.headContainer
{
    display: flex;
    position: relative;
}

.headContainer:first-child
{
    width: 98%!important;
}

.addEntry
{    
    color: #5d97f6;
    background-color: #2f2f2f;
}

.gridContainer
{
    height: 65%;
}

.ngContainer
{
    height: calc(100vh - 5.33rem);
}

.editSpan
{
    color: #5d97f6!important;
}

.headerTable > *
{
    font-weight: bold;
}

.flexDiv
{
    display: flex;
}

.searchContainer
{
    float: right;
    width: 25rem;
    padding: .2em;
    height: 3rem;
}

.addEntryContainer
{
    float:right;
    position: absolute;
    right: 0;
}

.fullHeight
{
    height: 100%;
} */

.notification-group .row0
{
    width: 30%!important;
}
.notification-group .row1
{
    width: 40%!important;
}
.notification-group .row2
{
    width: 30%!important;
}
.notiGrp {
    padding-left: 0!important;
}
.notiGrp .notification-group .row:before{
    clear: both !important;
    content: none !important;
}
.notiPanelDefault {
    border-color: transparent !important;
}
