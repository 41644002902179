/* Confirm PIN Start Code */

.confirmPin .modelboxopenInnner {
    width: 50rem;
    height: 26rem;
    text-align: left;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9;
    background: #1f1f1f;
    border-radius: 4px;
  }
  
  .newPin {
    color: #e0e0e0;
    font-family: "Montserrat-Regular";
    font-size: 1.16rem;
    margin-bottom: 1.66rem;
  }
  
  .pinErrorTxt {
    color: #d65166;
    font-family: "Montserrat-Regular";
    font-size: 1rem;
    margin-bottom: 1.66rem;
  }
  
  .kpigraph1 .formgroup {
    margin: 0;
  }
  
  .confirmPin .form-control {
    max-width: 25rem;
    font-family: "Montserrat-Regular";
    letter-spacing: 0.4px;
    border-color: #5d5e60;
    margin-top: 0.66rem;
  }
  /* Confirm PIN End Code */
  
  /* Forget Password Start Code */
  .forgetFormBg {
    width: 100%;
    max-width: 31.66rem;
    padding: 2.91rem 1.66rem;
    margin: auto;
    background: rgba(56, 56, 56, 0.8);
    box-shadow: 0 0 1.66rem rgba(0, 0, 0, 0.25);
    border-radius: 4px;
  }
  
  .checkEmail {
    font-family: "Montserrat-Regular";
    color: #e0e0e0;
    font-size: 1.16rem;
  }
  
  .rightSign {
    margin: 2.5rem 0;
  }
  
  .confirmMsg {
    font-family: "Montserrat-Regular";
    font-size: 1rem;
    color: #e0e0e0;
    margin-bottom: 3.33rem;
  }
  
  .newPassword {
    color: #a0a0a0;
    font-family: "Montserrat-Regular";
    font-size: 1rem;
    margin-bottom: 1.66rem;
  }
  
  .resetFormBg {
    width: 100%;
    max-width: 31.66rem;
    padding: 2.91rem 1.66rem;
    margin: auto;
    background: rgba(56, 56, 56, 0.8);
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
  }
  
  .lableReset {
    color: #e0e0e0;
    font-family: "Montserrat-Regular";
    font-size: 0.83rem;
    font-weight: normal;
  }
  
  .light .confirmPin .modelboxopenInnner {
    background: #ffffff;
  }
  
  .light .widthcardMS,
  .light .newPassword, .light .lableReset, .light .form-control.customForm, 
  .light .titleDiv, .light .newPin, .light .confirmPin .footerPopupWithBtn .cancelBtn {
    color: #3b414b;
  }
  
  .light .confirmPin .footerPopupWithBtn .cancelBtn:hover,
  .light .confirmPin .footerPopupWithBtn .cancelBtn:focus {
    background: #b6b6b7;
    color: #3b414b;
  }
  
  .light .form-control.customForm::placeholder {
    color: #a6aab4;
  }
  
  /* Forget Password End Code */
  
  /* CSS for help icon in severity column */
  
  .helpToolTip {
    position: relative;
    display: inline-block;
    visibility: visible;
  }
  
  .helpToolTip .helpToolTiptext {
    visibility: hidden;
    width: 29.16rem;
    background-color: #000;
    color: #e0e0e0;
    text-align: center;
    border-radius: 6px;
    padding: 1.25rem 0.83rem 0;
    font-family: Montserrat-Regular;
    font-size: 1rem;
    box-shadow: 0.16 0.16rem 1.66rem 0 rgba(0, 0, 0, 0.25);
    position: absolute;
    z-index: 1;
    top: 2.25rem;
    right: 0%;
    margin-right: -2.33rem;
  }
  
  .maploaderhome {
    top: 0;
    left: 0;
    position: absolute;
    background: rgba(0, 0, 0, 0.5);
    z-index: 999;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .light .maploaderhome {
    background: rgba(255, 255, 255, 0.5);
  }
  
  .innerTooltip {
    position: relative;
    top: -1.33rem;
    left: 4.16rem;
    width: 1.66rem;
  }
  
  .helpToolTip .helpToolTiptext .row {
    text-align: left;
    padding-left: 1.25rem;
    margin-bottom: 1.25rem;
  }
  
  .helpToolTip .innerTooltip svg {
    stroke: #8f8f8f;
  }
  
  .helpToolTip .innerTooltip svg:hover {
    stroke: #5d97f6;
  }
  
  .light .helpToolTip .innerTooltip svg:hover {
    stroke: #4285f4;
  }
  
  .helpToolTip .innerTooltip:hover .helpToolTiptext {
    visibility: visible;
  }
  
  .light .helpToolTip .helpToolTiptext {
    color: #3b414b;
    background-color: #fff;
    box-shadow: 4px 0 8px rgba(0, 0, 0, 0.08), -4px 0 8px rgba(0, 0, 0, 0.08);
  }
  
  .helpToolTip .helpToolTiptext .row .left {
    float: left;
    color: #a0a0a0;
  }
  
  .col-md-1.clsmargintop,
  .col-md-2.clsmargintop {
    padding-left: 0;
  }
  
  /* Menu Popup container - for alarm tables */
  
  .popup {
    position: relative;
    display: inline-block;
    cursor: pointer;
    user-select: none;
  }
  
  .popup .popuptext {
    visibility: hidden;
    position: absolute;
    width: 12.4rem;
    height: 8rem;
    background-color: rgb(44, 43, 43);
    color: #fff;
    text-align: left;
    font-family: Montserrat-Regular;
    font-size: 1rem;
    border-radius: 0;
    z-index: 9;
    box-shadow: 2px 2px 20px 0 rgba(0, 0, 0, 0.25);
    box-shadow: 4px 4px 20px 0 rgba(0, 0, 0, 0.6);
    top: 0;
    right: 2rem;
  }
  
  .popup .popuptext .row {
    padding-left: 0.83rem;
    margin-bottom: 0;
    padding-top: 0.41rem;
    width: 13.80rem;
    height: 2.66rem;
    background: #1f1f1f;
  }
  
  .popup .popuptext .line {
    list-style: none;
    color: white;
    width: 12.4rem;
    height: 2.08rem;
  }
  
  .popup .show {
    visibility: visible;
  }
  
  .light .popup .popuptext {
    background-color: #e0e0e0;
    color: #404243;
  }
  
  .light .popup .popuptext .row {
    background: #f1f9ff;
  }
  
  .light .popup .popuptext .line {
    color: #404243;
  }
  
  .popup .popuptext .row:nth-child(2n + 1) {
    background: #252525;
  }
  
  .light .popup .popuptext .row:nth-child(2n + 1),
  .light .loginDropdown .dropdown-toggle-profile {
    background: #ffffff;
    border: 0.5px solid #EBEEF2;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.04);
  }
  
  /* Unauthorized Access Code Start Here*/
  
  .containerr {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .iconMsg {
    display: flex;
    vertical-align: middle;
    width: 2.5rem;
  }
  
  .fontSizeColor {
    color: #e0e0e0;
    font-family: "Montserrat-Regular";
    font-size: 1.16rem;
  }
  
  .pad40 {
    padding: 0 .33rem;
  }
  
  .minheight100 {
    min-height: 8.33rem;
  }
  
  .UnauthorizedAccess .footerPopupWithBtn .btn {
    float: right;
    margin-right: 3.33rem;
  }
  
  .light .bookmark-div .bookmark-toast {
    background: #e3ebf6;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    color: #3b414b;
  }
  
  .widthcardMS p {
    color: #e0e0e0;
    letter-spacing: 0.4px;
    text-align: left;
  }
  .light .widthcardMS p {
    color: #3b414b;
  }
  /* Unauthorized Access Code End Here*/
  
  /* Confirmation Code Start Here*/
  .UnauthorizedAccess .modelboxopenInnner,
  .Confirmation .modelboxopenInnner {
    width: 50rem;
    height: 20.83rem;
  }
  
  .Confirmation .footerPopupWithBtn .btn {
    float: right;
    margin-right: 3.33rem;
  }
  
  .Confirmation .minheight100 {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 8.33rem;
  }
  
  /* Confirmation Code End Here*/
  
  /* Page Not Found Code Start Here*/
  
  .commanErrorCls {
    height: 100vh;
    width: 100%;
    background: #121212;
  }
  
  .light .commanErrorCls {
    background: #f2f4f6;
  }
  
  .commanErrorCls header {
    width: 100%;
    margin-left: 0;
    box-shadow: none;
  }
  
  .errorBodyContent {
    height: calc(100vh - 8rem);
    text-align: center;
    position: relative;
    background: rgba(31, 31, 31, 0.8);
    border-radius: 4px;
    width: calc(100% - 2.66rem);
    margin: 1.33rem auto 0 auto;
  }
  
  .internalPageError {
    width: calc(100% - 8.33rem);
    margin: 0 0 0 6%;
  }
  
  .commanErrorCls footer {
    right: 0;
    padding-right: 1.33rem;
    font-size: 0.83rem;
  }
  
  .innerPageNotFound {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    top: 50%;
    text-align: -webkit-center;
  }
  #logoutpage .innerPageNotFound {
    left: 47%;
    transform: translate(-47%, -50%);
  }
  .logoError {
    width: 12.5rem;
    height: auto;
    position: relative;
    left: 1.33rem;
    top: 1.66rem;
  }
  
  .logoError img {
    /* content: url("../dashboard/images/errorPageLogo.png"); */
  }
  
  .light .logoError img {
    /* content: url("../dashboard/images/errorPageLogoBlack.png"); */
  }
  
  #ConfigureGroups .comnBtns.MuiButton-containedPrimary {
    text-transform: capitalize;
  }
  
  .light .offlinebg .bookMarkIcon.bookmarked path {
    stroke: #5d97f6;
  }
  
  .popupCloseButton a:focus {
    text-decoration: none;
  }
  
  .txtNotFound {
    color: #e0e0e0;
    font-family: "Montserrat-Regular";
    font-size: 2.66rem;
    margin-bottom: 2.5rem;
  }
  .iconNotFound svg {
    height: 6.66rem;
    width: 6.66rem;
  }
  .fontSizeColor12 {
    color: #e0e0e0;
    font-family: "Montserrat-Regular";
    font-size: 1.66rem;
  }
  
  .contentAreaPartInner .fontSizeColor12 {
    font-family: "Montserrat-Medium";
    font-size: 1rem;
  }
  
  .contentAreaPartInner .pad24LR .form-group {
    margin-bottom: 2.66rem;
  }
  
  .errorHead {
    color: #e0e0e0;
    font-size: 1.33rem;
    font-family: "Montserrat-Regular";
    margin: 0;
    padding: 2rem 0 0 2.66rem;
    text-align: left;
  }
  
  .logoutfont20 {
    color: #e0e0e0;
    font-size: 1.66rem;
    font-family: "Montserrat-Regular";
  }
  
  .logoutfont16 {
    color: #e0e0e0;
    font-size: 1.33rem;
    font-family: "Montserrat-Regular";
    margin: 1rem 0;
  }
  
  .logoutfont14 {
    color: #e0e0e0;
    font-size: 1.33rem;
    font-family: "Montserrat-Regular";
  }
  
  .light .checkEmail, .light .confirmMsg,
  .light .logoutfont20, .light .logoutfont16, .light .logoutfont14 {
    color: #3b414b;
  }
  
  .logoutfont14 a {
    color: #5d97f6;
    font-style: italic;
  }
  
  .light .logoutfont14 a {
    color: #4285f4;
  }
  .logoutfont14 a:hover {
    color: #82adf3;
    text-decoration: none;
  }
  .light .logoutfont14 a:hover {
    color: #185abc;
  }
  /* Page Not Found Code End Here*/
  
  .light .forgetFormBg {
    background: rgba(255, 255, 255, 0.8);
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.25);
  }
  
  /* Close Icon For Login, Reset and Confirm Start Here*/
  
  .form-signin {
    position: relative;
  }
  
  .closeLogins {
    position: absolute;
    top: 0.83rem;
    right: 0.83rem;
    z-index: 8;
    cursor: pointer;
  }
  
  /* Close Icon For Login, Reset and Confirm End Here*/
  
  /* Change Password /Pin Start Here*/
  
  .contentAreaPart {
    background: #1f1f1f;
    border-radius: 4px;
    width: calc(100% - 1.33rem);
    height: calc(100vh - 8rem);
  }
  
  .pad24LR {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  
  .padd24T {
    padding-top: 2.66rem;
  }
  
  .padd24B {
    padding-bottom: 2rem;
  }
  
  .contentAreaPart .labelStyle {
    font-weight: normal;
  }
  
  .RedStart {
    font-size: 1.16rem;
    color: #ff0000;
    padding-left: 0.25rem;
  }
  
  .contentAreaPart .form-control {
    display: block;
    width: 100%;
    padding: 0.0833rem 0.4166rem;
    font-size: 0.833rem;
    line-height: 1.42857143;
    color: #fff;
    background-color: transparent;
    border: 1px solid #5d5e60;
    border-radius: 4px;
    height: 2.66rem;
    max-width: 27.5rem;
  }
  
  .light .contentAreaPart .form-control {
    border: 1px solid #ccc;
  }
  
  .contentAreaPart h2 {
    padding-top: 0;
    margin-top: 0;
    padding-bottom: 2.5rem;
  }
  
  .contentAreaPartInner {
    height: calc(100vh - 14rem);
  }
  
  /* Change password / pin End Here*/
  .text-center.checkEmail.linkExpire {
    font-size: 1.33rem;
  }
  .linkExpireMain .pad20 {
    padding: 0.83rem 1.25rem;
  }
  .bgColorRedSvg {
    height: 4rem;
    width: 4rem;
    background-color: #da5a50;
    border-radius: 50%;
    display: inline-block;
    position: relative;
  }
  .bgColorRedSvg svg {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  .form-signin .linkExpireMain .btn-primary {
    max-width: 21.66rem;
    margin: 0 auto;
  }
  .confirmPin .form-control {
  
    background: #1f1f1f;
  }
  .light .confirmPin .form-control {
  
    background: transparent;
  }