.changePassword{
    background-color: var(--primary-color);
    height: 100%;
    padding: 3rem 2rem;
    display: flex;
    flex-direction: column;
  }
  .changePassword>form{
    display: grid;
    grid-template-rows: auto max-content;
    height: 100%;
  }
  .formGroup:not(:last-child){
    margin-bottom: 2.6rem;
  }
  .formGroup label{
    display: inline-block;
    margin-bottom: 1rem;
    font-family: "Montserrat-Medium";
    text-transform: capitalize;
    position: relative;
  }
  .formGroup label::after{
    content: '*';
    display: inline-block;
    position: absolute;
    top: 0;
    right: 0;
    color: red;
    transform: translateX(calc(100% + 0.3rem));
    font-size: 1.2rem;
    vertical-align: text-top;
  }
  .formGroup input{
    display: block;
    outline: none;
    height: 2.6rem;
    background-color: transparent;
    border: 1px solid var(--input-border-color);
    padding: 0.4rem 0.6rem;
    color: var(--white);
    width: 27%;
    border-radius: 0.3rem;
  }
  .formGroup input::placeholder{
    color: var(--input-border-color);
    text-transform: capitalize;
  }
  .formGroup input:focus{
    border-color: var(--sky-blue);
  }
  .submitControll{margin-top: 3rem;}
  .submitControll input{
    padding: 1.1rem;
    text-align: center;
    width: 8%;
    min-width: fit-content;
    text-transform: capitalize;
    border-radius: 0.2rem;
    border:none;
    outline: none;
    font-size: 1.16rem;
    cursor: pointer;
  }
  .submitControll input:not(:last-child){
    margin-right: 1.6rem;
  }
  .submitControll .btnBlue{
    background-color: var(--sky-blue);
    color: var(--black);
  }
  .submitControll .btnBlue:hover{
    background-color: var(--sky-blue-hover-color)
  }
  .submitControll .btnGray{
    background-color: #2f2f2f;
    color: var(--white);
  }
  .submitControll .btnGray:hover{
    background-color: #3e3e3e;
  }
  .msg{
    color: var(--white);
    padding: 0.9rem 1.5rem;
    font-size: 0.83rem;
    text-transform: capitalize;
    margin-bottom: 1rem;
  }
  .error{
    background-color: var(--light-danger);
  }
  .success{
    background-color: rgba(57, 181, 57, 0.5098039215686274);
  }
  .lbl{
     display: block!important;
  }
  .lbl span{
    position: relative;
    bottom: 5px;
  }