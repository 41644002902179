.EM__datepicker{
    position: relative;
    z-index:2;
}
.EM__datepicker>.icon{
    position: absolute;
    top: 50%;
    right: 1rem;
    transform: translateY(-50%);
    pointer-events: none;
}
.EM__datepicker input{
    width: 100%;
}
.EM__dateRangePicker{
    display: flex;
    gap: 1rem;
    position: relative;
    z-index: 1;
}