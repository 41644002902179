.LogTable__body {
    overflow: overlay;
    width: auto;
    height:70vh;
}
.LogTable__dataTable>tbody td{
    background-color: var(--dashboard-background);
}
.LogTable__dataTable>thead  th,
.LogTable__dataTable>tbody tr:nth-child(even) td{
    background-color: var(--primary-color);
}
.LogTable__dataTable th,
.LogTable__dataTable td{
    padding: 1rem;
    text-align: center;
}
.LogTable__dataTable th:last-child,
.LogTable__dataTable td:last-child{
    position: sticky;
    right: 0;
}
.LogTable__dataTable>thead tr{
    position: sticky;
    top: 0;
    z-index: 1;
}
.popup.EnergyMeter__popup{
    width: 50%;
}
.EnergyMeter__popup input[type="date"]{
    /* background-color: var(--sky-blue); */
    background-color: transparent;
    padding: 0.5rem 1rem;
    /* position: absolute;
    transform: translate(-50%,-50%);
    top: 50%;
    left: 50%; */
    font-family: "Roboto Mono",monospace;
    color: #ffffff;
    font-size: 1rem;
    border: none;
    border: 1px solid var(--input-border-color);
    outline: none;
    border-radius: 5px;
}
.EnergyMeter__popup ::-webkit-calendar-picker-indicator{
    /* background-color: #ffffff; */
    background-color: transparent;
    padding: 5px;
    cursor: pointer;
    border-radius: 3px;
}
.new-row{
    /* border: 1px solid var(--input-border-color); */
    border: 1px solid var(--sky-blue);
}
.updated-row {
    color:green;
}
.LogTable__dataTable .EDIT{
    cursor: pointer;
}