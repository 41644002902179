.switchBtn {
    --btnWidth: 3rem;
    display: inline-block;
    background-color: var(--primary-color);
    /* border: 1px solid var(--input-border-color); */
    position: relative;
    width: 3rem;
    height: calc(var(--btnWidth) / 2);
    border-radius: 1rem;
    overflow: hidden;
    cursor: pointer;
}
.switchBtn.disable{
    cursor: not-allowed;
}
.switchBtn::after {
    --cricleTop: -50%;
    --cricleleft: 0;
    content: '';
    background-color: var(--secondry-color);
    display: block;
    width: 50%;
    aspect-ratio: 1/1;
    transition: all .3s;
    position: absolute;
    top: 50%;
    left: 0;
    border-radius: 50%;
    border: 1px solid var(--input-border-color);
    transform: translate(var(--cricleleft), var(--cricleTop));
}
.switchBtn.active::after {
    --cricleleft: 100%;
    background-color: var(--sky-blue);
}
