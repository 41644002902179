.digital-container {
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: 15px;
  height: calc(100vh - 120px) !important;
}
.top-box {
  display: flex;
  justify-content: space-between;
  margin: 10px 10px 11px 0px;
}
.box-1 {
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  
}

.digital-btn {
  background-color: #121212;
  border: 1px solid #5d97f6;
  color: #5d97f6;
  border-radius: 4px;
  padding: 5px 10px 5px 10px;
  /* letter-spacing: 0.5px; */
  font-family: "Montserrat", "Helvetica", sans-serif !important;
  height: 37px;
  font-size: 0.9vw;
}

.history-container {
  height: 65%;
}
.digital-btn:hover {
  color: white;
  background-color: #5d97f6;
}
.selct {
  /* border: 0.5px solid #5d5e60; */
  border-radius: 4px;
  font-family: "Montserrat", "Helvetica", sans-serif !important;
}
.btn-go {
  background: #5d97f6;
  border-radius: 4px;
  font-family: "Montserrat", "Helvetica", sans-serif !important;
  padding: 5px 10px 5px 10px;
  width: 80px;
  border: none;
  /* margin-top: 5px; */
  height: 36px;
  margin-left: 10px;
}

.mid-box {
  /* border: 1px solid red; */
  display: flex;
}
.left-con {
  width: 50%;
  /* border: 2px solid gold; */
}
.right-con {
  width: 50%;
  /* border: 2px solid gray; */
}
.left-top-box {
  width: 100%;
  /* height: auto; */
  height: 290px;
  background: #1f1f1f;
  border-radius: 4px;
  /* margin: 20px; */
  margin-top: 0px;
}
.left-down-box {
  background: #1f1f1f;
  border-radius: 4px;
  width: 100%;
  /* height:auto; */
  height: 215px;
  /* margin-left: 20px; */
  padding-bottom:10px;
  margin-top: 10px;
}
.right-top-box {
  background: #1f1f1f;
  border-radius: 4px;
  height: 160px;
  margin: 0px 10px 20px 10px;
}
.right-middle-box {
  background: #1f1f1f;
  border-radius: 4px;
  height: 140px;
  margin: 20px 10px 10px 10px;
  padding: 10px 20px;
  margin-top: -10px;
  /* width: 100%; */
  /* border: 2px solid red; */
}
.right-down-box {
  background: #1f1f1f;
  border-radius: 4px;
  height: 195px;
  margin: 0px 10px 0px 10px;
  padding: 17px 20px;
}

/*date input box */
.rs-picker-default .rs-picker-toggle.rs-btn {
  color: #fff !important;
  background-color: #1f1f1f !important;
  border: none;
  width: 210px;
  border-radius: 4px !important;
  height: 38px;
}

.rs-picker-toggle-textbox {
  color: #fff !important;
  background-color: #1f1f1f !important;
  border: 2px solid #3a3a3a !important;
}
.rs-picker-has-value .rs-btn .rs-picker-toggle-value,
.rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value {
  color: white !important;
}

.rs-picker-menu .rs-calendar {
  color: #fff !important;
  background-color: #1f1f1f !important;
  border: 1px solid #3a3a3a !important;
}

.rs-picker-daterange-header {
  color: #fff !important;
  background-color: #1f1f1f !important;
  border: 1px solid #3a3a3a !important;
}

.rs-picker-menu .rs-picker-toolbar {
  color: #fff !important;
  background-color: #1f1f1f !important;
  border: 1px solid #3a3a3a !important;
}
.rs-btn-link {
  display: none;
}
/* .rs-btn {
  width: 100% !important;
} */
.rs-stack-item{
  overflow: visible !important;
}
.rs-picker-toggle-value {
  color: white !important;
  margin-top: 6px !important;
 font-size: 12px !important;
}
.rs-picker-daterange-predefined {
  display: none !important;
}
.rs-picker-toolbar-ranges {
  display: none !important;
}
