.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  background-color: var(--primary-color);
  width: var(--sidebar-width);
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 2.5rem;
  transition: width 0.5s ease-in-out;
  /* z-index: 2; */
  z-index: 3;
}
.sidebar.openMenu {
  width: 20%;
  box-shadow: 0 4px 20px rgb(0 0 0 / 30%);
}
.menuBtn {
  position: absolute;
  display: block;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 0.2rem;
  width: 100%;
}
.menuBtn span {
  position: absolute;
  display: block;
  height: 100%;
  width: 100%;
  background: #8f8f8f;
  transition: all 0.5s ease-in-out;
  cursor: pointer;
}
.menuBtn .topLine {
  transform: translateY(calc(-100% - 0.5rem));
}
.menuBtn .middleLine {
  width: 50%;
}
.openMenu .menuBtn .middleLine {
  transform: translateX(100%);
}
.menuBtn .bottomLine {
  transform: translateY(calc(100% + 0.5rem));
}
.sideNav {
  width: 100%;
  list-style-type: none;
}
.sideNav > li > .navAnchor {
  display: flex;
  align-items: center;
  text-decoration: none;
  height: 100%;
  overflow: hidden;
}
.sideNav > li > .navAnchor .icon {
  padding: 0.7rem;
  min-width: var(--sidebar-width);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.menuIcon {
  height: var(--sidebar-width);
}
.sideNav li a > .icon>div {
  height: 1.8rem;
  width: 1.8rem;
}
.sideNav li a > .icon svg,
.menuIcon > .navAnchor > .icon > .customeMenu {
  height: 1.8rem;
  width: 1.8rem;
}
.customeMenu {
  cursor: pointer;
  position: relative;
}
.text {
  color: var(--white);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding-right: 1rem;
  color: var(--text-color);
  line-height: 1.5;
}
.sideNav > li {
  position: relative;
}
.subMenu {
  display: none;
  position: absolute;
  top: 0;
  left: 100%;
  background-color: var(--primary-color);
}
.subMenu {
  width: 16.5rem;
  list-style-type: none;
}
.subMenu > li > a {
  text-decoration: none;
  color: var(--white);
  padding: 0.7rem 0.83rem;
  display: block;
  white-space: nowrap;
  font-size: 1rem;
}
.sideNav > li:not(.menuIcon):hover,
.sideNav > li.active,
.subMenu > li > a:hover,
.subMenu > li.active > a {
  color: var(--sky-blue);
  background-color: var(--secondry-color);
}
.sideNav > li:not(.menuIcon):hover > a .text ,
  .sideNav > li.active > a .text {
  color: var(--sky-blue);
}
.sideNav > li:not(.menuIcon):hover > .subMenu {
  display: block;
}
.sideNav > li:not(.menuIcon)::after {
  display: inline-block;
  content: "";
  position: absolute;
  width: 0.3rem;
  height: 100%;
  background-color: var(--sky-blue);
  top: 0;
  left: 0;
  display: none;
}
.sideNav > li:not(.menuIcon):hover::after,
.sideNav > li.active::after {
  display: block;
}
.logo {
  flex-grow: 1;
}
.logo>a {
}
.logo svg {
  width: 7rem;
}
.navItem {
}
