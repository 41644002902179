.alarmBar.padTopOnly.row.dropRow {
  display: flex;
  justify-content: space-between;
  margin: 0;
  padding: 1.1rem 0 0.83rem;
}

.cm-wrapper {
  padding-left: 0 !important;
}
.cmRow {
  margin: 0rem 0 1rem 0;
  padding: 1rem 1rem 0 1rem;
  display: flex;
  justify-content: space-between;
}
.cm-wrapper .panel-default {
  border-color: transparent;
}

.executionResult .tab-pane.fade.no-gutters.in.active , .alertnoti .tab-pane.fade.no-gutters.in.active {
    width: calc(83% - 1.6rem);
    margin-left: auto;
    overflow: hidden;
    height: calc(100vh - 10rem);
    padding-top: 1rem;
}
.executionResult .t.tab-pane.fade.no-gutters.in.active , .alertnoti .t.tab-pane.fade.no-gutters.in.active {
  width: 100%;
}
.executionResult .exresult .contentPart, .alertnotifylog .contentPart {
  padding-right: 0;
}

.executionResult .cm-filter .MuiFormControl-marginNormal, 
.alertnoti .cm-filter .MuiFormControl-marginNormal
{
  margin-top: 0;
  margin-bottom: 0;
  width: 100%;
}

.cm-report .filterDiv , .executionResult .filterDiv, .alertnoti .filterDiv {
  z-index: auto;
}
.executionResult .filterDiv .MuiAutocomplete-popperDisablePortal,
.alertnoti .filterDiv .MuiAutocomplete-popperDisablePortal{
  position: relative !important;
}
.executionResult .contentPart , .alertnoti .contentPart{
  z-index: 0;
}
.exResultRow, .altRow {
  padding: 1.1rem 1rem 3em;
}

.alarm-pagination {
  padding-left: 1.2rem;
}
.tab-settingbtn{
  position: absolute;
  right: 1.5rem;
  top: 1rem;
  background: transparent;
  border: 0;
}
.cm-fia-desc {
  line-height: 20px;
}
.isresolvtmstmp input[type="text"] {
  border: none!important;
}
/* .executionResult .alarm-pagination , .alertnoti .alarm-pagination {
  padding-left: 1.2rem;
} */
/* .exResultRow .filterDiv .MuiAutocomplete-popper,.alertnoti .filterDiv .MuiAutocomplete-popper {
  z-index: 129;
} */
.alt-emailtxt pre {
  background-color: transparent !important;
  color: #fff !important;
}

.activeParent {
  display: flex!important;
  justify-content: center;
}
.activeCheck {
  position: relative;
  top: -14px;
}
.gd-tabledata .tbl-head, .fn-tableData .tbl-head, .mc-tabledata .tbl-head {
  cursor: pointer;
}
.gd-tabledata .tbl-head i, .fn-tableData .tbl-head i, .mc-tabledata .tbl-head i{
  margin-left:4px;
}
