
.notification-template .row0
{
    width: 30%!important;
}
.notification-template .row1
{
    width: 40%!important;
}
.notification-template .row2
{
    width: 30%!important;
}

/* .notification-template [type="radio"]:checked, [type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
} */

/* Common Radio Button CSS Start*/

[type="radio"]:checked,
[type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
}

[type="radio"]:checked+label,
[type="radio"]:not(:checked)+label {
    position: relative;
    padding-left: 1.6rem;
    cursor: pointer;
    line-height: 1.2;
    display: inline-block;
    color: #666;
}

[type="radio"]:not(:checked)+label:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 1.2rem;
    height: 1.2rem;
    border: 1px solid #8f8f8f;
    border-radius: 100%;
    background: #3b3b3b;
}

[type="radio"]:checked+label:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 1.2rem;
    height: 1.2rem;
    border: 1px solid #5d97f6;
    border-radius: 100%;
    background: #3b3b3b;
}

.light [type="radio"]:checked+label:before {
    border: 1px solid #4285f4;
}

[type="radio"]:checked+label:after,
[type="radio"]:not(:checked)+label:after {
    content: "";
    width: 0.82rem;
    height: 0.82rem;
    background: #5d97f6;
    position: absolute;
    top: 0.2rem;
    left: 0.2rem;
    border-radius: 100%;
    transition: all 0.2s ease;
    border: 1px solid #5d97f6;
}

.light [type="radio"]:checked+label:after,
.light [type="radio"]:not(:checked)+label:after {
    background: #4285f4;
    border: 1px solid #4285f4;
}

[type="radio"]:not(:checked)+label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}

[type="radio"]:checked+label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}

.light [type="radio"]:checked+label,
.light [type="radio"]:not(:checked)+label {
    color: #3b414b;
}

.light [type="radio"]:checked+label:before,
.light [type="radio"]:not(:checked)+label:before {
    border: 1px solid #8f8f8f;
    background: #fff;
}
.light [type="radio"]:checked+label:before {
    border: 1px solid #4285f4;
}
[type="radio"]:checked+label:hover,
[type="radio"]:not(:checked)+label:hover {
    color: #e0e0e0;
}
.light [type="radio"]:checked+label:hover,
.light [type="radio"]:not(:checked)+label:hover {
    color: #666;
}
[type="radio"]:checked+label:hover:before,
[type="radio"]:not(:checked)+label:hover:before{
    border: 1px solid #5d97f6; 
}
.light [type="radio"]:checked+label:hover:before,
.light [type="radio"]:not(:checked)+label:hover:before{
    border: 1px solid #4285f4; 
}
/* Common Radio Button CSS End*/