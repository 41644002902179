.overview-head {
  display: flex;
  justify-content: space-between;
  padding: 5px 8px 8px 0px;
}
.overview-head-text {
  font-family: "Montserrat", "Helvetica", sans-serif !important;
  font-weight: 500;
  font-size: 13px;
  /* text-decoration: underline; */
  letter-spacing: 0.004em;
  color: #ffffff;
}
.color-code {
  display: flex;
}
#dip-off {
  width: 11px;
  height: 11px;
  background: #ed5f53;
  margin-top: 1px;
}
#dip-on {
  width: 11px;
  height: 11px;
  background: #65aa69;
  margin-top: 1px;
  margin-left: 10px;
}
.dip-text{
    font-family: "Montserrat", "Helvetica", sans-serif !important;
    margin-left: 5px;
}
.date-range-visavail{
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 25px;
  font-size: 10px;
}
.visavail-date{
  width: 59px;
  
}