html,
body {
  overflow: hidden;
}

body.dark,
body {
  background-color: #121212;
  letter-spacing: 0.4px;
  font-family: "Montserrat-Regular";
  font-variant: common-ligatures;
}

body.light {
  background-color: #f2f4f6;
  letter-spacing: 0.4px;
  font-family: "Montserrat-Regular";
  font-feature-settings: normal;
  font-variant: unset;
}

/*comman css start here*/

/* Error In Light Theme Start Code */

.light .errorBodyContent {
  background: #ffffff;
  border: 1px solid #E4E7EC;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.02);
}

.light .txtNotFound {
  color: #3b414b;
}

.light .smallTxtNotFound.fontSizeColor12 {
  color: #58585a;
}

.light .errorHead {
  color: #3b414b;
}

.light .commanErrorCls footer {
  color: #58585a;
}

.iconNotFound{
  margin-bottom: 2.5rem;
}

.smallTxtNotFound{
  margin-bottom: 2rem;
}
/* Error In Light Theme End Code */

/* combo box for filter */

.comboDiv {
  width: 100%;
  border-radius: 0;
  float: left;
  margin-top: 1.16rem;
  margin-bottom: 0.66rem;
}

.comboDiv>div>div>div{
  padding-right: 1.66rem;
}
.filterDiv .comboDiv>div>div>div.Mui-focused{
  font-size: 0.83rem;
}
.filterDiv .comboDiv>div>div>div{
  padding-right: 0rem;
  font-size: 0.83rem;
}
.IconBeta {
  top: -0.25rem;
  right: -0.41rem;
  z-index: 9;
  cursor: pointer;
}

.onlyCustomReport.IconBeta {
  top: -1rem;
  right: -1.66rem;
  z-index: 9;
  cursor: pointer;
  position: absolute;
}

.tooltipBeta {
  font-size: 1rem;
  padding: 0.83rem;
  border-radius: 4px;
  position: absolute;
  z-index: 98;
  width: 12.5rem;
  text-align: center;
  left: 0;
  right: 0;
  top: 2rem;
  background: #000;
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.25);
  color: #e0e0e0;
  display: none;
}

.light .tooltipBeta {
  background-color: #fff;
  color: #3b414b;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

.monitoringcontentWrap .nav.nav-tabs.tabsName li:last-child:hover .tooltipBeta {
  display: block;
}

.configureHeading {
  margin: 2rem 0;
  font-size: 1rem;
  color: #e5e5e5;
  display: flex;
  justify-content: space-between;
}

.configureHeading .downloadIcon {
  margin-bottom: -0.16rem;
}

.downloadTitle {
  margin-left: 0.83rem;
  color: #5d97f6;
}

.configure .MuiPaper-root {
  border-top: 1px solid #1f1f1f;
  border-bottom: 1px solid rgb(204, 204, 204, 0.6);
  background-color: transparent;
}

.configure .saveAsBtn {
  margin-right: 2.5rem;
  font-size: 0.14rem;
  color: #ffffff;
  background: #5d97f6;
  box-shadow: 1px 4px 10px rgba(0, 0, 0, 0.25);
  border-color: #5d97f6;
  border-radius: 6px;
}

.configure .saveBtn {
  font-size: 1.08rem;
  color: #ffffff;
  background: #5d97f6;
  box-shadow: 1px 4px 10px rgba(0, 0, 0, 0.25);
  border-color: #5d97f6;
  border-radius: 6px;
}

.configure .cancelBtn {
  font-size: 1.08rem;
  color: #8f8f8f;
  background: #2f2f2f;
  border-color: #2f2f2f;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
}

.configure .MuiPaper-root .MuiPaper-root {
  height: 49vh;
  overflow-y: auto;
  border: none;
}

.configure .btn-row {
  margin-top: 1.16rem;
  display: flex;
  justify-content: space-between;
}

.configure .MuiListItemIcon-root,
.configure .MuiListItemText-root {
  color: #e0e0e0;
}

.comboDiv .font10 {
  font-family: "Montserrat-Medium";
  color: #e0e0e0;
}

.light .comboDiv .font10 {
  color: #3b414b;
}

.comboDiv .input-group {
  width: 100%;
  background: #1f1f1f;
  border-color: #1f1f1f !important;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.comboDiv .form-control {
  background: #1f1f1f;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.02);
  border-radius: 4px;
  border: 1px solid transparent;
  padding: 1px 0.41rem;
  height: 2.16rem;
  font-size: 0.83rem;
  color: #3b414b;
}
.comboDiv .MuiAutocomplete-root>div{
  position: relative;
}

.comboDiv .MuiAutocomplete-input {
  background-color: transparent;
  color: #a0a0a0;
  font-size: 1rem;
  font-family: FontAwesome, Montserrat-Medium;
  border: 0;
  /* width: 100%; */
  width: 90%;
  padding: 0.25rem 0.16rem;
}

.comboDiv .MuiAutocomplete-inputFocused {
  opacity: 1;
}

.comboDiv .input-group .caret {
  /* content: "";
  background: url(../images/dropdownArrowDown.png) center center transparent no-repeat; */
  width: 0.83rem;
  height: 0.83rem;
  transition: transform 0.5s ease-in-out;
  color: transparent;
}
.comboDiv .input-group .caret::after{
  display: inline-block;
  content: url('./caret.svg');
  color: #000;
  line-height: 1;
}

.filterDiv .form-group input {
  background: transparent;
  border: none;
}

.filterDiv .input-group .caret {
  /* content: "^"; */
  /* background: url("../images/dropdownArrowDown.png") center center transparent no-repeat; */
  transition: transform 0.5s ease-in-out;
  color: transparent;
  position: absolute;
  top: 0%;
  right: 0.83rem;
  /* transform: translateY(-50%); */
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  border: 0;
  line-height: 1;
  height: 100%;
}

.filterDiv .MuiAutocomplete-input,
.filterDiv .Mui-focused input {
  font-size: 0.83rem;
  font-family: "Montserrat-Regular";
  padding: 0.45rem 0.83rem;
  margin-top: 0;
  color: #e0e0e0;
  background: transparent;
  box-sizing: border-box;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.02);
  border-radius: 4px;
  outline: none;
  line-height: 1;
}
.filterDiv .comboDiv.datepick .Mui-focused input{
  padding: 0.45rem 0;
  line-height: 1;
}
.filterDiv .comboDiv.datepick .MuiInputBase-input{
  /* pointer-events: none; */
}
.filterDiv .comboDiv.datepick .MuiInputBase-input, .MuiInputBase-root{
color: #e0e0e0;
}
.light .filterDiv .comboDiv.datepick .MuiInputBase-input, .MuiInputBase-root{
color: #3b414b;
}
.filterDiv .comboDiv .date_range .MuiInputBase-input,
.filterDiv .comboDiv .date_range .Mui-focused input,
.filterDiv .comboDiv .date_range .MuiInputBase-root{
  padding: 0.08rem 0;
  width: 100% !important;
}
.filterDiv .comboDiv .date_range .MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-formControl {
  padding: 1px 0;
}
.filterDiv .comboDiv .date_range .MuiInputBase-root.Mui-focused.MuiInput-root.MuiInput-underline.MuiInputBase-formControl {
  min-width: 14.66rem;
}
.filterDiv .comboDiv .date_range svg{
fill: #e0e0e0;
}
.light .filterDiv .comboDiv .date_range svg {
  fill: #3b414b;
}
.light .filterDiv .MuiAutocomplete-input,
.light .filterDiv .Mui-focused input {
  color: #3b414b;
}

.comboDiv .input-group [aria-expanded="true"] .caret {
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
}

.MuiAutocomplete-listbox {
  width: 100%;
  border-radius: 0 0 4px 4px;
  margin: -0.16rem 0 0;
  color: #58585a;
  background: #1f1f1f;
  max-height: 21vh;
  overflow: auto;
  border: 1px solid #5d5e60;
  box-shadow: -4px 0 10px rgba(0, 0, 0, 0.25),
    4px 4px 10px rgba(0, 0, 0, 0.25);
}

.MuiAutocomplete-option[data-focus="true"] a {
  color: white;
  text-decoration: none;
}

.MuiAutocomplete-option a {
  color: white;
  text-decoration: none;
}

.light .MuiAutocomplete-option a {
  color: black;
  text-decoration: none;
}

.filterDiv .input-group {
  width: 100%;
  opacity: 0.88;
  background: #1f1f1f;
  border-color: #5d5e60 !important;
  box-sizing: border-box;
  border: 0.5px solid #ccc;
  border-radius: 4px;
}

.light .filterDiv .input-group {
  background: #fff;
  opacity: 0.88;
  border: 0.5px solid #a6aab4 !important;
  border-radius: 4px;
  color: #58585a;
}

.dropdown.disabledDropDown .borderInput,
.disabledDropDown .btn-default.disabled.focus,
.disabledDropDown .btn-default.disabled:focus,
.disabledDropDown .btn-default.disabled:hover,
.disabledDropDown .btn-default[disabled].focus,
.disabledDropDown .btn-default[disabled]:focus,
.disabledDropDown .btn-default[disabled]:hover,
.disabledDropDown fieldset[disabled] .btn-default.focus,
.disabledDropDown fieldset[disabled] .btn-default:focus,
.disabledDropDown fieldset[disabled] .btn-default:hover {
  background-color: #3b3b3b;
}

.disabledCls {
  cursor: not-allowed;
}

.disabledDropDown .disabledCls {
  cursor: not-allowed;
}

.disabledAuto .MuiAutocomplete-input {
  cursor: not-allowed;
}

.noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.enterGroupName {
  position: absolute;
  z-index: 9;
  width: 16.66rem;
  left: 20.83rem;
  top: 0;
}

ul.enterGroupNameUL {
  padding: 0;
  margin: 0;
  list-style: none;
}

.enterGroupName .inputDiv.form-control {
  background: #2a2a2a;
  border-radius: 4px !important;
  padding-top: 0;
  padding-bottom: 0;
  height: 2.33rem;
  border-color: #2a2a2a;
  font-size: 0.83rem;
  color: #fff;
}

.light .enterGroupName .inputDiv.form-control {
  background: #fff;
  color: #000;
}

.tabArea .nav-tabs.tabUlsInner.margTab a {
  margin: 0.25rem 0.83rem;
}

ul.nav.nav-tabs.tabsName.tabUlsInner.margTab {
  height: 3.16rem;
}

.ddivClsMargin .input-group {
  display: block;
}

.titleClass {
  font-family: Montserrat-Medium;
  text-align: left;
  font-size: 1rem;
  color: #e0e0e0;
  margin-bottom: 0.83rem;
  margin-top: 0.5rem;
}

.light .titleClass {
  color: #58585a;
}

.MuiButtonGroup-contained .floatLeft {
  float: left;
  margin-top: 1.66rem;
  height: 2.33rem;
  padding: 0.5rem 0;
  width: 8.33rem;
  font-family: montserrat-Regular;
}

.clsWrapCheck .MuiButtonGroup-contained,
.filterDiv .MuiButtonGroup-contained {
  box-shadow: none;
}

.filterDiv .MuiInputBase-input,
.MuiInputBase-root {
  padding: 0.08rem 0;
  font-size: 0.83rem;
  height: 1.75rem;
  font-family: "Montserrat-Regular";
}

.filterDiv .MuiInput-underline:after,
.filterDiv .MuiInput-underline:before,
.filterDiv .MuiInput-underline:hover:not(.Mui-disabled):before {
  border-bottom: 0;
}

.MuiSwitch-sizeSmall {
  margin-left: 4.66rem;
}

::-webkit-calendar-picker-indicator {
  filter: invert(1);
  cursor: pointer;
}

.ddivClsMargin input,
.form-control.customForm,
.searchParatr,
.MuiAutocomplete-root {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.leftSideVerticalTab span.TabTxt {
  margin-left: 1rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: block;
  margin-right: 1rem;
}

.DatePickers-container-5 label+.MuiInput-formControl {
  margin-top: 0;
}

.DatePickers-textField-6 .MuiFormLabel-root {
  display: none;
}

label.font10.mainDateRange {
  display: block;
}

.filterDiv .DatePickers-textField-6 .MuiAutocomplete-input,
.filterDiv .DatePickers-textField-6 .Mui-focused input {
  padding: 1px 0;
}

.filterDiv .Mui-focused input[type="date"] {
  padding: 1px 0;
}

.filterDiv .MuiInputBase-root input[value=""] {
  color: #a0a0a0 !important;
  text-transform: uppercase;
}

a:focus {
  outline: none !important;
  outline: 0 auto -webkit-focus-ring-color !important;
  outline-offset: 0 !important;
}
/* combo box for filter End */

/* KPI Graph Start*/
.confirmPin .graphboxopenInnner {
  width: 75rem;
  height: 43.33rem;
  text-align: left;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9;
  background: #1f1f1f;
  border-radius: 4px;
}

span.iconTxt.comingSoon {
  border-right: 0;
}

.parameterdiv {
  background-color: #1f1f1f;
}
.logologin img{
  width: 23.08rem;
  }
.popuptitle {
  margin: 0;
  padding: 0;
  font-size: 1.33rem;
  color: #e0e0e0;
  width: calc(100% - 0.83rem);
  font-family: "Montserrat-Medium";
}

.timefilterdiv {
  padding-top: 0.83rem;
}

/* KPI Graph End*/

/* Footer CSS in DarkMode Start */

.footer {
  height: 2rem;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  bottom: 0;
  z-index: 6;
  background: #121212;
}

.light .footer {
  color: #3b414b;
  background: #f2f4f6;
}

.rotateCls .fa {
  transform: rotateX(-180deg);
}

.hide {
  display: none;
}

/* Footer CSS in BarkMode End */

/*comman css end here*/

/*Auto Fill bg color Start Code */

input {
  filter: none !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition-property: background, color !important;
  transition-duration: 5000s !important;
  transition-delay: 5000s !important;
  transition-timing-function: ease-in-out !important;
}

/*Auto Fill bg color End Code */

.light .hcm_trendsDiv p {
  color: #3b414b;
}

.KPILimitContent .MuiTableCell-root {
  font-family: "Montserrat-Medium";
}

*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.new {
  padding: 4.16rem;
}
.KPILimitContent input#filled-basic{
  color: #e0e0e0;
}
.light .KPILimitContent input#filled-basic{
  color: #3b414b;
}
.checkSpoc.form-group {
  display: block;
  margin-bottom: 0;
}

.checkSpoc.form-group input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.checkSpoc.form-group label {
  position: relative;
  cursor: pointer;
}

.checkSpoc.form-group label:before {
  content: "";
  -webkit-appearance: none;
  background-color: transparent;
  border: 1px solid #8f8f8f;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0 -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 0.5rem;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  border-radius: 4px;
}

.form-group.checkSpoc.onlyKPICheckbox label:before {
  margin-right: 0.6rem;
  margin-bottom: 0.16rem;
}

.form-group.checkSpoc.onlyKPICheckbox label {
  font-size: 1rem;
  margin-bottom: 0;
}

.form-group.checkSpoc.onlyKPICheckbox input:checked+label:after {
  top: 0.3rem;
  left: 0.4rem;
  width: 0.5rem;
  height: 0.83rem;
}
#KPI .form-group.checkSpoc.onlyKPICheckbox input:checked+label:after {
  /* top: 0.16rem; */
}
.KPIRadioButtonPanel.form-radio-div {
  justify-content: center;
}

.checkSpoc.form-group input:checked+label:before {
  background-color: #5d97f6;
  border: 1px solid #5d97f6;
}
.light .checkSpoc.form-group input:checked+label:before {
  background-color: #4285f4;
  border: 1px solid #4285f4;
}

.checkSpoc.form-group input:checked+label:after {
  content: "";
  display: block;
  position: absolute;
  top: 0.1rem;
  left: 0.4rem;
  width: 0.4rem;
  height: 0.83rem;
  border: solid #000;
  border-width: 0 1px 1px 0;
  transform: rotate(45deg);
}
.panelrighttext .pagination-tab.text-center{
text-align: right;
}
#MachineList .input-group.disabledAuto.disabledCls,
#overview .input-group.disabledAuto.disabledCls{
  pointer-events: none;
}
.comboDiv.nodropcls{
  cursor: no-drop;
}
.input-group .form-control{
  z-index:unset;
}
