.pareto-box {
  /* margin: 10px; */
  width: 98%;
  height: 250px;
}
.pareto-head {
  display: flex;
  justify-content: space-between;
  padding:17px 20px 0px 20px;
}
.pareto-head-text {
  font-family: "Montserrat", "Helvetica", sans-serif !important;
  font-weight: 500;
  font-size: 13px;
/* text-decoration: underline; */
  letter-spacing: 0.004em;
  color: #ffffff;
}
