.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0;
  position: sticky;
  top: 0;
  /* z-index: 1; */
  z-index: 2;
  background-color: var(--dashboard-background);
}
.profileInfo {
  position: relative;
  user-select: none;
}
.profileInfo__ShortName {
  color: var(--sky-blue);
  background-color: #424242;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0.4rem;
  min-height: 2rem;
  min-width: 2rem;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
  font-size: 0.83rem;
  cursor: pointer;
  text-transform: uppercase;
}
.profileInfo__ShortName:hover,
.profileInfo__ShortName.active {
  background-color: var(--sky-blue);
  color: var(--white);
}
.profileInfo__panel {
  display: none;
  position: absolute;
  top: calc(100% + 1rem);
  right: 0;
  box-shadow: -4px 4px 25px rgb(0 0 0 / 70%);
  background-color: var(--primary-color);
}
.profileInfo__panel.showPanel {
  display: block;
}
.profileInfo__panel > div:not(:last-child) {
  border-bottom: 1px solid rgba(154, 154, 154, 0.1);
}
.profileInfo__panel::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
  right: 8px;
  height: 1rem;
  width: 1rem;
  background-color: var(--primary-color);
  transform: translateY(-50%) rotate(45deg);
}
.profileInfo__infoContainer {
  display: flex;
  padding: 1rem;
  align-items: center;
  text-decoration: none;
}
.profileInfo__info > span {
  display: block;
}
.profileInfo__icon {
  margin-right: 0.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.profileInfo__info {
  color: var(--white);
}
.profileInfo__icon svg {
  height: 1.6rem;
  width: 1.6rem;
}
.profileInfo__email {
  font-size: 0.83rem;
  color: var(--text-color);
  margin-top: 0.4rem;
  white-space:nowrap;
}
.profileInfo__logout {
  cursor: pointer;
}
.header__pageInfo {
  font-family: "Montserrat-SemiBold";
  font-size: 1.2rem;
  text-transform: capitalize;
}
.profileInfo__infoLabel {
}
.header__left {
  display: flex;
  align-items: center;
}
.header__logo {
  margin-right: 1rem;
  width: 6rem;
}
