

  
  .ftrInput {
    background-color: transparent;
    border: 1px solid var(--input-border-color);
    padding: 0.6rem 1rem;
    border-radius: 0.2rem;
    font-size: 1rem;
    color: var(--text-color);
    outline: none;
  }
  .batchSearch {
    margin-bottom: 1rem;
  }
  .batchSearch label {
    color: var(--text-color);
    margin-bottom: 0.6rem;
    font-weight: 700;
    display: block;
  }
  .ftrTable th {
    text-align: left;
}
.ftrUploadSection label {
    display: block;
    margin-bottom: 0.5rem;
  }
  .ftrUploadSection>div{
      display: flex;
      flex-wrap: wrap;
      align-items: center;
  }
  .ftrFileInput{}
  .ftrUploadRun {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1rem;
  }
  .detailsContainer {
    background-color: var(--primary-color);
    padding: 1rem;
  }
  
  .detailsContainer:not(:last-child) {
    margin-bottom: 1rem;
  }
  .detailTitle {
    color: var(--text-color);
    font-weight: 700;
    margin-bottom: 1rem;
    display: block;
  }
  .ftrTable {
    color: var(--text-color);
    border-collapse: separate !important;
    border-spacing: 0.6rem;
  }
  
  .ftrTable tr th,
  .ftrTable tr td {
    min-width: fit-content;
    width: 18%;
    padding-right: 2rem;
  }
  
  .ftrTable tr th:first-child,
  .ftrTable tr td:first-child {
    width: 6%;
  }
  
  .ftrTable .ftrInput {
    width: 100%;
  }
  .ftrBtns {
    display: flex;
    justify-content: flex-end;
  }
  .ftrBtns a{
    text-decoration: none;
  }
  .hrRow {
    height: 0.1rem;
    background-color: var(--text-color);
    margin: 1rem 0;
  }