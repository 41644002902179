.EM__Select{
  width: 100%;
  position: relative;
}
.EM__Select_btn{
    background-color: black;
    width: 200px;
    outline: none;
    border: none;
    font-size: 1.2rem;
    padding: 1rem;
    border-radius: 0.5rem;
    border-left: 0.5rem solid var(--sky-blue);
    width: 100%;
    cursor: default;
    background-image:
      linear-gradient(45deg, transparent 50%, rgb(255, 255, 255) 50%),
      linear-gradient(135deg, rgb(255, 255, 255) 50%, transparent 50%);
    background-position:
      calc(100% - 20px) calc(1em + 2px),
      calc(100% - 15px) calc(1em + 2px),
      calc(100% - 2.5em) 0.5em;
    background-size:
      5px 5px,
      5px 5px,
      1px 1.5em;
    background-repeat: no-repeat;
   
  }
  
.EM__Select.expand .EM__Select_btn{


  background-image:
  linear-gradient(45deg, rgb(248, 248, 248) 50%, transparent 50%),
  linear-gradient(135deg, transparent 50%, rgb(255, 255, 255) 50%);
background-position:
  calc(100% - 15px) 1em,
  calc(100% - 20px) 1em,
  calc(100% - 2.5em) 0.5em;
background-size:
  5px 5px,
  5px 5px,
  1px 1.5em;
background-repeat: no-repeat;
/* border-color: var(--sky-blue); */
outline: 0;
}
.EM__Select.expand .EM__Select_btn{
  
}
.EM_options{
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  list-style-type: none;
  background-color: var(--light-black);
  border: 1px solid var(--input-border-color);
  display: none;
  z-index: 1;
  max-height: 13rem;
  overflow: auto;
}
.EM__Select.expand .EM_options{
  display: block;
}
.EM_options>li label{
  padding: 1em;
}
.EM_options>li:nth-child(even){
  background-color: var(--secondry-color);
}
.EM_options label{
  display: block;
  margin: 0;
}
.EM_options input[type="radio"]{
  position: static!important;
  appearance: none;
  display: none;
}
.EM_options input[type="checkbox"]{
  margin: 0;
  margin-right: 0.5rem;
  cursor: pointer;
}
.EM_options>li:hover input+*, 
.EM_options input:checked+*{
  color: var(--sky-blue);
}
 
.EM_options_content{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.EM_phase_input{
  display: flex;
  align-items: center;
}
.EM_options_content input{
  margin: 0;
  margin-right: 1rem;
  width: 2.5rem;
  text-align: center;
  padding: 0;
}
.d-flex{
  display: flex!important;
}
.EM_no_record{
  padding: 1rem;
  font-weight: 700;
  cursor: default;
}
.EM__phasePercentage{
  font-size: 0.9em;
}
.EM__phasePercentage::after{
  content: '%';
  display: inline-block;
  margin-left: 1em;
}
