#allMachineList .middleDivContent {
  height: calc(100vh - 13.4rem);
}

.dropdownheight button {
  height: 2rem;
}

.alerterrormsg {
  color: #e0e0e0;
  height: 2rem;
  font-size: 0.83rem;
  padding-top: 0.416rem;
}

.light .alerterrormsg {
  color: #ff0000;
}

.row.cardRowLR {
  margin-left: -0.83rem;
  margin-right: -0.83rem;
}

.MuiCircularProgress-colorPrimary {
  color: #5d97f6 !important;
  z-index: 20;
}

.clsabsolute {
  position: absolute;
}

#monitoring img {
  content: url(../images/monitoringIcon.svg);
}

.leftMenu ul #monitoring:hover img,
.leftMenu ul #monitoring.active img {
  content: url(../images/monitoringDarkModeFilled.svg);
}

.light .leftMenu ul #monitoring:hover img,
.light .leftMenu ul #monitoring.active img {
  content: url(../images/monitoringLightModeFilled.svg);
}

#alerts-and-notifications img {
  content: url(../images/GreyBell.svg);
}

.leftMenu ul #alerts-and-notifications:hover img,
.leftMenu ul #alerts-and-notifications.active img {
  content: url(../images/darkmodeFilledBellicon.svg);
}

.light .leftMenu ul #alerts-and-notifications:hover img,
.light .leftMenu ul #alerts-and-notifications.active img {
  content: url(../images/LightmodeFilledBellicon.svg);
}

.loginDropdown li.liItem1 .bellSvg img.withoutCount {
  content: url(../images/grayIconBell.svg);
  margin-top: -0.5rem;
  margin-left: -0.33rem;
} 

.loginDropdown li.liItem1 .bellSvg:hover img,
.loginDropdown li.liItem1 .bellSvg img.withCount {
  content: url(../images/blueIconBell.png);
  width: 100%;
}
.loginDropdown li.liItem1 .bellSvg:hover svg path{
fill: #5d97f6;
stroke: none;
}
#reports img {
  content: url(../images/reportsIcon.svg);
}

.leftMenu ul #reports:hover img,
.leftMenu ul #reports.active img {
  content: url(../images/reportsDarkModeFilled.svg);
}

.light .leftMenu ul #reports:hover img,
.light .leftMenu ul #reports.active img {
  content: url(../images/reportsLightModeFilled.svg);
}

#settings img {
  content: url(../images/settingsIcon.svg);
}

.leftMenu ul #settings:hover img,
.leftMenu ul #settings.active img {
  content: url(../images/settingsDarkModeFilled.svg);
}

.light .leftMenu ul #settings:hover img,
.light .leftMenu ul #settings.active img {
  content: url(../images/settingsLightModeFilled.svg);
}

#help img {
  content: url(../images/helpIcon.svg);
}

.leftMenu ul #help:hover img,
.leftMenu ul #help.active img {
  content: url(../images/helpDarkModeFilled.svg);
}

.light .leftMenu ul #help:hover img,
.light .leftMenu ul #help.active img {
  content: url(../images/helpLightModeFilled.svg);
}

.amcMachine .outercircle {
  height: 80%;
  width: 80%;
  background: #cf7803;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
  color: #e0e0e0;
}

.light .amcMachine .outercircle {
  background: #f49a47;
  color: #2f2f2f;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
}

.listMachine li.amcMachine.active .outercircle,
.amcMachine:hover .outercircle {
  background: #ea8600;
}
.amcMachine:hover .machineName {
  color: #e0e0e0;
}
.light .amcMachine:hover .machineName {
  color: #3b414b;
}

.light .allMachine .outercircle {
  background: #5d97f6;
  height: 4.58rem;
  width: 4.58rem;
  color: #2f2f2f;
}

.machineStatus {
  position: absolute;
  bottom: 2rem;
  z-index: 3;
  left: 10rem;
  right: 0;
  width: 28%;
  max-width: 100%;
  min-width: fit-content;
  margin: 0 auto;
}
.listMachine {
  margin: 0 auto;
  width: 100%;
  padding: 0;
  list-style: none;
  display: flex;
}

.tab-content {
  position: relative;
  height: 100%;
}
.live-map-tracking .tab-content {
  position: static;
}
.live-map-tracking .tabArea {
  position: relative;
}
.listMachine .circle {
  font-size: 1.4rem;
  text-align: center;
  line-height: 1;
  font-family: "Montserrat-Medium";
  color: #ffffff;
}

.light .listMachine .circle {
  color: #121212;
}

.outercircle {
  height: 5rem;
  width: 5rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

#LiveMapTracking .contentPart {
  padding-right: 0;
}

#MachineList .contentPart {
  padding-right: 1.33rem;
}

.heightDiv {
  height: 80%;
}

.heightDiv .mCSB_inside > .mCSB_container {
  margin-right: 0.83rem;
}

.heightDiv .mCSB_scrollTools {
  width: 0.83rem;
}

.heightDiv .closeIconAlert {
  right: 2.5rem;
}

.heightDiv .mCustomScrollBox {
  max-height: 41.66rem !important;
}

.loginDropdown .liItem1 .alertContentUl {
  height: auto !important;
}

.viewAllBtn {
  font-size: 0.83rem;
  text-align: center;
  color: #5d97f6;
  font-family: "Montserrat-Medium";
}

.col-md-2.SideTabGroup {
  overflow-y: auto;
  height: 100%;
  max-height: calc(100vh - 11rem);
  scrollbar-color: #cccccc #121212;
  scrollbar-width: thin;
  border-radius: 0 0 4px 4px;
}

.light .col-md-2.SideTabGroup {
  scrollbar-color: #cccccc #e5e5e5;
}

.MuiAutocomplete-option:hover span {
  color: #5d97f6 !important;
}
.light .MuiAutocomplete-option:hover span {
  color: #4285F4 !important;
}
.KPILimitTable .headerTable span:nth-child(2) {
  text-align: left;
}

#ConfigureLimits .KPIRows > span:nth-child(2).row1 {
  justify-content: left;
  text-align: left;
  overflow: hidden;
  white-space: break-spaces;
  display: block;
  line-height: 2.16rem;
  margin-left: 0;
}

#ConfigureLimits .KPIRows > span .drop-text {
  width: 8rem;
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

#ConfigureLimits .dropdown-menu > li > a {
  padding: 0.25rem 1rem;
}

.prod .MuiButtonGroup-contained {
  box-shadow: none;
}

.graphDivContent {
  margin-top: 0.3rem;
  height: 51%;
  width: 100%;
}

.supportDivContent {
  height: 100%;
  width: 33.22%;
  border-radius: 0 0 4px 4px;
  float: left;
  background: rgba(153, 153, 153, 0.1);
}

.light .supportDivContent {
  background: #ffffff;
}

.graphDivContent.row {
  margin-left: 0;
  margin-right: 0;
}

.supportDivContent {
  margin-right: 0.3%;
}

.supportDivContent:last-child {
  margin-right: 0%;
}

.supportDivContent.col-sm-3 {
  width: 24.77%;
  padding-right: 0.83rem;
  padding-left: 0.83rem;
}

.supportBoxIcon {
  float: left;
  padding-top: 1%;
  padding-left: 3%;
}

.supportBoxIcon img {
  display: inline;
}

.supDivTxt {
  float: left;
  font-size: 1rem;
  color: rgba(255, 255, 255, 0.87);
  padding-left: 0.416rem;
  padding-top: 2%;
  font-family: "Montserrat-Medium";
}

.light .supDivTxt {
  color: #3b414b;
}

.countnumb {
  font-size: 1.33rem;
  color: rgba(255, 255, 255, 0.87);
}


/* Slick slider end here */

#FailureModeListing svg path {
    fill: #8F8F8F;
}
.leftMenu ul li#FailureModeListing:hover path,
.leftMenu ul li.active#FailureModeListing path {
    stroke: #5d97f6;
}
