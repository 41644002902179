.headers {
  display: flex;
  justify-content: space-between;
}
.inputBox {
  border: 1px solid #868686;
  border-radius: 4px;
  width: 34rem;
  height: 2.2rem;
}
.input {
  border: none;
  background-color: #252525;
  font-size: 12px;
  outline: none;
  padding-left: 10px;
  width: 96%;
  height: 95%;
  border-radius: 4px;
  font-family: "Montserrat-Regular";
}
.icon path {
  fill: #868686 !important;
}

.plusIcon path {
  fill: rgb(56, 163, 245) !important;
}
.plusIcon {
  margin-top: 2px !important;
}
.addBtn {
  width: auto;
  height: auto;
  background-color: #2f2f2f;
  border-radius: 4px;
  display: flex;
  padding: 5px;
  font-size: 12px;
  cursor: pointer;
}
.addBtnText {
  margin-top: 3px;
  font-family: "Montserrat-Regular";
  color: rgb(56, 163, 245);
  margin-left: 5px;
}
.pagination {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  padding-bottom: 5px;
  /* padding: 10px; */
  /* border: 2px solid red; */
}
.btn button {
  background: transparent;
}
.btn {
  display: flex;
}
.pageIndex {
  font-family: "Montserrat-Regular";
  margin-top: 3px;
  margin-left: 8px;
  font-size: 12px;
}
.table {
  font-family: "Montserrat-Regular";
  border-collapse: collapse;
  width: 100%;
  margin-top: 10px;
  font-size: 12px;
  /* overflow-x: scroll;  */
  /* border: 1px solid green; */
  overflow: hidden;
}
.headTable {
  /* background-color: #1f1f1f;
  overflow-x: scroll; 
  width:100%; */
  background-color: #1f1f1f;
  padding: 0.5rem 0rem;
  font-size: 1rem;
  /* border: 1px solid green; */
}
.tbody {
  overflow-y: scroll;
  overflow-x: hidden;

  /* border: 2px solid red; */

  height: calc(100vh - 21rem);
  width: 100%;
}
.tr {
  padding-top: 4px;
}

.td,
.th {
  text-align: left;
  padding: 5px;
  color: #e0e0e0;
  /* border: 1px solid green; */
  width: 104px !important;
  line-break: auto;
  overflow-wrap: break-word;
}
.th{
  font-family: "Montserrat-medium";
}
.td{
  font-weight: 700;
  font-size: 0.83rem;
}

.tr:nth-child(even) {
  background-color: #1f1f1f;
}
/* .delete path {
  fill: #d3d9e0 !important;
} */
.tableIcon {
  /* border: 2px solid red; */
  width: 50px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}
.results {
  font-family: "Montserrat-Regular";
  margin-top: 5px;
  font-size: 12px;
}
.ServoMotorParameter_th__upWCS {
  display: none !important;
}

/* @media only screen and (max-width: 1300px) {
  .table {
    display: none;
  }
} */
.edit:hover > div > svg path {
  stroke: #5d97f6 !important;
}
.delete:hover > div > svg path {
  stroke: #5d97f6 !important;
}

@media screen and (max-width: 1230px) {
  .tbody {
  height: calc(100vh - 22rem);
    
  }
}

.tableNo{
  background-color: #252525;
  color: #e0e0e0;
  width: 100%;
  padding-top: 0%;
  text-align: center;
  border-radius: 0 0 4px 4px;
  font-size: 1rem;
  line-height: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: calc(100vh - 18.5rem);
}