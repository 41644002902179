.rsdAdd.modelboxopen {
  z-index: 999;
}
.modelboxopenInnnerRsd {
  width: 100%;
  height: 100%;
  text-align: left;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9;
  background: #1f1f1f;
  border-radius: 4px;
}

.div-header {
  padding: 10px;
  height: 48px;
  color: white;
  margin-left: 5px;
}

.leftfloat {
  float: left;
}
.rightfloat {
  float: left;
}

.mrginTop {
  margin-top: 5px;
}

.dropdownlables {
  display: inline-block;
  position: relative;
  color: #e0e0e0;
  font-size: 0.8;
  font-family: "Montserrat-Medium";
  margin-bottom: 1rem;
}
.save_stylebtn button,
.save_stylebtn button:hover,
.save_stylebtn button:focus {
  padding: 4px 12px;
  width: 80px;
  height: 42px;
  background: #5d97f6;
  border-radius: 4px;
  box-shadow: none;
  outline: none;
  border: none;
  color: #000;
  font-family: "Montserrat-Medium";
  font-size: 14px;
  text-transform: capitalize;
}
.save_stylebtn.reset_rsdbtn button.MuiButton-root {
  background: #2f2f2f;
  color: #5d97f6;
  font-size: 12px;
  text-transform: capitalize;
}
#weekly th,
#weekly td,
#monthly th,
#monthly td {
  border: 1px solid #313131;
}
#weekly th th,
#weekly td td,
#monthly th td,
#monthly td td {
  border: 0px solid #313131;
}
#weekly tr,
#weekly th,
#weekly td,
#monthly tr,
#monthly th,
#monthly td {
  text-align: center;
  font-size: 12px;
  font-family: "Montserrat-Medium";
  font-weight: normal;
}
#weekly tr,
#monthly tr,
#monthly tr tr {
  background-color: #2a2a2a;
  border-radius: 4px 4px 0px 0px;
  padding: 10px 5px;
  color: #e0e0e0;
  height: 40px;
}
#weekly tr tr,
#monthly tr tr,
#monthly tr tr tr {
  background-color: transparent;
}
#weekly tr:nth-child(2n + 2) {
  background: #252525;
}
.dropdown_rsd > div {
  border: 1px solid #1f1f1f;
  box-sizing: border-box;
  border-radius: 4px;
  color: #fff;
  background: #252525;
  width: 100%;
  text-align: left;
  padding: 1px 10px;
  line-height: 20px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.css-1uccc91-singleValue {
  color: #fff;
}
.dropdownInput {
  display: inline-block;
  width: 6em;
  margin-right: 0.5em;
  padding-top: 1.5em;
}

.hr {
  padding: 0;
  margin-bottom: 5px;
  border: 0;
  border-top: 1px solid #646363;
}

.rsdFilterDiv {
  min-width: 120px;
}

.font-color {
  color: white;
  padding-right: 2px;
  padding-left: 2px;
}

totalbackground {
  background-color: rgb(21, 21, 21);
}

.editEntrylables {
  font-size: 12px;
  color: #e0e0e0;
  font-family: "Montserrat-Medium";
  line-height: 32px;
}
.spaceTop {
  margin-top: 20px;
}
.spaceBottom {
  margin-bottom: 20px;
}
.inputStyles input {
  border: 0.5px solid #1f1f1f;
  box-sizing: border-box;
  border-radius: 4px;
  background: transparent;
  padding: 5px 10px;
  outline: none;
  box-shadow: none;
  color: #e0e0e0;
  max-width: 100%;
  width: 100%;
}
.weelyTable {
  width: 100%;
  border: 1px solid;
  padding: 10px;
  margin-bottom: 20px;
  margin-top: 20px;
}
table {
  width: 100%;
  color: aliceblue;
  border: 0px solid #313131;
  /* border-collapse: collapse !important; */
}

.light table {
  color: #0468bf;
}

#weekly,
#monthly th {
  text-align: center;
}
.pointer {
  cursor: pointer;
}

/* #weekly td {
  text-align: center;
}
#weekly th {
  border: 1px solid;
}

#weekly td {
  border: 1px solid;
} */

/* #monthly th {
  text-align: center;
  border: 1px solid;
}
#monthly td {
  padding: 3px;
} */
/* 
.tables12 {
  border: 1px solid;
}

#monthly th {
  padding: 0px;
}
#monthly th tr {
  border: 1px solid;
}
#monthly th td {
  border: 1px solid;
}

#monthly tbody table {
  padding: 0px;
}
#monthly tbody th {
  padding: 0px;
}
#monthly tbody td {
  padding: 0px;
  text-align: center;
}
#monthly tbody tr {
  padding: 0px;
  text-align: center;
  border: 1px solid;
} */
#monthly {
  height: calc(100% - 30rem);
}
#monthly .montlydata_tr:nth-child(2n + 1) {
  background-color: #1e1e1e;
}
.modelboxopenInnnerRsdPop {
  width: 700px;
  height: 500px;
  text-align: left;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9;
  background: #1f1f1f;
  border-radius: 4px;
}
.calenderHiglate {
  color: blue;
}
.item_listbtn {
  margin: 0;
  padding: 0;
  list-style: none;
}
.item_listbtn li {
  display: inline-block;
}
.item_listbtn span {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  top: 1px;
  right: 5px;
}
.item_listbtn span.font-color_red {
  background-color: #ed5f53;
}
.item_listbtn span.font-color_green {
  background-color: #5aaf6c;
}
.item_listbtn span.font-color_yellow {
  background-color: #fcd050;
}
.item_listbtn span.font-color_blue {
  background-color: #355dcd;
}
.item_listbtn span.font-color_cyan {
  background-color: #08b1f1;
}


.item_listbtn li {
  color: #e0e0e0;
  font-size: 12px;
  font-family: "Montserrat-Medium";
  margin-left: 25px;
}
.rightpos {
  float: right;
  margin-top: 20px;
}
.download-table-xls-button,
.download-table-xls-button:hover,
.download-table-xls-button:focus {
  padding: 6px 12px;
  background: #2f2f2f;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  color: #e0e0e0;
  border: 0px;
  outline: none;
  margin: 5px 0;
}

.modelboxopenInnnerRsdPop .cla-width input.MuiInputBase-input {
  font-size: 16px;
  color: #fff;
}
.cla-width .MuiInput-underline:hover:not(.Mui-disabled):before {
  border-bottom: 2px solid #fff;
}
.cla-width .MuiInput-underline:before {
  background: #fff;
  height: 2px;
}
.modelboxopenInnnerRsdPop .cla-width > div {
  width: 100%;
}
.modelboxopenInnnerRsdPop .cla-width button svg {
  width: 24px;
  height: 24px;
  margin-bottom: 5px;
}

.modelboxopenInnnerRsdPop .cla-width button svg path:first-child {
  fill: #fff;
}
.save_stylebtn.onlysavebtns {
  padding: 15px;
  width: 100%;
  text-align: revert;
  display: flex;
  justify-content: flex-end;
  background: #272727;
}
#monthly tr,
#monthly th,
#monthly td {
  padding: 0px 0px !important;
}

#weekly th th.w_word,
#weekly td td.w_word,
#monthly th td.w_word,
#monthly td td.w_word {
  padding: 0 10px !important;
}
/* .montlydata_tr table table tr td{
  border-right: 1px solid #000 !important;
}
.montlydata_tr table table table tr td{
  border-right: 0px solid #000 !important;
} */
/* .tableFixHead {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
  overflow-x: scroll;
  height: 240px;
}
  .tableFixHead tbody {
  display: block;
  width: 100%;
  overflow: auto;
  height: 120px;
}
.tableFixHead thead tr {
  display: block;
} */
/* .tableFixHead thead table tr {
  display: inline;
} */
/* .tableFixHead th,
.tableFixHead  td {
} 
.tableFixHead th {
  background: #ABDD93;
}*/
#monthlydata thead th {
  position: sticky;
  top: 0;
  /* z-index: 99; */
  background: #2a2a2a;
}
#monthlydata tbody {
  overflow-y: scroll;
}
.tableFixHead {
  /* height: 230px; */
  height: 190px;
  overflow: scroll;
}
.weeklyTable thead th {
  position: sticky;
  top: 0;
  background: #2a2a2a;
}
.weeklyTable thead tr:last-child th {
  position: sticky;
  top: 39px;
  background: #2a2a2a;
}
#monthlydata thead tr:last-child th::after,
.weeklyTable thead tr:last-child th::after {
  content: " ";
  left: 0;
  bottom: 0;
  background: #313131;
  height: 2px;
  width: 100%;
  position: absolute;
}
.weeklyTable thead tr:last-child table th,
.weeklyTable thead tr:last-child table td {
  border: none;
} 
/* .weeklyTable thead tr:last-child table th::after{
  content: " ";
  left: 0;
  bottom: 0;
  background: #fff;
  height: 2px;
  width: 100%;
  position: absolute;
} */
table.weeklyTable tbody {
  overflow-y: scroll;
}
.weekTbleFix {
  height: 16rem;
  overflow-y: scroll;
}
#weekly .innerTableweek tr,
#weekly .innerTableweek td {
  padding: 0px !important;
}
#weekly .innerTableweek td span {
  border-radius: 4px;

  height: 24px;
  width: 40px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
.addEntryRSD button {
  color: #5d97f6;
  background-color: #2f2f2f;
  font-size: 10px;
  text-transform: capitalize;
}

.MuiDialog-root-dynamic {
  z-index: 99999 !important;
}
.weeklyTable input:focus {
  outline: none;
}
.col-md-6.col-xs-6.mrginTop {
  margin-bottom: 10px;
}
.row1row2 {
  height: calc(100vh - 350px);
}
.rsdAdd .row3 {
  height: 272px;
}
.Inputclass {
  padding: 5px;
}
.marginLeft {
  margin-left: 10px;
}
.rsdyearTbl #rds-table tr, .rsdyearTbl #rds-table th, .rsdyearTbl #rds-table td {
  padding: 0px 10px !important;
}