.formBody{
    display: grid;
    grid-template-columns: repeat(2,1fr);
    gap: 1.5rem;
}
.formBody .Form__field>input{
    display: block;
    width: 100%;
    padding: 0.9rem;
}
.formBody .Form__field>label{
    font-size: 1.1rem;
    margin-bottom: 1rem;
}
.formFooter{
    margin-top: 2rem;
}
.formFooter input{
    margin-left: auto;
    display: block;
    width: fit-content;
}
.Form__field_switch{
    display: block;
    margin-top: 0.5rem;
}
.formBody .Form__field>textarea {
    display: block;
    width: 100%;
    padding: 0.9rem;
}

.formBody textarea {
    display: block;
    outline: none;
    background-color: transparent;
    border: 1px solid var(--input-border-color);
    padding: 0.4rem 0.6rem;
    color: var(--white);
    border-radius: 0.3rem;
}