.zeroAlertModal {
    position: fixed;
    top: 3%;
    left: 36%;
    z-index: 45;
}

.zeroAlertDiv {
    background-color: #932f26;
    border-radius: 4px;
    font-family: "Montserrat-Regular";
    font-size: 1rem;
    color: #e0e0e0;
    width: 39.58rem;
    height: 2.66rem;
    align-items: center;
    display: grid;
}

.zeroAlertModalInner {
    text-align: -webkit-center;
}

.InternetConnectionModal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 15;
    background: rgba(0, 0, 0, 0.7);
}

.footerPopupWithBtn {
    height: 5rem;
    width: 21.66rem;
    float: right;
}
.passwordChangeDiv .footerPopupWithBtn{
    float: left;
    margin-left: 2rem;
}
.wAuto {
    width: auto;
}

.mr45 {
    margin-right: 3.75rem
}

.create_Machines .contentPopup {
    padding-top: 0.83rem;
    height: 41.83rem;
}

.descriptionCreate {
    padding: 0 2rem;
}

.descriptionCreate label {
    font-weight: normal;
    min-height: 1.66rem;
}

.descriptionCreate .clsmargintop {
    padding-top: 3.25rem;
}

.redStar {
    color: #ea4335;
    font-size: 1.83rem;
    padding: 0 0.08rem;
    position: relative;
    top: 0.33rem;
}

.font12 {
    font-family: "Montserrat-Medium";
    font-size: 1rem;
    color: #e0e0e0;
}

.font14 {
    font-size: 1.16rem;
    color: #e0e0e0;
}

.create_Machines .descriptionCreate .font14{
    font-size: 1rem;
}

.descriptionCreate .borderInput {
    padding: 0.33rem 0.41rem;
    position: relative;
}

.form-control.customForm {
    height: 2.66rem;
    padding: 0.33rem 1rem;
    font-size: 1rem;
    color: #fff;
    background-color: transparent;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    -webkit-transition: border-color ease-in-out 0.15s, -webkit-box-shadow ease-in-out 0.15s;
    -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    border: 1px solid #5d5e60;
}

.light .form-control.customForm {
    border: 1px solid #a6aab4;
}

.form-control.customForm::placeholder {
    color: #1f1f1f;
}

.light .form-control.customForm::placeholder {
    color: #1f1f1f;
}

#alertname::placeholder{
    color: #e0e0e0;
    font-size: 0.83rem;
}

.light #alertname::placeholder{
    color: #3b414b;
}

.descriptionCreate .drop-text {
    color: #e0e0e0;
    font-size: 1rem;
}

.clsmargintop {
    padding-top: 3rem;
    padding-left: 0;
    padding-right: 0;
    width: 3.33rem;
}

.alertTrigrdDiv {
    width: 4.16rem;
    padding-right: 0;
}

.alertCheckbox .form-group,
.customchecks .form-group {
    float: left;
    width: auto;
}

.alertCheckbox .form-group input,
.customchecks .form-group input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
}

.alertCheckbox .form-group label,
.customchecks .form-group label {
    position: relative;
    cursor: pointer;
    font-family: "Montserrat-Regular";
}

.alertCheckbox .form-group label:before,
.customchecks .form-group label:before {
    content: "";
    -webkit-appearance: none;
    background-color: #3b3b3b;
    border: 1px solid #cccccc61;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
    padding: 0.41rem;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 0.83rem;
    border-radius: 4px;
    height: 1.33rem;
    width: 1.33rem;
    margin-bottom: 0.25rem;
}

.alertCheckbox .form-group input:checked+label::before,
.customchecks .form-group input:checked+label::before {
    background-color: #5d97f6;
    border: none;
}

.light .alertCheckbox .form-group input:checked+label::before,
.light .customchecks .form-group input:checked+label::before {
    background-color: #4285f4;
}

.alertCheckbox .form-group input:checked+label:after,
.customchecks .form-group input:checked+label:after {
    content: "";
    display: block;
    position: absolute;
    top: 0.08rem;
    left: 0.41rem;
    width: 0.5rem;
    height: 0.83rem;
    border: solid #000;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}
.customchecks .form-group input:checked+label:after {
    top: 0.2rem;
}
.or {
    float: left;
    width: 2rem;
    color: #fff;
    line-height: 2.66rem;
}

.light .or {
    color: #3b414b;
}

.custombox {
    float: left;
    width: auto;
}

.font14opacity {
    color: rgba(255, 255, 255, 0.87);
    font-size: 1rem;
}

.form-group.clsTB {
    padding-top: 0.41rem;
    padding-left: 1.25rem;
}

.customchecks .form-group {
    width: auto;
    float: left;
    margin-right: 1.66rem;
}

.footerPopupWithBtn .cancelBtn {
    font-size: 1.16rem;
    color: #e0e0e0;
    background: #2f2f2f;
    border-color: #2f2f2f;
    box-shadow: none;
    border-radius: 6px;
    height: 3.33rem;
    width: 8.33rem;
    float: left;
    margin-right: 1.66rem;
    display: flex;
    justify-content: center;
    align-items: center;
}
.passwordChangeDiv .footerPopupWithBtn .cancelBtn{
    margin-left: 1.66rem;  
}

.light .footerPopupWithBtn .cancelBtn {
    color: #3b414b;
    background: #edf0f3;
    border-color: #B6B6B7;
    border: none;
    box-shadow: none;
}

.footerPopupWithBtn .cancelBtn:hover {
    color: #fff;
    background-color: #3e3e3e;
    border-color: #3e3e3e !important;
    outline: 0;
    outline: 0px auto -webkit-focus-ring-color;
    outline-offset: 0px;
}

.light .footerPopupWithBtn .cancelBtn:hover {
    color: #3b4143;
    background-color: #E0E4EA !important;
    border-color: #3e3e3e !important;
}

.footerPopupWithBtn .saveBtn {
    font-size: 1.16rem;
    font-family: "Montserrat-Medium";
    color: #000000;
    background: #5D97F6;
    box-shadow: none;
    border-color: #5D97F6;
    border-radius: 6px;
    height: 3.33rem;
    width: 8.33rem;
    float: left;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
}

.light .footerPopupWithBtn .saveBtn {
    background: #4285F4 !important;
    border-color: #4285F4 !important;
    box-shadow: none;
    color: #fff;
}

.footerPopupWithBtn .saveBtn:hover,
.footerPopupWithBtn .saveBtn:active,
.footerPopupWithBtn .saveBtn:focus {
    color: #000;
    background-color: #6AA2FF;
    border-color: #6AA2FF;
    outline: 0;
    outline: 0px auto -webkit-focus-ring-color;
    outline-offset: 0px;
}

.light .footerPopupWithBtn .saveBtn:hover,
.light .footerPopupWithBtn .saveBtn:active,
.light .footerPopupWithBtn .saveBtn:focus {
    background-color: #296FEA !important;
    color: #fff;
}

.create_Machines .containerIner2 {
    position: relative;
}

.heightper94 {
    height: 94%;
}

.create_Machines .dropdown-menu>li>a {
    padding: 0.25rem 0.41rem;
}