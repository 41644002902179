.passwordResetSuccess {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    /* background-color: #f8f8f8; */
  }
  
.passwordResetSuccess  .card {
    background-color: #2f2f2f;
    padding: 2rem;
    border-radius: 8px;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    margin: auto;
  }
  
  .passwordResetSuccess .icon {
    margin-bottom: 1rem;
  }
  
  .passwordResetSuccess  h1 {
    font-size: 1.5rem;
    color: #fff;
    font-family: "Montserrat", "Helvetica", sans-serif;
    margin-bottom: 0.5rem;
  }
  
  .passwordResetSuccess p {
    color: #fff;
    font-family: "Montserrat", "Helvetica", sans-serif;
    margin-bottom: 2rem;
  }
  
  .passwordResetSuccess .loginButton {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s ease;
  }
  
  .passwordResetSuccess .loginButton:hover {
    background-color: #0056b3;
  }
  .passwordResetSuccess .linkExLogo{
    text-align: center;
    margin-bottom: 2rem;
  }
  .passwordResetSuccess .passresetContainer {
    width: 95%;
  }
  .passwordResetSuccess .linkExLogo svg{
    width: 20%;
  }