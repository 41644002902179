.popup{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    min-width: -moz-fit-content;
    min-width: fit-content;
    background-color: var(--secondry-color);
    padding: 1rem 2rem 3rem;
    z-index: 3;
    display: grid;
    grid-template-rows: max-content auto;
    grid-gap: 1rem;
    gap: 1rem;
}
.popup__head {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.popup_title{
    font-size: 2rem;
}