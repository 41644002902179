.defect-doughnut-chart {
  width: 30%;
  height: 130px;
  margin-top: 15px;
}

.defect-doughnut-table {
  width: 75%;
  height: 160px;
}

.defect-doughnut-table table {
  font-family: "Montserrat", "Helvetica", sans-serif !important;
  border-collapse: collapse;
  min-width: 100% !important;
  border: 0.5px solid #333334;
  margin-top: 2px;
}

.defect-doughnut-table td,
.defect-doughnut-table th {
  text-align: left;
  padding: 3.5px;
  font-family: "Montserrat", "Helvetica", sans-serif !important;

}

.defect-doughnut-table tr:nth-child(even) {
  background-color: #252525;
}

.Defects-heading {
  font-family: "Montserrat", "Helvetica", sans-serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  /* text-decoration: underline; */
  color: #ececec;
}