:root {
  --primary: #5d97f6;
  --primary-background: #121212;
  --secondary-background: #252525;
  --grid-odd-color: #252525;
  --grid-even-color: #1e1e1e;
  --primary-font-color: #e0e0e0;
  --accordin-color: #2f2f2f;
  --bg: #1f1f1f;
  --btn-primary: #5D97F6;
  --btn-primary-color: #000000;
  --btn-secondary: #2f2f2f;
  --btn-secondary-color: #e0e0e0;
  --btn-primary-hover: #6AA2FF;
  --btn-secondary-hover: #3e3e3e;
  --border-color: #5d5e60;
}

:root .light {

  --primary-background: #f2f4f6;
  --secondary-background: #fafbfc;
  --grid-odd-color: #fafbfc;
  --grid-even-color: #ffffff;
  --primary-font-color: #3b414b;
  --accordin-color: #fafbfc;
  --bg: #ffffff;
  --btn-primary: #4285F4;
  --btn-primary-color: #ffffff;
  --btn-secondary: #edf0f3;
  --btn-secondary-color: #3b414b;
  --btn-primary-hover: #296FEA;
  --btn-secondary-hover: #b6b6b7;
  --border-color: #a6aab4;
}

.failuremodulePage .badge {
  position: unset;
  margin-left: inherit;
  margin-top: inherit;
}

.failuremodulePage .MuiOutlinedInput-notchedOutline {
  border: none;
}

.failuremodulePage.wrapper {

  background-color: var(--primary-background);
  overflow-y: auto;
}

.failuremodulePage .contentWrap {
  background: var(--secondary-background);
  border-radius: 4px;
}

.ant-drawer-header,
.ant-drawer-content {
  background: var(--grid-odd-color);
}

.ant-drawer-title {
  color: var(--primary-font-color);
  font-size: 1.2rem;
}

.failuremodulePage .panel-default>.panel-heading {
  background-color: var(--accordin-color);
  border: var(--accordin-color) solid 0.5px;
  border-radius: 4px;
  height: 3rem;
  color: #fff;
  padding: 1rem;
  margin-top: 10px;
}

.failuremodulePage .panel-default>.panel-heading:after {
  content: url('../images/caret-1920.svg');
  float: right;
  position: relative;
  right: 0rem;
  margin-top: -1rem;
  background-repeat: no-repeat;
  background-position: center right 1rem;
  background-size: 1rem;
}

.failuremodulePage .panel-default>.panel-heading.active {
  background: var(--accordin-color);
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;

}

.failuremodulePage .panel-default>.panel-heading.active:after {
  content: url('../images/caret-1920.svg');
  float: right;
  position: relative;
  right: 0rem;
  width: 100%;
  margin-top: -1rem;
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  background-repeat: no-repeat;
  background-position: center right 1rem;
  background-size: 1rem;
}

.failuremodulePage .pannelWrap.overflowScrolls .panel-default>.panel-heading:after,
.failuremodulePage .pannelWrap.overflowScrolls .panel-default>.panel-heading.active:after {
  content: none;
}

.failuremodulePage .tab-content {
  padding-top: 0.833rem;
}

.failuremodulePage .panel-default>.panel-heading {
  background-color: var(--grid-even-color);
  border: var(--accordin-color) solid 0.5px;
  border-radius: 4px;
  height: 3rem;
  color: #fff;
  padding: 1rem;
  margin-top: 10px;

}

.failuremodulePage .pannelWrap .panel-default>.panel-heading {
  background-image: none;
}

.failuremodulePage ::-webkit-input-placeholder,
.notification-template-editor ::-webkit-input-placeholder,
#edit-notification-group-fields ::-webkit-input-placeholder {
  color: #8f8f8f !important;
  font-size: 0.83rem;
  font-family: "Montserrat-Regular";
  opacity: 1 !important;
}

.failuremodulePage .alertDivs .headerTable span:nth-child(6),
.failuremodulePage .alertDivs .alarmRows span:nth-child(6) {
  width: 9%;
  float: left;
  text-align: center;
  display: block;
}

.failuremodulePage .overflowScrolls {
  height: calc(100vh - 21.5rem);
}

.failuremodulePage .icon-btn {
  padding: 0;
  min-width: auto !important;
  position: absolute;
  top: -3.5rem;
  right: 0px;
}

.failuremodulePage .confirmPin .modelboxopenInnner {
  height: 94vh;
}

.failuremodulePage .descriptionCreate {
  padding: 0rem;
  height: calc(100vh - 13rem);
  overflow: auto;
}

.failuremodulePage .descriptionCreate .col-md-12 {
  padding: 2rem;
}

.failuremodulePage .descriptionCreate .modal-panel:nth-child(3) .col-md-12 {
  border: none !important;
}

.failuremodulePage .icon-btn img {
  height: 1.5rem !important;
}

.light .failuremodulePage .tabArea .tab-content>.active {
  background: #fafbfc;
}

.dark .failuremodulePage .tabArea .tab-content>.active {
  background: var(--secondary-background);
  border-radius: 4px;
  height: calc(100vh - 10.9rem);
}

.failuremodulePage .btn,
.ant-btn {
  font-size: 1.16rem;
  font-family: "Montserrat-Medium";
  border-radius: 6px;
  height: 3.33rem;
  min-width: 8.33rem;

}

.ant-btn {

  min-width: unset;
  margin: 1rem 0 1rem 0.5rem;
}

.light .failuremodulePage .panel-default>.panel-heading,
.light .failuremodulePage .panel-default>.panel-heading+.panel-collapse>.panel-body {
  background-color: #ffffff !important;
  border: 0.5px solid #e4e7ec;
  color: #3b414b;
}

.failuremodulePage .panel-default>.panel-heading:first-child {
  margin-top: 0;
}

.failuremodulePage .pannelWrap .panel-default>.panel-heading:first-child {
  margin-top: 0;
  padding: 0;
  border-color: transparent;
  border-radius: 0;
}

.failuremodulePage .headerTable {
  padding-left: 0;
  padding-right: 0;
  ;
}

.failuremodulePage .pannelWrap .panel-default:nth-child(2n + 1) .panel-heading {
  margin-top: 0;
  padding: 0;
  border-color: transparent;
  border-radius: 0;
}

.failuremodulePage .link.disabled {
  opacity: 0.3;
}

.failuremodulePage .link:not([class*="disabled"]) {
  cursor: pointer;
}

.failuremodulePage .link:hover:not([class*="disabled"]) {
  color: var(--primary);
}

.failuremodulePage .form-group label,
.failuremodulePage .fia-desc {
  font-family: "Montserrat-Regular";
  font-size: 1rem;
  font-weight: inherit;
  margin-bottom: 6px;
  margin-top: 6px;
  color: var(--primary-font-color);
}

.failuremodulePage .descriptionCreate .col-md-12 {
  border-bottom: 1px solid #313131;
}

.light .failuremodulePage .descriptionCreate .col-md-12 {
  border-bottom: 1px solid #eeeeee;
}

.failuremodulePage .descriptionCreate {
  padding-top: 1rem;
}

.failuremodulePage .tabArea .tabsName li.active:first-child a {
  margin-left: 0;
}

.failuremodulePage .text-area-color strong {
  background-color: transparent !important;
  color: var(--primary-font-color) !important;
}

.failuremodulePage .alertDivs .alarmRows span:nth-child(6) {
  cursor: pointer;
}

.failuremodulePage .alertDivs .alarmRows span.active:nth-child(5) {
  color: #52b56d;
  cursor: pointer;
}

.failuremodulePage .alertDivs .alarmRows span.inactive:nth-child(5) {
  color: #ed5f53;
  cursor: pointer;
}

.failuremodulePage .input-group.search-container svg {
  position: absolute;
  right: 1rem;
  top: 0.45rem;
  height: 1.1rem;
  width: 1.1rem;
}

.failuremodulePage .input-group.search-container svg path {
  fill: #8f8f8f !important;
}

.failuremodulePage .input-group.search-container .form-control {
  border-radius: 4px;
  height: 2.2rem;
  width: 34rem;
}

.failuremodulePage .input-group.search-container .form-control::placeholder {
  color: #8f8f8f !important;
  font-size: 0.83rem;
  font-family: "Montserrat-Regular";
  opacity: 1 !important;
}

.failuremodulePage .alertDivs .alarmRows span:nth-child(7) {
  color: #5d97f6;
  cursor: pointer;
}

.failuremodulePage .panel.panel-default {
  background-color: transparent;

}

.failuremodulePage .row0.rotate {
  padding: 0;
}

.failuremodulePage .panel-default>.panel-heading+.panel-collapse>.panel-body {
  border: var(--accordin-color) solid 0.5px;
  border-radius: 4px;
}

.failuremodulePage .panel-title,
.failuremodulePage .grid-label {
  font-family: "Montserrat-Medium";
  font-size: 1rem;
}

.failuremodulePage .panel-default>.panel-heading+.panel-collapse>.panel-body input,
.failuremodulePage .panel-default>.panel-heading+.panel-collapse>.panel-body select,
.failuremodulePage textarea {
  background: var(--grid-odd-color);
  border: #5D5E60 solid 0.5px;
  color: var(--primary-font-color);
  height: 2.66rem !important;
  border-radius: 4px;
  padding: 0.33rem 1rem;
  font-family: "Montserrat-Regular";
  font-size: 0.83rem !important;
  outline: none;
}

.light .failuremodulePage .panel-default>.panel-heading+.panel-collapse>.panel-body input,
.light .failuremodulePage .panel-default>.panel-heading+.panel-collapse>.panel-body select,
.light .failuremodulePage textarea {
  background: transparent;
  border-color: #a6aab4;
  color: #3b414b;
}

.failuremodulePage .MuiInput-underline:after {
  display: none !important;
}

.failuremodulePage .customchecks .form-group input:checked+label:after {
  top: 0.07rem;
  border: var(--primary-background) solid 0.1rem;
  left: 0.0611rem;
  width: 1.12rem;
  height: 1.12rem;
}

.light .failuremodulePage .customchecks .form-group input:checked+label:after {
  top: 0.07rem;
  border: #ffffff solid 0.1rem;
  left: 0.0611rem;
  width: 1.12rem;
  height: 1.12rem;
}

.light .failuremodulePage .pannelWrap .panel-default:nth-child(2n + 1) .panel-heading {
  background: #fafbfc !important;
}

.failuremodulePage .MuiInput-underline:hover:not(.Mui-disabled):before,
.failuremodulePage .MuiInput-underline:before {
  border-bottom: none !important;
}

.failuremodulePage .btn-primary,
.ant-btn-primary,
.failuremodulePage .ant-modal-footer .ant-btn.ant-btn-primary {
  color: var(--btn-primary-color) !important;
  font-family: "Montserrat-Medium";
  border-color: var(--btn-primary) !important;
  background-color: var(--btn-primary) !important;
  border-radius: 6px !important;
}

.ant-btn-group .ant-btn-primary {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.ant-btn-group .ant-btn-icon-only {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  border-top-right-radius: 6px !important;
  border-bottom-right-radius: 6px !important;
  min-width: 1rem !important;
}

.failuremodulePage .btn-primary:hover,
.failuremodulePage .btn-primary:focus,
.failuremodulePage .btn-primary:active,
.failuremodulePage .ant-modal-footer .ant-btn.ant-btn-primary:hover,
.failuremodulePage .ant-modal-footer .ant-btn.ant-btn-primary:focus,
.failuremodulePage .ant-modal-footer .ant-btn.ant-btn-primary:active .failuremodulePage .ant-btn-primary:hover,
.failuremodulePage .ant-btn-primary:focus,
.failuremodulePage .ant-btn-primary:active,
.failuremodulePage .ant-btn-primary:hover {
  background-color: var(--btn-primary-hover) !important;
  border-color: var(--btn-primary-hover) !important;
}

.failuremodulePage .btn-primary:hover,
.failuremodulePage .btn-primary:focus,
.failuremodulePage .btn-primary:active {
  background-color: var(--btn-primary);
  border-color: var(--btn-primary);
}

.failuremodulePage .btn.add-entry-button {
  background: var(--accordin-color);
  color: #5d97f6 !important;
  margin-left: 10px;
  height: 2.7em;
  padding: 0.3rem 1rem;
  font-size: 0.93rem;
}

.failuremodulePage .btn.add-entry-button:hover,
.failuremodulePage .btn.add-entry-button:focus,
.failuremodulePage .btn.add-entry-button:active {
  background: var(--accordin-color);
  color: #6AA2FF !important;
}

.light .failuremodulePage .btn.add-entry-button:hover,
.light .failuremodulePage .btn.add-entry-button:focus,
.light .failuremodulePage .btn.add-entry-button:active {
  color: #6AA2FF !important;
}

.light .failuremodulePage .btn.add-entry-button {

  background: #edf0f3;
  border-color: #edf0f3;
  margin-left: 10px;
  height: 2.366rem;
}

.light .failuremodulePage .btn.add-entry-button:hover,
.light .failuremodulePage .btn.add-entry-buttonfocus,
.light .failuremodulePage .btn.add-entry-buttonactive {
  background: #edf0f3 !important;
  color: #6AA2FF !important;
}

.failuremodulePage .btn-outline {
  background: transparent;
  color: var(--btn-primary);
}

.failuremodulePage .btn-outline:hover,
.failuremodulePage .btn-outline:focus,
.btn-outline:active {
  background: #272727;
  color: var(--primary) !important;
}

.light .failuremodulePage .btn-outline {
  background: transparent;
  color: var(--primary);
}

.failuremodulePage .btn svg {
  margin-right: 0.93rem;
  height: 0.93rem;
  width: 0.93rem;
  position: relative;
  top: 0.1rem;
}

.failuremodulePage .btn.add-entry-button svg path {
  stroke: #5d97f6 !important;
}

.failuremodulePage .btn.add-entry-button:hover svg path,
.failuremodulePage .btn.add-entry-button:focus svg path,
.failuremodulePage .btn.add-entry-button:active svg path {
  stroke: #6AA2FF !important;
}

.failuremodulePage .btn-secondary,
.failuremodulePage .ant-btn-button {
  background: var(--btn-secondary);
  border: var(--btn-secondary);
  color: var(--btn-secondary-color);

}

.failuremodulePage .ant-modal-footer .ant-btn {
  background: var(--btn-secondary);
  border: var(--btn-secondary);
  color: var(--btn-secondary-color);
  margin-right: 1.66rem;
}

.failuremodulePage .btn-secondary:hover,
.failuremodulePage .btn-secondary:focus,
.failuremodulePage .btn-secondary:active,
.ant-btn-button:hover,
.ant-btn-button:focus,
.ant-btn-button:active,
.failuremodulePage .ant-modal-footer .ant-btn:active,
.failuremodulePage .ant-modal-footer .ant-btn:hover,
.failuremodulePage .ant-modal-footer .ant-btn:focus {
  background: var(--btn-secondary-hover);
  border: var(--btn-secondary-hover);
  color: var(--btn-secondary-color);
}

.failuremodulePage .ant-modal-footer .ant-btn {
  width: 8.33rem;
}

.failuremodulePage .btn img {
  margin: 0 0.3rem;
  height: 1rem;
  position: relative;
  bottom: 0.1rem;
}

.failuremodulePage .recommended .btn {
  width: auto !important;
  min-width: auto !important;
  height: 2.66rem;
  padding: .5rem 0.7rem;
}

.light .failuremodulePage .recommended .btn {
  width: auto !important;
  min-width: auto !important;
  height: 2.66rem;
  background: #edf0f3;
  border-color: #B6B6B7;
  margin-right: 0;
}

.failuremodulePage .panel-default>.panel-heading+.panel-collapse.collapse.in>.panel-body {
  border-top-right-radius: 0px !important;
  border-top-left-radius: 0px !important;
  background: var(--grid-odd-color);
  max-height: calc(100vh - 18rem);
  overflow: auto;
  padding: 1.3rem;
}

.failuremodulePage .critical-label {
  margin-left: 0;
}

.failuremodulePage .MuiInputBase-root,
.failuremodulePage .descriptionCreate .MuiInputBase-root {
  background: var(--grid-odd-color);
  border: #5D5E60 solid 0.5px;
  color: var(--primary-font-color);
  height: 2.66rem !important;
  border-radius: 4px !important;
  padding: 0.33rem 1rem !important;
  font-family: "Montserrat-Regular";
  font-size: 0.83rem;
  box-shadow: none;
}

 #failure-mode-form .MuiInputBase-root {
  background: var(--secondary-background);
}

.light .failuremodulePage .MuiInputBase-root,
.light .failuremodulePage .descriptionCreate .MuiInputBase-root {
  background: transparent;
  border: #a6aab4 solid 0.5px;
  color: #3b414b;
}

.failuremodulePage .input-control .MuiAutocomplete-input,
.failuremodulePage .input-control .Mui-focused input {
  font-size: 0.83rem;
  font-family: FontAwesome, Montserrat-Regular;
  padding: 0.41rem 0.83rem;
  margin-top: 0;
  color: var(--primary-font-color);
  background: transparent;
  box-sizing: border-box;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.02);
  border-radius: 4px;
  outline: none;
}

.failuremodulePage .panel-default>.panel-heading+.panel-collapse>.panel-body .input-control input,
.failuremodulePage .MuiInputBase-root input {
  border: none !important;
  height: 1.2rem !important;
}

.failuremodulePage .panel-default>.panel-heading+.panel-collapse>.panel-body .input-control .Mui-focused.Mui-focused.MuiAutocomplete-inputRoot,
.light .failuremodulePage .MuiInputBase-root.Mui-focused,
.failuremodulePage .MuiInputBase-root.Mui-focused {
  border: #5d97f6 solid 0.5px !important;
  box-shadow: none !important;
  outline: none;
}

.failuremodulePage .panel-default>.panel-heading+.panel-collapse>.panel-body .input-control .Mui-focused.Mui-focused fieldset,
.failuremodulePage .panel-default>.panel-heading+.panel-collapse>.panel-body .input-control fieldset,
.failuremodulePage .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border: none;
  box-shadow: none !important;
}

.failuremodulePage .MuiButtonBase-root svg {
  fill: #5d5e60;
}


.failuremodulePage .no-gutters {
  padding: 0 !important;
}

.failuremodulePage .recommended textarea {
  width: calc(100% - 54px);
  float: left;
  margin-right: 10px;
  height: 80px !important;
  word-break: break-word;
}

.failuremodulePage .radio-button.customchecks .form-group label:before {
  border-radius: 1rem !important;
}

.failuremodulePage .recommended img {
  margin: 0;
  ;
}


.failuremodulePage .critical-label>.icon::before {
  border-color: #131212 !important;
}

.failuremodulePage .critical-label>.icon {
  background: #3b3b3b;
}

.light .failuremodulePage .critical-label>.icon {
  background: #ffffff;
}

.failuremodulePage .tooltip_formula {
  cursor: pointer;
}

.failuremodulePage .recommended-action-list {
  color: var(--primary-font-color);
}

.failuremodulePage .recommended-action-list .card {
  background: var(--accordin-color);
  border-radius: 4px;
  padding: 10px 10px 5px;
  margin-top: 10px;
}

.failuremodulePage .formula-dropdown ul {
  background: var(--primary-background) !important;
}

.failuremodulePage .formula-dropdown ul li {
  color: var(--primary-font-color);
}

.failuremodulePage .recommended-action-list .card svg {
  width: 1.1rem;
  height: 1.1rem;
  cursor: pointer;
}

.failuremodulePage .recommended-action-list .card .fa {
  cursor: pointer;
}

.light .failuremodulePage .recommended-action-list .card {
  background: #edf0f3;
}

.failuremodulePage .mb-15 {
  margin-bottom: 15px;
}

.failuremodulePage .grid-header .btn {
  margin-left: 1.66rem;
}

/* Autocomplete */

.failuremodulePage .input-control .form-group input {
  background: transparent;
  border: none;
}

.failuremodulePage .input-control .input-group .caret {
  /* content: "^"; */
  /* background: url("../images/dropdownArrowDown.png") center center transparent no-repeat; */
  transition: transform 0.5s ease-in-out;
  color: transparent;
  position: absolute;
  top: 50%;
  right: 0.83rem;
  transform: translateY(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  border: 0;
}

.failuremodulePage .input-control .comboDiv.datepick .Mui-focused input {
  padding: 0.41rem 0;
}

.failuremodulePage .input-control .comboDiv.datepick .MuiInputBase-input,
.failuremodulePage .MuiInputBase-root {
  color: var(--primary-font-color);
}

.failuremodulePage .customchecks .form-group {
  margin-bottom: 0;
  ;
}

.light .failuremodulePage .input-control .comboDiv.datepick .MuiInputBase-input,
.failuremodulePage .MuiInputBase-root {
  color: #3b414b;
}

.failuremodulePage .input-control .comboDiv .date_range .MuiInputBase-input,
.failuremodulePage .input-control .comboDiv .date_range .Mui-focused input,
.failuremodulePage .input-control .comboDiv .date_range .MuiInputBase-root {
  padding: 0.08rem 0;
  width: 100% !important;
}

.failuremodulePage .input-control .comboDiv .date_range .MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-formControl {
  padding: 1px 0;
}

.failuremodulePage .input-control .comboDiv .date_range .MuiInputBase-root.Mui-focused.MuiInput-root.MuiInput-underline.MuiInputBase-formControl {
  min-width: 14.66rem;
}

.failuremodulePage .input-control .comboDiv .date_range svg {
  fill: var(--primary-font-color);
}

.light .failuremodulePage .input-control .comboDiv .date_range svg {
  fill: #3b414b;
}

.light .failuremodulePage .input-control .MuiAutocomplete-input,
.light .failuremodulePage .input-control .Mui-focused input {
  color: #3b414b;
}

.failuremodulePage .comboDiv .input-group [aria-expanded="true"] .caret {
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
}

.failuremodulePage .MuiAutocomplete-listbox {
  width: 100%;
  border-radius: 0 0 4px 4px;
  margin: -0.16rem 0 0;
  max-height: 21vh;
  overflow: auto;

}

.failuremodulePage .MuiAutocomplete-option[data-focus="true"] a {
  color: white;
  text-decoration: none;
}

.failuremodulePage .MuiAutocomplete-option a {
  color: white;
  text-decoration: none;
}

.light .failuremodulePage .MuiAutocomplete-option a {
  color: black;
  text-decoration: none;
}

.failuremodulePage .input-control .input-group {
  width: 100%;
  box-sizing: border-box;

}

.failuremodulePage .dropdown.disabledDropDown .borderInput,
.failuremodulePage .disabledDropDown .btn-default.disabled.focus,
.failuremodulePage .disabledDropDown .btn-default.disabled:focus,
.failuremodulePage .disabledDropDown .btn-default.disabled:hover,
.failuremodulePage .disabledDropDown .btn-default[disabled].focus,
.failuremodulePage .disabledDropDown .btn-default[disabled]:focus,
.failuremodulePage .disabledDropDown .btn-default[disabled]:hover,
.failuremodulePage .disabledDropDown fieldset[disabled] .btn-default.focus,
.failuremodulePage .disabledDropDown fieldset[disabled] .btn-default:focus,
.failuremodulePage .disabledDropDown fieldset[disabled] .btn-default:hover {
  background-color: #3b3b3b;
}

.failuremodulePage .disabledCls {
  cursor: not-allowed;
}

.failuremodulePage .disabledDropDown .disabledCls {
  cursor: not-allowed;
}

.failuremodulePage .disabledAuto .MuiAutocomplete-input {
  cursor: not-allowed;
}

.failuremodulePage .noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.failuremodulePage .enterGroupName {
  position: absolute;
  z-index: 9;
  width: 16.66rem;
  left: 20.83rem;
  top: 0;
}

.failuremodulePage ul.enterGroupNameUL {
  padding: 0;
  margin: 0;
  list-style: none;
}

.failuremodulePage .enterGroupName .inputDiv.form-control {
  background: #2a2a2a;
  border-radius: 4px !important;
  padding-top: 0;
  padding-bottom: 0;
  height: 2.33rem;
  border-color: #2a2a2a;
  font-size: 0.83rem;
  color: #fff;
}

.light .failuremodulePage .enterGroupName .inputDiv.form-control {
  background: #fff;
  color: #000;
}

.failuremodulePage .tabArea .nav-tabs.tabUlsInner.margTab a {
  margin: 0.25rem 0.83rem;
}

.failuremodulePage ul.nav.nav-tabs.tabsName.tabUlsInner.margTab {
  height: 3.16rem;
}

.failuremodulePage .ddivClsMargin .input-group {
  display: block;
}

.failuremodulePage .titleClass {
  font-family: Montserrat-Medium;
  text-align: left;
  font-size: 1rem;
  color: var(--primary-font-color);
  margin-bottom: 0.83rem;
  margin-top: 0.5rem;
}

.light .failuremodulePage .titleClass {
  color: #58585a;
}

.failuremodulePage .MuiButtonGroup-contained .floatLeft {
  float: left;
  margin-top: 1.66rem;
  height: 2.33rem;
  padding: 0.5rem 0;
  width: 8.33rem;
  font-family: montserrat-Regular;
}

.failuremodulePage .clsWrapCheck .MuiButtonGroup-contained,
.failuremodulePage .input-control .MuiButtonGroup-contained {
  box-shadow: none;
}

.failuremodulePage .input-control .MuiInputBase-input,
.failuremodulePage .MuiInputBase-root {
  padding: 0.08rem 0;
  font-size: 0.83rem;
  font-family: "Montserrat-Regular";
}

.failuremodulePage .input-control .MuiInput-underline:after,
.failuremodulePage .input-control .MuiInput-underline:before,
.failuremodulePage .input-control .MuiInput-underline:hover:not(.Mui-disabled):before {
  border-bottom: 0;
}

.failuremodulePage .MuiSwitch-sizeSmall {
  margin-left: 4.66rem;
}

.failuremodulePage ::-webkit-calendar-picker-indicator {
  filter: invert(1);
  cursor: pointer;
}

.failuremodulePage .ddivClsMargin input,
.failuremodulePage .form-control.customForm,
.failuremodulePage .searchParatr,
.failuremodulePage .MuiAutocomplete-root {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.failuremodulePage .leftSideVerticalTab span.TabTxt {
  margin-left: 1rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: block;
  margin-right: 1rem;
}

.failuremodulePage .DatePickers-container-5 label+.MuiInput-formControl {
  margin-top: 0;
}

.failuremodulePage .DatePickers-textField-6 .MuiFormLabel-root {
  display: none;
}

.failuremodulePage label.font10.mainDateRange {
  display: block;
}

.failuremodulePage .input-control .DatePickers-textField-6 .MuiAutocomplete-input,
.failuremodulePage .input-control .DatePickers-textField-6 .Mui-focused input {
  padding: 1px 0;
}

.failuremodulePage .input-control .Mui-focused input[type="date"] {
  padding: 1px 0;
}

.failuremodulePage .input-control.MuiInputBase-root input[value=""],
.failuremodulePage .panel-default>.panel-heading+.panel-collapse>.panel-body .MuiInputBase-root input[value=""] {
  color: #a0a0a0 !important;
  border: none !important;
  height: 2rem !important;
}

.failuremodulePage .MuiInputBase-root input[value=""]::placeholder {
  color: #8f8f8f !important;
  font-size: 0.83rem;
  font-family: "Montserrat-Regular";
  opacity: 1 !important;
}

.notification-group .alertDivs .row-0 {
  width: 30% !important;
  text-align: left;
  padding-left: 0.5rem;
}

.notification-group .alertDivs .row-1 {
  width: 50% !important;
  text-align: left;

}

.notification-group .alertDivs .row-2 {
  width: 20% !important;
  text-align: left;
  float: left;
}

.notification-group .alertDivs .row-2 span {
  color: #5d97f6;
  cursor: pointer;
}

.failuremodulePage.notification-group .panel-default>.panel-heading+.panel-collapse.collapse.in>.panel-body {

  max-height: calc(100vh - 10rem);
}

.failuremodulePage .valid-formula,
.failuremodulePage .invalid-formula {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 5px;
}

.failuremodulePage .valid-formula {
  background-color: lawngreen;
}

.failuremodulePage .invalid-formula {
  background-color: red;

}

.contact-details {
  border-top: var(--accordin-color) solid 1px;
  padding-top: 1rem;
  margin-top: 1rem;
}

.notification-group .contact-details .alertDivs .row-1 {
  width: 15% !important;
  text-align: left;
}

.notification-group .contact-details .alertDivs .row-2 {
  width: 15% !important;
  text-align: left;
}

.notification-group .contact-details .alertDivs .row-3 {
  width: 20% !important;
  text-align: left;
}

.notification-group .contact-details .alertDivs .row-4 {
  width: 20% !important;
  text-align: left;
}

.notification-group .contact-details .alertDivs .row-5 {
  width: 20% !important;
  text-align: left;
}

.notification-group .contact-details .alertDivs .row-6 {
  width: 10% !important;
  float: left;
  text-align: left !important;
}

.notification-group .contact-details .alertDivs .row-6 span {
  cursor: pointer;
  color: #5d97f6;
}

.notification-group .contact-details {
  border-top: var(--border-color) solid 1px;
}

.contact-details .font12 {
  font-weight: inherit;
  padding-top: 10px;
}

.contact-details .child-header {
  padding: 10px 0;
}

.failuremodulePage.notification-group .contact-details .overflowScrolls {
  height: calc(100vh - 32.5rem);
}

.failuremodulePage.notification-group .contact-details .pannelWrap.overflowScrolls {
  height: calc(100vh - 30rem);
}

.failuremodulePage.notification-group .contact-details .alertDivsBg {

  border-radius: 4px;
  height: calc(100vh - 27rem);
}

.failuremodulePage.notification-group .contact-details .alertDivs {
  height: calc(100vh - 30rem);
}

.notification-group .dropdown .btn-default,
.notification-group .dropdown .btn-default:focus,
.notification-group .dropdown .btn-default:hover,
.notification-group.dropdown .btn-default.active,
.notification-group .dropdown .btn-default:active,
.notification-group .open>.dropdown-toggle.btn-default {
  height: 2.66rem;
}

.notification-group .comboDiv>div>div>div {
  padding: 0;
}

.failuremodulePage .descriptionCreate .btn-default.dropdown-toggle {


  height: 2.66rem !important;
  border-radius: 4px;
  padding: 6px 12px !important;
  font-family: "Montserrat-Regular";
  font-size: 0.83rem;
}

.failuremodulePage .descriptionCreate .comboDiv>div>div>div {
  padding-right: 0;
}

.failuremodulePage .confirmPin .footerPopupWithBtn .saveBtn:hover,
.failuremodulePage .confirmPin .footerPopupWithBtn .saveBtn:focus {
  background-color: #6AA2FF !important;
}

.failuremodulePage .alertDivs .headerTable span:nth-child(3),
.failuremodulePage .alertDivs .alarmRows span:nth-child(3) {

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 20%;
}

.failuremodulePage .alertDivs .headerTable span:nth-child(4),
.failuremodulePage .alertDivs .alarmRows span:nth-child(4) {

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 40%;
}

.failuremodulePage .alertDivs span {
  cursor: pointer;
}

.failuremodulePage .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child {
  padding-left: 0;
}

.notification-group>div.panel.panel-default.alarmRows>div.panel-collapse.collapse.in>div>div>div:nth-child(2)>div.undefined>div>div.col-md-4>div>.redStar {
  font-size: 1.18rem !important;
}


.notification-template .alertDivs .row-0 {
  width: 20% !important;
  text-align: left;
  padding-left: 0.5rem;
}

.notification-template .alertDivs .row-1 {
  width: 30% !important;
  text-align: left;

}

.notification-template .alertDivs .row-2 {
  width: 30% !important;
  text-align: left;
}

.notification-template .alertDivs .row-3 {
  width: 20% !important;
  text-align: left;
  float: left;
}

.notification-template .alertDivs .row-3 span {
  color: #5d97f6;
  cursor: pointer;
}

#template-field>div>div {
  font-size: 0.83rem !important;
  height: 100px !important;

}

#template-field>div>div>textarea {
  font-size: 0.83rem !important;
  padding: 0.75rem 1rem !important;
  border-radius: 4px;
  outline: none;
  height: 100px !important;
}

#template-field>div>div>textarea:focus {
  border-color: #5d97f6 !important;
}

#template-field>div>div:nth-child(2)>ul>li>div>div {
  float: right;
  font-size: .8rem;
  padding: 6px 12px !important;
}

#edit-notification-group-fields>div>.error-field>div>div>form>#template-field>div>div>textarea,
.failuremodulePage .error-field textarea,
#edit-notification-group-fields>div>div>.error-field,
#edit-notification-group-role-field>.error-field,
#notification-group-name-text>.error-field,
#notification-group-name-text .error-field input,
#notification-group-name-text .error-field textarea {
  border: 0.5px solid #ea4335 !important;
  outline: none;
}

.failuremodulePage .mb-8 {
  margin-bottom: 3rem !important;
}

.failuremodulePage .delete .descriptionCreate,
.failuremodulePage .delete .modelboxopenInnner {
  height: auto !important;
}

.failuremodulePage .delete .descriptionCreate .col-md-12 {
  border: none;
}

#edit-notification-group-fields .text-area-color div div:nth-child(2),
#edit-notification-group-fields .text-area-color div div:nth-child(2) ul {
  height: 200px !important;
  overflow: auto !important;
}

.light .failuremodulePage #template-field>div>div:nth-child(2)>ul {
  background: #fff !important;
}

.light .failuremodulePage .modelboxopen .text-area-color div div:nth-child(2) ul {
  background: #f1f1f1 !important;
  box-shadow: rgba(0, 0, 0, 0.25) 2px 2px;
}

.failuremodulePage .text-area-color div div:nth-child(2),
.failuremodulePage .text-area-color div div:nth-child(2) ul {
  height: 200px !important;
  overflow: auto !important;
}

.light .failuremodulePage .text-area-color div div:nth-child(2) ul {
  background: #f1f1f1 !important;
  box-shadow: rgba(0, 0, 0, 0.25) 2px 2px;
}

.failuremodulePage .text-area-color ul li div,
.failuremodulePage .text-area-color div.user div:nth-child(2) {
  height: auto !important;
}

.failuremodulePage .error-field__input {
  border-color: #ea4335 !important;
}

.failuremodulePage .redStar {
  color: #ea4335;
  font-size: 1.83rem;
  padding: 0 0.08rem;
  position: relative;
  top: 0.33rem;
  line-height: 0px;
}

.failuremodulePage input.error-field,
.failuremodulePage select.error-field {
  border: 0.5px solid #ea4335 !important;
}

.failuremodulePage [type="radio"]:checked+label,
.failuremodulePage [type="radio"]:not(:checked)+label {
  color: var(--primary-font-color);
}

.light .failuremodulePage [type="radio"]:checked+label,
.failuremodulePage [type="radio"]:not(:checked)+label {
  color: #666666;
}

.toolstips.MuiTooltip-popper .fia-desc-head {
  font-size: 1rem;
  font-family: "Montserrat-Medium" !important;
}

.toolstips.MuiTooltip-popper .MuiTooltip-tooltip {
  padding: 1rem !important;
  background: #000000;
  border-radius: 0.4rem !important;
  line-height: 20px !important;
  max-width: 500px !important;
  font-family: "Montserrat-Regular" !important;
}

.light .toolstips.MuiTooltip-popper .MuiTooltip-tooltip {

  background-color: #fff;
  box-shadow: -4px -4px 8px -1px rgba(0, 0, 0, 0.08), 4px 4px 8px -1px rgba(0, 0, 0, 0.08);
  color: #2a2a2a;

}

.toolstips.MuiTooltip-popper span {
  font-size: 0.83rem;
  font-family: "Montserrat-Regular" !important;

}

.notification-template-editor .formula-dropdown div:nth-child(1) div:nth-child(1) div:nth-child(1) {
  height: 200px !important;
}

#notification-dialog>div>div>div.contentPopup>div>div>div>div>div>div.enterpins.placeColor>div {
  position: relative;
}

.failuremodulePage .contact-details .headerTable {
  background: var(--secondary-background);
  border-radius: 4px;
}

.failuremodulePage .contact-details .pannelWrap .panel-default:nth-child(2n + 1) .panel-heading,
.failuremodulePage .pannelWrap .panel-default:nth-child(2n + 1) .rowDiv,
.failuremodulePage .pannelWrap .panel-default:nth-child(2n + 1) .rowDiv:nth-child(2n + 1) {

  background-color: #1e1e1e;
  border: 0.5px solid var(--secondary-background);
  border-radius: 0;
}

.failuremodulePage .contact-details .pannelWrap .panel-default>.panel-heading,
.failuremodulePage .kpiPara .panel-heading:nth-child(2n) {
  background: var(--secondary-background);
  border: none;
  border-radius: 0;
}

.light .failuremodulePage .contact-details .headerTable {
  background: #fafbfc !important;
}

.light .failuremodulePage .contact-details .pannelWrap .panel-default:nth-child(2n + 1) .panel-heading,
.light .failuremodulePage .pannelWrap .panel-default:nth-child(2n + 1) .rowDiv,
.light .failuremodulePage .pannelWrap .panel-default:nth-child(2n + 1) .rowDiv:nth-child(2n + 1) {
  background-color: #ffffff !important;
  border: 0.5px solid #ffffff;
}

.light .failuremodulePage .contact-details .pannelWrap .panel-default>.panel-heading,
.light .failuremodulePage .kpiPara .panel-heading:nth-child(2n) {
  background: #fafbfc !important;
}

.notification-template-editor .radio-label label.font14 {
  font-size: 0.83rem;
}

.failuremodulePage .MuiAutocomplete-popupIndicator svg {
  display: none !important;
}

.failuremodulePage .MuiAutocomplete-popupIndicator .MuiIconButton-label::after,
.failuremodulePage .saveDraftBtn::after {
  display: inline-block;
  content: "";
  background: url('../images/caret-1920.svg') no-repeat center;
  color: #000;
  line-height: 1;
  width: 0.8rem;
  height: 1rem;
  background-size: contain;

}

.failuremodulePage .saveDraftBtn::after {
  margin-top: .5rem;
  display: inline-block;
  content: "";
  background: url('../images/downarror.svg') no-repeat center;
  line-height: 1;
  width: 0.8rem;
  height: 1rem;
  background-size: contain !important;
}

.light .failuremodulePage .saveDraftBtn::after {
  background: url('../images/downarror-light.svg') no-repeat center;
}

.failuremodulePage .ant-dropdown-open .saveDraftBtn {
  transform: rotate(180deg);
}

.failuremodulePage .radio-label label {
  padding-left: 1.6rem !important;
}




/*Multiselect dropdwon style*/

.MuiMenu-paper {
  outline: 0;
  background: var(--grid-odd-color) !important;


}

.light .MuiMenu-paper {
  background: #ffffff !important;

}

.MuiMenu-list .MuiTypography-body1 {
  color: #fff;
  font-family: "Montserrat-Regular";
  font-size: 0.83rem;
}

.MuiOutlinedInput-notchedOutline {
  border: none;
}

.light .MuiMenu-list .MuiTypography-body1 {
  color: #3b414b;

}

.MuiSelect-icon {
  color: #ffffff !important;
}

.light .MuiSelect-icon {
  color: #3b414b !important;
}
/* .failuremodulePage .fn-tableData  .MuiCheckbox-colorSecondary.Mui-checked {
  color: #5d97f6 !important;
} */
/* .fn-tableData .MuiCheckbox-root {
  color: var(--primary) !important;
} */
.failuremodulePage .fn-tableData .Mui-checked.MuiButtonBase-root svg {
  fill:#5d97f6;
}
.failuremodulePage .fn-tableData .MuiCheckbox-colorSecondary.Mui-checked {
  color: var(--primary) !important;
}
.MuiCheckbox-colorSecondary.Mui-checked {
  color: #5d97f6 !important;
}

.MuiCheckbox-root {
  color: #8f8f8f !important;
}

.MuiInput-underline.Mui-focused:after {
  display: none !important;
}

.light .MuiIconButton-label {
  color: var(--btn-primary) !important;
}

.MuiIconButton-colorSecondary:hover {
  background-color: rgba(93, 151, 246, 0.04) !important;
}

.MuiSelect-select:focus {
  background: transparent !important;
}

.MuiSelect-select.MuiSelect-select {
  color: var(--primary-font-color);
}

.custom-multiselect-mui .MuiSvgIcon-root.MuiSelect-icon path {
  display: none;
}

.custom-multiselect-mui svg.MuiSvgIcon-root.MuiSelect-icon {
  display: inline-block;
  background: url('../images/caret-1920.svg') no-repeat center;
  color: #000;
  line-height: 1;
  width: 0.8rem;
  height: 1rem;
  background-size: contain;
  margin-right: 0.4rem;
  margin-top: 0.3rem;
}

/*Multiselect dropdwon style*/
.failuremodulePage .MuiInputBase-root svg path {
  display: none;
}

.failuremodulePage .MuiInputBase-root .MuiSelect-icon {
  display: inline-block;
  background: url('../images/caret-1920.svg') no-repeat center;
  line-height: 1;
  width: 0.75rem;
  height: 1rem;
  background-size: contain;
  margin-right: 0.6rem;
  margin-top: 0.3rem;
}

.tooltip_formula svg {
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  margin-top: -0.3rem;
}

.tooltip_formula svg:hover path {
  fill: var(--btn-primary);
  stroke: #ffffff;
}

.tooltip_formula svg:hover path:first-child {
  stroke: none;
}

.notification-group .MuiInputBase-root {
  height: 2.66rem;
}

.notification-template-editor svg {
  margin-top: 0.7rem;
}

.failuremodulePage.descriptionCreate .drop-text {
  font-size: 0.83rem;
}


.delete-modal .footerPopupWithBtn .cancelBtn:hover {
  color: var(--primary-font-color) !important;
  background-color: var(--secondary-background) !important;
  border-color: #3e3e3e;
}

.delete-modal .footerPopupWithBtn .cancelBtn:active,
.delete-modal .footerPopupWithBtn .cancelBtn:focus,
.delete-modal .footerPopupWithBtn .cancelBtn.active {
  background: var(--accordin-color) !important;
  border-color: var(--accordin-color);
  color: var(--primary-font-color) !important;
}

.failuremodulePage #failure-mode-form .panel-default>.panel-heading+.panel-collapse.collapse.in>.panel-body {
  max-height: calc(100vh - 18rem);
}

.Addfailurenotification.failuremodulePage #failure-mode-form .panel-default>.panel-heading+.panel-collapse.collapse.in>.panel-body {
  max-height: calc(100vh - 14rem);
}

.failuremodulePage .recommended-action-list label:first-child {
  width: calc(100% - 6rem);
  word-break: break-word;
}

.failuremodulePage .link.edit svg {
  width: 1.1rem;
}

.failuremodulePage .sub-grid .headerTable {
  background: var(--accordin-color) !important;
  border-radius: 4px;
}

.failuremodulePage .sub-grid {
  float: left;
  width: calc(100% - 30px);
  border: var(--accordin-color) solid 1px;
  border-radius: 4px;
  margin-left: 15px;
  margin-bottom: 15px;
}

.light .failuremodulePage .sub-grid .headerTable {
  background: #ffffff !important;
}

.light .failuremodulePage .sub-grid {
  border: 1px solid var(--primary-font-color);
}
.failuremodulePage .fn-tableData .row1 {
  width: 0 !important;
}  
.failuremodulePage .row1 {
  width: 33.33% !important;
  display: block !important;
  float: left !important;
  text-align: left !important;
  margin-left: 0.83rem;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.failuremodulePage .row1 .failure-mode-listing-desc {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 0.4rem;
  padding-top: 0.4rem !important;

}

.failuremodulePage .row1 p.failure-mode-listing-sub-desc {
  font-size: 0.7rem;
  font-family: "Montserrat-Light";
}

.failuremodulePage .hover-row .panel.panel-default.alarmRows .panel-heading:hover {
  background-color: var(--accordin-color) !important;
  box-shadow: #04040426 -1px 2px 6px 2px;
  z-index: 1;
  position: relative;
}

.failuremodulePage .panel-default>.panel-heading .badge {
  color: var(--primary-font-color);
  background-color: var(--accordin-color);
  width: auto !important;
  font-family: 'Montserrat-Regular';
  font-weight: inherit;
  font-size: 0.83rem;
}

.failuremodulePage .panel-default>.panel-heading .badge span {
  padding: 0 !important
}

.failuremodulePage .row2,
.failuremodulePage .monitoringcontentWrap .row3,
.failuremodulePage .monitoringcontentWrap .row4,
.failuremodulePage .monitoringcontentWrap .row5,
.failuremodulePage .monitoringcontentWrap .row6,
.failuremodulePage .monitoringcontentWrap .row7 .failuremodulePage .monitoringcontentWrap .row8 {
  width: 11% !important;
  display: block !important;
  float: left !important;
  text-align: left !important;
}

.failuremodulePage .monitoringcontentWrap .row6 label {
  padding: 0 !important;
  margin: 0 !important;
}

.failuremodulePage .sub-grid .panel-heading {
  padding: 1rem;
  float: left;
  width: 100%;
}

.failuremodulePage .edit {
  cursor: pointer;
}

.failuremodulePage .edit svg:hover path {
  stroke: var(--primary);
}

.ant-drawer-close {
  order: 2;
  content: '&#x2715';
  color: var(--primary-font-color);
}

.notification-group.failuremodulePage .borderInput .caret::after {
  display: inline-block;
  background: url('../images/caret-1920.svg');
  line-height: 1;
  width: 0.75rem;
  height: 1rem;
  background-size: contain;
  content: '';
  padding: 0;
  margin: 0.7rem 0 0;
  background-repeat: no-repeat;
  left: 0.23rem;
  position: relative;
}

.failuremodulePage .error-field .MuiInputBase-root {
  border: 0.5px solid #ea4335 !important;
}

.failuremodulePage .panel-default>.panel-heading+.panel-collapse>.panel-body textarea {
  padding-top: 0.75rem !important;
}

.failuremodulePage .form-control.customForm,
.notification-template-editor .form-control.customForm {
  font-size: 0.83rem;
}

@media (max-width:1599px) {

  .failuremodulePage .panel-default>.panel-heading.active:after,
  .failuremodulePage .panel-default>.panel-heading:after {
    background-size: contain;
    font-size: 1px;
  }
}

.failuremodulePage .detailPage {
  background: transparent;
}

.failuremodulePage .fm-container {
  background: var(--bg);
  color: var(--primary-font-color);
  border-radius: 4px;
  padding: 1rem;
}

.failuremodulePage .potential-cause-container,
.failuremodulePage .details-container {
  border: var(--btn-secondary-hover) solid 1px;
  border-radius: 4px;
  padding: 1rem;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  font-size: 0.83rem;
}

.failuremodulePage .grid-header {
  font-size: 1rem;
  height: 2.2rem;
  display: flex;
  align-items: center;
}

.failuremodulePage .rpn-box {
  background: var(--grid-odd-color);
  padding: 0.5rem 1rem;
  border-radius: 4px;
  margin-left: 1rem;
}

.failuremodulePage .status-badge.potential-badge {
  background: var(--grid-odd-color);
  padding: 0.4rem 1rem;
  margin-right: 0.5rem;
  color: var(--primary-font-color);
  font-weight: 100;
  margin-bottom: 0.5rem;
  font-size: 0.79rem;
}

.failuremodulePage .potential-cause-container .edit svg {
  top: 0.2rem;
  position: relative;
  height: 1.1rem;
  padding: 0;
  margin: 0;
  width: 1.1rem;
  object-fit: contain;
}

.failuremodulePage .recommended-action-list .card {
  background: var(--primary-background);
  border-radius: 4px;
  padding: 0.5rem 1rem;
  margin-top: 0.5rem;
  color: var(--primary-font-color);
  word-wrap: break-word;
}

.failuremodulePage .recommended-action-list .card label {
  font-weight: 300;
  padding-bottom: 0 !important;
  margin-bottom: 0 !important;
}

.failuremodulePage .recommended-action-list .card .pull-right.edit {
  width: 65px;
  cursor: pointer;
}

.failuremodulePage .row6 span {
  padding: 0 !important;
  height: 1.16rem !important;
  width: 1.16rem !important;

}

.failuremodulePage .dotted-box {
  border-radius: 4px;
  border: var(--primary-font-color) dashed 1px;
  width: 100%;
  text-align: center;
  background: var(--accordin-color);
  cursor: pointer;
}

.failuremodulePage .dotted-box label {
  cursor: pointer;
}

.failuremodulePage .dotted-box:hover {
  background: var(--grid-even-color);
}

.failuremodulePage .potential-cause-card {
  border-radius: 4px;
  background: var(--primary-background);
  padding: 1rem;
  color: var(--primary-font-color);
}

.failuremodulePage .w-100 {
  width: calc(100% - 50rem);
  margin-right: 1rem;
}

.failuremodulePage .ant-drawer-header {
  border-color: var(--btn-secondary-hover);
}

.ant-menu {
  background: transparent !important;
}

.failuremodulePage .fia-desc div {
  font-size: 1rem;
  margin-top: 1rem;
}

.failuremodulePage .fia-desc span {
  font-size: 0.83rem;
  font-family: 'Montserrat-Light';
}

.failuremodulePage .tree-trunk {
  padding-top: 10px;
  border-left: 1px solid;
  margin-top: -5px;
  margin-left: 6px
}

.failuremodulePage .tree-trunk>div {
  line-height: 4px;
  text-indent: -31px;
  margin-left: 31px;
}

.failuremodulePage .tree-branch-content {
  line-height: 27px;
  text-indent: 0;
  margin-left: 4px
}

.failuremodulePage .tree-branch {
  display: inline-block;
  width: 30px;
  border-bottom: 1px solid;
  vertical-align: middle;
}

.failuremodulePage .model-container .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
  height: 2.2rem !important;
}

.failuremodulePage .model-container .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child {
  padding: 0;
  color: var(--primary-font-color);
}

.failuremodulePage .potential-cause-card p {
  font-size: 0.83rem;
  font-family: 'Montserrat-Light';
  color: var(--primary-font-color);
}

.failuremodulePage .potential-cause-card label {
  font-size: 0.83rem;
  font-family: 'Montserrat-Regular';
}

.failuremodulePage .formula-dropdown strong {
  background: transparent !important;
}

.failuremodulePage .recommended.recommended-action-list .card {
  background: var(--secondary-background);
}

.failuremodulePage .sub-grid-label {
  font-size: 0.83rem;
  color: var(--primary-font-color);
  font-family: 'Montserrat-Medium';
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.failuremodulePage .ml-8 {
  margin-left: 0.5rem;
}

.failuremodulePage .detail-content {

  font-size: 0.79rem;
  font-family: 'Montserrat-light';
}

.failuremodulePage .pinErrorTxt {
  color: #ea4335;
}

.ant-modal-title {
  font-size: 1.33rem;
  color: var(--primary-font-color);
  width: calc(100% - 0.83rem);
  font-family: "Montserrat-Medium";


}

.ant-modal-header,
.ant-modal-close-x {
  height: 5rem;
  align-items: center;
  display: flex;
  background: var(--bg);
  border-bottom: 1px solid #313131;
  color: var(--primary-font-color);
}

.ant-modal-header,
.ant-modal-close-x:hover {
  color: var(--primary);
}

.light .ant-modal-header,
.light .ant-modal-close-x {
  border-bottom: 1px solid #eeeeee;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
}

.ant-modal-body {
  font-size: 1.16rem;
  color: var(--primary-font-color);
}

.ant-modal-footer {
  border: none;
}

.ant-modal {
  width: 40rem !important;
  border-radius: 6px !important;
}

.ant-modal-content {
  border-radius: 6px !important;
  background: var(--bg);
}

.failuremodulePage .potential-cause-card .recomendedactionlist .recomendedaction.rows {
  max-height: 100px;
  overflow-y: auto;
}

.failuremodulePage .recomendedaction .card {
  background: var(--accordin-color);
  padding: 0.5rem;
  border-radius: 4px;
  margin-bottom: 0.3rem;
  margin-right: 0.4rem;
}

.failuremodulePage .grid-label svg {
  margin-right: 1rem;
  cursor: pointer;
  position: relative;
  top: 0.2rem;
  height: 1.2rem;
  width: 2rem;
}

.failuremodulePage .grid-label svg path {
  fill: var(--primary-font-color);
}

.failuremodulePage .grid-label svg:hover path {
  fill: var(--primary);
}

.failuremodulePage .formula-dropdown textarea {
  height: auto !important;
  overflow: auto;
  min-height: 2.66rem;
}

.failuremodulePage .formula-dropdown .__highlighter {
  height: auto !important;
}


.excelimport .row-0 {
  width: 20% !important;
  text-align: left !important;
}

.excelimport .row-1 {
  width: 20% !important;
  text-align: left !important;
}

.excelimport .row-2 {
  width: 15% !important;
  text-align: left !important;
}

.excelimport .row-3 {
  width: 15% !important;
  text-align: left !important;
}

.excelimport .row-4 {
  width: 15% !important;
  text-align: left !important;
}

.excelimport .row-5 {
  width: 13% !important;
  text-align: left !important;
}

.excelimport .row-6 {
  width: 10% !important;
  text-align: left !important;
}

.excelimport .a .disabled:hover {
  opacity: .8 !important;
  color: var(--primary-font-color) !important;
  cursor: not-allowed !important;
  text-decoration: none !important;
}

.excelimport a[disabled] {
  opacity: .4 !important;
  color: var(--primary-font-color) !important;
  text-decoration: none !important;
}

.excelimport a[disabled]:hover,
.excelimport a[disabled]:active {
  opacity: .4 !important;
  color: var(--primary-font-color) !important;
  cursor: not-allowed !important;
  text-decoration: none !important;
}

.excelimport span:hover {
  color: var(--primary-font-color) !important;
  cursor: default !important;
}

.excelimportpopup .MuiInputBase-root {
  padding-left: 1em !important;
}

.ant-message {
  z-index: 1301 !important;
}

.importfmea .notificationpanel {
  width: 25rem;
  height: 27rem;
  background-color: var(--btn-secondary);
  border-radius: .2rem;
  box-shadow: -4px -4px 8px -1px rgb(0 0 0 / 8%), 4px 4px 8px -1px rgb(0 0 0 / 8%);
  right: 0;
  left: unset;
}

.importfmea .notificationpanel .col-lg-12,
.importfmea .notificationpanel .alertContent.common-scroll,
.importfmea .noNotificationDiv {
  height: 24rem;
}

.importfmea .notificationpanel .alertContent.common-scroll {
  padding-top: .8em;
}

.importfmea .notificationicon {
  padding-top: .5em;
}

.importfmea .alertNumber {
  min-width: .5rem;
  width: 1rem;
  height: 1rem;
  right: .9rem;
  font-size: .5rem;
}

.failuremodulePage .btn svg {
  margin-right: 0.5rem;
}

.failuremodulePage .importExcelBtn {
  width: 100%;
  padding: 0;
}

.failuremodulePage .importExcelBtn .btn {
  margin-left: 1.66rem;
}

.excelimportpopup .filePicker input::placeholder,
#edit-notification-group-fields input::placeholder {
  color: var(--primary-font-color) !important;
  opacity: .6 !important;
}

.failuremodulePage .add-casual-paramater-button .link svg {
  width: 1.5rem !important;
  height: 1.5rem;
  position: relative;
  top: 0.35rem;
}

.failuremodulePage .add-casual-paramater-button .link svg path {
  stroke: var(--primary-font-color) !important;
}

.failuremodulePage .add-casual-paramater-button .link:hover svg path {
  stroke: var(--primary) !important;
}

.failuremodulePage .add-casual-paramater-button .link {
  padding: 0;
  margin: 0;
  position: relative;
  bottom: 0.5rem;
}

.failuremodulePage .edit svg {
  width: 1.1rem;
}

.MuiInputBase-root input[value=""]::placeholder,
.failuremodulePage .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child::placeholder {
  color: #8f8f8f !important;
  font-size: 0.83rem;
  font-family: "Montserrat-Regular";
  opacity: 1 !important;
}

.ant-drawer-close {
  margin-right: 0;
}

.failuremodulePage .noHistoryAlarms {
  height: calc(100vh - 14rem);
}

.failuremodulePage .alertDivs {
  height: calc(100vh - 18.1rem);
}

.failuremodulePage .alertDivsBg .float-container {
  margin: 0;
}

.failuremodulePage #FailureModeListing .alarmBar.padTopOnly.row.dropRow {
  padding-left: 0;
  padding-right: 0;
}

.failuremodulePage #FailureModeListing .contentWrap.monitoringcontentWrap {
  margin-right: 0;
  width: 100%;
}

.failuremodulePage .contentWrap.monitoringcontentWrap {
  width: calc(100% - 1.3rem);
}

.failuremodulePage #FailureModeListing svg path {
  fill: none;
}

.failuremodulePage .alertDivs .alarmRows span.row-0,
.failuremodulePage .alertDivs .headerTable span.row-0 {
  margin-left: 0.83rem;
}

.failuremodulePage .font10+svg {
  margin-left: 0.2rem;
}

/* ========= configure threshold  ========= */
/* .failuremodulePage .threshold-row1 {
  width: 16.66% !important;
  display: block !important;
  float: left !important;
  text-align: left !important;
  margin-left: 0.83rem;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.failuremodulePage .threshold-row1 .failure-mode-listing-desc {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 0.4rem;
  padding-top: 0.4rem !important;

}

.failuremodulePage .threshold-row1 p.failure-mode-listing-sub-desc {
  font-size: 0.7rem;
  font-family: "Montserrat-Light";
}

.failuremodulePage .threshold-row2  {
  width: 16.66% !important;
  display: block !important;
  float: left !important;
  text-align: left !important;
}
.failuremodulePage .threshold-row3  {
  width: 16.66% !important;
  display: block !important;
  float: left !important;
  text-align: left !important;
}
.failuremodulePage .threshold-row4  {
  width: 16.66% !important;
  display: block !important;
  float: left !important;
  text-align: left !important;
}
.failuremodulePage .threshold-row5  {
  width: 16.66% !important;
  display: block !important;
  float: left !important;
  text-align: left !important;
}
.failuremodulePage .threshold-row3  {
  width: 16.66% !important;
  display: block !important;
  float: left !important;
  text-align: left !important;
} */
.selct{
  width: 100%;
  background-color: transparent;
  font-size: 0.83rem;
  height: 2.66rem;
  /* border: */

}
.selct option{
  background-color: #252525;
  padding: 5px;
  font-size: 0.83rem !important;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
