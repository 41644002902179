.alarmToolTip {
    position: relative;
    display: inline-block;
    visibility: visible;
    top: 0.25rem;
    overflow: visible;
  }
  
  .icoinInfo {
    position: relative;
    top: 0.16rem;
    left: 0.5rem;
  }
  
  .refreshDiv {
    float: right;
    display: inline-flex;
    flex-direction: row-reverse;
    margin-right: 3.75rem;
  }
  
  .refreshBtn {
    color: #5d97f6;
    background: #2f2f2f;
  }
  
  .refreshBtn.font10,
  .refreshBtn.font10:active,
  .refreshBtn.font10:focus {
    color: #5d97f6;
  }
  
  .refreshBtn.font10:hover {
    color: #5d97f6;
  }
  
  .alarmText {
    margin-left: 0.66rem;
  }
  
  #events .alarmText {
    margin-left: 0.33rem;
  }
  
  .alarmBar.padTopOnly.row.dropRow {
    padding-top: 1.16rem;
  }
  
  .OnlyAlarmTxt .alarmText {
    position: relative;
    top: 0;
  }
  
  .activeAlarmCount {
    width:auto;
    height: 2rem;
    border-radius: 0.33rem;
    background: #3F4959;
    color: #e0e0e0;
    font-size: 1rem;
    text-align: center;
    padding: 0.33rem .66rem;
    margin-left: 0.4rem;
    font-family: Montserrat-semibold;
  }
  
  .light .activeAlarmCount {
    background: #cbd5e3;
    color: #3b414b;
  }
  
  .alertIcon {
    padding-left: 1.4rem;
    position: relative;
  }
  
  .alertIcon svg {
    height: 1.5rem;
    width: 1.5rem;
  }
  .inputCheck,
  .inputDate {
    margin-top: 3.25rem;
  }
  
  .inputDate label {
    margin-top: 0.66rem;
  }
  
  .inputCheck label {
    margin-left: 0.83rem;
  }
  
  .dateVal {
    margin-top: 0.83rem;
  }
  
  .alarmToolTip .alarmToolTipText,
  .createAlertToolTipText {
    background-color: #000;
    color: #e0e0e0;
    text-align: center;
    font-size: 0.83rem;
    border-radius: 0.5rem;
    font-family: "Montserrat-Regular";
    position: absolute;
    z-index: 1;
    left: 8.33rem;
    margin-left: -8.83rem;
  }
  
  .alarmToolTip .alarmToolTipText {
    visibility: hidden;
    width: 18.75rem;
    padding: 0.41rem 1.66rem;
  }
  
  .createAlertToolTipText {
    visibility: visible;
    width: 15.41rem;
    padding: 0.41rem 0.58rem;
  }
  
  .light .alarmToolTip .pageToolTipText,
  .light .createAlertToolTipText,
  .light .alarmToolTip .alarmToolTipText {
    background-color: #fff;
    color: #3b414b;
  }
  
  .alarmToolTip:hover .alarmToolTipText {
    visibility: visible;
  }
  
  .alarmToolTip .pageToolTipText {
    visibility: hidden;
    width: 5rem;
    background-color: #000;
    color: #e0e0e0;
    text-align: center;
    border-radius: 0.33rem;
    padding: 0.41rem 0;
    position: absolute;
    z-index: 1;
    bottom: 100%;
    margin-left: -2.5rem;
    font-size: 0.83rem;
    font-family: "Montserrat-Regular";
  }
  
  .alarmToolTip:hover .pageToolTipText {
    visibility: visible;
  }
  
  .alarmToolTip .machineToolTipText {
    visibility: hidden;
    background-color: #404243;
    color: #e0e0e0;
    text-align: center;
    border-radius: 0.5rem;
    padding: 1.25rem 0.83rem 0;
    font-family: Montserrat-Regular;
    font-size: 1rem;
    position: absolute;
    z-index: 999;
    top: 2rem;
    width: 33.33rem;
  }
  
  .alarmToolTip .machineToolTipText:after {
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 14px solid #000;
    position: absolute;
    content: "";
    top: -0.66rem;
    left: 0.33rem;
  }
  
  .light .tooltipBeta:after,
  .light .alarmToolTip .machineToolTipText:after {
    border-bottom: 14px solid #fff;
  }
  
  .light .alarmToolTip .machineToolTipText {
    background-color: #fff;
    color: #3b414b;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  }
  
  .alarmToolTip .machineToolTipText .row {
    margin-bottom: 0.6rem;
    width: 33.33rem;
    text-align: left;
  }
  
  .alarmToolTip .machineToolTipText .left {
    width: 12.5rem;
  }
  
  .alarmToolTip .machineToolTipText .right {
    width: calc(100% - 12.5rem);
  }
  
  .alarmToolTip:hover .machineToolTipText {
    visibility: visible;
  }
  
  .alarmToolTip .machineToolTipText .left,
  .alarmToolTip .graphToolTipText .left {
    color: #a0a0a0;
  }
  
  .light .alarmToolTip .graphToolTipText .left,
  .light .alarmToolTip .machineToolTipText .left {
    color: #58585a;
  }
  
  .alarmToolTip .graphToolTipText {
    visibility: hidden;
    background-color: #000;
    color: #e0e0e0;
    text-align: left;
    border-radius: 0.5rem;
    padding: 1.25rem 0.83rem 0.66rem 0.83rem;
    font-family: Montserrat-Regular;
    font-size: 1rem;
    position: absolute;
    z-index: 1;
    top: 98%;
    right: 0;
    left: 0;
    width: 15rem;
    margin: 0 auto;
  }
  
  .alarmToolTip .graphToolTipText::after {
    content: " ";
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -0.41rem;
    border-width: 0.41rem;
    border-style: solid;
    border-color: transparent transparent #000 transparent;
  }
  
  .light .alarmToolTip .graphToolTipText::after {
    border-color: transparent transparent #fff transparent;
  }
  
  .light .alarmToolTip .graphToolTipText {
    background-color: #fff;
    color: #3b414b;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  }
  
  .graphToolTipText .row {
    margin-left: -0.83rem;
    margin-right: -0.83rem;
  }
  
  .graphToolTipText .col-md-4,
  .graphToolTipText .col-md-8 {
    padding-left: 0.83rem;
    padding-right: 0.83rem;
  }
  
  .alarmToolTip .graphToolTipText .row {
    margin-bottom: 0.66rem;
    width: 100%;
    text-align: left;
  }
  
  .alarmToolTip:hover .graphToolTipText {
    visibility: visible;
  }
  
  .alarmTitle {
    color: #e0e0e0;
    font-family: Montserrat-Medium;
    font-size: 0.83rem;
    letter-spacing: 0.004em;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
  .alarmData .alarmTitle {
    font-size: 1rem;
  }
  .alarmRows span.row0 svg{
    width: 0.83rem;
    height: 0.5rem;
  }
  .light .alarmTitle {
    color: #3b414b;
  }
  
  .noHistoryAlarms {
    background-color: #252525;
    color: #e0e0e0;
    height: 100%;
    padding-top: 0%;
    text-align: center;
    border-radius: 0 0 4px 4px;
    font-size: 1rem;
    line-height: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  
  .light .noHistoryAlarms {
    background-color: #ffffff;
    color: #3b414b;
  }
  
  .heightNoRecord.noHistoryAlarms {
    height: calc(100vh - 17.58rem);
    display: flex;
    padding: 0;
    width: 100%;
  }
  
  .heightNoRecord.noHistoryAlarms.SettingModalLoader {
    height: calc(100vh - 15rem);
  }
  
  .heightNoRecord.noHistoryAlarms.centerTxt {
    justify-content: center;
    align-items: center;
  }
  
  .alertIcon svg:hover path,
  .icoinInfo:hover path,
  .icoinInfo:hover path.infoIcons1,
  .alertIcon svg:hover path.pathInfo1 {
    stroke: #5d97f6;
    fill: #5d97f6;
  }
  .light .alertIcon svg:hover path,
  .light .icoinInfo:hover path,
  .light .icoinInfo:hover path.infoIcons1,
  .light .alertIcon svg:hover path.pathInfo1 {
    stroke: #4285F4;
    fill: #4285F4;
  }
  .alertIcon svg:hover path.pathInfo2 {
    stroke: rgb(31, 30, 30);
    fill: #5d97f6;
  }
  .icoinInfo:hover path.infoIcons2,
  .icoinInfo:hover path.infoIcons3 {
    stroke: #ffffff;
    fill: none;
  }
  .light .alertIcon svg:hover path.pathInfo2,
  .light .pathInfo0:hover path.pathInfo3{
    stroke: #ffffff;
    fill: #ffffff;
  }
  .noRecords {
    color: #e0e0e0;
    text-align: center;
  }
  
  .activeAlarmHolder {
    height: 2.16rem;
  }
  
  .activeAlarmHolder .alarmText {
    margin-bottom: 0.33rem;
  }
  
  .alarmFilter {
    padding-left: 2.66rem;
  }
  
  .alarmTime {
    color: #e0e0e0;
    font-family: Montserrat-Regular;
    font-size: 0.83rem;
  }
  
  .light .alarmTime {
    color: #3b414b;
  }
  
  .alarmSlider {
    padding: 0.16rem 0;
    height: 6.66rem;
  }
  
  .alarmSettingHolder {
    color: #5d97f6;
    font-size: 1rem;
    letter-spacing: 0.004em;
    line-height: 1rem;
    align-items: center;
  }
  
  .light .alarmSettingHolder {
    color: #4285f4;
  }
  
  .alarmData {
    border-radius: 4px;
  }
  
  .alarmMarqueeCard a:hover {
    text-decoration: none;
  }
  
  .alertPagination .pagination-tab, .pagination-tab .pagination, .pagination-tab .navArrow, .pagination > li .alarmToolTip,
  .activeAlarmHolder, .alarmFilter {
    display: flex;
    align-items: center;
  }
  .pagination-tab.text-center {
    place-content: center;
  }
  .pagination > li {
    margin: 0 0.2rem;
  }
  .pagination > li .alarmToolTip {
    position: static;
  }
  .pagination > li > span:not(:last-child) {
    margin: 0 0.5rem;
  }
  .pagination-tab .pagination button {
    border: none;
    outline: none;
    background-color: transparent;
  }
  