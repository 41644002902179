.tableBox {
  margin-top: 10px;
  height: calc(100vh - 180px);
  overflow-y: auto;
  overflow-x: hidden;
}

.table {
  width: 100%;
  /* border-collapse: inherit; */
  /* border-spacing: 0 10px; */
  /* margin-top: 10px; */
  padding: 0 20px 10px 0px;
  margin-top: 10px;
}

.btn {
  height: 40px;
  margin-bottom: 10px;
  font-size: 12px;
  width: calc(100% - 20px);
}

.thead {
  background: #1f1f1f;
  /* border: 1px solid #494949; */
  border-radius: 5px !important;
  position: sticky;
  z-index: 9999;
}

.thead .th {
  font-family: "Montserrat", "Helvetica", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.4px;
  color: #ffffff;
  background: #1f1f1f;
}

.thead .th:first-child {
  border-bottom-left-radius: 5px !important;
  border-top-left-radius: 5px !important;
}

.thead .th:last-child {
  border-bottom-right-radius: 5px !important;
  border-top-right-radius: 5px !important;
}

.td div {
  font-family: "Montserrat", "Helvetica", sans-serif !important;
  font-style: normal;
  font-size: 13px;
  font-weight: 400 !important;
  color: #ffffff;
}

.th {
  padding: 10px;
  text-align: center;
  border: 1px solid #494949 !important;
}

.td {
  text-align: center;
  background: #2d2d2d;
  border: 1px solid #5d5d5d !important;
}

.ParamName {
  text-align: Left;
  padding-left: 20px;
  background: #2d2d2d;
  border: 1px solid #5d5d5d !important;
}

.capsule {
  padding-left: 10px;
}

.th {
  font-weight: bold;
}

.tr:hover {
  cursor: pointer;
  padding: 100px !important;
}

.tbody tr td {
  border: 1px solid black;
  margin-top: 10px !important;
  height: 50px !important;
  background-color: #434343;
}

.td:first-child {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.td:last-child {
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
}

.td div p {
  height: 30px;
  padding-top: 12px;
}

.label {
  font-weight: 600;
  color: #c3c3c3;
  font-size: 13px !important;
  font-family: "Montserrat", "Helvetica", sans-serif !important;
}

.td input {
  background-color: #252525;
  border: none;
  width: 100%;
  height: 38px;
  padding: 0 10px;
  border-radius: 5px;
}

.td input:focus {
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px rgb(102 175 233 / 60%);
}

.input input:first-child {
  margin-bottom: 5px;
}

.tbody {
  height: 200px;
}
.subHead {
  font-family: "Montserrat", "Helvetica", sans-serif !important;
  font-style: normal;
  font-size: 13px;
  font-weight: 600 !important;
  font-style: italic;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 30px;
  /* border: 1px solid red; */
}
.hint {
  color: #a9a9a9; /* Gray color */
  font-size: 10px;
  margin-top: 5px;
  font-style: italic;
  font-weight: normal;
  font-family: "Montserrat", "Helvetica", sans-serif !important;
}

.table tbody tr:nth-child(even){
  border-spacing: 0!important;
  border-collapse: inherit;
  margin-bottom: 10px;
  border-bottom: 10px solid #1f1f1f;
}
.thbr {
  border-right: 2px solid #494949 !important;
}

.errmsg{
  color: #e57777;
}