
.popup{
    position: fixed;
    top: 35%;
    left: 50%;
    transform: translate(-50%,-35%);
    background-color: var(--primary-color);
    padding: 1.5rem;
    color: var(--white);
    min-width: fit-content;
    width: 30%;
    box-shadow: var(--shadow);
    z-index: 3;
  }
  .popupHeader{
    display: flex;
    justify-content: flex-end;
  }
  .popupClose{
    font-size: 1.5rem;
    cursor: pointer;
  }
  .popupTitle{
    display: block;
    text-align: center;
    font-size: 1.6rem;
    font-weight: 700;
  }
  .popupData{
    text-align: center;
    font-size: 1.2rem;
    display: block;
    margin-top: 1rem;
  }
  .popupOk{
    display: block;
    margin: auto;
    margin-top: 2rem;
    width: fit-content;
    padding: 0.6rem 3rem;
    font-size: 1.2rem;
    background-color: var(--sky-blue);
    color: var(--white);
    border: 1px solid var(--sky-blue);
    font-family: "Montserrat-Medium";
    border-radius: 0.2rem;
    outline: none;
    cursor: pointer;
  }
  .popupBody{
    margin-top: 2rem;
  }
  .BGlayer{
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: var(--bg-layer-color);
    z-index: 2;
  }